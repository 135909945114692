

















































































































import chipplyIcons from "@/chipply/ImportIcons";
import ICountryDto from "@/chipply/interface/ICountryDto";
import IStateDto from "@/chipply/interface/IStateDto";
import { IAddress, WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        address: Object,
        disabled: Boolean,
        enableAutocomplete: Boolean,
        isRequired: Boolean,
        warning: String,
    },
})
export default class CAddressEditor extends Vue {
    @Prop({
        default: false,
        type: Boolean,
    })
    public requireZip!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public includeCounty!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideName!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideCompanyName!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public requireCityState!: boolean;

    public address!: IAddress | null;
    public states: IStateDto[] = [];
    public countries: ICountryDto[] = [];
    public isRequired!: boolean;
    public chipplyIcons = chipplyIcons;

    public async created() {
        this.countries = await WebHelper.getJsonData("/api/List/Countries", false);
        this.states = await WebHelper.getJsonData("/api/List/States", false);
    }

    public get computedStates() {
        if (this.address && this.address!.country) {
            return this.states.filter((x) => x.country === this.address!.country);
        }
        return this.states;
    }

    protected validateAddressField(value: string, fieldName: string) {
        const isCityOrState = fieldName === "city" || fieldName === "state";

        if (isCityOrState && !this.requireCityState && !this.isRequired) {
            return true;
        } else if (!isCityOrState && !this.isRequired) {
            return true;
        }

        if (value) {
            return true;
        }
        return "You must enter a value";
    }

    protected validateZipField(value: string) {
        if (!this.isRequired && !this.requireZip) {
            return true;
        }

        if (value) {
            return true;
        }

        return "You must enter a value";
    }
}
