import { ITextValue, Utils } from "chipply-common";
import { ChangeAccountPasswordViewModel } from "./ChangeAccountPasswordViewModel";
import EditAccountProfileViewModel from "./EditAccountProfileViewModel";
import UserLevel from "../UserLevel";
import ICompanyUserModel from "./ICompanyUserModel";
import { EventBus } from "../EventBus";

export default class EditUserViewModel extends EditAccountProfileViewModel {
    public defaultUserLevels = Utils.convertEnumToArray<object, number>(UserLevel);
    public filteredUserLevels: ITextValue<number>[] = [];
    public newPasswordViewModel!: ChangeAccountPasswordViewModel;
    public title = "Edit User";

    public constructor() {
        super();
        const newPasswordViewModel = new ChangeAccountPasswordViewModel();
        newPasswordViewModel.newPasswordTitle = "Password";
        newPasswordViewModel.confirmNewPasswordTitle = "Confirm Password";
        newPasswordViewModel.shouldValidateOriginalPassword = false;
        this.newPasswordViewModel = newPasswordViewModel;

        this.filteredUserLevels = this.defaultUserLevels;
    }

    protected createChangePasswordViewModel(): ChangeAccountPasswordViewModel {
        const vm = super.createChangePasswordViewModel();
        vm.shouldValidateOriginalPassword = false;
        vm.title = "Reset Password";
        return vm;
    }

    public isUserNameReadOnly(sessionUserLevel: UserLevel) {
        if (sessionUserLevel === UserLevel.Admin) {
            return false;
        } else {
            if (this.userId > 0) {
                return true;
            } else {
                // allow edit for new user
                return false;
            }
        }
    }

    protected toDto() {
        const dto = super.toDto();
        if (this.userId > 0) {
            return dto;
        }
        return { password: this.newPasswordViewModel.newPassword, ...dto };
    }

    protected update(model: ICompanyUserModel) {
        super.update(model);
        this.allowChangePassword = this.userLevelId <= UserLevel.DealerSalesperson;
    }

    public async saveAccount() {
        if (this.userId > 0) {
            return await super.saveAccount();
        } else {
            const baseUrl = `/api/Company/AddCompanyUser/`;
            const result = await this.saveAccountCore(baseUrl);
            if (result) {
                this.userId = result.userId;
                this.title = "Edit User";
            }
            return result;
        }
    }
}
