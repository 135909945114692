
























































































































































































































































































































































































import chipplyIcons from "@/chipply/ImportIcons";
import Component from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import EditOrganizationContact from "@/components/organization/EditOrganizationContact.vue";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import CEditButton from "../ui/CEditButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CDeleteButton from "../ui/CDeleteButton.vue";
import CAddressEditor from "../ui/CAddressEditor.vue";
import Validation from "@/validation";
import EditContact from "@/components/EditContact.vue";
import { mask } from "vue-the-mask";
import { Prop, Watch } from "vue-property-decorator";
import FormValidationMixin from "../FormValidationMixin";
import { mixins } from "vue-class-component";
import EditInfoViewModel from "@/chipply/dealer/EditInfoViewModel";
import { EventBus } from "@/chipply/EventBus";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { AsyncInteractionViewModel } from "chipply-common";
import CSaveDialog from "../dialogs/CSaveDialog.vue";

@Component({
    components: {
        EditLayout,
        CToolbarBtn,
        CEditButton,
        CAddButton,
        CDeleteButton,
        CAddressEditor,
        EditOrganizationContact,
        EditContact,
        CSaveDialog,
    },
    directives: {
        mask,
    },
})
export default class EditInfo extends mixins(FormValidationMixin) {
    public icons = chipplyIcons;
    public Validation = Validation;
    public vm = new EditInfoViewModel();
    public isCompanyValid = false;
    public isBillingValid = false;
    public isDisplayNameValid = false;

    public get isDisabled() {
        return !(this.isCompanyValid && this.isBillingValid);
    }

    public get isSaveDisabled() {
        return !this.isDisplayNameValid;
    }

    @Prop({
        type: Boolean,
    })
    public isAdmin!: boolean;

    @Prop({
        type: Number,
    })
    public companyId!: number;

    @Prop({
        type: Boolean,
    })
    public addNewDealer!: boolean;

    @Prop({
        type: Boolean,
    })
    public addNewOrg!: boolean;

    public async created() {
        this.vm.companyId = this.companyId;
        this.vm.isAdmin = this.isAdmin;
        this.vm.addNewDealer = this.addNewDealer;
        this.vm.addNewOrg = this.addNewOrg;
        await this.vm.list(this.companyId);

        if (this.vm) {
            EventBus.$on("top-navigation-started", (location: string, eventArgs: IAsyncEventArgs) => {
                if (!this.vm.hasChanges()) {
                    return;
                }
                const promise = new Promise<void | boolean>(async (resolve, reject) => {
                    this.vm.confirmViewModel = new AsyncInteractionViewModel();
                    const result = await this.vm.confirmViewModel.interact();
                    if (result === "cancel") {
                        eventArgs.cancel = true;
                        resolve(eventArgs.cancel);
                        this.vm.confirmViewModel = null;
                    } else if (result === "continue") {
                        resolve();
                    }
                });
                eventArgs.promises.push(promise);
            });
        }
    }

    public beforeDestroy() {
        EventBus.$off("top-navigation-started");
    }

    public get websitePrefixErrors() {
        if (!this.vm.items.websitePrefix) {
            return "";
        }
        return this.vm.websitePrefixIsValid ? "" : "This prefix is already in use.";
    }

    public validateDisplayName(value: string) {
        if (value) {
            if (this.vm.isModifyingSources) {
                for (const source of this.vm.sources) {
                    if (source.displayName.toLowerCase() == value.toLowerCase()) {
                        return "Display name is already in use.";
                    }
                }
            } else {
                for (const group of this.vm.groups) {
                    if (group.displayName.toLowerCase() == value.toLowerCase()) {
                        return "Display name is already in use.";
                    }
                }
            }
            return true;
        }
        return "You must enter a name.";
    }
}
