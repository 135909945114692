



































import { Vue, Component, Prop } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import { RowActionMethod } from "@/chipply/RowActionMethod";
import { IRowActionMenuItem } from "@/chipply/interface/IRowActionMenuItem";
import { IRowActionMenuArgs } from "@/chipply/interface/IRowActionMenuArgs";
@Component({})
export default class CRowActionMenu extends Vue {
    @Prop({ type: Array })
    public menuItems!: IRowActionMenuItem[];

    @Prop({ type: Object })
    public listLayoutItem!: any;

    public chipplyIcons = chipplyIcons;
    public isOverlayActive = false;
    public isOverlayEntered = false;

    public listStyleTop = "";
    public listStyleLeft = "";
    public listStyleBottom = "";

    public $refs!: {
        optionsButton: any;
    };

    public optionsButtonClicked() {
        const optionsButtonTop = this.$refs.optionsButton!.$el.getBoundingClientRect().top;
        const optionsButtonLeft = this.$refs.optionsButton!.$el.getBoundingClientRect().left;
        this.listStyleTop = optionsButtonTop > document.body.clientHeight - 250 ? "" : optionsButtonTop + "px";
        this.listStyleLeft = optionsButtonLeft + "px";
        this.listStyleBottom =
            optionsButtonTop > document.body.clientHeight - 250
                ? document.body.clientHeight - optionsButtonTop - 32 + "px"
                : "";
        this.isOverlayActive = !this.isOverlayActive;
        this.isOverlayEntered = false;
    }

    public menuItemClicked(methodName: RowActionMethod) {
        const args: IRowActionMenuArgs = { listLayoutItem: this.listLayoutItem, methodName: methodName };
        this.isOverlayActive = !this.isOverlayActive;
        this.isOverlayEntered = false;
        this.$emit("rowActionListClick", args);
    }

    public delayedCloseOverlay() {
        setTimeout(() => {
            if (!this.isOverlayEntered) this.isOverlayActive = false;
        }, 3000);
    }
}
