















































































































































import Component, { mixins } from "vue-class-component";
import Vue from "vue";
import chipplyIcons from "@/chipply/ImportIcons";
import BrandingViewModel from "@/chipply/view-model/BrandingViewModel";
import DealerBrandingViewModel from "@/chipply/view-model/DealerBrandingViewModel";
import { Prop, Watch } from "vue-property-decorator";
import CategoryBrandingModel from "@/chipply/dealer/CategoryBrandingModel";
import DealerCategoriesViewModel from "@/chipply/dealer/DealerCategoriesViewModel";

@Component({
    components: {},
})
export default class SlideImageField extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Object,
    })
    public viewModel!: BrandingViewModel | DealerBrandingViewModel;

    @Prop({
        type: Boolean,
    })
    public dealerVersion!: boolean;

    public async uploadSlideOrLogo(type: string) {
        this.$emit("upload", type);
    }
}
