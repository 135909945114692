







































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { EcertEditViewModel } from "@/chipply/ecerts/EcertEditViewModel";
import CMoney from "@/components/ui/CMoney.vue";
import { Utils } from "chipply-common";
import Validation from "@/validation";

@Component({
    components: {
        CMoney,
        CDecisionCard,
    },
    props: {},
})
export default class EcertEditCard extends Vue {
    @Prop({
        type: Object,
    })
    public vm!: EcertEditViewModel;
    public Utils = Utils;
    public Validation = Validation;
}
