import IEventFeeModel from "@/chipply/event/IEventFeeModel";
import StoreFeeViewModel from "@/chipply/event/StoreFeeViewModel";
import { ITextValue } from "chipply-common";

export default class CustomerStoreFeeViewModel extends StoreFeeViewModel<CustomerStoreFeeViewModel> {
    public apply(vm: CustomerStoreFeeViewModel): void {
        Object.assign(this, vm);
    }

    public createEditViewModel(): CustomerStoreFeeViewModel {
        return new CustomerStoreFeeViewModel(this.toModel(), this.allFeeTypes);
    }

    public toModel(): IEventFeeModel {
        return {
            eventFeeTypeId: this.eventFeeTypeId,
            feeCost: this.feeCost,
            feeId: this.feeId,
            feeName: this.feeName,
            processId: 0,
        };
    }

    public get feeTypes(): Array<ITextValue<number>> {
        const filteredFeeTypes: Array<ITextValue<number>> = [];
        for (const ft of this.allFeeTypes) {
            if (ft.chargeToType == 2) {
                filteredFeeTypes.push({
                    value: ft.feeTypeId,
                    text: ft.feeTypeName,
                });
            }
        }

        return filteredFeeTypes;
    }
}
