import { IStoreArtworkVariationModel } from "./IStoreArtworkVariationModel";
import { IArtworkVariationStandardColor } from "@/chipply/event/artworks/IArtworkVariationStandardColor";

export class ArtworkVariationItemViewModel {
    storeArtworkID = 0;
    id = 0;
    displayName = "";
    variationName = "";
    standardColorIds: number[] = [];
    standardColorDisplay = "";
    workOrderNotes = "";
    variationImage = "";
    variationImageUrl = "";
    public externalId = "";
    public quantityNeeded = 0;
    public quantityOrdered = 0;
    public additionalQuantity = 0;
    isPrimary = false;
    allowDelete = true;

    isReadingFile = false;
    formData: FormData | null = null;
    fileName = "No file chosen";
    variationPreview = "";

    constructor(dto?: IStoreArtworkVariationModel) {
        if (!dto) {
            return;
        }
        this.id = dto!.artworkVariationID;

        this.storeArtworkID = dto.artworkID;
        this.displayName = dto.displayName;
        this.variationImage = dto.variationImage;
        this.variationName = dto.variationName;
        this.workOrderNotes = dto.workOrderNotes;
        this.standardColorIds = dto.standardColorIds;
        this.variationImageUrl = dto.variationImageUrl;
        this.isPrimary = dto.isPrimary;
        this.externalId = dto.externalId;
        this.quantityNeeded = dto.quantityNeeded;
        this.quantityOrdered = dto.quantityOrdered;
        this.additionalQuantity = dto.additionalQuantity;
        this.allowDelete = dto.allowDelete;
    }

    public checkAllStandardColors = (standardColors: IArtworkVariationStandardColor[]) => {
        this.standardColorIds = [];
        for (const color of standardColors) {
            this.standardColorIds.push(color.standardColorId);
        }
    };

    public checkAllLightColors = (standardColors: IArtworkVariationStandardColor[]) => {
        this.standardColorIds = [];
        for (const color of standardColors) {
            if (color.isLight) {
                this.standardColorIds.push(color.standardColorId);
            }
        }
    };

    public checkAllDarkColors = (standardColors: IArtworkVariationStandardColor[]) => {
        this.standardColorIds = [];
        for (const color of standardColors) {
            if (!color.isLight) {
                this.standardColorIds.push(color.standardColorId);
            }
        }
    };

    convert(): IStoreArtworkVariationModel {
        const dto: IStoreArtworkVariationModel = {
            artworkVariationID: this.id,
            artworkID: this.storeArtworkID,
            displayName: this.displayName,
            externalId: this.externalId,
            variationName: this.variationName,
            variationImage: this.variationImage,
            standardColorIds: this.standardColorIds,
            workOrderNotes: this.workOrderNotes,
            variationImageUrl: this.variationImageUrl,
            isPrimary: this.isPrimary,
            quantityNeeded: this.quantityNeeded,
            quantityOrdered: this.quantityOrdered,
            additionalQuantity: this.additionalQuantity,
            allowDelete: this.allowDelete,
        };
        return dto;
    }
}
