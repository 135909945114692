
























import chipplyIcons from "@/chipply/ImportIcons";
import { ProductLayoutPageViewModel } from "@/chipply/process/ProductLayoutPageViewModel";
import EditLayout from "@/components/layout/EditLayout.vue";
import CArtworkSelect from "@/components/process/CArtworkSelect.vue";
import ProcessLayoutAddArtworkCard from "@/components/process/ProcessLayoutAddArtworkCard.vue";
import ProcessLayoutEditor from "@/components/process/ProcessLayoutEditor.vue";
import ProductLayoutEditor from "@/components/products/ProductLayoutEditor.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Validation from "@/validation";

@Component({
    components: {
        ProductLayoutEditor,
        ProcessLayoutEditor,
        ProcessLayoutAddArtworkCard,
        CAddButton,
        CArtworkSelect,
        CControlLabel,
        CDeleteButton,
        EditLayout,
        CToolbarBtn,
        CDecisionCard,
    },
    props: {
        eventId: Number,
    },
})
export default class StoreProductLayout extends Vue {
    public Validation = Validation;
    public chipplyIcons = chipplyIcons;

    public initLayout = false;

    @Prop({
        type: Number,
        default: 0,
    })
    public productId!: number;

    @Prop({
        type: Number,
        default: 0,
    })
    public defaultTab!: number;

    public vm = new ProductLayoutPageViewModel();

    public async created() {
        if (this.productId) {
            await this.initialize();
        }
    }

    public async initialize() {
        this.vm.initialize();
        this.vm.currentTab = this.defaultTab;
        await this.vm.get(this.productId);
        this.initLayout = true;
    }

    public async cancel() {
        await this.vm.cancel();
        this.$emit("cancel");
    }

    public async save() {
        await this.vm.save();
        this.$emit("accept");
    }
}
