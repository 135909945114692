import { CatalogEventBatchTypeEnum } from "@/chipply/event/CatalogEventBatchTypeEnum";
import { FieldRepositoryListResults } from "@/chipply/fields/FieldRepositoryListResults";
import ListUtils from "@/chipply/ListUtils";
import CatalogStoreSettingsPageModel from "@/chipply/store/settings/CatalogStoreSettingsPageModel";
import { CatalogStoreSettingsViewModel } from "@/chipply/store/settings/CatalogStoreSettingsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { typeDependencies } from "chipply-common";

@typeDependencies({
    types: {
        FieldRepositoryListResults,
    },
})
export default class CatalogStoreSettingsPageViewModel extends StoreSettingsPageViewModel {
    public store: CatalogStoreSettingsViewModel | null = null;

    public get saveDisabled() {
        return !(this.isValid && this.storeUrlValidation.valid && this.store!.distribution.isValid);
    }

    protected initializeSettingsViewModel(model: StoreSettingsPageModel): void {
        this.store = new CatalogStoreSettingsViewModel(model, this);
    }
}
