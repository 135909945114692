




















































































































































































import NavigationTopButton from "@/components/navigation/NavigationTopButton.vue";
import Vue from "vue";
import Component from "vue-class-component";
import Vuex from "vuex";
import { UrlUtils } from "chipply-common";
import * as userInfoStore from "@/store/UserInfoStore";
import { Watch } from "vue-property-decorator";
import UserLevel from "@/chipply/UserLevel";
import chipplyIcons from "@/chipply/ImportIcons";

Vue.use(Vuex);

@Component({
    components: { NavigationTopButton },
    props: {
        preventNavigation: Boolean,
        userLevel: Number,
    },
})
export default class NavigationTop extends Vue {
    public chipplyIcons = chipplyIcons;
    public name = "NavigationTop";
    public userLevel!: number;
    public userId!: number;
    public dealerId!: number;
    public isPremium = false;
    public $refs!: {
        mainMenuBlocker: HTMLDivElement;
    };

    public async created() {
        await userInfoStore.initialize(this.$store);
        this.userId = userInfoStore.getUserId(this.$store);
        this.dealerId = userInfoStore.getDealerId(this.$store);
        this.isPremium = userInfoStore.getIsPremium(this.$store);
    }
    public dashboardClicked() {
        window.location.assign("/ng/account-dashboard.html");
    }

    public myAccountClicked() {
        window.location.assign("/ng/edit-account-profile.html?v=" + Math.floor(Math.random() * 100000));
    }

    public organizationsClicked() {
        window.location.assign("/ng/list-organizations.html?v=" + Math.floor(Math.random() * 100000));
    }

    public storesClicked() {
        window.location.assign("/ng/store-and-catalog-batch.html?v=" + Math.floor(Math.random() * 100000));
    }

    public logoutClicked() {
        this.$emit("top-navigation-started", "/logout.aspx");
        window.location.assign("/logout.aspx");
    }

    public helpClicked() {
        this.$emit("top-navigation-started", "/help");
        window.open("https://chipply.ladesk.com/");
    }

    public chipplyLogoClicked() {
        if (this.userLevel === UserLevel.AdminProductManager || this.userLevel === UserLevel.Admin) {
            return;
        }
        this.$emit("top-navigation-started", "/");
        window.location.assign("/");
    }

    @Watch("preventNavigation")
    public preventNavigationChanged() {
        this.$refs.mainMenuBlocker.style.display = "";
    }

    public data() {
        return {
            UserLevel,
            userId: undefined,
            icons: chipplyIcons,
        };
    }

    public getVersionedLink(link: string) {
        return UrlUtils.getVersionedLink(link);
    }
}
