import { Address, IAddress } from "chipply-common";
import IPurchaseOrderTotals from "./IPurchaseOrderTotals";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import { PurchasingType } from "./PurchasingConstants";
import PurchaseOrderTotals from "./PurchaseOrderTotals";
import PurchaseOrderingShippingOptions from "./PurchaseOrderingShippingOptions";

export default class VendorPurchaseOrderHeader {
    public orderingShippingOptions: PurchaseOrderingShippingOptions = 0;
    public excludedWarehouseIds: number[] = [];
    public canExportPurchaseOrder = false;
    public canSelectWarehouse = false;
    public isCustomerPickup = false;
    public canOrderDigitally = false;
    public canCheckAvailability = false;
    public wasDigitalOrder = false;
    public wasManualOrder = false;
    public wasPullFromStockRequest = false;
    public totals: IPurchaseOrderTotals = new PurchaseOrderTotals();
    public shipTo: IAddress = new Address();
    public attention = "";
    public isPurchaseOrderInfoRequiredForPresubmit = false;
    public exceedsRowLimit = false;
    public vendorSettings: IVendorPurchaseOrderSettings | null = null;
    public vendorName = "";
    public purchaseOrderNumber = "";
    public dealerBranchId: number | null = null;
    public customerNumber = "";
    public shipEmail = "";
    public shipPhone = "";
    public type: PurchasingType | null = null;
    public purchaseOrderId: number | null = null;
}
