









import { Vue, Component, Prop } from "vue-property-decorator";
import { BatchSettingsNavigationDialogViewModel } from "@/chipply/store/batch/BatchSettingsNavigationDialogViewModel";
import { AsyncInteractionViewModel } from "chipply-common";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";

@Component({
    components: { CDecisionCard },
})
export default class BatchSettingsNavigationDialog extends Vue {
    @Prop({ type: Object })
    public vm!: BatchSettingsNavigationDialogViewModel;

    @Prop({ type: Boolean })
    public showDialog!: boolean;
}
