import IPurchaseOrderItemDetail from "./IPurchaseOrderItemDetail";
import IPurchasingLineItem from "./IPurchasingLineItem";

export type PurchasingAvailability = "Available" | "Unavailable" | "PartiallyAvailable" | "Unknown";

export const PurchaseOrderWarehouseJoinChar = ", ";

export interface IPurchaseOrderItemWarehouseOption {
    /**
     * Use warehouse Id from database instead of the SanMarWarehouse number for generic usage
     */
    warehouseId: number;
    warehouseName: string;
    ranking: number;
    stockQuantity: number;
    quantityToOrder: number;
}

export interface IPurchaseOrderLineItem extends IPurchasingLineItem {
    upc: string;
    id: string;
    color: string;
    size: string;
    style: string;
    sku: string;
    quantityToOrder: number;
    previousQuantityToOrder: number;
    availability: PurchasingAvailability;
    message: string;
    hasError: boolean;
    quantityOrdered: number;
    warehouse: string;
    closestWarehouseId: number;
    consolidatedWarehouseId: number;
    isFulfilled: boolean;
    productCost: number;
    totalCost: number;
    vendorId: number;
    vendorName: string;
    chipplyProductColorSizeId: number | null;
    eventProductColorSizeId: number | null;
    quantity: number;
    productName: number;
    shortDescription: number;
    colorCode: string;
    baseSize: string;
    productPrice: number;
    feedsInventory: number;
    updatedOn: string;
    selectedDetails: IPurchaseOrderItemDetail[];
    orderItemId: number | null;
    processHeader: string | null;
    saleOrder: string | null;
    processName: string | null;
    processId: number | null;
    wasManuallyOrdered: boolean;
    warehouseOptions: IPurchaseOrderItemWarehouseOption[];
}
