import { AsyncInteractionViewModel } from "chipply-common";

export class NexusTaxMessageCardViewModel extends AsyncInteractionViewModel {
    public dealerId = 0;
    public showCheckNexusSettingsMessage = false;
    public showDisabledTaxMessage = false;
    public state = "";
    public header1 = "Branch Added";
    public header2 = "Branch Disabled";

    public get nexusPageHref() {
        return `/ng/dealer-nexus.html?dealerid=${this.dealerId}`;
    }
}
