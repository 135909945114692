































import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import ChangeAccountPassword from "./ChangeAccountPassword.vue";
import FormValidationMixin from "../FormValidationMixin";
import Validation from "@/validation";
import EditUserViewModel from "@/chipply/dealer/EditUserViewModel";
import UserLevel from "@/chipply/UserLevel";
import EditAccountProfile from "./EditAccountProfile.vue";
import UserInfoMixin from "../page/UserInfoMixin";
import { isBoolean } from "lodash";

@Component({
    components: {
        EditAccountProfile,
        ChangeAccountPassword,
    },
    computed: {
        isUserNameReadOnly() {
            const me = this as EditUser;
            return me.vm.isUserNameReadOnly(me.userInfo.userLevel);
        },
        isEditModel() {
            const me = this as EditAccountProfile;
            return me.vm.userId > 0;
        },
    },
})
export default class EditUser extends mixins(FormValidationMixin, UserInfoMixin) {
    public name = "EditUser";
    public Validation = Validation;

    declare isEditModel: boolean;
    declare isUserNameReadOnly: boolean;

    @Prop({
        type: Object,
        default: () => new EditUserViewModel(),
    })
    public vm!: EditUserViewModel;

    @Prop({
        type: Number,
    })
    public userId!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    @Prop({
        type: isBoolean,
    })
    public isDealerAdminScreen!: boolean;

    mounted() {
        this.vm.isDealerAdminScreen = this.isDealerAdminScreen;
    }

    @Watch("vm.newPasswordViewModel.isValidAccountPassword")
    public updateIsValidPassword() {
        if (this.vm.newPasswordViewModel) {
            this.vm.isValidAccountPassword = this.vm.newPasswordViewModel.isValidAccountPassword;
        }
    }
}
