












































































































import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import ListLayout from "@/components/layout/ListLayout.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CDeleteDialog from "../../dialogs/CDeleteDialog.vue";
import { SearchStoreArtworksViewModel } from "@/chipply/event/artworks/SearchStoreArtworksViewModel";
import CDate from "@/components/ui/CDate.vue";
import CApiAutoComplete from "@/components/ui/CApiAutoComplete.vue";
import { Autocompletes } from "@/chipply/Autocompletes";
import Vue from "vue";

@Component({
    components: {
        CApiAutoComplete,
        ListLayout,
        CToolbarBtn,
        CEditButton,
        CDeleteDialog,
        CDate,
    },
})
export default class ArtworkFilters extends Vue {
    public Autocompletes = Autocompletes;

    public icons = chipplyIcons;

    @Prop({ type: Object })
    public vm!: SearchStoreArtworksViewModel;
}
