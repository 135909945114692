import OrderItemOptionViewModel from "@/chipply/ecom-orders/OrderItemOptionViewModel";
import OrderItemSizeViewModel from "@/chipply/ecom-orders/OrderItemSizeViewModel";
import EditableViewModel from "@/chipply/EditableViewModel";
import { IEdit, IOrderItem, IOrderItemColor, TaxType } from "chipply-common";

export default class OrderItemViewModel extends EditableViewModel implements IEdit<OrderItemViewModel> {
    public eventProductId!: number;
    public orderItemId!: number;

    public billFirstName!: string;

    public billLastName!: string;
    public designName: string | null = null;
    public colors!: IOrderItemColor[];
    public lowerQuantityBounds!: number;
    public options!: OrderItemOptionViewModel[];
    public orderNumber!: number;
    public productName!: string;
    public processName!: string;
    public _selectedColor!: number;
    public selectedQty!: number;
    public _selectedSize!: number;
    public selectedTaxType!: TaxType;
    public sellPrice!: number;
    public fundraising!: number;
    public processCost!: number;
    public productPrice!: number;
    public productCost!: number;
    public sizes!: OrderItemSizeViewModel[];
    public taxRate!: number | undefined;
    public upperQuantityBounds!: number;
    public imageUrl!: string;
    public color!: string;
    public size!: string;
    public sizingChart!: string;
    public description!: string;
    public qty!: number;
    public taxType!: TaxType;
    public internalNote!: string;
    public backorderNote!: string;
    public hideOptions!: boolean;
    public hideVendor!: boolean;
    public vendorName!: string;
    public hasPurchaseOrderItem!: boolean;
    public purchaseOrderColor!: string;
    public purchaseOrderSize!: string;
    public hideProductName!: boolean;
    public hideStyle!: boolean;

    public get individualItemTotal() {
        return this.sellPrice + this.sizePrice + this.optionsPrice;
    }
    public get itemTotal() {
        return this.selectedQty * this.individualItemTotal;
    }
    public optionsPrice!: number;
    public sizePrice!: number;
    public name!: string;
    public style!: string;

    constructor(orderItem: IOrderItem) {
        super();
        Object.assign(this, orderItem);

        const options = [];
        for (const option of orderItem.options) {
            options.push(new OrderItemOptionViewModel(option));
        }
        this.options = options;

        const sizes = [];
        for (const size of orderItem.sizes) {
            sizes.push(new OrderItemSizeViewModel(size));
        }
        this.sizes = sizes;
    }

    set selectedSize(size: number) {
        this._selectedSize = size;
        this.updateSize();
    }

    get selectedSize() {
        return this._selectedSize;
    }

    set selectedColor(color: number) {
        this._selectedColor = color;
        this.updateColor();
    }

    get selectedColor() {
        return this._selectedColor;
    }

    public updateColor() {
        for (const color of this.colors) {
            if (color.colorId !== this.selectedColor) {
                continue;
            }
            this.imageUrl = color.image1;
            this.color = color.colorName;
        }
    }

    public updateSize() {
        for (const size of this.sizes) {
            if (size.sizeId !== this.selectedSize) {
                continue;
            }
            this.size = size.sizeName;
        }
    }

    public createEditViewModel(): OrderItemViewModel {
        const data = JSON.parse(JSON.stringify(this));
        return new OrderItemViewModel(data);
    }

    public apply(vm: OrderItemViewModel): void {
        Object.assign(this, vm);
    }

    public toModel(): IOrderItem {
        const sizes = [];
        for (const size of this.sizes) {
            sizes.push(size.toModel());
        }

        const options = [];
        for (const option of this.options) {
            options.push(option.toModel());
        }

        return {
            backorderNote: this.backorderNote,
            billFirstName: this.billFirstName,
            billLastName: this.billLastName,
            colors: this.colors,
            description: this.description,
            designName: this.designName,
            eventProductId: this.eventProductId,
            fundraising: this.fundraising,
            hideOptions: this.hideOptions,
            hideVendor: this.hideVendor,
            internalNote: this.internalNote,
            lowerQuantityBounds: this.lowerQuantityBounds,
            name: this.name,
            options,
            optionsPrice: this.optionsPrice,
            orderItemId: this.orderItemId,
            orderNumber: this.orderNumber,
            processCost: this.processCost,
            productName: this.productName,
            processName: this.processName,
            productPrice: this.productPrice,
            productCost: this.productCost,
            qty: this.qty,
            selectedColor: this.selectedColor,
            selectedQty: this.selectedQty,
            selectedSize: this.selectedSize,
            selectedTaxType: this.selectedTaxType,
            sellPrice: this.sellPrice,
            sizePrice: this.sizePrice,
            sizes,
            sizingChart: this.sizingChart,
            style: this.style,
            taxRate: this.taxRate,
            taxType: this.taxType,
            upperQuantityBounds: this.upperQuantityBounds,
            vendorName: this.vendorName,
            hasPurchaseOrderItem: this.hasPurchaseOrderItem,
            purchaseOrderColor: this.purchaseOrderColor,
            purchaseOrderSize: this.purchaseOrderSize,
            hideProductName: this.hideProductName,
            hideStyle: this.hideStyle,
        };
    }
}
