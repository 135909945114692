import { AsyncInteractionViewModel, WebHelper } from "chipply-common";
import IProductDetailStandardColorsModel from "./IProductDetailStandardColorsModel";
import IProductDetailColorModel, { IProductDetailColorSaveModel } from "./IProductDetailColorModel";

export class EditProductDetailColorImageItemViewModel {
    public imageNumber = 0;
    public imageUrl = "";
    public autoCropOnSave = true;
    public removeImageOnSave = false;

    public constructor(imageNumber: number, imageUrl: string) {
        this.imageNumber = imageNumber;
        this.imageUrl = imageUrl;
    }

    public get imagePropertyName() {
        return `image${this.imageNumber}`;
    }

    public get imageUrlPropertyName() {
        return `image${this.imageNumber}Url`;
    }

    public get autoCropPropertyName() {
        return `autoCropOnSave${this.imageNumber}`;
    }

    public get removeImagePropertyName() {
        return `removeImageOnSave${this.imageNumber}`;
    }
}

export default class EditProductDetailColorEditViewModel extends AsyncInteractionViewModel {
    public storeId = 0;
    public productColorId = 0;
    public productId = 0;
    public colorName = "";
    public hexCode1 = "";
    public hexCode2 = "";

    public images: EditProductDetailColorImageItemViewModel[] = [];
    public standardColorId = 0;
    public standardColors: IProductDetailStandardColorsModel[] = [];
    public isAdminUser = false;
    public isEditOneColor = false;

    public productImagesFormData: FormData | null = null;

    public onImagePicked = async (item: EditProductDetailColorImageItemViewModel, e: File, type: string) => {
        if (!e) {
            return;
        }
        const fileName = e.name;
        if (!fileName || fileName.lastIndexOf(".") <= 0) {
            return;
        }
        item.removeImageOnSave = false;
        const fileReader = new FileReader();
        fileReader.readAsDataURL(e);
        fileReader.addEventListener("load", async () => {
            if (this.productImagesFormData == null) {
                this.productImagesFormData = new FormData();
                this.productImagesFormData.append("storeId", this.storeId.toString());
                this.productImagesFormData.append("productId", this.productId.toString());
                this.productImagesFormData.append("productColorId", this.productColorId.toString());
            }
            if (this.productImagesFormData.has(item.imagePropertyName)) {
                this.productImagesFormData.delete(item.imagePropertyName);
            }
            this.productImagesFormData.append(item.imagePropertyName, e, e.name);
        });
    };

    public deleteImage(item: EditProductDetailColorImageItemViewModel) {
        if (this.productImagesFormData != null) {
            if (this.productImagesFormData.has(item.imagePropertyName)) {
                this.productImagesFormData.delete(item.imagePropertyName);
            }
        }
        item.removeImageOnSave = true;
    }

    public async save() {
        const uploadedImages = await this.uploadImages();
        const savedColor = await this.saveColor(uploadedImages);
        return savedColor;
    }

    /**
     * upload images
     * @returns uploaded images' property names
     */
    public async uploadImages(): Promise<string[]> {
        if (this.isValidFormData() == false) {
            return [];
        }

        const options = {
            body: this.productImagesFormData,
            method: "POST",
        };

        const response = await fetch("/api/product/UploadProductColorImage", options);
        const resultsText = await response.text();
        const results = JSON.parse(resultsText);
        for (let i = 0; i < this.images.length; i++) {
            this.images[i].imageUrl = results[this.images[i].imageUrlPropertyName];
        }
        this.hexCode1 = results.hexCode1;
        this.hexCode2 = results.hexCode2;

        return this.images
            .filter((img) => this.productImagesFormData?.has(img.imagePropertyName))
            .map((img) => img.imagePropertyName);
    }

    private isValidFormData() {
        if (this.productImagesFormData == null) {
            return false;
        }
        let hasFile = false;
        const autoCrop: number[] = [];
        for (let i = 0; i < this.images.length; i++) {
            const item = this.images[i];
            if (this.productImagesFormData.has(item.imagePropertyName)) {
                hasFile = true;
                if (item.autoCropOnSave) {
                    autoCrop.push(item.imageNumber);
                }
            }
        }
        this.productImagesFormData.append("autoCrop", autoCrop.join(","));
        return hasFile;
    }

    public async saveColor(uploadedImages: string[]) {
        const args = {
            storeId: this.storeId,
            productColorId: this.productColorId,
            eventProductId: this.productId,
            colorName: this.colorName,
            hexCode1: this.hexCode1,
            hexCode2: this.hexCode2,
            standardColorId: this.standardColorId,
        } as IProductDetailColorSaveModel;
        const model: any = {};
        for (let i = 0; i < this.images.length; i++) {
            const item = this.images[i];
            model[item.imageUrlPropertyName] = item.imageUrl;

            // for images already uploaded, do not autocrop it again.
            model[item.autoCropPropertyName] = uploadedImages.includes(item.imagePropertyName)
                ? false
                : item.autoCropOnSave;
            model[item.removeImagePropertyName] = item.removeImageOnSave;
        }

        Object.assign(args, model);

        // save goes here
        const color = (await WebHelper.postJson(`/api/Product/SaveColor`, args)) as IProductDetailColorModel;

        return color;
    }
}
