





















































































































































































































































































































































































































import { EnvironmentConstants } from "@/chipply/EnvironmentConstants";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "@/chipply/EventBus";
import { FieldListItemEditorViewModel } from "@/chipply/fields/FieldListItemEditorViewModel";
import chipplyIcons from "@/chipply/ImportIcons";
import ArtworkProcessItem from "@/chipply/process/ArtworkProcessItem";
import IArtwork from "@/chipply/process/IArtwork";
import IArtworkProcessItem from "@/chipply/process/IArtworkProcessItem";
import IOptionProcessItem from "@/chipply/process/IOptionProcessItem";
import IProcess from "@/chipply/process/IProcess";
import IProcessOptionItem from "@/chipply/process/IProcessOptionItem";
import OptionProcessItem from "@/chipply/process/OptionProcessItem";
import { ProcessOptionListItemEditorViewModel } from "@/chipply/process/ProcessOptionListItemEditorViewModel";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
import FormValidationMixin from "@/components/FormValidationMixin";
import HtmlEditor from "@/components/HtmlEditor.vue";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import CArtworkSelect from "@/components/process/CArtworkSelect.vue";
import ProcessOptionEditor from "@/components/process/ProcessOptionEditor.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import CListItemEditor from "@/components/ui/CListItemEditor.vue";
import CMoney from "@/components/ui/CMoney.vue";
import CSaveButton from "@/components/ui/CSaveButton.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import Validation from "@/validation";
import { AsyncInteractionViewModel, ProcessItemType, Utils, WebHelper, ITextValue, UrlUtils } from "chipply-common";
import * as _ from "lodash";
import Sortable, { SortableEvent } from "sortablejs";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import EditLayout from "../layout/EditLayout.vue";
import IProcessItem from "@/chipply/process/IProcessItem";
import { DragDropHelper } from "@/components/DragDropHelper";

@Component({
    components: {
        CListItemEditor,
        CSaveButton,
        CArtworkSelect,
        CSaveDialog,
        LongRunningOperationDialog,
        CAddButton,
        CControlLabel,
        CDeleteButton,
        CEditButton,
        CMoney,
        HtmlEditor,
        ProcessOptionEditor,
        TextHeading,
        EditLayout,
    },
    directives: { mask },
    props: {
        processId: Number,
        eventId: Number,
    },
    computed: {
        computedDisableEditLayout() {
            const me = this as EditProcess;
            return !me.computedCanEditLayout();
        },
    },
})
export default class EditProcess extends mixins(UserInfoMixin) {
    public chipplyIcons = chipplyIcons;
    public processId!: number | null;
    public eventId!: number | null;
    public showAlert = false;
    public alertMessage: string | null = null;

    public $refs!: {
        addArtworkForm: any;
        addOptionForm: any;
        mainForm: any;
        table: HTMLElement;
    };

    protected ProcessItemType = ProcessItemType;
    protected Utils = Utils;
    protected Validation = Validation;

    public itemEditorViewModel: ProcessOptionListItemEditorViewModel | null = null;

    protected isValid = false;
    protected initialLoad = true;
    public uniqueArtworkLocations: Array<string | null> = [];
    protected selectedAddOptionType = 0;
    protected selectedAddArtworkType = 0;
    protected savingMessage = "Saving Process...";
    protected statusMessage = this.savingMessage;
    protected saving = false;
    protected selectedOptionItem: IOptionProcessItem | null = null;
    protected selectedOptions: IProcessOptionItem[] | null = null;
    protected selectedArtworkId: number | null = null;
    protected selectedArtworkName: string | null = null;
    protected selectedOptionId: number | null = null;
    protected selectedOptionType: ProcessItemType | null = null;
    protected selectedOptionName: string | null = null;
    protected showAddArtworkDialog = false;
    protected showAddOptionDialog = false;
    protected showEditOptionsDialog = false;
    protected confirmViewModel: AsyncInteractionViewModel | null = null;

    protected originalData: IProcess | null = null;
    protected clientDateTime: Date | null = null;

    protected addArtworkValid = false;
    protected addOptionValid = false;
    protected process: IProcess | null = null;

    protected optionProcessItems: IOptionProcessItem[] = [];

    protected artworks: IArtwork[] = [];
    protected artworkLocations: ITextValue<number>[] = [];
    protected options: IArtwork[] = [];
    protected get artworkHeaders() {
        return [
            { text: "", value: "imageUrl" },
            { text: "Artwork", value: "artwork" },
            { text: "Artwork Location", value: "artworkLocation" },
            { text: "Price", value: "price", width: "100px" },
            { text: "Notes", value: "notes", width: "150px" },
            { text: "Work Order Notes", value: "workOrderNotes", width: "150px" },
            { text: "", value: "actions" },
        ];
    }
    protected optionHeaders = [
        { text: "", value: "handle" },
        { text: "", value: "imageUrl" },
        { text: "Name", value: "name" },
        { text: "Options", value: "options" },
        { text: "Price", value: "price", width: "100px" },
        { text: "Required", value: "required" },
        { text: "Ecom Display", value: "notes" },
        { text: "Work Order Notes", value: "workOrderNotes", width: "150px" },
        { text: "Max Characters", value: "maxCharacters", width: "100px" },
        { text: "", value: "actions" },
    ];

    protected optionTypes = [
        {
            optionTypeId: ProcessItemType.OptionName,
            optionType: "Name (Text Field)",
        },
        {
            optionTypeId: ProcessItemType.OptionNumber,
            optionType: "Number (Text Field)",
        },
        {
            optionTypeId: ProcessItemType.OptionGroup,
            optionType: "Group (Drop Down List)",
        },
        {
            optionTypeId: ProcessItemType.OptionExclusiveGroup,
            optionType: "Exclusive Group (Drop Down List)",
        },
    ];

    public isDragAndDropInitialized = false;

    public computedCanEditLayout() {
        if (this.process!.options.length > 0) {
            return true;
        }

        let filters = ["Legacy", "Other"];
        for (const artwork of this.process!.artworks) {
            let location = this.artworkLocations.filter((x) => x.value == artwork.artworkLocationId);
            if (location.length > 0 && filters.indexOf(location[0].text) >= 0) {
                return true;
            }
        }

        return false;
    }

    public validateMainForm() {
        this.$nextTick(() => {
            this.$refs.mainForm.validate();
        });
    }

    public getArtworkDisplay(item: IProcessItem) {
        let filteredArtworks = this.artworks.filter((x) => x.artworkId == item.artworkId);
        if (filteredArtworks.length == 0) {
            return "";
        }
        return filteredArtworks[0].artName;
    }

    public getArtworkLocationDisplay(item: IProcessItem) {
        let locations = this.artworkLocations.filter((x) => x.value == item.artworkLocationId);
        if (locations.length == 0) {
            return "";
        }
        return locations[0].text;
    }

    public back() {
        const callback = () => {
            location.assign(UrlUtils.getVersionedLink(`/ng/edit-store-processes.html?eventid=${this.eventId}`));
        };
        this.saveBeforeLeave(callback);
    }

    public async created() {
        EventBus.$on("top-navigation-started", (location: string, eventArgs: IAsyncEventArgs) => {
            if (!this.hasChanges()) {
                return;
            }

            const promise = new Promise<void | boolean>(async (resolve, reject) => {
                this.confirmViewModel = new AsyncInteractionViewModel();
                const result = await this.confirmViewModel.interact();
                if (result === "cancel") {
                    eventArgs.cancel = true;
                    resolve(eventArgs.cancel);
                    this.confirmViewModel = null;
                } else if (result === "continue") {
                    resolve();
                }
            });
            eventArgs.promises.push(promise);
        });

        await this.loadProcessData();
    }

    public beforeDestroy() {
        EventBus.$off("top-navigation-started");
    }

    public async loadProcessData() {
        if (Utils.isNullOrUndefined(this.processId)) return;

        const data = await WebHelper.getJsonData(`/api/Process/${this.eventId}/${this.processId}`);
        this.process = data.process;
        this.clientDateTime = data.process.serverDateTimeOffset;
        this.originalData = JSON.parse(JSON.stringify(data.process));
        this.artworks = data.artworks;
        this.options = data.options;

        this.optionProcessItems = data.process.options;

        this.artworkLocations = data.artworkLocations;
        this.initialLoad = false;

        this.$nextTick(() => {
            this.$refs.mainForm.validate();

            DragDropHelper.initializeDragAndDrop(this.$refs.table, this, "optionProcessItems", this, null);

            this.isDragAndDropInitialized = true;
        });
    }

    @Watch("processId")
    public async processIdChanged() {
        await this.loadProcessData();
    }

    public get processData() {
        return {
            process: this.process,
            artworks: this.artworks,
            options: this.options,
            artworkLocations: this.artworkLocations,
        };
    }

    @Watch("processData", { deep: true })
    public async processDataChanged() {
        if (!this.initialLoad) {
            await this.checkForUpdates();
        }
    }

    public get processCost() {
        let processTotal = 0;
        if (!this.process) {
            return processTotal;
        }

        for (const opt of this.process.options) {
            if (!opt.required) {
                continue;
            }
            processTotal += opt.price || 0;
        }

        for (const art of this.process.artworks) {
            processTotal += art.price || 0;
        }

        return Utils.getCurrencyValue(processTotal, false, true);
    }

    protected hasChanges() {
        return !_.isEqual(this.process, this.originalData);
    }

    protected async checkForUpdates() {
        if (!this.processId) {
            return;
        }
        const isLastModificationDateTimeLater = await this.checkLastModificationDateTime(
            this.processId,
            this.clientDateTime
        );
        this.showAlert = isLastModificationDateTimeLater;

        if (isLastModificationDateTimeLater) {
            this.alertMessage =
                "A more recent save has been made to this page. Please refresh the page to see the latest changes.";
        }
    }

    protected async checkLastModificationDateTime(processId: number, clientDateTime: Date | null): Promise<boolean> {
        const isLastModificationDateTimeLater = await WebHelper.getJsonData(
            `/api/Process/LastModificationDateTime/${processId}?clientDateTime=${
                clientDateTime ? encodeURIComponent(clientDateTime.toString()) : ""
            }`
        );
        return isLastModificationDateTimeLater;
    }

    protected selectedOptionTypeChanged() {
        this.$refs.addOptionForm.validate();
    }

    protected selectedArtworkTypeChanged() {
        this.$refs.addArtworkForm.validate();
    }

    protected requireExistingOptionValue(value: any) {
        if (this.selectedAddOptionType !== 1) {
            return true;
        }
        return Validation.requireValue(value);
    }

    protected requireNewOption(value: any) {
        if (this.selectedAddOptionType !== 0) {
            return true;
        }
        return Validation.requireValue(value);
    }

    protected requireExistingArtworkValue(value: any) {
        if (this.selectedAddArtworkType !== 1) {
            return true;
        }
        return Validation.requireValue(value);
    }

    protected requireNewArtwork(value: any) {
        if (this.selectedAddArtworkType !== 0) {
            return true;
        }
        return Validation.requireValue(value);
    }

    protected async save() {
        this.statusMessage = this.savingMessage;
        this.saving = true;

        if (this.process?.options) {
            this.process.options = this.optionProcessItems;
        }

        try {
            const processId = await WebHelper.postJsonData("/api/Process", this.process!);
            this.processId = Number(processId);
            await this.loadProcessData();
            this.saving = false;
        } catch (e) {
            this.statusMessage = Utils.ServerErrorMessage;
        }
    }

    protected removeOption(item: IOptionProcessItem) {
        const itemIndex = this.process!.options.indexOf(item);
        this.process!.options.splice(itemIndex, 1);
    }

    protected async saveBeforeLeave(callback: () => void) {
        if (this.hasChanges()) {
            this.confirmViewModel = new AsyncInteractionViewModel();
            const result = await this.confirmViewModel.interact();
            if (result === "cancel") {
                this.confirmViewModel = null;
                return;
            } else if (result === "accept") {
                await this.save();
            } else if (result === "continue") {
                // ignore
            }
        }
        callback();
    }

    public async editLayout() {
        var callback = () => {
            location.assign(
                UrlUtils.getVersionedLink(
                    `/ng/store-process-layout.html?eventid=${this.eventId}&processid=${this.processId}`
                )
            );
        };
        await this.saveBeforeLeave(callback);
    }

    protected removeArtwork(item: IArtworkProcessItem) {
        const itemIndex = this.process!.artworks.indexOf(item);
        this.process!.artworks.splice(itemIndex, 1);
    }

    protected async editOptions(item: IOptionProcessItem) {
        this.selectedOptionItem = item;
        this.selectedOptions = JSON.parse(JSON.stringify(item.options));
        let itemEditorViewModel = new ProcessOptionListItemEditorViewModel();
        itemEditorViewModel.items = JSON.parse(JSON.stringify(item.options));
        itemEditorViewModel.itemName = "Option";
        this.itemEditorViewModel = itemEditorViewModel;
        this.showEditOptionsDialog = true;
        const result = await this.itemEditorViewModel.interact();
        this.itemEditorViewModel = null;
        this.showEditOptionsDialog = false;

        if (result === "accept") {
            this.selectedOptionItem!.options = itemEditorViewModel.items as IProcessOptionItem[];
        }
    }

    protected addOption() {
        this.selectedOptionId = null;
        this.selectedOptionType = null;
        this.selectedAddOptionType = 1;
        this.selectedOptionName = null;
        this.$refs.addOptionForm.validate();
        this.showAddOptionDialog = true;
    }

    protected addArtwork() {
        this.selectedArtworkId = null;
        this.selectedAddArtworkType = 1;
        this.selectedArtworkName = null;
        this.$refs.addArtworkForm.validate();
        this.showAddArtworkDialog = true;
    }

    protected artworkChanged(artworkProcessItem: IArtworkProcessItem) {
        for (const artwork of this.artworks) {
            if (artwork.artworkId !== artworkProcessItem.artworkId) {
                continue;
            }
            artworkProcessItem.imageUrl = artwork.imageUrl;
        }
    }

    protected addArtworkDialogClosed(accepted: boolean) {
        this.showAddArtworkDialog = false;

        if (!accepted) {
            return;
        }

        if (this.selectedAddArtworkType === 1) {
            this.process!.artworks.push(new ArtworkProcessItem(this.selectedArtworkId!, this.artworks));
        } else {
            const artwork = new ArtworkProcessItem();
            artwork.name = this.selectedArtworkName;
            this.process!.artworks.push(artwork);
        }
        this.validateMainForm();
    }

    protected isLegacyOrOther(artworkLocationId: number) {
        if (artworkLocationId === 1 || artworkLocationId === 2) {
            return true;
        }
        return false;
    }

    protected defaultEcomDisplay() {
        if (this.process!.designName) {
            return;
        }
        this.process!.designName = this.process!.name;
    }

    protected addOptionDialogClosed(accepted: boolean) {
        this.showAddOptionDialog = false;

        if (!accepted) {
            return;
        }

        if (this.selectedAddOptionType === 1) {
            let option: IArtwork | null = null;
            for (const o of this.options) {
                if (o.artworkId !== this.selectedOptionId) {
                    continue;
                }
                option = o;
                break;
            }
            this.process!.options.push(new OptionProcessItem(option!.type!, this.selectedOptionId!, this.options));
        } else {
            const newOption = new OptionProcessItem(this.selectedOptionType!);
            newOption.name = this.selectedOptionName;
            this.process!.options.push(newOption);
        }

        this.$nextTick(() => {
            this.$refs.mainForm.validate();
        });
    }

    protected optionChanged(artworkProcessItem: IArtworkProcessItem) {
        for (const artwork of this.options) {
            if (artwork.artworkId !== artworkProcessItem.artworkId) {
                continue;
            }
            artworkProcessItem.imageUrl = artwork.imageUrl;
            artworkProcessItem.type = artwork.type!;
        }
    }
    public validateArtworkLocations() {
        this.uniqueArtworkLocations = [];
        var seenLocations = [];
        for (const artwork of this.process!.artworks) {
            if (!this.isLegacyOrOther(artwork.artworkLocationId)) {
                seenLocations.push(artwork.artworkLocationId);
            }
        }
        seenLocations = seenLocations.filter((e, i, a) => a.indexOf(e) !== i); // get list of duplicates
        for (const artwork of this.process!.artworks) {
            if (seenLocations.includes(artwork.artworkLocationId)) {
                this.uniqueArtworkLocations.push("You must select a unique artwork location");
            } else {
                this.uniqueArtworkLocations.push(null);
            }
        }
    }
}
