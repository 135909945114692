import { render, staticRenderFns } from "./EcomOrderTreeSelector.vue?vue&type=template&id=3fe034bd&scoped=true"
import script from "./EcomOrderTreeSelector.vue?vue&type=script&lang=ts"
export * from "./EcomOrderTreeSelector.vue?vue&type=script&lang=ts"
import style0 from "./EcomOrderTreeSelector.vue?vue&type=style&index=0&id=3fe034bd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fe034bd",
  null
  
)

export default component.exports