import { render, staticRenderFns } from "./ColorTextField.vue?vue&type=template&id=f36151ec&scoped=true"
import script from "./ColorTextField.vue?vue&type=script&lang=ts"
export * from "./ColorTextField.vue?vue&type=script&lang=ts"
import style1 from "./ColorTextField.vue?vue&type=style&index=1&id=f36151ec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f36151ec",
  null
  
)

export default component.exports