import { AsyncInteractionViewModel, SimpleAsyncInteractionViewModel, Utils, WebHelper } from "chipply-common";
import { IStoreProcessDto, IStoreProcessItemDto } from "./IStoreProcessDto";

export class StoreProcessItemViewModel extends AsyncInteractionViewModel {
    id!: number;
    storeID!: number;
    storeName = "";
    processName = "";
    processDescription = "";
    hasProductCount = false;
    productCount = 0;
    isNoneProcess = false;
    organizationID!: number;
    organizationName = "";
    storeOpenDate = "";
    storeCloseDate = "";
    saleOrder = "";
    storeProcessItems: IStoreProcessItemDto[] = [];
    artItems: string[] = [];

    constructor(hasProductCount?: boolean, dto?: IStoreProcessDto) {
        super();
        if (Utils.isNullOrUndefined(dto)) {
            return;
        }
        this.id = dto!.storeProcessID;
        this.storeID = dto!.storeID!;
        this.storeName = dto!.storeName;
        this.processName = dto!.processName;
        this.processDescription = dto!.processDescription;
        if (hasProductCount == true) {
            this.hasProductCount = true;
            this.productCount = dto!.productCount!;
        }
        if (dto!.storeProcessItems != null && dto!.storeProcessItems.length > 0) {
            this.storeProcessItems = dto!.storeProcessItems!;
        }
        if (dto!.isNoneProcess) {
            this.isNoneProcess = true;
        }
        this.organizationID = dto!.organizationID;
        this.organizationName = dto!.organizationName;
        this.storeOpenDate = dto!.storeOpenDate;
        this.storeCloseDate = dto!.storeCloseDate;
        this.saleOrder = dto!.saleOrder;
        this.updateArtSummary();
    }

    public updateArtSummary() {
        const arts: string[] = [];
        const options: string[] = [];

        if (this.storeProcessItems.length > 0) {
            for (const storeProcessItem of this.storeProcessItems) {
                if (!storeProcessItem.storeArtworkID) {
                    continue;
                }
                if (storeProcessItem.processItemTypeID == 1) {
                    arts.push(`${storeProcessItem.storeProcessType}: ${storeProcessItem.artName}`);
                } else {
                    options.push(`${storeProcessItem.storeProcessType}: ${storeProcessItem.artName}`);
                }
            }
        }
        if (arts.length == 0 && options.length == 0) {
            this.artItems.push("No artwork or options defined.");
            return;
        }
        this.artItems.push(...arts);
        this.artItems.push(...options);
    }
}
