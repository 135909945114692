import IStoreContext from "@/chipply/event/IStoreContext";
import { DateTime } from "luxon";

export default class FileUtils {
    public static getBatchFileName(batchId: number, fileDescription: string, fileType: string) {
        return `Batch${batchId}-${fileDescription}.${fileType}`;
    }
    public static getStoreFileName(context: IStoreContext, fileDescription: string, fileType: string) {
        const saleOrder = context.saleOrder;
        const storeName = context.storeName;

        const fileName =
            this.cleanFileNamePart(saleOrder) +
            "-" +
            this.cleanFileNamePart(storeName) +
            "-" +
            fileDescription +
            this.getFileDateTimeStampString() +
            "." +
            fileType;

        return fileName;
    }

    protected static getFileDateTimeStampString() {
        let dateTime = DateTime.local().toLocaleString(DateTime.DATETIME_SHORT);
        dateTime = dateTime.replace(/ /g, "-");
        dateTime = dateTime.replace(/,/g, "");
        dateTime = dateTime.replace(/\//g, "-");
        dateTime = dateTime.replace(/:/g, "-");
        return dateTime;
    }

    protected static cleanFileNamePart(part: string) {
        part = part.replace(/\\/g, "");
        part = part.replace(/\//g, "");
        part = part.replace(/:/g, "");
        part = part.replace(/\?/g, "");
        part = part.replace(/\*/g, "");
        part = part.replace(/</g, "");
        part = part.replace(/>/g, "");
        part = part.replace(/\|/g, "");
        part = part.replace(/ /g, "");
        return part;
    }
}
