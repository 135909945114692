import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class DataTablePagingMixin extends Vue {
    public $refs!: {
        datatable: Vue;
    };

    private isSelectingNextPage = false;
    private wasLastPageSelected = false;
    private scrollLeft = 0;

    public mounted() {
        const tableChild = this.getDataTableRef().$el.firstElementChild;
        if (tableChild) {
            tableChild.addEventListener("scroll", this.checkNextPage);
        }
    }

    public checkNextPage(args: any) {
        const previousScrollLeft = this.scrollLeft;
        this.scrollLeft = args.target.scrollLeft;
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (args.target.scrollTop === 0) {
            return;
        }
        if (args.target.scrollLeft > previousScrollLeft) {
            return;
        }
        // 15 pixels subtracted from threshold to account for possible header height changes after a sort
        if (
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight - 15 &&
            !this.wasLastPageSelected
        ) {
            this.selectNextPage();
        }
    }

    protected getDataTableRef(): Vue {
        return this.$refs.datatable;
    }

    protected selectNextPageCore(): Promise<any> {
        throw new Error("Not implemented");
    }

    private async selectNextPage(): Promise<any> {
        this.isSelectingNextPage = true;
        try {
            await this.selectNextPageCore();
        } finally {
            this.isSelectingNextPage = false;
        }
    }
}
