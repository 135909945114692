import IPurchaseOrderArgs from "./IPurchaseOrderArgs";
import PurchasingFilters from "./PurchasingFilters";
import VendorPurchaseOrder from "./VendorPurchaseOrder";

export default class VendorPurchaseOrderArgs implements IPurchaseOrderArgs {
    public shouldGroupLikeItems = true;
    public filters: PurchasingFilters = new PurchasingFilters();
    public purchaseOrder!: VendorPurchaseOrder;
    public isBulkPurchaseOrder = false;
}
