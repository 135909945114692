import { EventBus } from "@/chipply/EventBus";
import { WebHelper } from "chipply-common";
import IAccountingSummaryResults from "@/chipply/interface/IAccountingSummaryResults";
import StoreTotals from "@/chipply/event/StoreTotals";
import { DateTime } from "luxon";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";
import { ReportHeaderViewModel } from "@/chipply/reports/ReportHeaderViewModel";

export default class AccountingSummaryViewModel {
    public eventId: number | null = null;
    public catalogBatchId: number | null = null;

    public storeTotals: StoreTotals = new StoreTotals();
    public notes: string | null = null;
    public headerViewModel: ReportHeaderViewModel = new ReportHeaderViewModel();

    public assignFields(results: IAccountingSummaryResults) {
        this.headerViewModel.eventId = this.eventId;
        this.headerViewModel.saleOrder = results.saleOrder;
        this.headerViewModel.name = results.name;
        this.headerViewModel.storeLogoUrl = results.storeLogoUrl;
        this.headerViewModel.dealerName = results.dealerName;
        this.headerViewModel.customerName = results.customerName;
        this.headerViewModel.salespersonName = results.salespersonName;
        this.headerViewModel.totalProcesses = results.totalProcessCount;
        this.headerViewModel.totalProducts = results.totals.productCount;
        this.headerViewModel.totalOrders = results.totals.orderTotals.orderCount;
        const serverOrderDate = DateTime.fromISO(results.orderDate);
        this.headerViewModel.orderDate = serverOrderDate.toLocaleString();
        this.headerViewModel.dueDate = DateTime.fromISO(results.dueDate).toISODate();
        this.headerViewModel.originalDueDate = this.headerViewModel.dueDate;
        this.headerViewModel.sectionHeader = "FULL STORE ACCOUNT SUMMARY";
        this.storeTotals = results.totals;
        this.notes = results.notes;
    }
}
