


















import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import HtmlTextField from "@/components/HtmlTextField.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import CRemoveButton from "@/components/ui/CRemoveButton.vue";
import Validation from "@/validation";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { OrganizationDistributionTypeEnum } from "@/chipply/event/OrganizationDistributionTypeEnum";
import { CustomerDistributionTypeEnum } from "@/chipply/event/CustomerDistributionTypeEnum";

@Component({
    components: {
        CRemoveButton,
        HtmlTextField,
        CEditButton,
    },
})
export default class OrganizationBranchCard extends Vue {
    public CustomerDistributionTypeEnum = CustomerDistributionTypeEnum;
    public OrganizationDistributionTypeEnum = OrganizationDistributionTypeEnum;
    public Validation = Validation;

    @Prop({
        type: Object,
        default: null,
    })
    public model!: IOrganizationBranchEditModel | null;
}
