export class ProductSearchConstants {
    public static readonly templateId = "templateId";
    public static readonly templateCategoryIds = "templateCategoryIds";

    public static readonly styleNameKeywords = "styleNameKeywords";
    public static readonly exactMatch = "exactMatch";
    public static readonly vendorId = "vendorId";

    public static readonly organizationId = "organizationId";
    public static readonly storeId = "storeId";
    public static readonly storeCategoryIds = "storeCategoryIds";
    public static readonly copyAllFromSelectedCategory = "copyAllFromSelectedCategory";
    public static readonly processId = "processId";
    public static readonly additionalFilter = "additionalFilter";

    public static readonly allProductsCategoryDisplay = "All Products";
    public static readonly allProductsCategoryId = -1;

    public static readonly noCategoryId = 0;
    public static readonly noCategoryDisplay = "No Category";

    public static readonly NO_WEIGHT = "no weight";
}
