import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class PagingMixin extends Vue {
    public $refs!: {
        scrollableElement: Element;
    };

    private isSelectingNextPage = false;
    private wasLastPageSelected = false;

    public getScrollableElement(): Element | null {
        return this.$refs.scrollableElement;
    }
    public mounted() {
        const scrollable = this.getScrollableElement();

        if (scrollable) {
            scrollable.addEventListener("scroll", this.checkNextPage);
        }
    }

    public checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (args.target.scrollTop === 0) {
            return;
        }
        if (
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight &&
            !this.wasLastPageSelected
        ) {
            this.selectNextPage();
        }
    }

    protected selectNextPageCore(): Promise<any> {
        throw new Error("Not implemented");
    }

    private async selectNextPage(): Promise<any> {
        this.isSelectingNextPage = true;
        try {
            await this.selectNextPageCore();
        } finally {
            this.isSelectingNextPage = false;
        }
    }
}
