import IDepositSummary from "@/chipply/deposits/IDepositSummary";
import IDepositSummaryResult from "@/chipply/deposits/IDepositSummaryResult";
import { DateTime } from "luxon";

export default class DepositSummaryResult implements IDepositSummaryResult {
    public amountCollected!: number;
    public amountPaid!: number;
    public chipplyFees!: number;
    public depositDate: string | undefined;
    public depositId!: number;
    public endingPeriod!: string;
    public processingFees!: number;
    public startingPeriod!: string;
    public statementDate!: string;
    public statementNumber!: number;
    public statusName!: string;

    public constructor(result: IDepositSummaryResult) {
        Object.assign(this, result);
    }
}
