













import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    props: {
        icon: String,
        label: String,
        disabled: Boolean,
        target: String,
        href: String,
    },
})
export default class CRowActionButton extends Vue {
    @Prop({ type: Boolean, default: true })
    public showTitle!: boolean;
}
