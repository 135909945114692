import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IPurchaseOrderWarehouse } from "../IPurchaseOrderWarehouse";
import { IVendorShipMethod, sanMarShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "SanMarPurchaseOrderSettings" })
export default class SanMarPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public shipMethod = "";
    public vendorName = "sanmar";
    public canSelectWarehouse = false;
    public warehouses: IPurchaseOrderWarehouse[] = [];
    public canCustomerPickup = false;

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return sanMarShipMethods;
    }
}
