import { SimpleAsyncInteractionViewModel, WebHelper, ITextValue } from "chipply-common";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import PageState from "../../PageState";
import { IListStoreArtworkFilters, ISearchArtworkArgs, ISearchArtworkResults } from "./IStoreArtworkDto";
import { EditStoreArtworksViewModel } from "./EditStoreArtworksViewModel";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import SearchArtworkViewModel from "@/chipply/event/artworks/SearchArtworkViewModel";
import { ListViewModel } from "@/chipply/view-model/ListViewModel";
import { IGetArtworkVendorInfoResults } from "./IStoreArtworkVariationModel";

export class SearchStoreArtworksViewModel extends ListViewModel {
    public static STATE_KEY = "searchArtworksState";
    public storeId!: number;

    public dataTableItemKey = "storeArtworkId";
    public dataTableLoading = false;
    public dataTableFixedHeader = true;
    public dataTableShowExpand = true;
    public dataTableNoDataText = "Use search filters to find an artwork inside of Chipply";
    public serverItemsLength = 0;
    public isArtworkPurchasingEnabled = false;
    public artworkVendors: ITextValue<number>[] = [];

    public items: SearchArtworkViewModel[] = [];
    public filters: IListStoreArtworkFilters = this.getDefaultFilters();

    public errorMessage = "";
    public importArtworkDialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public dataTableHeaders: object[] = [
        { text: "", sortable: false, value: "imageUrl", width: "2%" },
        { text: "Name", sortable: false, value: "artName", width: "15%" },
        { text: "Count", sortable: false, value: "artworkCount", width: "5%" },
        { text: "Type", sortable: false, value: "artType", width: "5%" },
        { text: "Type Code", sortable: false, value: "typeCode", width: "5%" },
        { text: "Organization", sortable: false, value: "orgName" },
        { text: "Sale Order", sortable: false, value: "saleOrder" },
        { text: "Store", sortable: false, value: "storeName", width: "10%" },
        { text: "Open Date", sortable: false, value: "openDate", width: "10%" },
        { text: "Close Date", sortable: false, value: "closeDate", width: "10%" },
        { text: "", sortable: false, value: "importArtwork", width: "15%" },
        { text: "", sortable: false, value: "placeholder" },
    ];

    public pageState!: PageState;
    public get pagination() {
        return this._pagination;
    }
    public set pagination(value: IVuetifyTableOptions) {
        this._pagination = value;
    }
    protected _pagination: IVuetifyTableOptions = {
        itemsPerPage: 20,
        page: 1,
        sortDesc: [],
        sortBy: [],
    };

    public constructor() {
        super();
        this.pageState = new PageState();
        this.pageState.itemsPerPage = 10;
    }

    public async list(isNewSearch?: boolean): Promise<any> {
        await this.refreshData(isNewSearch);
    }

    public clear() {
        this.filters = this.getDefaultFilters();
        this.items = [];
    }
    public getDefaultFilters() {
        const filters: IListStoreArtworkFilters = {
            organizationID: null,
            storeIDToExclude: this.storeId,
            storeID: null,
            storeName: null,
            saleOrder: null,
            artName: "",
            storeOpenDateFrom: null,
            storeOpenDateTo: null,
            storeCloseDateFrom: null,
            storeCloseDateTo: null,
            externalId: null,
            artworkVendor: null,
        };

        return filters;
    }

    public buildFilters(): IListStoreArtworkFilters {
        this.filters.storeIDToExclude = this.storeId;
        return this.filters;
    }

    public getPageState(): IPageReferrerSettings {
        return {
            description: "SearchArtworks",
            filters: this.buildFilters(),
            pagination: this.pagination,
            url: location.pathname + "?readstate=1",
        };
    }

    public getPageStateUrl(pageUrl: string) {
        const serializedValue = JSON.stringify(this.getPageState());
        const initialParameterJoin = pageUrl.indexOf("?") === -1 ? "?" : "&";
        let pageStateUrl = `${pageUrl}${initialParameterJoin}&eventid=${this.storeId}&v=${Math.floor(
            Math.random() * 100000
        )}`;

        pageStateUrl += `&${SearchStoreArtworksViewModel.STATE_KEY}=${encodeURIComponent(serializedValue)}`;

        const urlParams = new URLSearchParams(window.location.search);
        const editProcessesKey = EditStoreArtworksViewModel.STATE_KEY;
        if (urlParams.has(editProcessesKey)) {
            pageStateUrl += `?${editProcessesKey}=${encodeURIComponent(urlParams.get(editProcessesKey) as string)}`;
        }

        return pageStateUrl;
    }

    public isArtworkSelector(): boolean {
        return false;
    }

    protected buildSearchArgs(): ISearchArtworkArgs {
        const args: ISearchArtworkArgs = {
            filters: this.buildFilters(),
            pageState: this.pageState,
        };
        return args;
    }

    public async onStoreIdChanged(): Promise<void> {
        await Promise.all([this.refreshData(true)]);
    }

    public async refreshData(isNewSearch?: boolean): Promise<void> {
        if (this.dataTableLoading) {
            return;
        }
        if (!this.storeId) return;

        if (isNewSearch) {
            this.items = [];
            this.pageState.reset();
        }
        if (this.pageState.wasLastPageSelected) {
            return;
        }
        this.dataTableLoading = true;

        const pageUrl = "/ng/edit-store-artworks.html";
        history.replaceState(this.getPageState(), "", this.getPageStateUrl(pageUrl));
        await this.searchArtworks(this.buildSearchArgs());
    }

    protected async searchArtworks(searchArgs: ISearchArtworkArgs) {
        const baseUrl = `/api/Store/SearchArtworks`;
        this.dataTableLoading = true;
        const jsonResult = await WebHelper.postJsonData(baseUrl, searchArgs);
        const searchResults = JSON.parse(jsonResult) as ISearchArtworkResults;
        const items: SearchArtworkViewModel[] = [];
        searchResults.artworks.forEach((artwork) => {
            const itemViewModel = new SearchArtworkViewModel(artwork);
            items.push(itemViewModel);
        });
        this.items.push(...items);
        this.pageState.next(searchResults.artworks.length);
        this.serverItemsLength = searchResults.totalRows;
        this.dataTableLoading = false;
    }

    public openImagePreview(imageUrl: string) {
        window.open(imageUrl, "_blank");
    }

    public itemExpanded = async (args: { item: SearchArtworkViewModel; value: boolean }) => {
        if (args.item.isLoaded) {
            return;
        }
        args.item.filters = this.buildFilters();
        await args.item.list();
    };

    public async getArtworkVendorInfo() {
        this.dataTableLoading = true;
        const artworkVendorInfoResult = (await WebHelper.getJsonData(
            `/api/Store/GetArtworkVendorInfo`
        )) as IGetArtworkVendorInfoResults;
        this.artworkVendors = artworkVendorInfoResult.artworkVendors;
        this.isArtworkPurchasingEnabled = artworkVendorInfoResult.isArtworkPurchasingEnabled;
        this.dataTableLoading = false;
    }
}
