import { OrganizationDistributionTypeEnum } from "@/chipply/event/OrganizationDistributionTypeEnum";
import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import { IStoreOrganizationDistributionModel } from "@/chipply/store/settings/IStoreOrganizationDistributionModel";
import { IStoreSettingsDistributionModel } from "@/chipply/store/settings/IStoreSettingsDistributionModel";
import { StoreDistributionViewModel } from "@/chipply/store/settings/StoreDistributionViewModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { CustomerDistributionViewModel } from "@/components/store/settings/CustomerDistributionViewModel";

export class StoreOrganizationDistributionViewModel {
    public orgDistributionType: OrganizationDistributionTypeEnum | null = null;
    public customerDistribution: CustomerDistributionViewModel = new CustomerDistributionViewModel();
    public dealerBranchId = 0;
    public organizationBranchId = 0;
    public branchModel: IOrganizationBranchEditModel | null = null;
    public distroVm: StoreDistributionViewModel;

    public constructor(model: IStoreOrganizationDistributionModel, pageVm: StoreDistributionViewModel) {
        this.distroVm = pageVm;
        if (!model) {
            return;
        }

        this.orgDistributionType = model.type;
        this.customerDistribution = new CustomerDistributionViewModel(model.customerDistribution);
        this.dealerBranchId = model.dealerBranchId;
        this.organizationBranchId = model.organizationBranchId;
        this.branchModel = model.branchModel!;
    }

    public organizationDistributionChanged = () => {
        switch (this.orgDistributionType) {
            case OrganizationDistributionTypeEnum.PickUp:
                this.organizationBranchId = 0;
                if (this.distroVm.groupPickups.length === 1) this.dealerBranchId = this.distroVm.groupPickups[0].value;
                break;
            case OrganizationDistributionTypeEnum.Deliver:
                this.organizationBranchId = 0;
                this.dealerBranchId = 0;
                this.branchModel = null;
                break;
            case OrganizationDistributionTypeEnum.ShipTo:
                this.dealerBranchId = 0;
                this.organizationBranchId = 0;
                this.branchModel = null;
                break;
        }
    };

    public toModel(): IStoreOrganizationDistributionModel {
        return {
            type: this.orgDistributionType,
            organizationBranchId: this.organizationBranchId,
            dealerBranchId: this.dealerBranchId,
            customerDistribution: this.customerDistribution.toModel(),
        };
    }
}
