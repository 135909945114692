import EditableViewModel from "@/chipply/EditableViewModel";
import IFeeType from "@/chipply/event/IFeeType";
import IStoreFee from "@/chipply/event/IStoreFee";
import { IEdit, IEditable } from "chipply-common";
import { ITextValue } from "chipply-common";

export default abstract class StoreFeeViewModel<TViewModel extends IEditable>
    extends EditableViewModel
    implements IEdit<TViewModel>
{
    public feeId = 0;
    public feeName = "";

    public feeTypeName = "";
    public feeCost = 0;

    public abstract get feeTypes(): Array<ITextValue<number>>;

    public get isPercentage() {
        return this.eventFeeTypeId === 3 || this.eventFeeTypeId === 6;
    }

    protected allFeeTypes: Array<IFeeType> = [];
    protected _eventFeeTypeId = 0;

    public constructor(data: IStoreFee, feeTypes: Array<IFeeType>) {
        super();
        this.feeId = data.feeId;
        this.feeName = data.feeName;
        this.feeCost = data.feeCost || 0;

        this._eventFeeTypeId = data.eventFeeTypeId;

        this.allFeeTypes = feeTypes;

        this.setFeeType();
    }

    public abstract createEditViewModel(): TViewModel;
    public abstract apply(vm: TViewModel): void;

    public get eventFeeTypeId() {
        return this._eventFeeTypeId;
    }

    public set eventFeeTypeId(value: number) {
        this._eventFeeTypeId = value;
        this.setFeeType();
    }

    protected setFeeType() {
        for (const feeType of this.feeTypes) {
            if (feeType.value !== this.eventFeeTypeId) {
                continue;
            }
            this.feeTypeName = feeType.text;
            break;
        }
    }
}
