



































import Component from "vue-class-component";
import Vue from "vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import ShipStationSettingsViewModel from "@/chipply/view-model/dealer/ShipStationSettingsViewModel";
import Validation from "@/validation";

@Component({
    props: {
        vm: Object,
    },
    components: { TextHeading },
})
export default class ShipStationSettingsEditor extends Vue {
    public vm!: ShipStationSettingsViewModel;
    public validation = Validation;

    public async mounted() {
        await this.vm.getShipStationStores();
    }
}
