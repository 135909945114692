

















import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import OrganizationEditViewModel from "@/chipply/organization/OrganizationEditViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import CAddressEditor from "@/components/ui/CAddressEditor.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CPhone from "@/components/ui/CPhone.vue";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";
import { mask } from "vue-the-mask";

@Component({
    components: {
        CAddressEditor,
        CDecisionCard,
        CPhone,
    },
})
export default class AddOrganization extends mixins(FormValidationMixin) {
    public isValid = false;

    @Prop({
        type: Object,
    })
    public vm!: OrganizationEditViewModel;
    protected Validation = Validation;
    protected OrganizationStoreFeeViewModel = OrganizationStoreFeeViewModel;

    @Watch("vm")
    protected vmChanged() {
        this.validate();
    }
}
