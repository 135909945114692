import IProductColorProcessImageModel from "./IProductColorProcessImageModel";
import { ImageReviewViewModel } from "./ImageReviewViewModel";

export default class ProductReviewImageViewModel
    extends ImageReviewViewModel
    implements IProductColorProcessImageModel
{
    public imageNumber!: number;
    public eventProductColorProcessId!: number;

    public constructor(model: IProductColorProcessImageModel, hexCode1: string) {
        super();
        Object.assign(this, model);
        this.isArtwork = false;
        this.hexCode1 = hexCode1;
    }
}
