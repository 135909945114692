import EditableViewModel from "@/chipply/EditableViewModel";
import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import { Address } from "chipply-common";

export default class OrganizationBranchEditViewModel extends EditableViewModel {
    public orgId = 0;
    public orgBranchId = 0;
    public branchName = "";
    public address = new Address();

    public constructor(model?: IOrganizationBranchEditModel | null) {
        super();
        if (model) {
            this.orgBranchId = model.orgBranchId;
            this.branchName = model.branchName;
            this.address = model.address;
            this.orgId = model.orgId;
        }
    }

    public toModel(): IOrganizationBranchEditModel {
        return {
            address: this.address,
            branchName: this.branchName,
            orgBranchId: this.orgBranchId,
            orgId: this.orgId,
        };
    }
}
