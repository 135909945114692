
import { CatalogEventBatchTypeEnum } from "@/chipply/event/CatalogEventBatchTypeEnum";
import CatalogStoreSettingsPageViewModel from "@/chipply/store/settings/CatalogStoreSettingsPageViewModel";
import { CatalogStoreSettingsViewModel } from "@/chipply/store/settings/CatalogStoreSettingsViewModel";
import OrderStoreSettingsPageViewModel from "@/chipply/store/settings/OrderStoreSettingsPageViewModel";
import { OrderStoreSettingsViewModel } from "@/chipply/store/settings/OrderStoreSettingsViewModel";
import RequestorStoreSettingsPageViewModel from "@/chipply/store/settings/RequestorStoreSettingsPageViewModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import CCron from "@/components/ui/CCron.vue";
import CDate from "@/components/ui/CDate.vue";
import CTime from "@/components/ui/CTime.vue";
import Validation from "@/validation";
import { DateTime } from "luxon";
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    computed: {
        CatalogStoreSettingsViewModel() {
            return CatalogStoreSettingsViewModel;
        },
    },
    components: { CCron, CDate, CTime },
})
export default class StoreSchedulingSettings extends Vue {
    public Validation = Validation;
    public OrderStoreSettingsViewModel = OrderStoreSettingsViewModel;
    public CatalogEventBatchTypeEnum = CatalogEventBatchTypeEnum;
    public CatalogStoreSettingsPageViewModel = CatalogStoreSettingsPageViewModel;

    @Prop({
        type: Object,
    })
    public vm!: StoreSettingsPageViewModel;

    public get isOrder() {
        return this.vm instanceof OrderStoreSettingsPageViewModel;
    }

    public get isRequestor() {
        return this.vm instanceof RequestorStoreSettingsPageViewModel;
    }

    public get isCatalog() {
        return this.vm instanceof CatalogStoreSettingsPageViewModel;
    }

    public get closeDateRules() {
        if (this.isCatalog)
            return [
                this.vm.requireCloseDateGreaterThanOpen,
                Validation.requireDateDifferenceLessThan(this.vm.store!.openDate, this.vm.store!.closeDate!, 15),
            ];

        return [this.vm.requireCloseDateGreaterThanOpen];
    }
}
