

















import Component from "vue-class-component";
import Vue from "vue";
import CDecisionCard from "../ui/CDecisionCard.vue";

@Component({
    components: {
        CDecisionCard,
    },
    props: {
        show: Boolean,
        viewModel: Object,
        hideSave: Boolean,
    },
})
export default class CSaveDialog extends Vue {}
