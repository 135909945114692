import ListDealerBranchResults from "./ListDealerBranchResults";
import { WebHelper } from "chipply-common";
import ArtworkPurchaseOrderViewModel from "./ArtworkPurchaseOrderViewModel";
import ArtworkPurchaseOrderViewViewModel from "./ArtworkPurchaseOrderViewViewModel";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import { IPurchaseOrderLineItem } from "./IPurchaseOrderLineItem";
import IPurchasingLineItem from "./IPurchasingLineItem";
import PurchaseOrderViewModel from "./PurchaseOrderViewModel";
import VendorPurchaseOrder from "./VendorPurchaseOrder";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";

export default class PurchaseOrderHelper {
    public static removeItemFromArrayIfExists<T>(array: T[], itemArgs: T) {
        if (array.indexOf(itemArgs) >= 0) {
            const index = array.indexOf(itemArgs);
            if (index >= 0) {
                array.splice(index, 1);
            }
        }
    }

    public static addItemToArrayIfNotExists<T>(array: T[], itemArgs: T) {
        const index = array.indexOf(itemArgs);
        if (index < 0) {
            array.push(itemArgs);
        }
    }

    public static replaceArray<T>(targetToReplace: T[], arrayArgs: T[]) {
        targetToReplace.splice(0);
        targetToReplace.push(...arrayArgs);
    }

    public static updatePurchasingLineItem(
        sourceLineItem: IPurchasingLineItem,
        targetLineItemToUpdate: IPurchasingLineItem
    ) {
        targetLineItemToUpdate.quantityToOrder = sourceLineItem.quantityToOrder;
        targetLineItemToUpdate.message = sourceLineItem.message;
        targetLineItemToUpdate.hasError = sourceLineItem.hasError;
        targetLineItemToUpdate.availability = sourceLineItem.availability;
        targetLineItemToUpdate.quantityOrdered = sourceLineItem.quantityOrdered;
        targetLineItemToUpdate.isFulfilled = sourceLineItem.isFulfilled;
    }

    public static updatePurchaseOrder<T extends VendorPurchaseOrderHeader>(
        purchaseOrder: T,
        purchaseOrderViewModel: PurchaseOrderViewModel | ArtworkPurchaseOrderViewModel
    ) {
        purchaseOrder.shipEmail = purchaseOrderViewModel.shipEmail;
        purchaseOrder.shipPhone = purchaseOrderViewModel.shipPhone;
        purchaseOrder.customerNumber = purchaseOrderViewModel.customerNumber;
        purchaseOrder.purchaseOrderNumber = purchaseOrderViewModel.purchaseOrderNumber;
        purchaseOrder.vendorName = purchaseOrderViewModel.vendorName;
        purchaseOrder.attention = purchaseOrderViewModel.attention;
        purchaseOrder.shipTo = purchaseOrderViewModel.shipTo;
        purchaseOrder.type = purchaseOrderViewModel.selectedPurchaseOrderType;
        if (purchaseOrderViewModel.purchaseOrderId) {
            purchaseOrder.purchaseOrderId = purchaseOrderViewModel.purchaseOrderId;
        }
        if (purchaseOrderViewModel.shouldShipToBranch) {
            purchaseOrder.dealerBranchId = purchaseOrderViewModel.dealerBranchId;
        } else {
            purchaseOrder.dealerBranchId = null;
        }
        purchaseOrder.vendorSettings = purchaseOrderViewModel.vendorSettings;
    }

    public async getBranches(): Promise<ListDealerBranchResults> {
        const baseUrl = "/api/purchasing/listdealerbranches";
        const results = await WebHelper.postJson<ListDealerBranchResults>(baseUrl);
        return results;
    }
}
