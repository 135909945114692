


















import { Vue, Component, Prop } from "vue-property-decorator";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { NexusTaxMessageCardViewModel } from "@/chipply/dealer/NexusTaxMessageCardViewModel";

@Component({
    components: { CDecisionCard },
})
export default class NexusTaxMessageCard extends Vue {
    @Prop({
        type: Object,
    })
    public vm!: NexusTaxMessageCardViewModel;
}
