



















































































































































































































































import chipplyIcons from "@/chipply/ImportIcons";
import Component from "vue-class-component";
import ListLayout from "../layout/ListLayout.vue";
import DealerBranchesViewModel from "@/chipply/dealer/DealerBranchesViewModel";
import EditOrganizationContact from "@/components/organization/EditOrganizationContact.vue";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import CEditButton from "../ui/CEditButton.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CAddressEditor from "../ui/CAddressEditor.vue";
import Validation from "@/validation";
import EditContact from "@/components/EditContact.vue";
import { Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import FormValidationMixin from "../FormValidationMixin";
import { mixins } from "vue-class-component";
import NavigationArea from "@/chipply/NavigationArea";
import TextHeading from "../utility/TextHeading.vue";
import CRowActionButton from "../ui/CRowActionButton.vue";
import EditOrganizationBranch from "../organization/EditOrganizationBranch.vue";
import CDeleteDialog from "@/components/dialogs/CDeleteDialog.vue";
import CSaveDialog from "../dialogs/CSaveDialog.vue";
import { EventBus } from "@/chipply/EventBus";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { AsyncInteractionViewModel } from "chipply-common";
import NexusTaxMessageCard from "@/components/dealer/NexusTaxMessageCard.vue";

@Component({
    components: {
        ListLayout,
        CDeleteDialog,
        NexusTaxMessageCard,
        CToolbarBtn,
        CEditButton,
        CAddButton,
        CAddressEditor,
        EditOrganizationContact,
        EditContact,
        TextHeading,
        CRowActionButton,
        EditOrganizationBranch,
        CSaveDialog,
    },
    directives: {
        mask,
    },
})
export default class DealerBranches extends mixins(FormValidationMixin) {
    public icons = chipplyIcons;
    public isBranchValid = false;
    public isBillingValid = false;
    public NavigationArea = NavigationArea;
    public get isDisabled() {
        if (!this.vm.sameAsBranchInfo) {
            return !(this.isBranchValid && this.isBillingValid);
        } else {
            return !this.isBranchValid;
        }
    }
    @Prop({
        type: Number,
    })
    public companyId!: number | null;
    @Prop({
        type: Number,
    })
    public orgId!: number | null;
    @Prop({
        type: Boolean,
    })
    public isAdmin!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    @Watch("vm", { deep: true })
    protected vmChanged(newVal: any) {
        this.validate();
        if (newVal.isDetailViewVisible) {
            this.$nextTick(() => {
                this.validateBillingForm();
            });
        }
    }

    public Validation = Validation;
    public vm = new DealerBranchesViewModel();
    public showDialog: boolean = this.vm.showDialog;

    public async created() {
        this.vm.isAdmin = this.isAdmin;
        this.vm.companyId = this.companyId;
        this.vm.orgId = this.orgId;
        await this.vm.list(true);

        if (this.vm) {
            EventBus.$on("top-navigation-started", (location: string, eventArgs: IAsyncEventArgs) => {
                if (!this.vm.hasChanges()) {
                    return;
                }
                const promise = new Promise<void | boolean>(async (resolve, reject) => {
                    this.vm.confirmViewModel = new AsyncInteractionViewModel();
                    const result = await this.vm.confirmViewModel.interact();
                    if (result === "cancel") {
                        eventArgs.cancel = true;
                        resolve(eventArgs.cancel);
                        this.vm.confirmViewModel = null;
                    } else if (result === "continue") {
                        resolve();
                    }
                });
                eventArgs.promises.push(promise);
            });
        }
    }

    public beforeDestroy() {
        EventBus.$off("top-navigation-started");
    }

    public async validateBillingForm() {
        if (this.$refs.billingForm) {
            await (this.$refs.billingForm as any).validate();
        }
    }

    public rowClicked(row: any) {
        this.vm.edit(row);
    }

    public clearSuccessMessage(newVal: boolean) {
        if (newVal === false) {
            this.vm.successMessage = null;
        }
    }
}
