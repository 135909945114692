export default class OrderTotals {
    public averageOrderValue = 0;
    public productPrice = 0;
    public processPrice = 0;
    public combinedPrice = 0;
    public fundraising = 0;
    public sellPrice = 0;
    public additionalSizeCharge = 0;
    public options = 0;
    public manualAdjustment = 0;
    public shipping = 0;
    public handling = 0;
    public tax = 0;
    public order = 0;
    public ecertificate = 0;
    public purchaseOrder = 0;
    public netOrder = 0;
    public chipplyFee = 0;
    public subTotal = 0;
    public orderCount = 0;
    public coupon = 0;
    public creditCard = 0;
    public processingFee = 0;
    public productCost = 0;
    public orderComplete = 0;
    public completedOrderCount = 0;
    public ccFeesOnFundraising = 0;
    public itemsTotal = 0;
    public feeTotal = 0;
}
