import { FieldRepositoryListResults } from "@/chipply/fields/FieldRepositoryListResults";
import RequestorStoreSettingsPageModel from "@/chipply/store/settings/RequestorStoreSettingsPageModel";
import { RequestorStoreSettingsViewModel } from "@/chipply/store/settings/RequestorStoreSettingsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { typeDependencies } from "chipply-common";

@typeDependencies({
    types: {
        FieldRepositoryListResults,
    },
})
export default class RequestorStoreSettingsPageViewModel extends StoreSettingsPageViewModel {
    public store: RequestorStoreSettingsViewModel | null = null;

    protected initializeSettingsViewModel(model: StoreSettingsPageModel): void {
        this.store = new RequestorStoreSettingsViewModel(model, this);
    }
}
