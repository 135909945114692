import IFeeType from "@/chipply/event/IFeeType";
import IOrganizationStoreFee from "@/chipply/event/IOrganizationStoreFee";
import StoreFeeViewModel from "@/chipply/event/StoreFeeViewModel";
import { ITextValue } from "chipply-common";

export default class OrganizationStoreFeeViewModel extends StoreFeeViewModel<OrganizationStoreFeeViewModel> {
    public processName = "";
    protected processes: Array<ITextValue<number>> = [];
    protected _processId = 0;

    public constructor(data: IOrganizationStoreFee, processes: Array<ITextValue<number>>, feeTypes: Array<IFeeType>) {
        super(data, feeTypes);
        this._processId = data.processId;
        this.processes = processes;
        this.setProcessName();
    }

    public createEditViewModel(): OrganizationStoreFeeViewModel {
        return new OrganizationStoreFeeViewModel(this.toModel(), this.processes, this.allFeeTypes);
    }

    public get processId() {
        return this._processId;
    }

    public set processId(value: number) {
        this._processId = value;
        this.setProcessName();
    }

    public apply(vm: OrganizationStoreFeeViewModel): void {
        Object.assign(this, vm);
    }

    public toModel() {
        return {
            eventFeeTypeId: this.eventFeeTypeId,
            feeCost: this.feeCost,
            feeId: this.feeId,
            feeName: this.feeName,
            processId: this.processId,
        } as IOrganizationStoreFee;
    }

    protected setProcessName() {
        for (const process of this.processes) {
            if (process.value !== this.processId) {
                continue;
            }
            this.processName = process.text;
            break;
        }
    }

    public get feeTypes(): Array<ITextValue<number>> {
        const filteredFeeTypes: Array<ITextValue<number>> = [];
        const hasProcess = this.processId > 0;
        for (const ft of this.allFeeTypes) {
            if (ft.chargeToType == 1) {
                filteredFeeTypes.push({
                    value: ft.feeTypeId,
                    text: ft.feeTypeName,
                });
            } else if (!hasProcess && ft.chargeToType == 2) {
                filteredFeeTypes.push({
                    value: ft.feeTypeId,
                    text: ft.feeTypeName,
                });
            } else if (hasProcess && ft.chargeToType == 3) {
                filteredFeeTypes.push({
                    value: ft.feeTypeId,
                    text: ft.feeTypeName,
                });
            }
        }

        return filteredFeeTypes;
    }
}
