import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { WebHelper, Serializer, Address, Utils } from "chipply-common";
import { EditInfoModel } from "./EditInfoModel";
import ListCompanyInfoResults from "./ListCompanyInfoResults";
import { ITextValueDisabled } from "chipply-common";
import EditInfoSaveArgs from "./EditInfoSaveArgs";
import IDealerSourceModel from "../admin/IDealerSourceModel";
import IDealerSourceResults from "../admin/IDealerSourceResults";
import IDealerGroupResults from "../admin/IDealerGroupResults";
import IDealerGroupModel from "../admin/IDealerGroupModel";
import { UrlUtils } from "chipply-common";
import * as _ from "lodash";
import { AsyncInteractionViewModel } from "chipply-common";

export default class EditInfoViewModel extends PageViewModel {
    public items = new EditInfoModel();
    public originalData = new EditInfoModel();
    public websitePrefixIsValid = true;
    public isAdmin = false;
    public isReadingFile: string | boolean = false;
    public companyId: number | null = null;
    public logoImageFileName: string | null = "";
    public logoImagePath = "";
    public logoImageFormData: FormData | null = null;
    public taxExemptCertFileName: string | null = "";
    public taxExemptCertPath = "";
    public taxExemptCertFormData: FormData | null = null;
    public fileNameSuffix = "";
    public addNewDealer = false;
    public addNewOrg = false;
    public companyUsers: Array<ITextValueDisabled<number>> = [];
    public companyBranches: Array<ITextValueDisabled<number>> = [];
    public companySalespersons: Array<ITextValueDisabled<number>> = [];
    public confirmViewModel: AsyncInteractionViewModel | null = null;
    public sources: IDealerSourceModel[] = [];
    public groups: IDealerGroupModel[] = [];
    public newName = "";
    public toDeleteName = "";
    public showAddDialog = false;
    public showConfirmDialog = false;
    public showEditDialog = false;
    public showDeleteDialog = false;
    public isAdding = false;
    public isModifyingSources = false;
    public selectedGroup: number | null = null;
    public selectedSource: number | null = null;

    public dealerNote = "";

    public addressTypes = [
        { value: 1, text: "Commercial" },
        { value: 2, text: "Residential" },
    ];
    public timeZones = [
        { value: 1, text: "Hawaiian Time" },
        { value: 2, text: "Alaskan Time" },
        { value: 3, text: "Pacific Time" },
        { value: 4, text: "Mountain Time" },
        { value: 5, text: "Central Time" },
        { value: 6, text: "Eastern Time" },
        { value: 7, text: "Atlantic Time" },
    ];

    public get isDealerWebsitePrefixDisabled(): boolean {
        return !(this.addNewDealer && this.isAdmin);
    }

    public getSourceDisplayName(id: number) {
        for (const source of this.sources) {
            if (source.dealerSourceId == id) {
                return source.displayName;
            }
        }
        return "";
    }

    public getGroupDisplayName(id: number) {
        for (const group of this.groups) {
            if (group.dealerGroupId == id) {
                return group.displayName;
            }
        }
        return "";
    }

    public getDialogTitle() {
        if (this.isModifyingSources) {
            return "Add Dealer Source";
        }
        return "Add Dealer Group";
    }

    public addSourceClicked() {
        this.isModifyingSources = true;
        this.showAddDialog = true;
        this.isAdding = true;
    }

    public addGroupClicked() {
        this.isModifyingSources = false;
        this.showAddDialog = true;
        this.isAdding = true;
    }

    public editSourceClicked() {
        this.isModifyingSources = true;
        this.newName = this.getSourceDisplayName(this.selectedSource!);
        this.isAdding = false;
        this.showEditDialog = true;
    }

    public editGroupClicked() {
        this.isModifyingSources = false;
        this.newName = this.getGroupDisplayName(this.selectedGroup!);
        this.isAdding = false;
        this.showEditDialog = true;
    }

    public saveClicked() {
        this.showAddDialog = false;
        this.showEditDialog = false;
        this.showConfirmDialog = true;
    }

    public async confirmClicked() {
        if (this.isModifyingSources) {
            await this.saveSource();
        } else {
            await this.saveDealerGroup();
        }
        this.showConfirmDialog = false;
    }

    public cancelClicked() {
        this.showAddDialog = false;
        this.showEditDialog = false;
        this.showConfirmDialog = false;
        this.showDeleteDialog = false;
        this.newName = "";
        this.toDeleteName = "";
    }

    public deleteSourceClicked() {
        this.toDeleteName = this.getSourceDisplayName(this.selectedSource!);
        this.isModifyingSources = true;
        this.showDeleteDialog = true;
    }

    public deleteGroupClicked() {
        this.toDeleteName = this.getGroupDisplayName(this.selectedGroup!);
        this.isModifyingSources = false;
        this.showDeleteDialog = true;
    }

    public async confirmDeleteClicked() {
        if (this.isModifyingSources) {
            await this.deleteSource();
        } else {
            await this.deleteDealerGroup();
        }
        this.toDeleteName = "";
        this.showDeleteDialog = false;
    }

    public sourceDeleteDisabled() {
        if (this.selectedSource == null) {
            return true;
        }
        for (const source of this.sources) {
            if (source.dealerSourceId == this.selectedSource && source.usageCount > 0) {
                return true;
            }
        }
        return false;
    }

    public groupDeleteDisabled() {
        if (this.selectedGroup == null) {
            return true;
        }
        for (const group of this.groups) {
            if (group.dealerGroupId == this.selectedGroup && group.usageCount > 0) {
                return true;
            }
        }
        return false;
    }

    public async list(companyId: number): Promise<void> {
        let baseUrl = "";
        if (this.isAdmin && this.addNewDealer) {
            baseUrl = "/api/dealer/listadminnewdealerinfo";
        } else if (this.isAdmin) {
            baseUrl = `/api/dealer/listadmincompanyinfo/${companyId}`;
        } else {
            baseUrl = this.addNewOrg
                ? "/api/dealer/listcompanyinfo?isNewOrg=" + this.addNewOrg
                : "/api/dealer/listcompanyinfo?companyId=" + companyId;
        }
        try {
            this.statusMessage = this.loadingMessage;
            this.loading = true;
            const results = (await WebHelper.getJsonData(baseUrl)) as ListCompanyInfoResults;
            if (results) {
                this.items = results.info;
                if (this.items.billingAddress?.state === "") {
                    this.items.billingAddress.state = null;
                }
                this.originalData = JSON.parse(JSON.stringify(results.info));
                this.companyUsers = results.companyUsers;
                this.companyBranches = results.companyBranches;
                this.companySalespersons = results.companySalespersons;
            }
            if (this.isAdmin) {
                await this.getSourceData();
                await this.getGroupData();
            }
        } finally {
            this.loading = false;
        }
    }

    public async saveCompanyInfo(companyId: number): Promise<void> {
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/saveadmincompanyinfo";
        } else {
            baseUrl = this.addNewOrg
                ? "/api/dealer/savecompanyinfo?isNewOrg=" + this.addNewOrg
                : "/api/dealer/savecompanyinfo?companyId=" + companyId;
        }

        const args = new EditInfoSaveArgs();
        args.model = this.items;
        args.model.dealerSourceId = this.selectedSource;
        args.model.dealerGroupId = this.selectedGroup;
        args.companyId = companyId;
        args.isNewDealer = this.addNewDealer;
        args.isNewOrg = this.addNewOrg;

        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const result = await WebHelper.postJsonData(baseUrl, args);
            companyId = parseInt(result);
            this.companyId = companyId;
            if (this.addNewDealer && this.isAdmin && this.logoImageFormData) {
                await this.uploadLogoImage();
            }

            if (this.isAdmin) {
                window.location.href = `/ng/admin-edit-info.html?cid=${companyId}`;
            } else {
                window.location.href = `/ng/edit-info.html?cid=${companyId}`;
            }
        } catch (error) {
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    public async uploadLogoImage(): Promise<void> {
        let companyIdText = "";
        companyIdText = this.companyId!.toString();
        this.logoImageFormData?.append("companyId", companyIdText);
        const options = {
            body: this.logoImageFormData,
            method: "POST",
        };
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const response = await fetch("/api/dealer/UploadLogoImage", options);
            const resultsText = await response.text();
            const results = JSON.parse(resultsText);
            this.items.mainLogo = results;
        } catch {
            this.errorMessage = "An error ocurred while saving the data.  Please refresh and try again.";
        } finally {
            this.loading = false;
            this.logoImageFileName = "";
            this.fileNameSuffix = "";
            this.logoImageFormData = null;
        }
    }

    public onLogoImagePicked = (e: File) => {
        if (e !== undefined) {
            const fileName = e.name;
            if (!fileName || fileName.lastIndexOf(".") <= 0) {
                return;
            }
            try {
                this.isReadingFile = "accent";
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e);
                fileReader.addEventListener("load", async () => {
                    this.isReadingFile = false;
                    this.logoImageFileName = e.name;
                    this.logoImageFormData = new FormData();
                    this.logoImageFormData.append("file", e, this.logoImageFileName);
                    if (!this.addNewDealer) {
                        await this.uploadLogoImage();
                    }
                });
            } catch {
                this.isReadingFile = false;
            }
        } else {
            this.fileNameSuffix = "";
        }
    };

    public async uploadTaxExemptCert(): Promise<void> {
        let companyIdText = "";
        companyIdText = this.companyId!.toString();
        this.taxExemptCertFormData?.append("companyId", companyIdText);
        const options = {
            body: this.taxExemptCertFormData,
            method: "POST",
        };
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const response = await fetch("/api/dealer/UploadTaxExemptCert", options);
            const resultsText = await response.text();
            const results = JSON.parse(resultsText);
            this.taxExemptCertPath = results.imagePath;
        } catch {
            this.errorMessage = "An error occurred while saving the data. Please refresh and try again.";
        } finally {
            this.loading = false;
            this.taxExemptCertFileName = "";
            this.taxExemptCertFormData = null;
        }
    }

    public onTaxExemptCertPicked = (e: File) => {
        if (e !== undefined) {
            const fileName = e.name;
            if (!fileName || fileName.lastIndexOf(".") <= 0) {
                return;
            }
            try {
                this.isReadingFile = "accent";
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e);
                fileReader.addEventListener("load", async () => {
                    this.isReadingFile = false;
                    this.taxExemptCertFileName = e.name;
                    this.taxExemptCertFormData = new FormData();
                    this.taxExemptCertFormData.append("file", e, this.taxExemptCertFileName);
                    await this.uploadTaxExemptCert();
                });
            } catch {
                this.isReadingFile = false;
            }
        }
    };

    public async validateWebsitePrefix() {
        console.log("Building args");
        const args = {
            subdomain: this.items.websitePrefix,
        };
        console.log("Checking subdomain");
        if (!args.subdomain) {
            return;
        }
        console.log("Calling the server");
        const results = await WebHelper.postJsonData("/api/Dealer/ValidateSubdomain", args);
        const jsonResults = JSON.parse(results);
        this.websitePrefixIsValid = jsonResults.isValid;
    }

    public async getSourceData() {
        const results = (await WebHelper.getJsonData(`/api/Dealer/GetAdminDealerSources`)) as IDealerSourceResults;
        this.sources = [];
        for (const source of results.sources) {
            this.sources.push(source);
        }
        this.selectedSource = this.items.dealerSourceId;
    }

    public async getGroupData() {
        const results = (await WebHelper.getJsonData(`/api/Dealer/GetAdminDealerGroups`)) as IDealerGroupResults;
        this.groups = [];
        for (const group of results.groups) {
            this.groups.push(group);
        }
        this.selectedGroup = this.items.dealerGroupId;
    }

    public async saveSource() {
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const args = {
                displayName: this.newName,
            } as IDealerSourceModel;
            // if editing, use the edited item's id
            if (!this.isAdding) {
                args.dealerSourceId = this.selectedSource!;
            }
            await WebHelper.postJsonData("/api/Dealer/SaveAdminDealerSource", args);
            await this.getSourceData();
            if (this.isAdding) {
                // set v-select to newly added source
                const newSource = this.sources.slice(-1).pop() as IDealerSourceModel;
                this.selectedSource = newSource.dealerSourceId;
            }
        } finally {
            this.newName = "";
            this.loading = false;
        }
    }

    public async deleteSource() {
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const args = {
                displayName: this.newName,
                dealerSourceId: this.selectedSource,
            } as IDealerSourceModel;
            await WebHelper.postJsonData("/api/Dealer/DeleteAdminDealerSource", args);
            await this.getSourceData();
        } finally {
            this.loading = false;
        }
    }

    public async saveDealerGroup() {
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const args = {
                displayName: this.newName,
            } as IDealerGroupModel;
            // if editing, use the edited item's id
            if (!this.isAdding) {
                args.dealerGroupId = this.selectedGroup!;
            }
            await WebHelper.postJsonData("/api/Dealer/SaveAdminDealerGroup", args);
            await this.getGroupData();
            if (this.isAdding) {
                // set v-select to newly added group
                const newGroup = this.groups.slice(-1).pop() as IDealerGroupModel;
                this.selectedGroup = newGroup.dealerGroupId;
            }
        } finally {
            this.newName = "";
            this.loading = false;
        }
    }

    public async deleteDealerGroup() {
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            const args = {
                displayName: this.newName,
                dealerGroupId: this.selectedGroup,
            } as IDealerGroupModel;
            await WebHelper.postJsonData("/api/Dealer/DeleteAdminDealerGroup", args);
            await this.getGroupData();
        } finally {
            this.loading = false;
        }
    }

    public removePhoneFormatting(value: string | undefined) {
        if (!value) {
            return "";
        }
        return value.toString().replace(/\D/g, "");
    }

    public removeModelPhoneFormatting(model: EditInfoModel) {
        if (model) {
            model.billingPhone = this.removePhoneFormatting(model.billingPhone);
            model.billingFax = this.removePhoneFormatting(model.billingFax);
            model.phone = this.removePhoneFormatting(model.phone);
        }
        return model;
    }

    public hasChanges() {
        let currentData = JSON.parse(JSON.stringify(this.items));
        currentData = this.removeModelPhoneFormatting(currentData);
        this.originalData = this.removeModelPhoneFormatting(this.originalData);
        return !_.isEqual(currentData, this.originalData);
    }

    public backButtonClicked() {
        const callback = () => {
            if (this.isAdmin) {
                window.location.href = UrlUtils.getVersionedLink(`/ng/list-dealers.html`);
            } else {
                window.location.href = UrlUtils.getVersionedLink(`/ng/list-organizations.html`);
            }
        };
        this.saveBeforeLeave(callback);
    }

    protected async saveBeforeLeave(callback: () => void) {
        if (this.hasChanges()) {
            this.confirmViewModel = new AsyncInteractionViewModel();
            const result = await this.confirmViewModel.interact();
            if (result === "cancel") {
                this.confirmViewModel = null;
                return;
            } else if (result === "accept") {
                await this.save();
            } else if (result === "continue") {
                // ignore
            }
        }
        callback();
    }
}
