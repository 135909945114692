



























import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        href: String,
        outlined: Boolean,
        color: String,
        disabled: Boolean,
        icon: String,
        label: String,
        loading: Boolean,
        tooltip: String,
        target: String,
    },
})
export default class CToolbarBtn extends Vue {
    public chipplyIcons = chipplyIcons;
    public color: string | undefined;

    protected computedColor() {
        if (this.color) {
            return this.color;
        }
        return "primary";
    }
}
