export default class DealerFeesModel {
    public feeId: number | null = null;
    public dealerId: number | null = null;
    public feeName = "";
    public feeCost: number | null = null;
    public dealerFeeTypeId: number | null = null;
    public processType: number | null = null;
    public dealerFeeType = "";
    public formattedFeeCost = "";
}
