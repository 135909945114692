


























import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Watch } from "vue-property-decorator";

@Component({
    components: {},
    props: {
        type: {
            type: String,
        },
        inputKey: {
            type: Number,
        },
    },
})
export default class CImageInput extends Vue {
    public internalValue: File | null = null;

    public chipplyIcons = chipplyIcons;

    public localUrl = "";

    @Prop({
        default: "300px",
        type: String,
    })
    public imageWidth!: string;

    @Prop({
        default: "",
        type: String,
    })
    public label!: string;

    @Prop({
        type: String,
    })
    public logoUrl!: string;
    public dropZone: Element | null = null;

    protected async previewImage() {
        if (this.internalValue) {
            this.localUrl = URL.createObjectURL(this.internalValue);
        }
    }

    protected async deleteImage() {
        this.localUrl = "";
        this.internalValue = null;

        this.$emit("deleted", this.$props.logoUrl);
    }

    protected async cleared() {
        this.deleteImage();
    }

    @Watch("internalValue")
    protected async setParent(logo: File) {
        this.$emit("changed", logo, this.$props.type);
    }

    @Watch("value")
    protected async onLogoChanged(logo: File) {
        if (logo) {
            this.localUrl = logo.toString();

            this.internalValue = logo;
        }
    }

    @Watch("logoUrl")
    protected async onLogoUrlChanged(logoUrl: string) {
        this.localUrl = this.$props.logoUrl;
    }

    protected created() {
        this.localUrl = this.$props.logoUrl;
    }

    protected mounted() {
        this.localUrl = this.$props.logoUrl;
        this.subscribeFileDragDrop();
    }

    public beforeDestroy() {
        this.unsubscribeFileDragDrop();
    }

    protected onFileDropped(e: Event) {
        e.preventDefault();
        const dragevent = e as DragEvent;
        if (dragevent.dataTransfer) {
            const files = dragevent.dataTransfer.files;
            if (files && files.length > 0) {
                this.internalValue = files[0];
                this.previewImage();
            }
        }
    }

    protected preventDefaultHandler(e: Event) {
        e.preventDefault();
    }

    protected subscribeFileDragDrop() {
        this.dropZone = this.$el;
        if (this.dropZone) {
            this.dropZone.addEventListener("dragenter", this.preventDefaultHandler);
            this.dropZone.addEventListener("dragleave", this.preventDefaultHandler);
            this.dropZone.addEventListener("dragover", this.preventDefaultHandler);
            this.dropZone.addEventListener("drop", this.onFileDropped);
        }
    }

    protected unsubscribeFileDragDrop() {
        if (this.dropZone) {
            this.dropZone.removeEventListener("dragenter", this.preventDefaultHandler);
            this.dropZone.removeEventListener("dragleave", this.preventDefaultHandler);
            this.dropZone.removeEventListener("dragover", this.preventDefaultHandler);
            this.dropZone.removeEventListener("drop", this.onFileDropped);
        }
    }
}
