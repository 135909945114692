import { CatalogEventBatchTypeEnum } from "@/chipply/event/CatalogEventBatchTypeEnum";
import { IStoreFulfillmentViewModel } from "@/chipply/event/IStoreFulfillmentViewModel";
import { EventBus } from "@/chipply/EventBus";
import { ICatalogStoreSettingsModel } from "@/chipply/store/settings/ICatalogStoreSettingsModel";
import { StoreDistributionViewModel } from "@/chipply/store/settings/StoreDistributionViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import StoreSettingsViewModel from "@/chipply/store/settings/StoreSettingsViewModel";
import { DateTime } from "luxon";

export class CatalogStoreSettingsViewModel extends StoreSettingsViewModel implements IStoreFulfillmentViewModel {
    public batchType: CatalogEventBatchTypeEnum = CatalogEventBatchTypeEnum.IndividualOrder;
    public batchInterval: number | null = null;
    public hideBulkOrdering = false;
    private _scheduleCron: string | null = null;
    public cronTimeZone: string | null = null;
    public productionDueDateOffset = 0;
    public shipDueDateOffset = 0;
    public distribution: StoreDistributionViewModel;
    public hasOrders = true;
    public isBackordered = false;

    public batchTypeChanged = () => {
        if (this.batchType == CatalogEventBatchTypeEnum.DayInterval) {
            this.scheduleCron = "0 * * * *";
        }

        EventBus.$emit("validate");
    };

    public get scheduleCron() {
        return this._scheduleCron;
    }

    public set scheduleCron(value: string | null) {
        this._scheduleCron = value;
        this.cronChanged();
    }

    public constructor(model: StoreSettingsPageModel, pageVm: StoreSettingsPageViewModel) {
        super(model);
        const catalogModel = model.store as ICatalogStoreSettingsModel;
        this.hasOrders = catalogModel.hasOrders;
        this.batchType = catalogModel.batchType;
        this.batchInterval = catalogModel.batchInterval;
        this.hideBulkOrdering = catalogModel.hideBulkOrdering;
        this.scheduleCron = catalogModel.scheduleCron;
        this.cronTimeZone = catalogModel.cronTimeZone;
        this.productionDueDateOffset = catalogModel.productionDueDateOffset;
        this.shipDueDateOffset = catalogModel.shipDueDateOffset;
        this.distribution = new StoreDistributionViewModel(model, pageVm);
    }

    public toModel(): ICatalogStoreSettingsModel {
        const model = super.toModel() as ICatalogStoreSettingsModel;
        model.$typeHint = "CatalogStoreSettingsModel";
        model.distribution = this.distribution.toModel();
        model.batchType = this.batchType;
        model.batchInterval = this.batchInterval;
        model.hideBulkOrdering = this.hideBulkOrdering;
        model.scheduleCron = this.scheduleCron;
        model.cronTimeZone = this.cronTimeZone;
        model.productionDueDateOffset = this.productionDueDateOffset;
        model.shipDueDateOffset = this.shipDueDateOffset;
        return model;
    }

    public cronChanged = () => {
        const d = DateTime.now();
        this.cronTimeZone = d.zone.name;
    };

    public async organizationChanged() {
        await super.organizationChanged();
        this.distribution.organizationChanged();
    }
}
