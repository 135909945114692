import Vue from "vue";
import Component from "vue-class-component";
import { WebHelper } from "chipply-common";
import * as environmentStore from "@/store/EnvironmentStore";

@Component
export default class EnvironmentInfoMixin extends Vue {
    public environmentInfo: {
        previewWebsitePath: string;
        chipplyEventId: number;
        domain: string;
        userpilotEnabled: boolean;
        userpilotToken: string;
    } = {
        previewWebsitePath: "",
        chipplyEventId: 0,
        domain: "",
        userpilotEnabled: false,
        userpilotToken: "",
    };
    public needsBootstrap = false;
    public isEnvironmentInfoCreated = false;
    public environmentInfoCreated: Promise<void> | null = null;
    public async created() {
        this.environmentInfoCreated = new Promise(async (resolve) => {
            await this.bootstrap();
            await environmentStore.initialize(this.$store);

            this.environmentInfo.previewWebsitePath = environmentStore.getPreviewWebsitePath(this.$store);
            this.environmentInfo.chipplyEventId = environmentStore.getChipplyEventId(this.$store);
            this.environmentInfo.domain = environmentStore.getDomain(this.$store);
            this.environmentInfo.userpilotEnabled = environmentStore.getUserpilotEnabled(this.$store);
            this.environmentInfo.userpilotToken = environmentStore.getUserpilotToken(this.$store);
            this.isEnvironmentInfoCreated = true;
            resolve();
        });
    }
    protected async bootstrap() {
        if (!this.needsBootstrap) {
            return;
        }
        const bootstrap = await WebHelper.getJsonData("/api/Login/BootstrapUser");
        localStorage.setItem("userInfo", JSON.stringify(bootstrap.userData));
        localStorage.setItem("envInfo", JSON.stringify(bootstrap.environmentInfo));
    }
}
