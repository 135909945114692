import { IOrderStoreSettingsModel } from "@/chipply/store/settings/IOrderStoreSettingsModel";
import OrderStoreSettingsPageModel from "@/chipply/store/settings/OrderStoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import StoreSettingsViewModel from "@/chipply/store/settings/StoreSettingsViewModel";

export class OrderStoreSettingsViewModel extends StoreSettingsViewModel {
    public catalogStoreName = "";
    public catalogStoreId = 0;

    constructor(model: OrderStoreSettingsPageModel) {
        super(model);
        const orderModel = model.store as IOrderStoreSettingsModel;
        this.catalogStoreId = orderModel.catalogStoreId;
        this.catalogStoreName = orderModel.catalogStoreName;
    }
}
