




















































































import IDealerTax from "@/chipply/dealer/IDealerTax";
import chipplyIcons from "@/chipply/ImportIcons";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import { TaxType, WebHelper } from "chipply-common";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import Validation from "@/validation";
import CToolbarBtn from "../ui/CToolbarBtn.vue";

@Component({
    components: {
        TaxTypeSelection,
        TextHeading,
        CToolbarBtn,
    },
})
export default class DealerStoreTaxSettings extends Vue {
    public chipplyIcons = chipplyIcons;

    public taxSettings: IDealerTax | null = null;
    public isValid = false;
    public saving = false;

    public $refs!: { form: any };

    public async created() {
        await this.initialize();
    }

    public async initialize() {
        const results = (await WebHelper.getJsonData(`/api/Dealer/Tax`, true)) as IDealerTax;
        this.taxSettings = results;
        this.$nextTick(() => {
            this.$refs.form.validate();
        });
    }

    protected selectedTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.taxSettings!.selectedTaxType = taxSettings!.taxType;
        this.taxSettings!.taxRate = taxSettings!.taxRate;
    }

    protected selectedShippingTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.taxSettings!.shippingTaxMethod = taxSettings!.taxType;
        this.taxSettings!.shippingTaxRate = taxSettings!.taxRate;
    }

    protected selectedHandlingTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.taxSettings!.handlingTaxMethod = taxSettings!.taxType;
        this.taxSettings!.handlingTaxRate = taxSettings!.taxRate;
    }

    protected async save() {
        this.saving = true;
        await WebHelper.postJsonData("/api/Dealer/Tax", this.taxSettings!);
        this.saving = false;
    }
}
