import { render, staticRenderFns } from "./TextHeading.vue?vue&type=template&id=34e2b585&scoped=true"
import script from "./TextHeading.vue?vue&type=script&lang=ts"
export * from "./TextHeading.vue?vue&type=script&lang=ts"
import style0 from "./TextHeading.vue?vue&type=style&index=0&id=34e2b585&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34e2b585",
  null
  
)

export default component.exports