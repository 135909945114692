var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"grey-background",attrs:{"fluid":""}},[_c('v-card-title',[_vm._v("Transactional History")]),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"transactional-history-table",attrs:{"headers":_vm.headers,"must-sort":"","items":_vm.vm.transactions,"items-per-page":500,"loading":_vm.vm.tableLoading,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.transactionDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.transactionDate).toLocaleString())+" ")]}},{key:"item.transactionTypeID",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransactionTypeName(item.transactionTypeID))+" ")]}},{key:"item.amountCollected",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrency(item.amountCollected))+" ")]}},{key:"item.ecertTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrency(item.ecertTotal))+" ")]}},{key:"item.poTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrency(item.poTotal))+" ")]}},{key:"item.promoTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrency(item.promoTotal))+" ")]}},{key:"item.orderTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertCurrency(item.orderTotal))+" ")]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }