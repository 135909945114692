import { render, staticRenderFns } from "./EditOrderTaxSettings.vue?vue&type=template&id=22004a32&scoped=true"
import script from "./EditOrderTaxSettings.vue?vue&type=script&lang=ts"
export * from "./EditOrderTaxSettings.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22004a32",
  null
  
)

export default component.exports