




























































































































































































































































import Component from "vue-class-component";
import Vue from "vue";
import CDate from "@/components/ui/CDate.vue";
import { DateTime } from "luxon";
import Validation from "@/validation";
import IPurchasingViewModel from "@/chipply/purchasing/IPurchasingViewModel";
import { Prop } from "vue-property-decorator";
import PurchasingConstants from "@/chipply/purchasing/PurchasingConstants";
import { Autocompletes } from "@/chipply/Autocompletes";

@Component({
    props: {
        vm: Object,
        step: Number,
    },
    components: {
        CDate,
    },
})
export default class PurchasingFiltersEditor extends Vue {
    public vm!: IPurchasingViewModel;
    public step!: number;

    @Prop({ type: Boolean, default: false })
    public isPurchaseOrderVisible!: boolean;
    public Validation = Validation;
    public processSearchInput: string | null = null;
    public vendorSearchInput: string | null = null;
    public artworkVendorSearchInput: string | null = null;
    public typeSearchInput: string | null = null;
    public readonly typeSelectItems = Array.from(PurchasingConstants.purchaseTypeValueTextMap, ([v, t]) => ({
        value: v,
        text: t,
    }));

    public Autocompletes = Autocompletes;

    public dateBeforeToDate(value: string) {
        if (!this.vm.filters.ordersToDate) {
            return true;
        }
        if (!this.vm.filters.ordersFromDate) {
            return true;
        }
        const toDate = DateTime.fromISO(this.vm.filters.ordersToDate);
        const fromDate = DateTime.fromISO(this.vm.filters.ordersFromDate);
        return toDate > fromDate ? true : "PO From Date must be before PO To Date";
    }
}
