














































































































































































































































































































































































































































































































































































































































import { CatalogEventBatchTypeEnum } from "@/chipply/event/CatalogEventBatchTypeEnum";
import { EventBus } from "@/chipply/EventBus";
import chipplyIcons from "@/chipply/ImportIcons";
import CatalogStoreSettingsPageModel from "@/chipply/store/settings/CatalogStoreSettingsPageModel";
import CatalogStoreSettingsPageViewModel from "@/chipply/store/settings/CatalogStoreSettingsPageViewModel";
import GroupStoreSettingsPageModel from "@/chipply/store/settings/GroupStoreSettingsPageModel";
import GroupStoreSettingsPageViewModel from "@/chipply/store/settings/GroupStoreSettingsPageViewModel";
import OrderStoreSettingsPageModel from "@/chipply/store/settings/OrderStoreSettingsPageModel";
import OrderStoreSettingsPageViewModel from "@/chipply/store/settings/OrderStoreSettingsPageViewModel";
import RequestorStoreSettingsPageModel from "@/chipply/store/settings/RequestorStoreSettingsPageModel";
import RequestorStoreSettingsPageViewModel from "@/chipply/store/settings/RequestorStoreSettingsPageViewModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import TemplateStoreSettingsPageModel from "@/chipply/store/settings/TemplateStoreSettingsPageModel";
import TemplateStoreSettingsPageViewModel from "@/chipply/store/settings/TemplateStoreSettingsPageViewModel";
import FeeEditor from "@/components/FeeEditor.vue";
import FieldCollectionDesigner from "@/components/fields/FieldCollectionDesigner.vue";
import FormValidationMixin from "@/components/FormValidationMixin";
import HtmlTextField from "@/components/HtmlTextField.vue";
import CategoryTreeSelector from "@/components/input/CategoryTreeSelector.vue";
import EditLayout from "@/components/layout/EditLayout.vue";
import AddOrganization from "@/components/organization/AddOrganization.vue";
import AddOrganizationContact from "@/components/organization/AddOrganizationContact.vue";
import EditOrganizationBranch from "@/components/organization/EditOrganizationBranch.vue";
import EditOrganizationContactDecisionCard from "@/components/organization/EditOrganizationContactDecisionCard.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import StoreDistributionSettings from "@/components/store/settings/StoreDistributionSettings.vue";
import StoreSchedulingSettings from "@/components/store/settings/StoreSchedulingSettings.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import CCopyButton from "@/components/ui/CCopyButton.vue";
import CCron from "@/components/ui/CCron.vue";
import CDate from "@/components/ui/CDate.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import CRemoveButton from "@/components/ui/CRemoveButton.vue";
import CSuccessOrFailureCard from "@/components/ui/CSuccessOrFailureCard.vue";
import CSummaryCard from "@/components/ui/CSummaryCard.vue";
import CTime from "@/components/ui/CTime.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import Validation from "@/validation";
import { typeDependencies, Utils, ViewModelFactory, WebHelper } from "chipply-common";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
        StoreSchedulingSettings,
        CCron,
        StoreDistributionSettings,
        CDecisionCard,
        AddOrganizationContact,
        EditOrganizationContactDecisionCard,
        EditOrganizationBranch,
        AddOrganization,
        FeeEditor,
        FieldCollectionDesigner,
        TaxTypeSelection,
        CCopyButton,
        CSuccessOrFailureCard,
        CAddButton,
        CRemoveButton,
        CControlLabel,
        CDeleteButton,
        CDate,
        CEditButton,
        CSummaryCard,
        CTime,
        CToolbarBtn,
        EditLayout,
        HtmlTextField,
        CategoryTreeSelector,
    },
    props: {
        storeId: Number,
    },
})
@typeDependencies({
    types: {
        GroupStoreSettingsPageModel,
        CatalogStoreSettingsPageModel,
        RequestorStoreSettingsPageModel,
        TemplateStoreSettingsPageModel,
        OrderStoreSettingsPageModel,
    },
})
export default class Settings extends mixins(ScrollableContentMixin, FormValidationMixin) {
    public CatalogStoreSettingsPageViewModel = CatalogStoreSettingsPageViewModel;
    public RequestorStoreSettingsPageViewModel = RequestorStoreSettingsPageViewModel;
    public CatalogEventBatchTypeEnum = CatalogEventBatchTypeEnum;

    public Validation = Validation;
    public Utils = Utils;
    public name = "StoreSettings.vue";
    public chipplyIcons = chipplyIcons;
    public vm: StoreSettingsPageViewModel | null = null;
    public storeId!: number;

    @Prop({
        type: Number,
        default: 0,
    })
    public dealerId!: number;

    public get isCatalog() {
        return this.vm instanceof CatalogStoreSettingsPageViewModel;
    }

    public get isTemplate() {
        return this.vm instanceof TemplateStoreSettingsPageViewModel;
    }

    public get isRequestor() {
        return this.vm instanceof RequestorStoreSettingsPageViewModel;
    }

    public get isOrder() {
        return this.vm instanceof OrderStoreSettingsPageViewModel;
    }

    public get isGroup() {
        return this.vm instanceof GroupStoreSettingsPageViewModel;
    }

    public unmounted() {
        this.vm?.destroy();
    }

    public async created() {
        await this.initializeSettings();
    }

    @Watch("vm.store")
    public storeViewModelChanged() {
        this.validate();
    }

    @Watch("storeId")
    public async onStoreIdChanged() {
        await this.initializeSettings();
    }

    public get completeStoreUrl() {
        if (!this.vm || !this.vm.store) {
            return "";
        }

        return this.vm.store.dealerStorePrefix + this.vm.store.storeUrl;
    }
    protected async initializeSettings() {
        if (!this.storeId) {
            return;
        }

        const model = await WebHelper.getJsonData(`/api/Events/Settings/${this.storeId}`);

        const vm = ViewModelFactory.getViewModel({
            model,
        });

        vm.dealerId = this.dealerId;
        this.vm = vm;
        this.vm?.checkStoreUrl();

        if (this.vm && this.vm.store) {
            this.vm.checkAndResetDateFields(this.vm.store);
        }
    }

    protected getFeeHeaders() {
        return [
            { sortable: false, text: "", value: "edit" },
            { sortable: false, text: "Fee Type", value: "feeType" },
            { sortable: false, text: "Name", value: "feeName" },
            { sortable: false, text: "Amount", value: "feeCost" },
            { sortable: false, text: "", value: "delete" },
        ];
    }

    protected getOrganizationFeeHeaders() {
        return [
            { sortable: false, text: "", value: "edit" },
            { sortable: false, text: "Process Name", value: "processName" },
            { sortable: false, text: "Fee Type", value: "feeType" },
            { sortable: false, text: "Name", value: "feeName" },
            { sortable: false, text: "Amount", value: "feeCost" },
            { sortable: false, text: "", value: "delete" },
        ];
    }
}
