































import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
        TextHeading,
    },
})
export default class StoreCreationOptions extends Vue {
    public name = "StoreCreationOptions";
    @Prop({ type: String })
    public dialogTitle!: string;
    @Prop({ type: String })
    public eventName!: string;
    @Prop({ type: String })
    public selectedStoreName!: string;
    @Prop({ type: String })
    public eventType!: string;
    public selectedEventType = "";

    public optionsAccepted(accepted: boolean) {
        this.$emit("options-accepted", {
            accepted,
            eventType: this.selectedEventType,
            storeName: this.selectedStoreName,
        });
    }

    @Watch("eventType")
    protected onEventTypeChanged() {
        if (!this.eventType) {
            return;
        }
        this.selectedEventType = this.eventType;
    }

    @Watch("eventName")
    protected onEventNameChanged() {
        this.selectedStoreName = this.eventName;
    }

    public created() {
        this.selectedEventType = this.eventType;
        this.selectedStoreName = this.eventName;
    }
}
