
import CronCore from "@vue-js-cron/core";
import cronVuetify from "@vue-js-cron/vuetify";
import "@vue-js-cron/vuetify/dist/vuetify.css";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class CCron extends Vue {
    @Prop({
        type: String,
    })
    public value!: string | null;

    public internalValue = this.value;

    @Watch("value")
    public updateInternalValue() {
        this.internalValue = this.value;
    }

    public locale = "en";

    public periods = [
        { id: "month", text: "Month", value: ["day", /*'dayOfWeek',*/ "hour", "minute"] },
        { id: "week", text: "Week", value: ["dayOfWeek", "hour", "minute"] },
        { id: "day", text: "Day", value: ["hour", "minute"] },
        { id: "year", text: "Year", value: ["month", "day", "dayOfWeek", "hour", "minute"] },
    ];

    protected getMinuteItems() {
        const items = [
            { text: "00", alt: "00", value: 0 },
            { text: "15", alt: "15", value: 15 },
            { text: "30", alt: "30", value: 30 },
            { text: "45", alt: "45", value: 45 },
        ];
        return items;
    }

    protected getDayItems() {
        const items = [];
        for (let i = 1; i < 29; i++) {
            let day = { text: `${i}`, alt: `${i}`, value: i };
            items.push(day);
        }
        return items;
    }

    public fields = [
        { id: "minute", items: this.getMinuteItems() },
        { id: "hour", items: CronCore.locale.defaultItems(this.locale).hourItems },
        { id: "day", items: this.getDayItems() },
        { id: "month", items: CronCore.locale.defaultItems(this.locale).monthItems },
        { id: "dayOfWeek", items: CronCore.locale.defaultItems(this.locale).dayOfWeekItems },
    ];

    public modelValueUpdated(newValue: string) {
        this.$emit("input", newValue);
    }
}
