



























































































































































































































































































































































































import Vue from "vue";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import CAddButton from "@/components/ui/CAddButton.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";

import TreeSelector from "../input/TreeSelector.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import FundraisingSelection from "@/components/products/FundraisingSelection.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import CMoney from "@/components/ui/CMoney.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import { Utils } from "chipply-common";
import ProductMoreInfo from "@/components/products/ProductMoreInfo.vue";
import ProductsSelectorViewModel from "@/chipply/products/ProductsSelectorViewModel";
import LongRunningOperationDialog from "../utility/LongRunningOperationDialog.vue";
import ChipplyConstants from "@/chipply/ChipplyConstants";
import SpreadsheetProductViewModel from "@/chipply/view-model/SpreadsheetProductViewModel";
import ProductSearchModeSelector from "../products/add/ProductSearchModeSelector.vue";
import { ProductSearchConstants } from "@/chipply/products/add/ProductSearchConstants";
import { Cancelable, debounce } from "lodash";
import ErrorReporter from "../utility/ErrorReporter.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CAttention from "@/components/ui/CAttention.vue";

@Component({
    components: {
        CAddButton,
        CEditButton,
        CMoney,
        EventProductColorSizeGrid,
        FundraisingSelection,
        LongRunningOperationDialog,
        TaxTypeSelection,
        TextHeading,
        TreeSelector,
        ProductMoreInfo,
        ProductSearchModeSelector,
        ErrorReporter,
        CDecisionCard,
        CAttention,
    },
    props: {
        eventId: Number,
        catalogBatchId: Number,
        initialSelectedIds: Array,
        disabledIds: Array,
        maxHeight: Number,
    },
})
export default class ProductsSelector extends mixins(ScrollableContentMixin) {
    @Prop({
        default: () => new ProductsSelectorViewModel(),
        type: Object,
    })
    public vm!: ProductsSelectorViewModel;

    @Prop({
        default: false,
    })
    public onlyOrderedProducts!: boolean;

    public Utils = Utils;

    public chipplyIcons = chipplyIcons;

    public eventId!: number;

    public catalogBatchId!: number;

    public CHIPPLY_EVENT_ID = ChipplyConstants.CHIPPLY_EVENT_ID;

    public maxHeight!: number | null;
    public height: number | null = this.maxHeight;

    @Prop({
        default: true,
    })
    public tableMode!: boolean;

    @Prop({
        default: false,
    })
    public canChangeView!: boolean;

    public $refs!: {
        searchPanel: HTMLDivElement;
        moreInfoDialog: ProductMoreInfo;
        productsTable: Vue;
        productTileContainer: HTMLDivElement;
        attentionContainer: HTMLDivElement;
    };

    protected hasMobileHeader = false;

    @Prop({ default: false, type: Boolean })
    public multipleSelect!: boolean;

    public disabledIds!: number[];
    public initialSelectedIds!: number[];
    public handleDebouncedScroll!: ((args: any) => void) & Cancelable;
    public noDataAlertWidth = "100%";
    public productTileViewPaddingLeft = 10;

    public async created() {
        this.handleDebouncedScroll = debounce(this.checkNextPage, 100);
        this.vm.initialize(
            this.initialSelectedIds,
            this.disabledIds,
            this.onlyOrderedProducts,
            this.eventId,
            this.catalogBatchId,
            this.multipleSelect
        );
    }

    public async mounted() {
        this.subscribeScrollEvents();
        this.setNoDataAlertWidth();
    }

    public async beforeDestroy() {
        if (this.$refs.productsTable) {
            const tableChild = this.$refs.productsTable.$el.firstElementChild;
            if (tableChild) {
                tableChild.removeEventListener("scroll", this.handleDebouncedScroll);
            }
        }

        const reviewContainer = this.$refs.productTileContainer;
        if (reviewContainer) {
            reviewContainer.removeEventListener("scroll", this.handleDebouncedScroll);
            reviewContainer.addEventListener("scroll", this.handleDebouncedScroll);
        }
    }

    public setNoDataAlertWidth() {
        const productTileContainer = this.$refs.productTileContainer;
        if (productTileContainer) {
            // make the v-alert width be the same with product grid tile
            const productGridTileWidth = 280;
            const columnsCountInOneLine = Math.floor(
                (productTileContainer.scrollWidth - this.productTileViewPaddingLeft) / productGridTileWidth
            );
            this.noDataAlertWidth = columnsCountInOneLine * productGridTileWidth + "px";
        }
    }

    @Watch("vm.showNoDataWarning")
    protected resetSize() {
        this.$nextTick(() => {
            this.setNoDataAlertWidth();
            this.resizeContent();
        });
    }

    protected getVariableHeadroom(): number {
        const tableFooterSize = 0;
        const attentionContainerHight = (this.$refs.attentionContainer || { clientHeight: 0 }).clientHeight;
        return tableFooterSize + (this.$refs.searchPanel || { clientHeight: 0 }).clientHeight + attentionContainerHight;
    }

    @Watch("eventId")
    protected async onEventIdChanged() {
        this.vm.onEventIdChanged(this.eventId);
    }

    protected hasHeaderSlot() {
        return !!this.$slots.heading;
    }

    protected rowClicked(row: { [index: string]: number }, args: any) {
        if (this.multipleSelect) {
            return;
        }
        this.$emit("close", true, row.eventProductId);
    }

    protected tileClicked(evt: PointerEvent, item: SpreadsheetProductViewModel) {
        item.isSelected = !item.isSelected;
        this.vm.rowCheckboxClick(evt, item);
        if (this.multipleSelect) {
            return;
        }
        this.$emit("close", true, item.eventProductId);
    }

    protected close(accepted: boolean) {
        this.$emit("close", accepted, this.vm.selectedIds);
    }

    public copyCategories() {
        const categoryIds = this.vm.searchMode.calCategoryIds;
        if (categoryIds.length == 1 && categoryIds.includes(ProductSearchConstants.allProductsCategoryId)) {
            this.doneSelected(true, []);
        } else {
            this.doneSelected(false, categoryIds);
        }
    }

    public previewStore(evt: any) {
        this.$emit("previewStore", evt);
    }

    public async doneSelected(isCopyAllFromStore = false, sourceCategoryIds: number[] = []) {
        if (
            !isCopyAllFromStore &&
            (!sourceCategoryIds || !sourceCategoryIds.length) &&
            this.vm.selectedItemsCount > 0
        ) {
            const accepted = await this.vm.raiseProductSelectedDialog();
            if (!accepted) {
                return;
            }
        }

        let selectedIds = isCopyAllFromStore || sourceCategoryIds.length > 0 ? [] : this.sortedSelectedIds();

        this.$emit("change", {
            useFeedProducts: this.vm.useFeedProducts,
            useChipplyTemplateDealer: this.vm.useChipplyTemplateDealer,
            sourceStoreId: this.vm.eventId,
            isCopyAllFromStore: isCopyAllFromStore,
            sourceCategoryIds: sourceCategoryIds,
            sourceDealerId: this.vm.dealerId,
            selectedIds: this.sortedSelectedIds(),
        });
    }

    private sortedSelectedIds() {
        const selectedIds = this.vm.selectedIds;
        if (selectedIds.length == 0) {
            return selectedIds;
        }

        if (this.vm.allProductIds.length > 0) {
            const sortedIds: number[] = [];
            this.vm.allProductIds.forEach((id) => {
                if (selectedIds.includes(id)) {
                    sortedIds.push(id);
                }
                if (sortedIds.length == selectedIds.length) {
                    return sortedIds;
                }
            });
            return sortedIds;
        }

        if (this.vm.items.length > 0) {
            const sortedIds: number[] = [];
            this.vm.items.forEach((item) => {
                if (item.isSelected) {
                    sortedIds.push(item.eventProductId);
                }
                if (sortedIds.length == selectedIds.length) {
                    return sortedIds;
                }
            });
            return sortedIds;
        }
        return selectedIds;
    }

    @Watch("tableMode")
    protected isTableModeChanged() {
        this.$nextTick(() => {
            this.subscribeScrollEvents();
        });
    }

    private subscribeScrollEvents() {
        if (this.$refs.productsTable) {
            const tableChild = this.$refs.productsTable.$el.firstElementChild;
            if (tableChild) {
                tableChild.removeEventListener("scroll", this.handleDebouncedScroll);
                tableChild.addEventListener("scroll", this.handleDebouncedScroll);
            }
        }

        const reviewContainer = this.$refs.productTileContainer;
        if (reviewContainer) {
            reviewContainer.removeEventListener("scroll", this.handleDebouncedScroll);
            reviewContainer.addEventListener("scroll", this.handleDebouncedScroll);
        }
    }

    private checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.vm.isSelectingNextPage) {
            return;
        }
        if (
            args.target.scrollTop !== 0 &&
            Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight &&
            !this.vm.wasLastPageSelected
        ) {
            this.vm.selectNextPage();
        }
    }
}
