import { defaultView, typeHint, viewModel } from "chipply-common";
import ShipStationSettingsEditor from "@/components/dealer/ShipStationSettingsEditor.vue";
import IntegrationSettingsViewModel from "../IntegrationSettingsViewModel";
import ShipStationSettings from "./ShipStationSettings";
import IShippingStore from "@/chipply/dealer/IShippingStore";
import { WebHelper } from "chipply-common";

@defaultView(ShipStationSettingsEditor)
export default class ShipStationSettingsViewModel extends IntegrationSettingsViewModel<ShipStationSettings> {
    public shippingStores: IShippingStore[] = [];
    public loading = false;

    public async getShipStationStores() {
        try {
            this.shippingStores = [];
            const defaultStore: IShippingStore = {
                storeName: "No default",
                storeId: null,
            };
            this.loading = true;
            const data: IShippingStore[] = await WebHelper.getJsonData(`/api/ShipStation/GetShippingStores`);
            this.shippingStores = data;
            this.shippingStores.push(defaultStore);
        } finally {
            this.loading = false;
        }
    }
}
