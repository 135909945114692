import FilterItemDisplayViewModel from "@/chipply/view-model/FilterItemDisplayViewModel";
import IFilterItemViewModel from "@/chipply/view-model/IFilterItemViewModel";
import FilterItemSimpleViewModel from "./FilterItemSimpleViewModel";
import FilterItemSingleSelectViewModel from "./FilterItemSingleSelectViewModel";
import FilterItemMultiSelectViewModel from "./FilterItemMultiSelectViewModel";
import { filter } from "vue/types/umd";

export default class FilterViewModel {
    public filterItems: { [key: string]: IFilterItemViewModel } = {};

    // These are bound to chips in the filter banner
    public appliedFilters: FilterItemDisplayViewModel[] = [];

    // This method should be called after the "list" method successfuly runs
    public updateAppliedFilters() {
        const displayViewModels: FilterItemDisplayViewModel[] = [];
        for (const currentFilterName in this.filterItems) {
            const filterItem = this.filterItems[currentFilterName];
            const FilterDisplayValues = filterItem.getFilterDisplayValues();
            for (const filterDisplayValue of FilterDisplayValues) {
                const filterItemDisplayViewModel = new FilterItemDisplayViewModel();
                filterItemDisplayViewModel.filterName = currentFilterName;
                filterItemDisplayViewModel.displayText = filterDisplayValue;
                displayViewModels.push(filterItemDisplayViewModel);
            }
        }
        this.appliedFilters = displayViewModels;
    }

    public clear(item: FilterItemDisplayViewModel) {
        const filterItem = this.filterItems[item.filterName];

        if (filterItem instanceof FilterItemSimpleViewModel) {
            filterItem.value = null;
        } else if (filterItem instanceof FilterItemSingleSelectViewModel) {
            filterItem.selected = null;
        } else if (filterItem instanceof FilterItemMultiSelectViewModel) {
            filterItem.selected = filterItem.selected.filter((x) => x.text != item.displayText);
        }
    }

    public clearAll() {
        for (const currentFilterName in this.filterItems) {
            const filterItem = this.filterItems[currentFilterName];
            if (filterItem instanceof FilterItemSimpleViewModel) {
                filterItem.value = null;
            } else if (filterItem instanceof FilterItemSingleSelectViewModel) {
                filterItem.selected = null;
            } else if (filterItem instanceof FilterItemMultiSelectViewModel) {
                filterItem.selected = [];
            }
        }
    }
}
