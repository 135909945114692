import { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import NavigationArea from "@/chipply/NavigationArea";
import Component from "vue-class-component";

/**
 * Page Mixin for Page components that need to support a Catalog Batch
 * Designed for use with other mixins in case the page works in multiple scenarios
 * @class
 * @mixes UserInfoMixin
 */
@Component
export default class CatalogBatchPageMixin extends mixins(UserInfoMixin) {
    public NavigationArea = NavigationArea;

    public catalogBatchId: number | null = null;
    public menuArgs: object | null = null;
    public init = false;
    public area: NavigationArea | null = null;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("batchid")) {
            this.area = NavigationArea.CatalogBatchDetails;
            this.catalogBatchId = Number(urlParams.get("batchid"));
            this.menuArgs = {
                $type: "Chipply.Service.Menu.CatalogBatchDetailsMenuArgs, Chipply.Service",
                catalogBatchId: this.catalogBatchId,
            };
            this.init = true;
        }
    }
}
