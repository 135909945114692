import { FieldRepositoryListResults } from "@/chipply/fields/FieldRepositoryListResults";
import ListUtils from "@/chipply/ListUtils";
import GroupStoreSettingsPageModel from "@/chipply/store/settings/GroupStoreSettingsPageModel";
import { GroupStoreSettingsViewModel } from "@/chipply/store/settings/GroupStoreSettingsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { typeDependencies } from "chipply-common";

@typeDependencies({
    types: {
        FieldRepositoryListResults,
    },
})
export default class GroupStoreSettingsPageViewModel extends StoreSettingsPageViewModel {
    public store: GroupStoreSettingsViewModel | null = null;

    public get saveDisabled() {
        return !(this.isValid && this.storeUrlValidation.valid && this.store!.distribution.isValid);
    }

    protected initializeSettingsViewModel(model: StoreSettingsPageModel): void {
        this.store = new GroupStoreSettingsViewModel(model, this);
    }
}
