





















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import { Utils } from "chipply-common";
import { DateTime } from "luxon";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
})
export default class CDate extends Vue {
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;
    @Prop({
        type: String,
    })
    public value!: string | number;
    public inputValue: string | number | null = null;
    @Prop()
    public label!: string;
    public showMenu = false;
    @Prop()
    public rules!: any[];
    @Prop({ type: Boolean })
    public hideDetails!: boolean;
    @Prop()
    public disabled!: boolean;
    @Prop()
    public clearable!: boolean;
    @Prop({ default: chipplyIcons.mdiCalendar, type: String })
    public icon!: string;
    @Prop({ type: Boolean, default: false })
    public useWhiteInputText!: boolean;

    @Prop({
        type: String,
        default: "1900-01-01",
    })
    public minimumDate!: string;

    @Prop({
        type: String,
        default: "2199-12-31",
    })
    public maximumDate!: string;

    @Prop({
        type: String,
        default: "Black",
    })
    public calendarColor!: string;

    public inputTextValue = "";

    public mounted() {
        if (this.value) {
            this.onValueChanged();
        }
    }

    @Watch("value")
    public onValueChanged() {
        this.inputValue = this.value;
        if (this.inputValue) {
            // this.inputTextValue = this.inputValue.toString();
            const date = DateTime.fromISO(this.inputValue.toString());
            this.inputTextValue = date.toLocaleString();
        }
    }

    @Watch("inputValue")
    public onInputValueChanged() {
        this.$emit("change", this.inputValue);
    }
}
