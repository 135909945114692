
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import FilterItemDisplayViewModel from "@/chipply/view-model/FilterItemDisplayViewModel";
import CChip from "./CChip.vue";
import { EventBus } from "@/chipply/EventBus";

@Component({
    components: {
        CChip,
    },
})
export default class CFilterBanner extends Vue {
    @Prop({
        default: {},
        type: Object,
    })
    public vm!: PageViewModel;

    @Prop({
        type: Function,
    })
    public refreshDataFunc!: () => void;

    public filterButtonClicked() {
        if (this.vm) {
            this.vm.isOptionsDrawerVisible = !this.vm.isOptionsDrawerVisible;
        }
        EventBus.$emit("show-filter-editor");
    }

    public clearFilter(item: FilterItemDisplayViewModel) {
        this.vm.filtersViewModel.clear(item);
        this.refreshDataFunc();
    }
}
