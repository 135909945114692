import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import MergeFields from "@/chipply/merge/MergeFields";
import { ITextValue } from "chipply-common";

export abstract class StoreMergeTemplateViewModelBase extends AutomatedMergeTemplateViewModel {
    public get smsMergeFields(): Array<ITextValue<string>> {
        if (this.trigger === EmailTemplateTriggerTypeEnum.AbandonedCart) {
            return MergeFields.getSmsAbandonedCartMergeFields();
        } else if (this.trigger === EmailTemplateTriggerTypeEnum.BatchCreation) {
            return MergeFields.getSmsBatchCreationMergeFields();
        } else {
            return MergeFields.getSmsStoreTriggerMergeFields();
        }
    }

    public get htmlBodyMergeFields(): Array<ITextValue<string>> {
        if (this.trigger === EmailTemplateTriggerTypeEnum.AbandonedCart) {
            return MergeFields.getAbandonedCartBodyMergeFields();
        } else if (this.trigger === EmailTemplateTriggerTypeEnum.BatchCreation) {
            return MergeFields.getBatchCreationBodyMergeFields();
        } else {
            return MergeFields.getStoreTriggerBodyMergeFields();
        }
    }

    public get htmlSubjectMergeFields(): Array<ITextValue<string>> {
        if (this.trigger === EmailTemplateTriggerTypeEnum.AbandonedCart) {
            return MergeFields.getAbandonedCartSubjectMergeFields();
        } else if (this.trigger === EmailTemplateTriggerTypeEnum.BatchCreation) {
            return MergeFields.getBatchCreationSubjectMergeFields();
        } else {
            return MergeFields.getStoreTriggerSubjectMergeFields();
        }
    }
}
