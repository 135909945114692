import { defaultViewKey } from "chipply-common";
import RootStore from "@/store/RootStore";
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
export default class ViewFactory {
    public static getView(viewModel: object): any {
        const viewType = Reflect.getMetadata(defaultViewKey, viewModel.constructor);
        //const vueClass = Vue.extend(type);
        const component = new viewType({ vuetify, store: RootStore });
        component.vm = viewModel;
        return component;
    }
}
