import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import cronVuetify from "@vue-js-cron/vuetify";
import "@vue-js-cron/vuetify/dist/vuetify.css";

import { ChipplyIconLibrary } from "@/components/chipply-icons/ChipplyIconLibrary";

Vue.use(cronVuetify);
Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg",
        values: ChipplyIconLibrary,
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                accent: "#878787",
                delete: "#FF5252",
                error: "#FF5252",
                info: "#2196F3",
                primary: "#056DB9",
                secondary: "#e6e4e4",
                success: "#4CAF50",
                summary: "#f7f7f7",
                test: "#0f5384",
                warning: "#FFC107",
                black: "#333",
            },
            // dark: {
            //     primary: '#878787',
            //     secondary: '#e6e4e4',
            //     accent: '#0f5384',
            //     test: '#0f5384',
            //     error: '#FF5252',
            //     info: '#2196F3',
            //     success: '#4CAF50',
            //     warning: '#FFC107',
            //     delete: '#FF5252'
            // }
        },
    },
});
