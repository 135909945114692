import EditableViewModel from "@/chipply/EditableViewModel";
import IOrganizationContactViewModel from "@/chipply/organization/IOrganizationContactViewModel";
import IStoreOrganizationContactModel from "@/chipply/organization/IStoreOrganizationContactModel";
import { IEdit, Utils } from "chipply-common";

export default class OrganizationContactEditViewModel
    extends EditableViewModel
    implements IEdit<OrganizationContactEditViewModel>, IOrganizationContactViewModel
{
    public storeOrganizationContactId = 0;
    public contactId = 0;
    public isPrimary = false;
    public firstName = "";
    public lastName = "";
    public email = "";
    public formattedPhoneNumber = "";
    public phoneExtension = "";

    public constructor(contact?: IStoreOrganizationContactModel) {
        super();
        this.updateFromModel(contact);
    }

    public updateFromModel(contact?: IStoreOrganizationContactModel) {
        if (contact) {
            this.storeOrganizationContactId = contact.storeOrganizationContactId;
            this.contactId = contact.contactId;
            this.isPrimary = contact.isPrimary;
            this.firstName = contact.firstName;
            this.lastName = contact.lastName;
            this.email = contact.email;
            this.formattedPhoneNumber = Utils.formatPhone(contact.phoneNumber);
            this.phoneExtension = contact.phoneExtension;
        }
    }

    public apply(vm: OrganizationContactEditViewModel): void {
        this.storeOrganizationContactId = vm.storeOrganizationContactId;
        this.contactId = vm.contactId;
        this.isPrimary = vm.isPrimary;
        this.firstName = vm.firstName;
        this.lastName = vm.lastName;
        this.email = vm.email;
        this.formattedPhoneNumber = vm.formattedPhoneNumber;
        this.phoneExtension = vm.phoneExtension;
    }

    public createEditViewModel(): OrganizationContactEditViewModel {
        return new OrganizationContactEditViewModel(this.toModel());
    }

    public toModel(): IStoreOrganizationContactModel {
        return {
            contactId: this.contactId,
            email: this.email,
            firstName: this.firstName,
            isPrimary: this.isPrimary,
            lastName: this.lastName,
            phoneNumber: Utils.stripPhoneFormatting(this.formattedPhoneNumber),
            storeOrganizationContactId: this.storeOrganizationContactId,
            phoneExtension: this.phoneExtension,
        };
    }
}
