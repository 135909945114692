














import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class CSaveButton extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        default: "success",
        type: String,
    })
    public color!: string;

    @Prop({
        default: false,
        type: Boolean,
    })
    public disabled!: boolean;

    @Prop({
        default: "Save",
        type: String,
    })
    public tooltip!: string;

    @Prop({
        default: false,
        type: Boolean,
    })
    public fab!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public small!: boolean;
}
