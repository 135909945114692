import { AsyncInteractionViewModel } from "chipply-common";
import IEcertDto from "@/chipply/ecerts/IEcertDto";
import _ from "lodash";

export class EcertEditViewModel extends AsyncInteractionViewModel {
    public get ecert() {
        return this._ecert;
    }
    public set ecert(value) {
        this._ecert = value;
        this._originalEcert = JSON.parse(JSON.stringify(value));
    }
    public get hasChanges() {
        return !_.isEqual(this._originalEcert, this.ecert);
    }
    public isValid = false;
    private _ecert: IEcertDto | null = null;
    private _originalEcert: IEcertDto | null = null;
}
