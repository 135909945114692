import { IPurchaseOrderWarehouse } from "./IPurchaseOrderWarehouse";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import { IVendorShipMethod } from "./VendorShipMethods";

export default class VendorPurchaseOrderSettings implements IVendorPurchaseOrderSettings {
    [x: string]: unknown;
    vendorName = "";
    shipMethod = "";
    canSelectWarehouse = false;
    warehouses: IPurchaseOrderWarehouse[] = [];

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return [];
    }

    public hasAvailableShipMethods() {
        return this.getAvailableShipMethods().length > 0;
    }
}
