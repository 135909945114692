













import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import OrganizationEditViewModel from "@/chipply/organization/OrganizationEditViewModel";
import OrganizationBranchEditViewModel from "@/chipply/organization/OrganizationBranchEditViewModel";
import OrganizationContactEditViewModel from "@/chipply/organization/OrganizationContactEditViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import EditOrganizationContact from "@/components/EditContact.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CPhone from "@/components/ui/CPhone.vue";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";
import { mask } from "vue-the-mask";

@Component({
    components: {
        EditOrganizationContact,
        CDecisionCard,
    },
})
export default class EditOrganizationContactDecisionCard extends mixins(FormValidationMixin) {
    public isValid = false;

    @Prop({
        type: Object,
    })
    public vm!: OrganizationContactEditViewModel;
    protected Validation = Validation;

    @Watch("vm")
    protected vmChanged() {
        this.validate();
    }
}
