import { IListDataTableHeaderItem, ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { ITextValue, Utils, WebHelper } from "chipply-common";

import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import _ from "lodash";
import SortProperty from "../data-access/SortProperty";
import PageState from "../PageState";
import ICompanyUserModel, { IListCompanyUserFilters } from "./ICompanyUserModel";
import CompanyUserItemViewModel from "./CompanyUserItemViewModel";
import UserLevel from "../UserLevel";
import EditUserViewModel from "./EditUserViewModel";
import { EventBus } from "../EventBus";
import { SimpleAsyncInteractionViewModel } from "chipply-common";
import NavigationArea from "../NavigationArea";
import { UrlUtils } from "chipply-common";
import OrganizationContactAddViewModel from "../organization/OrganizationContactAddViewModel";
import OrganizationContactEditViewModel from "../organization/OrganizationContactEditViewModel";
import userLevel from "../UserLevel";

export class UsersViewModel extends ListPageViewModel {
    public dataTableItemKey = "id";
    public dataTableShowExpand = false;
    public dataTableDisableSort = false;
    public availableUserLevels: ITextValue<number>[] = [];
    public defaultUserLevels = Utils.convertEnumToArray<object, number>(UserLevel);
    public dataTableHeaders: IListDataTableHeaderItem[] = [
        { text: "First Name", value: "firstName", width: "150px" },
        { text: "Last Name", value: "lastName", width: "150px" },
        { text: "User Level", value: "userLevelId", width: "150px" },
        { text: "User Name", value: "userName", width: "200px" },
        { text: "Email", value: "email" },
        { text: "Created On", value: "createdOn", width: "130px" },
        { text: "Enabled", sortable: true, value: "enabled", width: "100px" },
    ];

    public get pagination() {
        return this._pagination;
    }
    public set pagination(value: IVuetifyTableOptions) {
        if (!_.isEqual(this._pagination, value)) {
            this._pagination = value;
        }
    }
    private _pagination: IVuetifyTableOptions = {
        itemsPerPage: 100,
        page: 1,
        sortDesc: [true, false, false],
        sortBy: ["enabled", "firstName", "lastName"],
    };

    public pageState!: PageState;
    public dataTableLoading = false;
    public dataTableFixedHeader = true;
    public serverItemsLength = 0;
    public items: CompanyUserItemViewModel[] = [];
    public companyId!: number;
    public orgId: number | null = null;
    public dealerId!: number;
    public isDetailViewVisible = false;
    public editorViewModel: EditUserViewModel | null = null;
    public filters: IListCompanyUserFilters = this.getDefaultFilters();
    public selectedItems: CompanyUserItemViewModel[] = [];
    public disableDialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public loggedInUserLevelId: userLevel = 0;

    public organizationContactAddViewModel: OrganizationContactAddViewModel | null = null;

    public get showAddContactDialog() {
        return this.organizationContactAddViewModel != null;
    }

    public get showDisableDialog() {
        return this.disableDialogViewModel != null;
    }

    public constructor() {
        super();
        this.pageState = new PageState(this._pagination);
    }

    public getDefaultFilters() {
        const filters: IListCompanyUserFilters = {
            companyId: this.companyId,
            dealerId: null,
            userLevels: this.availableUserLevels.map((x) => x.value),
            userLevel: null,
            isEnabled: null,
            firstName: "",
            lastName: "",
            email: "",
            userName: "",
        };
        return filters;
    }

    public clear() {
        this.filters = this.getDefaultFilters();
    }

    public async refreshData(): Promise<void> {
        await this.list(true);
    }

    public buildFilters(): IListCompanyUserFilters {
        const filters = {
            ...this.filters,
        } as IListCompanyUserFilters;
        filters.companyId = this.companyId;
        if (this.dealerId > 0) {
            filters.dealerId = this.dealerId;
        }
        filters.userLevels = this.availableUserLevels.map((x) => x.value);
        return filters;
    }

    public async list(isNewSearch: boolean): Promise<any> {
        if (!this.companyId) {
            console.log("Invalid companyId. Will not query server.");
            return;
        }

        if (this.dataTableLoading) {
            return;
        }

        if (isNewSearch) {
            this.items = [];
            this.selectedItems = [];
            this.pageState.reset();
        }

        const sortProperties = [];
        for (let i = 0; i < this._pagination.sortBy.length; i++) {
            const sortBy = this._pagination.sortBy[i];
            const sortDesc = this._pagination.sortDesc[i];
            const sortProperty = new SortProperty(sortBy, sortDesc);
            sortProperties.push(sortProperty);
        }

        const args = {
            filters: this.buildFilters(),
            pageState: this.pageState,
            sortProperties,
        };

        try {
            if (this.pageState.wasLastPageSelected) {
                return;
            }

            this.dataTableLoading = true;
            this.statusMessage = this.loadingMessage;
            this.errorMessage = null;

            const serverResults = await WebHelper.postJsonData(`/api/Company/CompanyUsers`, args);
            const results = await JSON.parse(serverResults);
            this.pageState.next(results.companyUsers.length);

            const items = [];
            for (const dto of results.companyUsers) {
                const item = new CompanyUserItemViewModel(dto as ICompanyUserModel);
                items.push(item);
            }
            this.items.push(...items);
            this.serverItemsLength = results.totalRows;
        } catch (error) {
            console.log(error);
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.dataTableLoading = false;
            this.statusMessage = null;
        }
    }

    public back() {
        this.isDetailViewVisible = false;
        this.editorViewModel = null;
        this.refreshData();
    }

    public async addUser() {
        if (this.area == NavigationArea.OrganizationDetails) {
            // use add dialog for adding organization contact
            const organizationContactAddViewModel = new OrganizationContactAddViewModel([], true);
            this.organizationContactAddViewModel = organizationContactAddViewModel;
            const result = await this.organizationContactAddViewModel.edit();
            this.organizationContactAddViewModel = null;
            if (result?.canceled) {
                return;
            }
            this.loading = true;
            try {
                const response = await WebHelper.postJsonData(
                    `/api/Organization/PostOrganizationContact/${this.orgId!}`,
                    organizationContactAddViewModel.toModel()
                );
                const contact = JSON.parse(response);
                const orgContact = new OrganizationContactEditViewModel(contact);
                if (orgContact.contactId > 0) {
                    this.successMessage = "Contact Added";
                }
            } finally {
                this.loading = false;
            }
            this.list(true);
        } // use edit view for adding user
        else {
            const editorViewModel = new EditUserViewModel();
            editorViewModel.area = this.area;
            editorViewModel.menuData = this.menuData;
            switch (this.area) {
                case NavigationArea.OrganizationDetails:
                    editorViewModel.title = "Add Contact";
                    break;
                case NavigationArea.SuperadminAdmins:
                    editorViewModel.title = "Add Admin";
                    break;
                default:
                    editorViewModel.title = "Add User";
                    break;
            }
            editorViewModel.companyId = this.companyId;
            if (this.dealerId > 0) {
                editorViewModel.dealerId = this.dealerId;
            }
            editorViewModel.shouldHideLoginFields = this.shouldHideLoginFields();
            editorViewModel.filteredUserLevels.length = 0;
            editorViewModel.filteredUserLevels.push(...this.availableUserLevels);
            editorViewModel.allowBackToParent = true;

            editorViewModel.loggedInUserLevelId = this.loggedInUserLevelId;

            this.editorViewModel = editorViewModel;
            this.isDetailViewVisible = true;
        }
    }

    public editUser(userIdToEdit: number) {
        const editorViewModel = new EditUserViewModel();
        editorViewModel.area = this.area;
        editorViewModel.menuData = this.menuData;

        switch (this.area) {
            case NavigationArea.OrganizationDetails:
                editorViewModel.title = "Edit Contact";
                break;
            case NavigationArea.SuperadminAdmins:
                editorViewModel.title = "Edit Admin";
                break;
            default:
                editorViewModel.title = "Edit User";
                break;
        }
        editorViewModel.shouldHideLoginFields = this.shouldHideLoginFields();
        editorViewModel.userId = userIdToEdit;
        editorViewModel.loggedInUserLevelId = this.loggedInUserLevelId;

        if (this.dealerId > 0) {
            editorViewModel.dealerId = this.dealerId;
        }
        editorViewModel.filteredUserLevels.length = 0;
        editorViewModel.filteredUserLevels.push(...this.availableUserLevels);
        editorViewModel.allowBackToParent = true;
        this.editorViewModel = editorViewModel;
        this.isDetailViewVisible = true;
    }

    private shouldHideLoginFields() {
        if (
            this.availableUserLevels.length == 2 &&
            this.availableUserLevels.findIndex((x) => x.value == UserLevel.OrganizationDirector) > -1 &&
            this.availableUserLevels.findIndex((x) => x.value == UserLevel.OrganizationUser) > -1
        ) {
            return true;
        } else {
            return false;
        }
    }

    public getUserLevelDisplay(userLevelId: number) {
        return this.defaultUserLevels.find((ul) => ul.value === userLevelId)?.text;
    }

    public updateUserLevels(userLevelIds: number[]) {
        this.filters.userLevels = userLevelIds;
        this.availableUserLevels = this.defaultUserLevels.filter((x) => userLevelIds.indexOf(x.value) !== -1);
    }

    public async autoLogin(item: CompanyUserItemViewModel) {
        try {
            this.dataTableLoading = true;
            this.statusMessage = this.loadingMessage;
            this.errorMessage = null;

            const args = {
                userId: item.userId,
            };

            const serverResults = await WebHelper.postJsonData(`/api/Login/CreateAutoLogin`, args);
            const results = await JSON.parse(serverResults);
            location.assign("/AutoLogin.aspx?guid=" + results.id);
        } catch (error) {
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.dataTableLoading = false;
            this.statusMessage = null;
        }
    }

    public async disableDialog(item: CompanyUserItemViewModel) {
        if (item.userId <= 0) {
            return;
        }
        const disableDialogViewModel = new SimpleAsyncInteractionViewModel();
        this.disableDialogViewModel = disableDialogViewModel;
        const result = await this.disableDialogViewModel.interact();
        this.disableDialogViewModel = null;
        if (result === "cancel") {
            return;
        }
        await this.disableUser(item);
    }

    public async disableUser(item: CompanyUserItemViewModel) {
        const editorViewModel = new EditUserViewModel();
        editorViewModel.userId = item.userId;
        if (this.dealerId > 0) {
            editorViewModel.dealerId = this.dealerId;
        }
        this.editorViewModel = editorViewModel;
        await this.editorViewModel.getAccount();
        this.editorViewModel.enabled = false;
        await this.editorViewModel.saveAccount();
        await this.refreshData();
    }

    public backButtonClicked() {
        window.location.href = UrlUtils.getVersionedLink(`/ng/list-organizations.html`);
    }
}
