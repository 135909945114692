














import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        xSmall: Boolean,
        disabled: Boolean,
    },
})
export default class CDeleteButton extends Vue {
    public chipplyIcons = chipplyIcons;
    public xSmall: boolean | undefined;
    public isXSmall = false;
    public isSmall = true;

    public mounted() {
        if (this.xSmall) {
            this.isSmall = false;
            this.isXSmall = true;
        } else {
            this.isSmall = true;
        }
    }
}
