



















































































































































































































































































































































import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import HtmlTextField from "@/components/HtmlTextField.vue";
import OrganizationBranchCard from "@/components/store/settings/OrganizationBranchCard.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { IStoreFulfillmentViewModel } from "@/chipply/event/IStoreFulfillmentViewModel";
import { OrganizationDistributionTypeEnum } from "@/chipply/event/OrganizationDistributionTypeEnum";
import { CustomerDistributionTypeEnum } from "@/chipply/event/CustomerDistributionTypeEnum";
import ChipplyIcons from "@/chipply/ImportIcons";

@Component({
    components: {
        OrganizationBranchCard,
        HtmlTextField,
        CEditButton,
        CAddButton,
    },
})
export default class StoreDistributionSettings extends mixins(FormValidationMixin) {
    public CustomerDistributionTypeEnum = CustomerDistributionTypeEnum;
    public OrganizationDistributionTypeEnum = OrganizationDistributionTypeEnum;
    public Validation = Validation;
    public chipplyIcons = ChipplyIcons;

    @Prop({
        type: Object,
        default: null,
    })
    public vm!: StoreSettingsPageViewModel | null;

    // I need to get the v-form to re-render as there seems
    // to be a bug with switching the object instance you are
    // working with a v-form. Forcing the v-form to rerender seems to fix this.
    // This is needed to keep the Save button working after the first press
    @Watch("vm.store.distribution")
    public viewChanged() {
        this.reset = true;
        this.$nextTick(() => {
            this.reset = false;
        });
    }

    @Watch("vm.store.distribution.shippingMethodId")
    public shippingIdChanged() {
        this.reset = true;
        this.$nextTick(() => {
            this.reset = false;
        });
    }

    @Watch("distribution.allowMilitaryShipping")
    public militaryShippingChanged() {
        this.distribution.updateMilitaryShippingMethods();
    }

    public reset = false;

    public get distribution() {
        return (this.vm!.store! as IStoreFulfillmentViewModel).distribution;
    }
}
