
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class CTextButton extends Vue {
    @Prop({
        type: String,
    })
    public label!: string;
}
