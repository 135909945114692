













































































































































































































































import OrderItemViewModel from "@/chipply/ecom-orders/OrderItemViewModel";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import TextHeading from "@/components/utility/TextHeading.vue";
import Validation from "@/validation";
import { ProcessItemType, TaxType, Utils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { mask } from "vue-the-mask";

@Component({
    components: {
        TaxTypeSelection,
        TextHeading,
    },
    directives: { mask },
    props: {
        orderItem: Object,
    },
})
export default class EditOrderItem extends Vue {
    public Utils = Utils;
    public name = "EditOrderItem";
    public orderItem!: OrderItemViewModel;
    public quantities!: number[];
    public isValid!: boolean;
    public showPurchasingWarning = false;
    public isPurchasingColorWarning = false;
    public originalSelectedSize: number | null = null;
    public originalSelectedColor: number | null = null;

    public $refs!: { form: any };

    public get computedWarningMessage() {
        let originalColorName = this.orderItem.purchaseOrderColor;
        let originalSizeName = this.orderItem.purchaseOrderSize;
        return `This item has a purchase order associated with it for the ${
            this.isPurchasingColorWarning ? "color " + originalColorName : "size " + originalSizeName
        } , are you sure you would like to change the ${this.isPurchasingColorWarning ? "color" : "size "}?`;
    }

    public async created() {
        if (!this.orderItem) {
            return;
        }
        await this.initialize();
    }

    public async initialize() {
        this.buildQuantities();
        this.$nextTick(() => {
            this.$refs.form.validate();
        });
        this.originalSelectedColor = this.orderItem.selectedColor;
        this.originalSelectedSize = this.orderItem.selectedSize;
    }

    public data() {
        return {
            Validation,
            isValid: false,
            quantities: [],
        };
    }

    protected optionsChanged() {
        let totalCost = 0;

        for (const option of this.orderItem.options) {
            let selectedValueDisplay: string | null = null;
            if (
                option.processTypeId === ProcessItemType.OptionExclusiveGroup ||
                option.processTypeId === ProcessItemType.OptionGroup
            ) {
                if (option.eventProcessOptionId! <= 0) {
                    continue;
                }

                if (!option.isRequired) {
                    totalCost += option.cost;
                }

                for (const optionListItem of option.optionsList) {
                    if (optionListItem.optionId !== option.eventProcessOptionId) {
                        continue;
                    }
                    totalCost += optionListItem.price;
                    selectedValueDisplay = optionListItem.optionName;
                }
            } else if (Utils.hasValue(option.optionValue)) {
                if (!option.isRequired) {
                    totalCost += option.cost;
                }
                selectedValueDisplay = option.optionValue;
            }

            option.optionValueDisplay = selectedValueDisplay;
        }

        this.orderItem.optionsPrice = totalCost;
    }

    protected sizeChanged() {
        for (const size of this.orderItem.sizes) {
            if (size.sizeId !== this.orderItem.selectedSize) {
                continue;
            }
            this.orderItem.sizePrice = size.cost;
            break;
        }
        if (this.orderItem.hasPurchaseOrderItem) {
            this.isPurchasingColorWarning = false;
            this.showPurchasingWarning = true;
        }
    }

    protected colorChanged() {
        if (this.orderItem.hasPurchaseOrderItem) {
            this.showPurchasingWarning = true;
            this.isPurchasingColorWarning = true;
        }
    }

    public purchasingWarningCancelClicked() {
        if (this.isPurchasingColorWarning) {
            this.orderItem.selectedColor = this.originalSelectedColor!;
        } else {
            this.orderItem.selectedSize = this.originalSelectedSize!;
        }
        this.showPurchasingWarning = false;
    }

    protected selectedTaxSettingsChanged(taxSettings: { taxType: TaxType; taxRate: number | undefined }) {
        this.orderItem.selectedTaxType = taxSettings.taxType;
        this.orderItem.taxRate = taxSettings.taxRate;
    }

    protected buildQuantities() {
        this.quantities = [];

        if (!this.orderItem) {
            return;
        }

        for (let i = this.orderItem.lowerQuantityBounds; i <= this.orderItem.upperQuantityBounds; i++) {
            this.quantities.push(i);
        }
    }
}
