









































import Component, { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import EditOrderNotesViewModel from "@/chipply/manage-orders/EditOrderNotesViewModel";
import { Prop } from "vue-property-decorator";
import CMoney from "../ui/CMoney.vue";
import { Utils } from "chipply-common";

@Component({
    components: {
        CMoney,
    },
})
export default class TransactionalHistory extends mixins(UserInfoMixin) {
    @Prop()
    public orderId!: number;
    @Prop()
    public vm!: EditOrderNotesViewModel;

    public name = "TransactionalHistory";

    public headers = [
        { text: "Transaction Date", value: "transactionDate", sort: (a: string, b: string) => this.sortDates(a, b) },
        { text: "Transaction Type", value: "transactionTypeID" },
        { text: "Reference Number", value: "ccTransactionRef" },
        { text: "CC 4 Digits", value: "ccLast4Digits" },
        { text: "Amount Collected", value: "amountCollected" },
        { text: "Amount E-Cert", value: "ecertTotal" },
        { text: "PO Total", value: "poTotal" },
        { text: "Promo Total", value: "promoTotal" },
        { text: "Order Total", value: "orderTotal" },
    ];

    public async created() {
        await this.vm.getTransactions();
    }

    public getTransactionTypeName(id: number) {
        switch (id) {
            case 1:
                return "Original Order";
            case 2:
                return "Additional Charge";
            case 3:
                return "Refund";
            case 4:
                return "Void";
            case 5:
                return "Chipply Fee";
            case 6:
                return "E-Certifcate";
            case 7:
                return "Original PO Order";
            case 8:
                return "PO Void";
            case 9:
                return "PO Refund";
            case 10:
                return "PO Additional Charge";
            case 11:
                return "E-Certificate Addition";
            case 12:
                return "E-Certificate Refund";
            case 13:
                return "E-Certificate Void";
            default:
                return "Unknown";
        }
    }

    public sortDates(a: string, b: string) {
        const dateA = new Date(a);
        const dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
    }

    public convertCurrency(amount: number) {
        return amount < 0
            ? "(" + Utils.getCurrencyValue(-amount, false, true) + ")"
            : Utils.getCurrencyValue(amount, false, true);
    }
}
