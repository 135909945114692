





















































import Component, { mixins } from "vue-class-component";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import { Prop } from "vue-property-decorator";
import { IWorkOrderPageStaticTop } from "@/chipply/reports/WorkOrder/IWorkOrderPageStaticTop";
import chipplyIcons from "../../chipply/ImportIcons";
import { isBoolean } from "lodash";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";
import WorkOrderStaticTopToggleOptions from "@/chipply/reports/WorkOrder/WorkOrderStaticTopToggleOptions";

@Component({
    computed: {
        chipplyIcons() {
            return chipplyIcons;
        },
    },
})
export default class WorkOrderReportHeading extends mixins(ScrollableContentMixin) {
    @Prop({
        type: Object,
    })
    public staticTop!: IWorkOrderPageStaticTop;

    @Prop({
        type: isBoolean,
    })
    public AllowDueDateUpdate!: boolean;

    @Prop({
        type: Object,
    })
    public toggleOptions!: WorkOrderStaticTopToggleOptions;

    public UpdateDueDate() {
        this.$emit("UpdateDueDate", "01/01/2021");
    }
}
