













import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        prop: "value",
        event: "change",
    },
})
export default class CSwitch extends Vue {
    @Prop({
        type: String,
    })
    public label!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public disabled!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public value!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public italics!: boolean;

    public internalValue = this.value;

    @Watch("value")
    public valueChanged() {
        this.internalValue = this.value;
    }

    public inputChanged = (value: boolean) => {
        this.internalValue = value;
        this.$emit("change", this.internalValue);
    };
}
