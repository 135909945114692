















import { EventType } from "@/chipply/event/EventType";
import IBatchedCopyEventArgs from "@/chipply/event/IBatchedCopyEventArgs";
import IServerStatus from "@/chipply/IServerStatus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import * as userInfoStore from "@/store/UserInfoStore";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import StoreCreationOptions from "@/components/events/StoreCreationOptions.vue";
import { EventCopierDialogViewModel } from "@/chipply/event/EventCopierDialogViewModel";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import TextHeading from "@/components/utility/TextHeading.vue";

@Component({
    components: { TextHeading, CDecisionCard, LongRunningOperationDialog, StoreCreationOptions },
    model: {
        prop: "value",
        event: "change",
    },
})
export default class EventCopier extends Vue {
    public name = "EventCopier";

    @Prop({ type: Object })
    public vm!: EventCopierDialogViewModel;
    public userId!: number;

    public async created() {
        await userInfoStore.initialize(this.$store);
        this.userId = userInfoStore.getUserId(this.$store);
    }
}
