import ArtworkReviewImageViewModel from "./ArtworkReviewImageViewModel";
import IEventProductColorReviewModel from "./IProductColorReviewModel";
import ProductReviewImageViewModel from "./ProductReviewImageViewModel";

export default class ProductColorReviewViewModel {
    eventProductColorId!: number;
    eventProductColorProcessId!: number;
    hexCode1!: string;
    colorName!: string;
    colorImages: ProductReviewImageViewModel[] = [];
    artworkImages: ArtworkReviewImageViewModel[] = [];
    sortOrder!: number;

    public constructor(dto?: IEventProductColorReviewModel) {
        if (!dto) {
            return;
        }
        this.eventProductColorId = dto.eventProductColorId;
        this.eventProductColorProcessId = dto.eventProductColorProcessId;
        this.sortOrder = dto.sortOrder;
        this.hexCode1 = dto.hexCode1;
        this.colorName = dto.colorName;
        this.colorImages = dto.colorImages.map((c) => new ProductReviewImageViewModel(c, dto.hexCode1));
        this.artworkImages = dto.artworkImages.map((c) => new ArtworkReviewImageViewModel(c, dto.hexCode1));
    }

    // public convertFromColor(color: ISpreadsheetProductColorDto) {
    //     this.eventProductColorId = color.eventProductColorId;
    //     return this;
    // }

    public toModel(): IEventProductColorReviewModel {
        return {
            eventProductColorId: this.eventProductColorId,
            eventProductColorProcessId: this.eventProductColorProcessId,
            hexCode1: this.hexCode1,
            colorName: this.colorName,
            sortOrder: this.sortOrder,
            colorImages: [],
            artworkImages: [],
        };
    }
}
