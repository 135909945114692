
































































import { CanvasHelper } from "@/chipply/canvas/CanvasHelper";
import { ProcessLayoutPageViewModel } from "@/chipply/process/ProcessLayoutPageViewModel";
import { EventBus } from "@/chipply/EventBus";
import { IProcessItemArtworkBoxPosition } from "@/chipply/process/IProcessItemArtworkBoxPosition";
import { IProcessArtworkBox } from "@/chipply/process/IProcessArtworkBox";
import ProcessLayoutCanvas from "@/components/process/ProcessLayoutCanvas.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import { fabric } from "fabric";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";

@Component({
    components: {
        ProcessLayoutCanvas,
        CAddButton,
        CDeleteButton,
    },
})
export default class ProcessLayoutEditor extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        type: Object,
        required: true,
    })
    public vm!: ProcessLayoutPageViewModel;
}
