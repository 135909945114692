import EditableViewModel from "@/chipply/EditableViewModel";
import { ITextValueDisabled } from "chipply-common";
import IOrganizationContactViewModel from "@/chipply/organization/IOrganizationContactViewModel";
import IStoreOrganizationContactModel from "@/chipply/organization/IStoreOrganizationContactModel";
import Validation from "@/validation";
import { Utils } from "chipply-common";

export enum OrganizationContactAddType {
    Existing = 0,
    New = 1,
}

export default class OrganizationContactAddViewModel
    extends EditableViewModel
    implements IOrganizationContactViewModel
{
    public contacts: Array<ITextValueDisabled<number>> = [];
    public existingId = 0;
    public isPrimary = false;
    public firstName = "";
    public lastName = "";
    public email = "";
    public formattedPhoneNumber = "";
    public showNewContactOnly = false;
    public phoneExtension = "";
    public addType: OrganizationContactAddType = OrganizationContactAddType.Existing;

    public constructor(contacts: Array<ITextValueDisabled<number>>, showNewContactOnly?: boolean) {
        super();
        this.contacts = contacts;
        this.showNewContactOnly = showNewContactOnly === true;
    }

    public toModel(): IStoreOrganizationContactModel {
        return {
            contactId: 0,
            email: this.email,
            firstName: this.firstName,
            isPrimary: this.isPrimary,
            lastName: this.lastName,
            phoneNumber: Utils.stripPhoneFormatting(this.formattedPhoneNumber),
            storeOrganizationContactId: 0,
            phoneExtension: this.phoneExtension,
        };
    }

    public get existingContactDropDownRules() {
        const addType = this.addType;

        if (addType === OrganizationContactAddType.Existing) {
            return [Validation.requireValueNonZeroList];
        }

        return [];
    }
}
