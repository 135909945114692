























import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        copyContent: String,
        tooltip: String,
        xSmall: Boolean,
    },
})
export default class CCopyButton extends Vue {
    public tooltip!: string | null;
    public showTooltip = false;
    public copyContent!: string;

    protected computedTooltip() {
        return this.tooltip || "Copy";
    }

    protected copy() {
        this.showTooltip = true;
        navigator.clipboard.writeText(this.copyContent);
        setTimeout(() => {
            this.showTooltip = false;
        }, 1000);
    }
}
