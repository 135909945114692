import { render, staticRenderFns } from "./EditOrganizationContact.vue?vue&type=template&id=7a91e0ac&scoped=true"
import script from "./EditOrganizationContact.vue?vue&type=script&lang=ts"
export * from "./EditOrganizationContact.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a91e0ac",
  null
  
)

export default component.exports