import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, founderShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "FounderPurchaseOrderSettings" })
export default class FounderPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public vendorName = "founder";

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return founderShipMethods;
    }
}
