import { ActionContext } from "vuex";
import { IRootState } from "@/store/RootStore";
import { getStoreAccessors } from "vuex-typescript";
import { IEnvironmentState } from "./IEnvironmentState";

type EnvironmentContext = ActionContext<IEnvironmentState, IRootState>;

export const environmentStore = {
    namespaced: true,

    state: {
        chipplyEventId: undefined,
        previewWebsitePath: undefined,
        domain: "",
        userpilotEnabled: undefined,
        userpilotToken: undefined,
    },

    getters: {
        getPreviewWebsitePath(state: IEnvironmentState) {
            return state.previewWebsitePath;
        },
        getChipplyEventId(state: IEnvironmentState) {
            return state.chipplyEventId;
        },
        getDomain(state: IEnvironmentState) {
            return state.domain;
        },
        getUserpilotEnabled(state: IEnvironmentState) {
            return state.userpilotEnabled;
        },
        getUserpilotToken(state: IEnvironmentState) {
            return state.userpilotToken;
        },
    },

    mutations: {
        setEnvironment(state: IEnvironmentState, item: IEnvironmentState) {
            state.previewWebsitePath = item.previewWebsitePath;
            state.chipplyEventId = item.chipplyEventId;
            state.domain = item.domain;
            state.userpilotEnabled = item.userpilotEnabled;
            state.userpilotToken = item.userpilotToken;
        },
    },

    actions: {
        initializeEnvironment(context: EnvironmentContext) {
            const environmentString = localStorage.getItem("envInfo");
            if (!environmentString) {
                return;
            }

            const environment = JSON.parse(environmentString);
            commitEnvironment(context, environment);
        },
    },
};

const { commit, read, dispatch } = getStoreAccessors<IEnvironmentState, IRootState>("envInfo");

const mutations = environmentStore.mutations;

export const commitEnvironment = commit(mutations.setEnvironment);

const getters = environmentStore.getters;

export const getPreviewWebsitePath = read(getters.getPreviewWebsitePath);
export const getChipplyEventId = read(getters.getChipplyEventId);
export const getDomain = read(getters.getDomain);
export const getUserpilotEnabled = read(getters.getUserpilotEnabled);
export const getUserpilotToken = read(getters.getUserpilotToken);
export const initialize = dispatch(environmentStore.actions.initializeEnvironment);
