import { ITextValue } from "chipply-common";

export default class EcomOrderListFilters {
    public billEmail?: string;
    public billFirstName?: string;
    public billLastName?: string;
    public billPhone?: string;
    public dealerIds?: ITextValue<number>[];
    public orderId?: number;
    public ordersFromDate?: string;
    public ordersToDate?: string;
    public orderStatus?: string;
    public organizationId?: number;
    public saleOrder?: string;
    public eventId?: number;
    public storeStatus?: string;
    public estimatedShipDate?: string;
    public shippingType?: string;
    public catalogBatchId?: number;
    public postalCode?: string;
    public orderTotal?: number;
    public additionalInfoText?: string;
    public additionalInfoList?: string;
    public additionalInfoYesNo?: boolean;
    public isChargeback?: boolean;
}
