var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-chip',{staticClass:"chipStandard",class:{
        chipDisabled: !_vm.enabled,
        chipSelected: _vm.isSelected || _vm.isFilterBanner,
        chipNotSelected: !_vm.isSelected && !_vm.isFilterBanner,
    },style:({
        backgroundColor: _vm.isSelected || _vm.isFilterBanner ? '#056db9' : '#FFF',
        paddingLeft: _vm.icon ? '8px' : null,
    }),attrs:{"disabled":!_vm.enabled,"close-icon":_vm.closeIcon,"close":_vm.close,"title":_vm.title,"dark":_vm.dark,"variant":"flat"},on:{"click":_vm.handleClick,"click:close":_vm.handleCloseClick}},[(_vm.icon)?_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":"","color":_vm.isSelected ? 'white' : null}},[_vm._v(_vm._s(_vm.icon))])],1):_vm._e(),_vm._t("default"),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.title))])],2)}
var staticRenderFns = []

export { render, staticRenderFns }