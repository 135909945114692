import DealerCategoriesModel from "@/chipply/dealer/DealerCategoriesModel";
import GetSelectedCategoryResults from "@/chipply/dealer/GetSelectedCategoryResults";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { ITextValueDisabled } from "chipply-common";
import ListUtils from "@/chipply/ListUtils";
import SelectedCategorySaveArgs from "@/chipply/dealer/SelectedCategorySaveArgs";
import { IStoreSettingsViewModel } from "@/chipply/store/settings/IStoreSettingsViewModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import Validation from "@/validation";
import ITreeItem from "@/chipply/interface/i-tree-item";
import {
    DateUtils,
    Serializer,
    SimpleAsyncInteractionViewModel,
    SimpleAsyncInteractionWithDataViewModel,
    WebHelper,
} from "chipply-common";
import { DateTime } from "luxon";
import IStoreSettingsModel from "@/chipply/store/settings/IStoreSettingsModel";
import { isEqual } from "lodash";
import { EventBus } from "@/chipply/EventBus";
import { OrganizationHelper } from "@/chipply/organization/OrganizationHelper";
import CatalogStoreSettingsPageViewModel from "@/chipply/store/settings/CatalogStoreSettingsPageViewModel";

export abstract class StoreSettingsPageViewModel extends PageViewModel {
    public dealerId = 0;
    public menu = false;
    public store: IStoreSettingsViewModel | null = null;
    public organizationHelper: OrganizationHelper | null = null;

    public salespersons: Array<ITextValueDisabled<number>> = [];
    public dealerCategories: DealerCategoriesModel[] = [];

    public successFailureDialogViewModel: SimpleAsyncInteractionWithDataViewModel<boolean> | null = null;
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public cancelAcceptDialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public selectedCategoryIds: number[] | null = [];

    public get storeStatuses() {
        if (this instanceof CatalogStoreSettingsPageViewModel && !this.store!.isLegacyCatalogBatch) {
            return [
                { text: "Closed (Ready To Process)", value: null },
                { text: "Complete", value: 5 },
            ];
        }
        return [
            { text: "Closed (Ready To Process)", value: null },
            { text: "Production", value: 3 },
            { text: "Sorting", value: 4 },
            { text: "Complete", value: 5 },
            { text: "Backordered", value: 7, disabled: true },
        ];
    }
    public storeUrlValidation = {
        valid: true,
        loading: false,
        saleOrder: null as string | null,
        id: null as number | null,
    };

    protected originalModel: IStoreSettingsModel | null = null;

    protected abstract initializeSettingsViewModel(model: StoreSettingsPageModel): void;

    public refreshViewModel(model: StoreSettingsPageModel) {
        if (model.store) {
            this.checkAndResetDateFields(model.store);
        }
        this.fetchDealerCategories();
        this.initializeSettingsViewModel(model);
        this.initializeOrganizationHelper();
        this.organizationHelper!.processes = model.processes;
        this.organizationHelper!.feeTypes = model.feeTypes;
        this.filterLists(model);
        this.originalModel = Serializer.deserialize(Serializer.serialize(this.store?.toModel()));
    }

    public async refresh() {
        const model = await WebHelper.getJsonData(`/api/Events/Settings/${this.store?.storeId}`);
        this.refreshViewModel(model);
    }

    public created(model: StoreSettingsPageModel) {
        EventBus.$on("top-navigation-started", this.handlePageNavigation);
        this.refreshViewModel(model);
        this.loading = false;
    }

    public initializeOrganizationHelper() {
        this.organizationHelper = new OrganizationHelper(this.store, this);
    }

    public destroy() {
        EventBus.$off("top-navigation-started", this.handlePageNavigation);
    }

    public async save() {
        if (this.saveDisabled) {
            this.successFailureDialogViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.successFailureDialogViewModel.html = `Please fix page validation errors.`;
            this.successFailureDialogViewModel.data = false;
            await this.successFailureDialogViewModel.interact();
            this.successFailureDialogViewModel = null;
            return false;
        }

        if (this.hasProductTaxChanges()) {
            this.cancelAcceptDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.cancelAcceptDialogViewModel.headerText = "Product Tax Changed";
            this.cancelAcceptDialogViewModel.text = `All products in the store will be updated with new product tax settings.`;
            const dialogResult = await this.cancelAcceptDialogViewModel.interact();
            this.cancelAcceptDialogViewModel = null;
            if (dialogResult == "cancel") {
                const newProductTax = {
                    taxRate: this.originalModel!.productTax.taxRate,
                    taxType: this.originalModel!.productTax.taxType,
                };
                this.store!.productTax = newProductTax;
                return false;
            }
        }

        this.statusMessage = "Saving...";
        this.loading = true;
        this.saveSelectedDealerCategories();
        const resultsString = await WebHelper.postJsonData("/api/StoreSettings/Save", this.store!.toModel());

        const results = Serializer.deserialize(resultsString);
        if (!results.url.isValid) {
            const saleOrder = results.url.stores[0].saleOrder;
            const storeId = results.url.stores[0].storeId;
            this.successFailureDialogViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.successFailureDialogViewModel.html = `Could not save, the store URL is in use by <a style="color: darkblue" href="/ng/settings.html?eventid=${storeId}" target="_blank">Sales Order ${saleOrder}</a>`;
            this.successFailureDialogViewModel.data = false;
            await this.successFailureDialogViewModel.interact();
            this.successFailureDialogViewModel = null;
        }

        if (results.weightsInvalid) {
            this.dismissDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.dismissDialogViewModel.headerText = "PRODUCTS WITHOUT WEIGHT";
            this.dismissDialogViewModel.text = `There are products in this store with no weight set.  Please configure the product weights when shipping is enabled.`;
            await this.dismissDialogViewModel.interact();
            this.dismissDialogViewModel = null;
        }

        if (results.url.isValid) {
            await this.refresh();
        }

        this.loading = false;
        EventBus.$emit("store-settings-updated");
        return results.url.isValid;
    }

    public get saveDisabled() {
        return !(this.isValid && this.storeUrlValidation.valid);
    }

    public get dealerCategoryTreeItems() {
        return this.transformCategoriesToTreeItems(this.dealerCategories);
    }

    public async fetchDealerCategories(): Promise<void> {
        const path = "/api/dealer/ListDealerCategories";
        try {
            const results = await WebHelper.getJsonData(path);
            const allCategories: DealerCategoriesModel[] = results.categories;
            this.dealerCategories = this.sortCategoriesRecursively(allCategories);
            if (this.store !== null) {
                await this.fetchSelectedCategoryIds(this.store.storeId);
            } else {
                this.errorMessage = "Error: Store information is unavailable.";
            }
        } catch (error) {
            this.errorMessage = "Failed to fetch dealer categories.";
        }
    }

    public async fetchSelectedCategoryIds(storeId: number): Promise<void> {
        const path = `/api/dealer/GetSelectedDealerCategories?storeId=${storeId}`;
        try {
            const results = (await WebHelper.getJsonData(path)) as GetSelectedCategoryResults;
            this.selectedCategoryIds = results.selectedCategoryIds;
        } catch (error) {
            this.errorMessage = "Error fetching selected categories.";
        }
    }

    public async saveSelectedDealerCategories(): Promise<void> {
        if (!this.store || !this.store.storeId) {
            this.errorMessage = "Store ID is missing.";
            return;
        }
        const path = "/api/dealer/SaveSelectedCategories";
        const args = new SelectedCategorySaveArgs();
        args.storeId = this.store.storeId;
        args.categoryIds = this.selectedCategoryIds;

        try {
            await WebHelper.postJsonData(path, args);
        } catch (error) {
            this.errorMessage = `Error saving selected categories.`;
        }
    }

    public sortCategoriesRecursively(categories: DealerCategoriesModel[]): DealerCategoriesModel[] {
        return categories.map((category) => ({
            ...category,
            subCategories: category.subCategories ? this.sortCategoriesRecursively(category.subCategories) : [],
        }));
    }

    public transformCategoriesToTreeItems(categories: DealerCategoriesModel[]): ITreeItem[] {
        return categories
            .filter((category) => category.isActive)
            .map((category) => {
                const treeItem: ITreeItem = {
                    id: category.categoryId!,
                    name: category.categoryName!,
                    children:
                        category.subCategories && category.subCategories.length > 0
                            ? this.transformCategoriesToTreeItems(category.subCategories)
                            : [],
                };
                return treeItem;
            });
    }

    public onDealerCategoryChange(selectedIds: number[]): void {
        if (!this.store) {
            return;
        }
        if (this.store.storeId) {
            this.selectedCategoryIds = selectedIds;
        }
    }

    public requireCloseDateGreaterThanOpen = () => {
        if (!this.store!.closeDate) {
            return "Close Date must be greater than Open Date";
        }
        const closeDateString = DateUtils.getDstDate(this.store!.closeDate, this.store!.closeTime);
        const openDateString = DateUtils.getDstDate(this.store!.openDate, this.store!.openTime);

        return DateTime.fromISO(closeDateString) > DateTime.fromISO(openDateString)
            ? true
            : "Close Date must be greater than Open Date";
    };

    public requireProductionDateGreaterThanClose = () => {
        if (!this.store!.closeDate || !this.store!.productionDueDate) {
            return "Production Due Date must be greater than or equal to Close Date";
        }
        const closeDateString = DateUtils.getDstDate(this.store!.closeDate, "00:00");

        const deliverDueDate = DateTime.fromISO(this.store!.productionDueDate);

        return deliverDueDate >= DateTime.fromISO(closeDateString)
            ? true
            : "Production Due Date must be greater than or equal to Close Date";
    };

    public requireShipDateGreaterThanProduction = () => {
        if (!this.store!.productionDueDate || !this.store!.deliverDueDate) {
            return "Ship/Deliver Due Date must be greater than or equal to Production Due Date";
        }
        const deliverDueDate = DateTime.fromISO(this.store!.productionDueDate);
        const shipDueDate = DateTime.fromISO(this.store!.deliverDueDate);
        return shipDueDate >= deliverDueDate
            ? true
            : "Ship/Deliver Due Date must be greater than or equal to Production Due Date";
    };

    public async checkStoreUrl() {
        if (!this.store!.storeUrl) {
            return;
        }

        if (this.store!.storeUrl.toUpperCase() === "CAT") {
            this.storeUrlValidation.valid = false;
            this.storeUrlValidation.saleOrder = "CAT";
            this.storeUrlValidation.id = 0;
            return;
        }
        this.storeUrlValidation.loading = true;

        const validateArgs = {
            startDate: DateUtils.getDstDate(this.store!.openDate, this.store!.openTime),
            storeId: this.store!.storeId,
            storeUrl: this.store!.storeUrl,
        };

        const results = await WebHelper.postJsonData(`/api/StoreSettings/ValidateUrl`, validateArgs);
        const deserializedResults = JSON.parse(results);

        this.storeUrlValidation.saleOrder = deserializedResults.isValid
            ? null
            : deserializedResults.stores[0].saleOrder;
        this.storeUrlValidation.id = deserializedResults.isValid ? null : deserializedResults.stores[0].storeId;
        this.storeUrlValidation.valid = deserializedResults.isValid;

        this.storeUrlValidation.loading = false;
    }

    public get storeUrlErrors() {
        if (this.storeUrlValidation.valid) {
            return "";
        } else if (this.storeUrlValidation.saleOrder === "CAT") {
            return "Store URL is already in use";
        } else {
            return `Store URL is already in use by Sales Order ${this.storeUrlValidation.saleOrder}`;
        }
    }

    public get storeUrlRules() {
        return [Validation.requireUrlPart];
    }

    public get isStoreScheduled() {
        return !this.store!.storeReady || this.store!.openTimeInFuture;
    }

    protected hasChanges() {
        const modelsEqual = isEqual(this.originalModel, this.store?.toModel());
        const newModelWithErrors = !this.isValid && this.isStoreScheduled;
        return !modelsEqual || newModelWithErrors;
    }

    protected hasProductTaxChanges() {
        const originalProductTaxModel = this.originalModel?.productTax;
        const currentProductTaxModel = this.store?.toModel().productTax;
        return !isEqual(originalProductTaxModel, currentProductTaxModel);
    }

    protected filterLists(data: StoreSettingsPageModel) {
        if (!this.store) {
            return;
        }
        this.organizationHelper!.organizations = ListUtils.filterUnusedDisabledValues(
            data.organizations,
            this.store.orgId
        );
        this.store.organizations = this.organizationHelper!.organizations;
        this.organizationHelper!.branches = data.organizationBranches;
        this.organizationHelper!.orgBranches = ListUtils.filterUnusedDisabledValues(
            data.organizationBranches,
            this.store.orgBranchId
        );
        this.organizationHelper!.users = ListUtils.filterUnusedDisabledValues(
            data.organizationContacts,
            ...this.organizationHelper!.getOrganizationContactIds()
        );
        this.salespersons = ListUtils.filterUnusedDisabledValues(data.salespersons, this.store.salespersonId);
    }

    private isDateTimeMinValue(dateString: string | null): boolean {
        const minDateStart = "0001-01-01T00:00:00";
        return dateString !== null && dateString.startsWith(minDateStart);
    }

    public checkAndResetDateFields<T extends IStoreSettingsViewModel | IStoreSettingsModel>(store: T): void {
        if (this.isDateTimeMinValue(store.closeDate)) {
            store.closeDate = null;
        }
        if (this.isDateTimeMinValue(store.deliverDueDate)) {
            store.deliverDueDate = null;
        }
        if (this.isDateTimeMinValue(store.productionDueDate)) {
            store.productionDueDate = null;
        }
    }

    public async enableForceBatch() {
        this.loading = true;
        await WebHelper.postJsonData(`/api/StoreSettings/ForceBatch/${this.store!.storeId}`);
        await this.refresh();
        this.loading = false;
    }
}
