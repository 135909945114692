

























import Component from "vue-class-component";
import tinymce from "tinymce";
import "tinymce/themes/silver";
import "tinymce/themes/mobile";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/paste";
import Editor from "@tinymce/tinymce-vue";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

const test = tinymce.init;
const tinymceDefault = require("@tinymce/tinymce-vue").default;

@Component({
    components: { Editor },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        color: {
            default: "black",
            type: String,
        },
        fontFamily: {
            default: "Verdana",
            type: String,
        },
        fontSize: {
            default: "12pt",
            type: String,
        },
        fontStyle: {
            default: "normal",
            type: String,
        },
        height: {
            default: "130",
            type: String,
        },
        maxlength: Number,
        value: String,
    },
})
export default class HtmlTextField extends Vue {
    public height!: string | undefined;
    public name = "HtmlTextField";
    public menubar = false;
    public color!: string | undefined;
    public editorValue!: string | undefined;
    public editorId!: string;
    public value!: string | undefined;
    public fontFamily!: string | undefined;
    public fontSize!: string | undefined;
    public fontStyle!: string | undefined;
    public maxlength: number | undefined;

    @Prop({
        default: "",
    })
    public label!: string;

    @Prop({
        default: false,
        type: Boolean,
    })
    public hideToolbar!: boolean;

    public data() {
        return {
            editorId: Math.floor(Math.random() * 1000000).toString(),
            editorValue: this.value,
            rawEditor: tinymceDefault,
        };
    }

    // TODO: In the future see if there is some way to bind some props to the editor directly
    public editorInit(): object {
        return {
            branding: false,
            color_map: [
                "#000000",
                "Black",
                "#58585B",
                "Grey Dark",
                "#A7A9AB",
                "Grey Med",
                "#C0C0C0",
                "Grey Light",
                "#A25C34",
                "Brown",
                "#7A0036",
                "Maroon",
                "#F16521",
                "Orange",
                "#ED1C24",
                "Red",
                "#FF69B4",
                "Hot Pink",
                "#C41134",
                "Cardinal",
                "#D2B268",
                "Vegas",
                "#FEC10D",
                "Gold",
                "#004724",
                "Forest",
                "#007732",
                "Kelly",
                "#C0D746",
                "Lime",
                "#742A90",
                "Purple",
                "#001644",
                "Navy",
                "#17479E",
                "Royal",
                "#698DB6",
                "Light Blue",
                "#FFFFFF",
                "White",
            ],
            content_style: `body { font-family: ${this.fontFamily}; font-size: ${this.fontSize}; font-style: ${this.fontStyle}; color: ${this.color} }`,
            contextmenu: false,
            default_link_target: "_blank",
            height: this.height,
            link_default_protocol: "https",
            menubar: false,
            mobile: {
                plugins: ["link lists autolink"],
                theme: "mobile",
                toolbar: this.hideToolbar ? [] : ["undo", "bold", "italic", "styleselect"],
            },
            plugins: "link lists autolink paste ",
            toolbar: this.hideToolbar
                ? ""
                : "fontsizeselect forecolor fontselect | bold italic | alignleft aligncenter alignright alignjustify | link",
            setup: (editor: any) => {
                editor.on("init", (evt: any) => {
                    this.$emit("editorInit", evt);
                });

                editor.on("Change", (evt: any) => {
                    this.$emit("editorChange", evt);
                });

                editor.on("SetContent", (evt: any) => {
                    this.$emit("editorSetContent", evt);
                });
            },
        };
    }

    @Watch("value")
    protected onValueChanged(nv: string, ov: string) {
        this.editorValue = nv;
    }

    public get formattedEditorValue() {
        if ((tinymce.editors as any)[this.editorId]) {
            return (tinymce.editors as any)[this.editorId].getContent();
        }
        return null;
    }

    protected onEditorKeyDown(evt: KeyboardEvent, ed: any) {
        if (!this.maxlength) {
            return true;
        }
        if (
            (evt.code && evt.code.toLowerCase() === "backspace") ||
            evt.code.toLowerCase() === "delete" ||
            evt.altKey ||
            evt.ctrlKey
        ) {
            return true;
        }

        if (ed.contentDocument.body.innerHTML.length >= (this.maxlength as number)) {
            evt.preventDefault();
            evt.stopPropagation();
            return false;
        }
    }

    protected onEditorPaste(evt: any, ed: any) {
        if (!this.maxlength) {
            return true;
        }
        const effectiveMaxLength = this.maxlength as number;
        setTimeout(() => {
            const body = ed.getBody();
            if (this.editorValue && this.editorValue.length > this.maxlength!) {
                ed.setContent(body.innerText.substring(0, effectiveMaxLength));
                ed.notificationManager.open({
                    closeButton: true,
                    text: "The text entered is larger than the allowed size and has been truncated.",
                    timeout: 4000,
                    type: "warning",
                });
            }
        });
    }

    protected editorValueChanged(nv: string, ov: string) {
        this.$emit("change", nv);
    }
}
