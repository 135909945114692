enum StoreListSortBy {
    NameAsc = 0,
    NameDesc = 1,
    OpenDateAsc = 2,
    OpenDateDesc = 3,
    CloseDateAsc = 4,
    CloseDateDesc = 5,
    InHouseCompletionDateAsc = 6,
    InHouseCompletionDateDesc = 7,
    ShipDateAsc = 8,
    ShipDateDesc = 9,
    IsDealerpurchasingEnrolledAsc = 10,
    IsDealerpurchasingEnrolledDesc = 11,
    TypeAsc = 12,
    TypeDesc = 13,
}

export default StoreListSortBy;
