









































































































































import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CEditButton from "@/components/ui/CEditButton.vue";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import CDeleteDialog from "../../dialogs/CDeleteDialog.vue";
import ScrollableContentMixin from "../../ScrollableContentMixin";
import { SearchStoreArtworksViewModel } from "@/chipply/event/artworks/SearchStoreArtworksViewModel";
import CDecisionCard from "../../ui/CDecisionCard.vue";
import CDate from "@/components/ui/CDate.vue";
import CDataTable from "@/components/ui/CDataTable.vue";

@Component({
    components: {
        CToolbarBtn,
        CEditButton,
        CDeleteDialog,
        CDecisionCard,
        CDate,
        CDataTable,
    },
    props: {},
})
export default class SearchArtworkDataTable extends mixins(ScrollableContentMixin) {
    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    @Prop({ type: Object })
    public vm!: SearchStoreArtworksViewModel;

    public declare $refs: {
        searchArtworksTable: CDataTable;
    };
    public get hasImportArtworkDialogViewModel() {
        return this.vm && this.vm.importArtworkDialogViewModel != null;
    }
}
