import { render, staticRenderFns } from "./StoreCreationOptions.vue?vue&type=template&id=18fe7142&scoped=true"
import script from "./StoreCreationOptions.vue?vue&type=script&lang=ts"
export * from "./StoreCreationOptions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fe7142",
  null
  
)

export default component.exports