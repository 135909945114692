







import { Component, Prop } from "vue-property-decorator";
import InternalNotes from "./InternalNotes.vue";
import TransactionalHistory from "./TransactionalHistory.vue";
import EditOrderNotesViewModel from "@/chipply/manage-orders/EditOrderNotesViewModel";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import { mixins } from "vue-class-component";

@Component({
    components: {
        InternalNotes,
        TransactionalHistory,
    },
})
export default class EcomOrdersNotesTransactions extends mixins(UserInfoMixin) {
    @Prop()
    public orderId!: number;
    @Prop()
    public vm!: EditOrderNotesViewModel;

    public name = "EcomOrdersNotesTransactions";

    public async created() {
        this.vm.orderId = this.orderId;
    }
}
