import { EventBus } from "@/chipply/EventBus";
import { IWorkOrderPageStaticTop } from "@/chipply/reports/WorkOrder/IWorkOrderPageStaticTop";
import { AsyncInteractionViewModel, DateUtils } from "chipply-common";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";
import { WorkOrderReportOptionsViewModel } from "@/chipply/reports/WorkOrder/WorkOrderReportOptionsViewModel";
import WorkOrderReportToggleOptions from "@/chipply/reports/WorkOrder/workOrderReportToggleOptions";
import { DateTime } from "luxon";

export class ReportHeaderViewModel {
    public eventId: number | null = null;
    public catalogBatchId: number | null = null;
    public sectionHeader = "";
    public name = ""; // store or catalog batch name
    public storeLogoUrl = "";
    public saleOrder = "";
    public dealerName = "";
    public orderDate = "";
    public customerName = ""; // org name
    public salespersonName = ""; // dealer salesperson name
    public processId = 0;
    public processName = "";
    public dueDate = "";
    public closedDate = "";
    public totalProducts = 0;
    public totalProcesses = 0;
    public totalOrders = 0;
    public originalDueDate!: string;
    public dueDateWarningViewModel: AsyncInteractionViewModel | null = null;

    constructor(model?: IWorkOrderPageStaticTop) {
        if (!model) return;
        this.saleOrder = model.saleOrder;
        this.dealerName = model.dealerName;
        this.orderDate = DateUtils.getDateString(DateTime.fromISO(model.orderDate), true);
        this.customerName = model.customerName;
        this.salespersonName = model.salesPersonName;
        this.dueDate = DateUtils.parseDateString(model.dueDate).dateString;
        this.originalDueDate = this.dueDate;
        this.name = model.name;
        this.storeLogoUrl = model.storeLogoUrl;
    }
    public async updateDueDate(updatedDueDate: string) {
        if (updatedDueDate == this.originalDueDate) return;

        if (!this.processId) {
            this.dueDateWarningViewModel = new AsyncInteractionViewModel();
            const result = await this.dueDateWarningViewModel.interact();
            this.dueDateWarningViewModel = null;
            if (result === "cancel") {
                this.dueDate = this.originalDueDate;
                return;
            }
        }

        this.originalDueDate = updatedDueDate;
        this.dueDate = updatedDueDate;
        EventBus.$emit("update-due-date", {
            dueDate: this.dueDate,
            storeId: this.eventId,
            processId: this.processId,
        });
    }
}
