import { FieldRepositoryListResults } from "@/chipply/fields/FieldRepositoryListResults";
import ListUtils from "@/chipply/ListUtils";
import RequestorStoreSettingsPageModel from "@/chipply/store/settings/RequestorStoreSettingsPageModel";
import { RequestorStoreSettingsViewModel } from "@/chipply/store/settings/RequestorStoreSettingsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import TemplateStoreSettingsPageModel from "@/chipply/store/settings/TemplateStoreSettingsPageModel";
import { TemplateStoreSettingsViewModel } from "@/chipply/store/settings/TemplateStoreSettingsViewModel";
import { typeDependencies } from "chipply-common";

@typeDependencies({
    types: {
        FieldRepositoryListResults,
    },
})
export default class TemplateStoreSettingsPageViewModel extends StoreSettingsPageViewModel {
    public store: TemplateStoreSettingsViewModel | null = null;

    public get saveDisabled() {
        return !(this.isValid && this.storeUrlValidation.valid && this.store!.distribution.isValid);
    }

    protected initializeSettingsViewModel(model: StoreSettingsPageModel): void {
        this.store = new TemplateStoreSettingsViewModel(model, this);
    }
}
