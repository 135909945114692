import IOptionProcessItem from "@/chipply/process/IOptionProcessItem";
import IArtwork from "@/chipply/process/IArtwork";
import IProcessOptionItem from "@/chipply/process/IProcessOptionItem";
import { ProcessItemType } from "chipply-common";

export default class OptionProcessItem implements IOptionProcessItem {
    public processItemId = 0;
    public artworkId = 0;
    public artworkLocationId = 0;
    public imageUrl: string | null = null;
    public maxCharacters: number | null = null;
    public name: string | null = null;
    public notes: string | null = null;
    public price: number | null = null;
    public required = false;
    public workOrderNotes: string | null = null;
    public type: ProcessItemType;
    public options: IProcessOptionItem[] | null;
    public sortOrder = 0;

    constructor(type: ProcessItemType, artworkId?: number, artworks?: IArtwork[]) {
        this.artworkId = artworkId ?? 0;
        this.type = type;
        this.maxCharacters = 100;

        if (this.type === ProcessItemType.OptionExclusiveGroup || this.type === ProcessItemType.OptionGroup) {
            this.options = [];
        } else {
            this.options = null;
        }

        if (this.artworkId > 0 && artworks) {
            for (const artwork of artworks) {
                if (this.artworkId !== artwork.artworkId) {
                    continue;
                }
                this.imageUrl = artwork.imageUrl;
            }
        } else {
            switch (this.type) {
                case ProcessItemType.OptionName:
                    this.imageUrl = "/images/store/no-person-box.png";
                    break;
                default:
                    this.imageUrl = "/images/store/no-number-box.png";
                    break;
            }
        }
    }
}
