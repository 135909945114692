
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    model: {
        prop: "value",
        event: "change",
    },
})
export default class CAttention extends Vue {
    @Prop({
        type: Boolean,
        default: false,
    })
    public value!: boolean;

    @Prop({
        type: String,
        default: "",
    })
    public message!: string;

    public showAttention = this.value;

    @Watch("value")
    public valueChange() {
        this.showAttention = this.value;
    }

    @Watch("showAttention")
    public showAttentionChanged() {
        this.$emit("change", this.showAttention);
    }
}
