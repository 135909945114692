














































import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import DataTablePagingMixin from "@/components/DataTablePagingMixin";
import { ListViewModel } from "@/chipply/view-model/ListViewModel";
import { NormalizedScopedSlot } from "vue/types/vnode";

@Component({})
export default class CDataTable extends mixins(ScrollableContentMixin, DataTablePagingMixin) {
    @Prop({
        type: Object,
    })
    public viewModel!: ListViewModel;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    @Prop()
    public itemClass!: any;

    @Prop({
        type: String,
        default: "",
    })
    public tableStyle!: string;

    @Prop({
        type: String,
        default: "0px",
    })
    public parentRowActionsRightPosition!: string;

    @Prop({
        type: Number,
        default: null,
    })
    public parentTableTopPosition!: number | null;

    @Prop({
        type: Number,
    })
    public parentTableScrollTop!: number;

    public async mounted() {
        await this.$nextTick();
        this.resizeContent();
        const tableWrapper = this.getTableWrapper();
        tableWrapper.addEventListener("scroll", () => {
            this.repositionRowActions();
        });
    }

    protected getParentHeight(): number {
        if (this.containerHeight) {
            return this.containerHeight;
        }
        return this.parentHeight;
    }

    protected async selectNextPageCore(): Promise<any> {
        return await this.viewModel.list();
    }
    public getItemClass(item: any) {
        let classes = "cDataTableRowStyle ";
        if (this.itemClass && typeof this.itemClass === "function") {
            classes += this.itemClass(item);
        } else if (this.itemClass) {
            classes += this.itemClass;
        }
        return classes;
    }
    public filterSlots(slots: { [p: string]: NormalizedScopedSlot | undefined }) {
        const filteredSlots: { [p: string]: NormalizedScopedSlot | undefined } = {};
        for (let key of Object.keys(slots)) {
            if (key === "rowActionsTemplate") {
                continue;
            }
            filteredSlots[key] = slots[key];
        }
        return filteredSlots;
    }

    public get dataTableHeaders() {
        if (!this.viewModel) return [];
        let headers = [];
        headers.push(...this.viewModel.dataTableHeaders);
        headers.push({
            text: "",
            value: "rowActionsTemplate",
            sortable: false,
            align: "right",
            width: "0px",
        });
        return headers;
    }

    @Watch("viewModel.items")
    protected async itemsChanged() {
        await this.$nextTick();
        this.repositionRowActions();
    }

    @Watch("$vuetify.breakpoint.name")
    protected async breakpointChanged() {
        await this.$nextTick();
        this.repositionRowActions();
    }

    public resizeElements = () => {
        this.resizeContent();
        this.repositionRowActions();
    };

    protected getTableWrapper() {
        return this.$refs.datatable.$el.children[0] as HTMLElement;
    }

    @Watch("parentRowActionsRightPosition")
    protected updateChildRowActionsRightPosition() {
        this.repositionRowActions();
    }

    @Watch("parentTableScrollTop")
    protected updateChildRowActionsVisibility() {
        this.repositionRowActions();
    }

    /**
     * Repositions the row actions elements on the table to the right hand of the viewing pane,
     * in context of scroll position
     * @function repositionRowActions
     * @returns {void}
     */
    public repositionRowActions = (): void => {
        // TODO: Double check this code after Vue 3 upgrade
        const tableWrapper = this.getTableWrapper();
        const rowActionsElements = tableWrapper.querySelectorAll(".cDataTableRowActions");
        const rightOfViewingPane = tableWrapper.scrollWidth - tableWrapper.offsetWidth - tableWrapper.scrollLeft;
        let rightPosition = rightOfViewingPane + "px";

        // If there is a parent table, get positioning info from the parent
        if (this.parentRowActionsRightPosition != "0px") {
            rightPosition = this.parentRowActionsRightPosition;
        }

        for (let ra of rowActionsElements) {
            (ra as HTMLElement).style.right = rightPosition;
            if (this.parentTableTopPosition) {
                // hide row actions when they are too close to the top of the parent table and can no longer be hovered by the user
                const rowTopPosition = (ra as HTMLElement).parentElement!.getBoundingClientRect().top;
                (ra as HTMLElement).style.visibility =
                    rowTopPosition < this.parentTableTopPosition + 30 ? "hidden" : "visible";
            }
        }
    };
}
