import IEventProductColorSizeDto from "@/chipply/products/i-event-product-color-size-dto";
import IEventProductSize from "./i-event-product-size";

export default class EventProductColorSizeViewModel {
    public feedsProductUpc: string | null;
    public lastUpc: string | null = null;
    private _upc: string | null = null;
    public get upc(): string | null {
        return this._upc;
    }
    public set upc(value: string | null) {
        if (this._upc != value) {
            this.lastUpc = this._upc && this._upc.length > 0 ? this._upc : null;
            this._upc = value;
        }
    }
    public totalInventory: number | null;
    public qtyLimit: number | null;
    public feedsThreshold: number | null;
    public enabled: boolean;
    public eventProductColorSizeId: number;
    public eventProductColorId: number;
    public eventProductSizeId: number;
    public color: string | null;
    public size: string | null;
    public isValidUpc = true;

    public constructor(
        model: IEventProductColorSizeDto,
        sizes: { [key: number]: string },
        colors: { [key: number]: string },
        feedsThreshold: number | null
    ) {
        this.feedsProductUpc = model.feedsProductUpc;
        if (model.upc != null && model.upc.length > 0) {
            this.upc = model.upc;
        }
        this.totalInventory = model.totalInventory ?? 0;
        this.qtyLimit = model.qtyLimit;
        this.feedsThreshold = feedsThreshold;
        this.enabled = model.enabled;
        this.eventProductColorSizeId = model.eventProductColorSizeId;
        this.eventProductColorId = model.eventProductColorId;
        this.eventProductSizeId = model.eventProductSizeId;

        this.color = this.eventProductColorId in colors ? colors[this.eventProductColorId] : "";
        this.size = this.eventProductSizeId in sizes ? sizes[this.eventProductSizeId] : "";
    }

    public get isAboveThreshold() {
        const threshold = this.feedsThreshold;
        const inventory = this.totalInventory;
        if (threshold === null) {
            return true;
        }
        if (inventory === null) {
            return true;
        }
        return inventory > threshold;
    }

    public get inventoryColor() {
        return this.isAboveThreshold ? "black" : "red";
    }
    public toModel(): IEventProductColorSizeDto {
        return {
            eventProductColorSizeId: this.eventProductColorSizeId,
            enabled: this.enabled,
            feedsProductUpc: this.feedsProductUpc,
            qtyLimit: this.qtyLimit,
            totalInventory: this.totalInventory,
            upc: this.upc,
            eventProductColorId: this.eventProductColorId,
            eventProductSizeId: this.eventProductSizeId,
        };
    }
}
