import { IOrder, WebHelper } from "chipply-common";
import { ITransaction } from "./ITransaction";
import OrderViewModel from "../ecom-orders/OrderViewModel";
import _ from "lodash";

export default class EditOrderNotesViewModel {
    public orderId!: number | null;
    public loadingMessage: string | null = null;
    public notesLoading = false;
    public tableLoading = false;
    public errorMessage = "";
    public originalOrder: IOrder | null = null;
    public transactions: ITransaction[] = [];
    public internalNote = "";
    public maxLength = 2000;

    public async saveOrder() {
        if (!this.originalOrder) {
            return;
        }
        this.loadingMessage = "Saving...";
        this.originalOrder!.internalNote = this.internalNote;
        const orderVm = new OrderViewModel(this.originalOrder!);
        await WebHelper.postJsonData("/api/EcomOrder/SaveInternalNotes", orderVm);
        this.loadingMessage = null;
        await this.getInternalNotes();
        await this.getTransactions();
    }

    public redirectIfNoLock(lockInfo: any) {
        if (!lockInfo.successful) {
            location.assign("/ng/ecom-orders.html?errorMessage=" + encodeURIComponent(lockInfo.failureReason));
            return true;
        }
        return false;
    }

    public async getInternalNotes() {
        this.loadingMessage = "Loading...";
        this.notesLoading = true;
        try {
            const orderData = await WebHelper.getJsonData(`/api/EcomOrder/GetOrder/${this.orderId}/`, true);
            this.originalOrder = orderData.order as IOrder;
            this.originalOrder!.internalNote = this.originalOrder!.internalNote ?? "";
            this.internalNote = this.originalOrder!.internalNote;
            if (this.redirectIfNoLock(orderData.lockInfo)) {
                return;
            }
        } finally {
            this.loadingMessage = null;
            this.notesLoading = false;
        }
    }

    public async getTransactions() {
        this.loadingMessage = "Loading...";
        this.tableLoading = true;
        try {
            this.transactions = (await WebHelper.getJsonData(
                `/api/EcomOrder/GetTransactionHistory/${this.orderId}`
            )) as ITransaction[];
        } finally {
            this.loadingMessage = null;
            this.tableLoading = false;
        }
    }

    public get hasChanges() {
        return !_.isEqual(this.originalOrder!.internalNote!, this.internalNote);
    }

    public lessThanMaxLength() {
        if (this.internalNote.length <= this.maxLength) {
            return true;
        } else return "Notes exceed maximum length.";
    }
}
