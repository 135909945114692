














import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        show: Boolean,
        viewModel: Object,
        hideSave: Boolean,
    },
})
export default class CYesNoDialog extends Vue {
    @Prop({
        default: "300px",
        type: String,
    })
    public dialogWidth!: string;
}
