


























































































































































































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import CDate from "@/components/ui/CDate.vue";
import AccountingSummaryViewModel from "@/chipply/event/AccountingSummaryViewModel";
import { Utils } from "chipply-common";
import chipplyIcons from "@/chipply/ImportIcons";
import ReportHeader from "@/components/reports/ReportHeader.vue";
import { WorkOrderReportOptionsViewModel } from "@/chipply/reports/WorkOrder/WorkOrderReportOptionsViewModel";
import AccountingSummaryToggleOptions from "@/chipply/reports/AccountingSummaryToggleOptions";
@Component({
    components: { ReportHeader, CDate },
})
export default class CAccountingSummary extends Vue {
    @Prop({ type: Object })
    public vm!: AccountingSummaryViewModel;
    @Prop({ type: Object })
    public options!: WorkOrderReportOptionsViewModel | AccountingSummaryToggleOptions;
    public Utils = Utils;
    public chipplyIcons = chipplyIcons;

    public manualAdjExpandLeft = () => {
        this.options.includeAccountingSummaryManualAdjustmentsLeft =
            !this.options.includeAccountingSummaryManualAdjustmentsLeft;
    };

    public manualAdjExpandRight = () => {
        this.options.includeAccountingSummaryManualAdjustmentsRight =
            !this.options.includeAccountingSummaryManualAdjustmentsRight;
    };
}
