import { ICopyProductsToProcessArgs } from "@/chipply/products/ICopyProductsToProcessArgs";
import { IProcessInfoDto } from "@/chipply/products/IProcessInfoDto";
import { IProcessProductDto } from "@/chipply/products/IProcessProductDto";

export class ProcessProductsCopyViewModel {
    public eventId: number;
    public eventProcessId: number;
    public processName: string;
    public products: IProcessProductDto[];
    public assigned = false;
    public copyToProcessName = "";
    public copyToProcessId = 0;
    public artworkLocations: number[] = [];

    constructor(data: IProcessInfoDto) {
        this.eventId = data.eventId;
        this.eventProcessId = data.eventProcessId;
        this.processName = data.processName;
        this.products = data.products;
    }

    public toModel(): ICopyProductsToProcessArgs {
        const model: ICopyProductsToProcessArgs = {
            copyFromEventId: this.eventId,
            copyFromProcessId: this.eventProcessId,
            copyProcessOptions: {
                copyProcess: this.copyToProcessId === 0,
                copyToProcessId: this.copyToProcessId,
                newProcessName: this.copyToProcessName,
            },
            artworkLocations: this.artworkLocations,
        };

        model.eventProductIds = [];
        for (const product of this.products) {
            model.eventProductIds.push(product.eventProductId);
        }

        return model;
    }
}
