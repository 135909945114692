import ArtworkPurchaseOrderArgs from "./ArtworkPurchaseOrderArgs";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import ISubmitPurchaseOrderArgs from "./ISubmitPurchaseOrderArgs";

export default class SubmitArtworkPurchaseOrderArgs
    extends ArtworkPurchaseOrderArgs
    implements ISubmitPurchaseOrderArgs<IArtworkPurchasingLineItem>
{
    public isBulkPurchaseOrder = false;
}
