import UserLevel from "@/chipply/UserLevel";
import * as userInfoStore from "@/store/UserInfoStore";
import { WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class UserInfoMixin extends Vue {
    public userInfo: {
        userLevel: UserLevel;
        userId: number;
        dealerId: number;
        dealerName: string;
        isPremium: boolean;
    } = {
        userLevel: UserLevel.OrganizationDirector,
        dealerName: "",
        userId: 0,
        dealerId: 0,
        isPremium: false,
    };
    public needsBootstrap = false;
    public isUserInfoCreated = false;
    public userInfoCreated: Promise<void> | null = null;
    public async created() {
        this.userInfoCreated = new Promise(async (resolve) => {
            await this.bootstrap();
            await userInfoStore.initialize(this.$store);
            this.userInfo.userLevel = userInfoStore.getUserLevel(this.$store);
            this.userInfo.userId = userInfoStore.getUserId(this.$store);
            this.userInfo.dealerId = userInfoStore.getDealerId(this.$store);
            this.userInfo.dealerName = userInfoStore.getDealerName(this.$store);
            this.userInfo.isPremium = userInfoStore.getIsPremium(this.$store);
            this.isUserInfoCreated = true;
            resolve();
        });
    }
    protected async bootstrap() {
        if (!this.needsBootstrap) {
            return;
        }
        const bootstrap = await WebHelper.getJsonData("/api/Login/BootstrapUser");
        localStorage.setItem("userInfo", JSON.stringify(bootstrap.userData));
        localStorage.setItem("envInfo", JSON.stringify(bootstrap.environmentInfo));
    }
}
