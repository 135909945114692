import { ITextValue, WebHelper } from "chipply-common";
import { IProductSpreadsheetFilters } from "./IProductSpreadsheetFilters";
import IGetProductSpreadsheetResults, {
    IGetProductSpreadsheetExtendedResults,
    IGetProductSpreadsheetMetaDataResults,
} from "../interface/i-get-product-spreadsheet-results";
import { ProductSearchConstants } from "./add/ProductSearchConstants";
import IEventProductsReviewModel from "./IEventProductsReviewModel";
import ChipplyConstants from "../ChipplyConstants";
import ITreeItem from "../interface/i-tree-item";
import IProcessCost from "../process/IProcessCost";

export default class ProductsSpreadsheetHelper {
    public static async getSpreadsheetData(
        storeId: number,
        pageNumber: number,
        pageSize: number,
        filters: IProductSpreadsheetFilters,
        sortBy: number,
        nestChildren: boolean
    ) {
        const baseUrl = `/api/ProductSpreadsheet/${
            storeId > 0 ? storeId : 0
        }/${pageNumber}/${pageSize}/${nestChildren}`;

        const queryString = ProductsSpreadsheetHelper.appendQueryStrings(sortBy, filters, false);
        const results = (await WebHelper.getJsonData(baseUrl + queryString, true)) as IGetProductSpreadsheetResults;
        return results;
    }

    public static async getSpreadsheetMetaData(storeId: number) {
        if (storeId > 0) {
            const baseUrl = `/api/ProductSpreadsheetMetaData/${storeId}`;
            const results = (await WebHelper.getJsonData(baseUrl, true)) as IGetProductSpreadsheetMetaDataResults;
            return results;
        } else {
            return {
                categories: [] as ITreeItem[],
                vendors: [] as Array<{
                    vendorName: string;
                    vendorId: number;
                    isFromFeed: boolean;
                }>,
                processes: [] as Array<ITextValue<number>>,
                processCosts: {} as { [processId: number]: IProcessCost },
            } as IGetProductSpreadsheetMetaDataResults;
        }
    }

    public static async searchProduct(
        storeId: number,
        catalogBatchId: number,
        pageNumber: number,
        pageSize: number,
        filters: IProductSpreadsheetFilters,
        sortBy: number,
        shouldReturnAllProductIds: boolean,
        nestChildren: boolean
    ) {
        const baseUrl = `/api/ProductSpreadsheet/searchproducts/${storeId > 0 ? storeId : 0}/${
            catalogBatchId > 0 ? catalogBatchId : 0
        }/${pageNumber}/${pageSize}/${nestChildren}`;

        const queryString = ProductsSpreadsheetHelper.appendQueryStrings(sortBy, filters, shouldReturnAllProductIds);
        const results = (await WebHelper.getJsonData(baseUrl + queryString, true)) as IGetProductSpreadsheetResults;
        return results;
    }

    private static appendQueryStrings(
        sortBy: number,
        filters: IProductSpreadsheetFilters,
        shouldReturnAllProductIds: boolean
    ) {
        let queryString = `?sortby=${sortBy}`;

        if (filters.styleNameKeywords) {
            queryString += "&filter=" + encodeURI(filters.styleNameKeywords);
        }
        if (filters.vendorIds && filters.vendorIds.length) {
            for (const vendorId of filters.vendorIds) {
                queryString += "&vendorids=" + vendorId;
            }
        }
        if (filters.dealerId && filters.dealerId > 0) {
            queryString += "&dealerid=" + filters.dealerId;
        }
        if (filters.searchMode > 0) {
            queryString += "&searchmode=" + filters.searchMode;
        }
        if (filters.excludeStoreId && filters.excludeStoreId > 0) {
            queryString += "&excludestoreid=" + filters.excludeStoreId;
        }

        if (filters.isEnabled !== null && filters.isEnabled !== undefined) {
            queryString += "&isEnabled=" + filters.isEnabled;
        }
        if (filters.organizationId && filters.organizationId > 0) {
            queryString += "&organizationid=" + filters.organizationId;
        }
        if (filters.processIds && filters.processIds.length > 0) {
            for (const processId of filters.processIds) {
                queryString += "&processids=" + processId;
            }
        }
        if (filters.categoryIds && filters.categoryIds.length) {
            for (const catId of filters.categoryIds) {
                queryString += "&categoryids=" + catId;
            }
        }
        if (filters.weights && filters.weights.length) {
            for (const weight of filters.weights) {
                queryString += "&weights=" + weight;
            }
        }

        if (filters.taxType !== null && filters.taxType !== undefined && !Number.isNaN(Number(filters.taxType))) {
            queryString += "&taxtype=" + filters.taxType;
        }

        if (filters.minProdPrice && !Number.isNaN(Number(filters.minProdPrice))) {
            queryString += "&minprice=" + filters.minProdPrice;
        }
        if (filters.maxProdPrice && !Number.isNaN(Number(filters.maxProdPrice))) {
            queryString += "&maxprice=" + filters.maxProdPrice;
        }
        if (filters.onlyOrderedProducts) {
            queryString += "&op=true";
        }

        if (filters.exactMatch) {
            queryString += "&exactMatch=" + filters.exactMatch;
        }

        if (shouldReturnAllProductIds) {
            queryString += "&tryincludeallproductids=" + shouldReturnAllProductIds;
        }
        return queryString;
    }

    public static async getSpreadsheetDataByProductIds(
        storeId: number,
        dealerId: number | null,
        useFeedProducts: boolean,
        useChipplyTemplateDealer: boolean,
        productIds: number[],
        nestChildren: boolean
    ): Promise<IGetProductSpreadsheetResults> {
        const baseUrl = `/api/ProductSpreadsheet/GetByProductIds`;
        const args = {
            eventId: storeId,
            dealerId: dealerId,
            productIds: productIds,
            nestChildren: nestChildren,
            useFeedProducts: useFeedProducts,
            useChipplyTemplateDealer: useChipplyTemplateDealer,
            shouldCheckHasOrders: storeId == ChipplyConstants.CHIPPLY_EVENT_ID ? false : true,
        };

        const jsonResult = await WebHelper.postJsonData(baseUrl, args);
        const results = JSON.parse(jsonResult) as IGetProductSpreadsheetResults;
        return results;
    }

    public static async getSpreadsheetExtendedDataByProductIds(
        storeId: number,
        dealerId: number | null,
        useFeedProducts: boolean,
        useChipplyTemplateDealer: boolean,
        productIds: number[],
        nestChildren: boolean
    ): Promise<IGetProductSpreadsheetExtendedResults> {
        const baseUrl = `/api/ProductSpreadsheet/GetExtendedDataByProductIds`;
        const args = {
            eventId: storeId,
            dealerId: dealerId,
            productIds: productIds,
            nestChildren: nestChildren,
            useFeedProducts: useFeedProducts,
            useChipplyTemplateDealer: useChipplyTemplateDealer,
            shouldCheckHasOrders: storeId == ChipplyConstants.CHIPPLY_EVENT_ID ? false : true,
        };

        const jsonResult = await WebHelper.postJsonData(baseUrl, args);
        const results = JSON.parse(jsonResult) as IGetProductSpreadsheetExtendedResults;
        return results;
    }

    public static convertToNumericWeights(weightList: string[]): number[] {
        const numericWeights: number[] = [];
        if (!weightList) {
            return numericWeights;
        }
        for (const currentItem of weightList) {
            if (currentItem === ProductSearchConstants.NO_WEIGHT) {
                numericWeights.push(null as any);
                numericWeights.push(0);
            } else {
                const currentWeight = Number(currentItem);
                if (!isNaN(currentWeight)) {
                    numericWeights.push(currentWeight);
                }
            }
        }
        return numericWeights;
    }

    public static convertToTextWeights(weightList: number[] | null): string[] {
        const textWeights: string[] = [];
        if (!weightList) {
            return textWeights;
        }
        for (const currentItem of weightList) {
            if (currentItem === null || currentItem.toString() === "0") {
                if (textWeights.indexOf(ProductSearchConstants.NO_WEIGHT) === -1) {
                    textWeights.push(ProductSearchConstants.NO_WEIGHT);
                }
            } else {
                textWeights.push(currentItem.toString());
            }
        }
        return textWeights;
    }

    public static async getProductReviewImages(eventId: number, eventProductIds: number[]) {
        const baseUrl = `/api/ProductSpreadsheet/GetProductReviewImages`;
        const args = {
            eventId: eventId,
            eventProductIds: eventProductIds,
        };

        const jsonResult = await WebHelper.postJsonData(baseUrl, args);
        const results = JSON.parse(jsonResult) as IEventProductsReviewModel;
        return results;
    }

    public static async saveProductReviewImages(args: IEventProductsReviewModel) {
        const baseUrl = `/api/ProductSpreadsheet/SaveProductReviewImages`;
        const results = await WebHelper.postJsonData(baseUrl, args);
        return results;
    }
}
