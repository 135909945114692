import SpreadsheetProductViewModel from "../view-model/SpreadsheetProductViewModel";
import { ImageReviewViewModel } from "@/chipply/products/ImageReviewViewModel";

export interface IProductsSpreadsheetSaveRunRefreshEventArgs {
    suppressNag?: boolean;
    forceSave?: boolean;
    statusMessage?: string;
    postSaveCallback?: (...args: any[]) => Promise<any>;
    postSaveCallbackArgs?: any[];

    postRefreshCallback?: (...args: any[]) => Promise<any>;
    selectedProductIds?: number[];
    refreshAll?: boolean;
    triggerStoreSettingsUpadated?: boolean;
}

export interface IProductsSpreadsheetSaveSpreadsheetEventArgs {
    suppressZeroPriceNag?: boolean;
    forceSave?: boolean;
    allowOverwrite?: boolean;
}

export interface IProductsSpreadsheetRequestEditLayoutEventArgs {
    eventProductId: number;
    imageNumber: number;
}

export interface IProductsSpreadsheetRefreashReviewDataEventArgs {
    productIds?: number[];
    forceRefresh: boolean;
}

export interface IProductsSpreadsheetRefreshStatusEventArgs {
    statusMessage: string;
}

export interface IProductsSpreadsheetLoadingStatusChangedEventArgs {
    loading: boolean;
    statusMessage: string | null;
    errorMessage: string | null;
}

export class ProductsSpreadsheetEvents {
    public static readonly RequestSaveRunRefreshEvent = "request-products-spreadsheet-save-run-refresh";
    public static readonly RequestShowProductTaxEvent = "request-products-spreadsheet-show-product-tax";
    public static readonly RequestSaveSpreadsheetEvent = "request-products-spreadsheet-save-spreadsheet";
    public static readonly ProductMultipleProcessClosedEvent = "products-spreadsheet-product-multiple-process-closed";
    public static readonly RequestClearSelectedEvent = "request-products-spreadsheet-clear-selected";
    public static readonly RequestEditLayoutEvent = "request-products-spreadsheet-edit-layout";
    public static readonly RequestRefreshReviewDataEvent = "request-products-spreadsheet-refresh-review-data";
    public static readonly RequestRefreshStatusEvent = "request-products-spreadsheet-refresh-status-data";
    public static readonly RequestLoadingStatusChangedEvent = "request-loading-status-changed-event";
    public static readonly MarkLastScrollPositionEvent = "mark-products-spreadsheet-last-scroll-position-event";
    public static readonly ResetLastScrollPositionEvent = "reset-products-spreadsheet-last-scroll-position-event";
}
