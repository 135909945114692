import { WebHelper } from "chipply-common";
import EditableViewModel from "../EditableViewModel";
import IEventProductColor from "../products/i-event-product-color";
import IEventProductColorSizeDto from "../products/i-event-product-color-size-dto";
import IEventProductSize from "../products/i-event-product-size";
import IProductInfo from "../products/IProductInfo";
import IProductSelectorSizeMapping from "../purchasing/IProductSelectorSizeMapping";
import { IPurchaseOrderLineItem } from "../purchasing/IPurchaseOrderLineItem";
import ManualSizeMapping from "../purchasing/ManualSizeMapping";
import SubstituteProductArgs from "../purchasing/SubstituteProductArgs";
import SubstituteSizeMapping from "../purchasing/SubstituteSizeMapping";
export default class ProductColorSizeSelectorViewModel extends EditableViewModel {
    public eventProductId!: number;
    public colors: IEventProductColor[] = [];
    public productName!: string;
    public processName!: string;
    public selectedQty!: number;
    public sellPrice!: number;
    public fundraising!: number;
    public processCost!: number;
    public productPrice!: number;
    public sizes!: IEventProductSize[];
    public imageUrl!: string;
    public sizingChart = "";
    public description = "";
    public qty!: number;
    public hideVendor!: boolean;
    public vendorName!: string;
    public productTitle = "";

    public originalProductVendorName = "";
    public originalProductName = "";
    public originalProductStyle = "";
    public originalProductColor = "";
    public originalProductSize = "";
    public isPerformingColorAssignments = false;
    public isPerformingManualAdd = false;
    public toReplaceProductImage = "";

    public colorSizes!: IEventProductColorSizeDto[][];

    public sizeMappings = new Array<SubstituteSizeMapping>();

    public manualSizeMappings = new Array<ManualSizeMapping>();

    public get individualItemTotal() {
        return this.sellPrice + this.sizePrice + this.optionsPrice;
    }
    public get itemTotal() {
        return this.selectedQty * this.individualItemTotal;
    }
    public optionsPrice!: number;
    public sizePrice!: number;
    public name!: string;
    public style!: string;

    public selectedSize: number | null = null;

    public selectedColor: number | null = null;

    public async getColorSizeInfo(eventProductId: number): Promise<void> {
        const results = await (WebHelper.getJsonData(
            `/api/EventProducts/MoreInfo/${eventProductId}`
        ) as Promise<IProductInfo>);
        this.sizes = results.colorSizeInfo.sizes;
        this.colors = results.colorSizeInfo.colors;
        this.colorSizes = results.colorSizeInfo.colorSizes;
        this.productTitle = results.colorSizeInfo.productTitle;
        this.description = results.description;
        this.sizingChart = results.sizingChart;
        for (const size of this.sizes) {
            if (size.sizeName === this.originalProductSize) {
                this.selectedSize = size.eventProductSizeId;
                break;
            }
        }
        for (const color of this.colors) {
            if (color.colorName === this.originalProductColor) {
                this.selectedColor = color.eventProductColorId;
                break;
            }
        }
        for (const sizeMapping of this.sizeMappings) {
            for (const size of this.sizes) {
                if (size.sizeName === sizeMapping.sizeName) {
                    sizeMapping.chipplyProductSizeId = size.eventProductSizeId;
                    break;
                }
            }
        }
    }

    public initializeSizeMappings(items: IPurchaseOrderLineItem[]) {
        for (const item of items) {
            const mapping = new SubstituteSizeMapping();
            mapping.sizeName = item.size;
            const existingItems = this.sizeMappings.find((x) => x.sizeName === item.size);
            if (!existingItems) {
                this.sizeMappings.push(mapping);
            }
        }
    }

    public populateManualSizeMappings() {
        this.manualSizeMappings.splice(0);
        if (!this.selectedColor) {
            return;
        }
        for (const item of this.sizes) {
            const colorSizeId = this.getColorSizeId(this.selectedColor, item.eventProductSizeId);
            if (colorSizeId) {
                const mapping = new ManualSizeMapping();
                mapping.sizeName = item.sizeName;
                mapping.chipplyProductSizeId = item.eventProductSizeId;
                mapping.chipplyProductColorSizeId = colorSizeId;
                this.manualSizeMappings.push(mapping);
            }
        }
    }

    protected getColorSizeId(colorId: number, sizeId: number): number | null {
        for (const colorSizeDtoArray of this.colorSizes) {
            for (const colorSize of colorSizeDtoArray) {
                if (colorSize.eventProductSizeId === sizeId && colorSize.eventProductColorId === colorId) {
                    return colorSize.eventProductColorSizeId;
                }
            }
        }
        return null;
    }

    public toModel() {
        return null;
    }

    public toManualSizeMappings(): Array<ManualSizeMapping> {
        return this.manualSizeMappings;
    }

    public toSubstituteProductArgs(): SubstituteProductArgs {
        const args = new SubstituteProductArgs();
        for (const items of this.colorSizes) {
            for (const item of items) {
                if (item.eventProductColorId === this.selectedColor && item.eventProductSizeId === this.selectedSize) {
                    args.replacementChipplyProductColorSizeId = item.eventProductColorSizeId;
                }
            }
        }
        for (const mapping of this.sizeMappings) {
            for (const items of this.colorSizes) {
                for (const item of items) {
                    if (
                        item.eventProductColorId === this.selectedColor &&
                        item.eventProductSizeId === mapping.chipplyProductSizeId
                    ) {
                        mapping.chipplyProductColorSizeId = item.eventProductColorSizeId;
                    }
                }
            }
        }
        args.sizeMappings = this.sizeMappings;
        if (args.sizeMappings.length > 0) {
            args.replacementChipplyProductColorSizeId = null;
        }
        return args;
    }

    public getQuantityAvailable(mapping?: IProductSelectorSizeMapping | undefined): number | null {
        let selectedSize = this.selectedSize;
        if (mapping) {
            selectedSize = mapping.chipplyProductSizeId;
        }
        if (!this.selectedColor || !selectedSize || !this.colorSizes) {
            return null;
        }
        for (const item of this.colorSizes) {
            for (const colorSize of item) {
                if (
                    colorSize.eventProductSizeId === selectedSize &&
                    colorSize.eventProductColorId === this.selectedColor
                ) {
                    return colorSize.totalInventory;
                }
            }
        }
        return null;
    }

    public colorChanged(): void {
        if (!this.isPerformingManualAdd) {
            return;
        }
        this.populateManualSizeMappings();
    }

    public updateOriginalProductInfo(selectedLine: IPurchaseOrderLineItem) {
        this.originalProductStyle = selectedLine.style;
        this.originalProductVendorName = selectedLine.vendorName;
        this.originalProductColor = selectedLine.color;
        this.originalProductSize = selectedLine.size;
    }
}
