import { IStoreFulfillmentViewModel } from "@/chipply/event/IStoreFulfillmentViewModel";
import { ITemplateStoreSettingsModel } from "@/chipply/store/settings/ITemplateStoreSettingsModel";
import { StoreDistributionViewModel } from "@/chipply/store/settings/StoreDistributionViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import StoreSettingsViewModel from "@/chipply/store/settings/StoreSettingsViewModel";

export class TemplateStoreSettingsViewModel extends StoreSettingsViewModel implements IStoreFulfillmentViewModel {
    public hideBulkOrdering = false;
    public distribution: StoreDistributionViewModel;

    public constructor(model: StoreSettingsPageModel, pageVm: StoreSettingsPageViewModel) {
        super(model);
        const templateModel = model.store as ITemplateStoreSettingsModel;
        this.hideBulkOrdering = templateModel.hideBulkOrdering;
        this.distribution = new StoreDistributionViewModel(model, pageVm);
    }

    public toModel(): ITemplateStoreSettingsModel {
        const model = super.toModel() as ITemplateStoreSettingsModel;
        model.$typeHint = "TemplateStoreSettingsModel";
        model.hideBulkOrdering = this.hideBulkOrdering;
        model.distribution = this.distribution.toModel();
        return model;
    }

    public async organizationChanged() {
        await super.organizationChanged();
        this.distribution.organizationChanged();
    }
}
