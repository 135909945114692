import ChipplyConstants from "@/chipply/ChipplyConstants";
import { IProductAdderFilters } from "./IProductAdderFilters";
import { IProductSearchModeFunction } from "./IProductSearchModeFunction";
import { ProductSearchConstants } from "./ProductSearchConstants";

export abstract class ProductSearchMode<T, R> {
    static DealerTemplateProducts = "MyTemplates";
    static ChipplyTemplateProducts = "ChipplyTemplates";
    static ChipplyProducts = "ChipplyProducts";
    static DealerProducts = "MyProducts";
    static FeedProducts = "FeedProducts";
    static GeneralProducts = "CommonProduct";

    public id!: number;
    public name = "";
    public display = "";
    public isSelected = false;
    public isTemplateMode = false;

    public get calcStoreId() {
        const storeId = this.isTemplateMode ? this.filters.templateId : this.filters.storeId;
        return storeId ?? 0;
    }

    public set calcStoreId(eventId: number) {
        if (this.name == ProductSearchMode.ChipplyProducts) {
            this.filters.storeId = ChipplyConstants.CHIPPLY_EVENT_ID;
            return;
        }
        if (this.isTemplateMode) {
            this.filters.templateId = eventId;
        } else {
            this.filters.storeId = eventId;
        }
    }

    public get sortBy() {
        if (this.useFeedProducts) {
            return [
                { text: "Style #", value: 10 },
                { text: "Vendor Name", value: 2 },
                { text: "Product Name A - Z", value: 6 },
                { text: "Product Name Z - A", value: 7 },
                { text: "Price Low to High", value: 8 },
                { text: "Price High to Low", value: 9 },
            ];
        } else {
            return [
                { text: "Style #", value: 10 },
                { text: "Sort Order", value: 0 },
                { text: "Vendor Name", value: 2 },
                { text: "Process Name", value: 4 },
                { text: "Product Name A - Z", value: 6 },
                { text: "Product Name Z - A", value: 7 },
                { text: "Price Low to High", value: 8 },
                { text: "Price High to Low", value: 9 },
            ];
        }
    }

    public get useFeedProducts() {
        return this.name === ProductSearchMode.FeedProducts;
    }

    public get useChipplyTemplateDealer() {
        return this.name === ProductSearchMode.ChipplyTemplateProducts;
    }

    public get calCategoryIds() {
        if (this.isTemplateMode) {
            return this.filters.templateCategoryIds;
        } else {
            return this.filters.storeCategoryIds;
        }
    }

    public filters: IProductAdderFilters = {
        styleNameKeywords: null,
        vendorIds: [],
        organizationId: null,
        storeId: null,
        storeCategoryIds: [],
        exactMatch: false,
        templateId: null,
        templateCategoryIds: [],
        isCopyAllFromSelectedCategories: false,
        processId: null,
        sortBy: this.sortBy[0].value,
        pageSize: 50,
        pageNumber: 1,
        taxType: null,
        dealerId: null,
    };

    public clearFilters() {
        this.filters = {
            styleNameKeywords: null,
            vendorIds: [],
            organizationId: null,
            storeId: null,
            storeCategoryIds: [],
            exactMatch: false,
            templateId: null,
            templateCategoryIds: [],
            isCopyAllFromSelectedCategories: false,
            processId: null,
            sortBy: this.filters.sortBy,
            pageSize: this.filters.pageSize,
            pageNumber: 1,
            taxType: null,
            dealerId: null,
        };
    }

    abstract apply(func: IProductSearchModeFunction<T, R>, input: T): R;

    public constructor(name: string, display: string) {
        this.name = name;
        this.display = display;
        if (this.useFeedProducts || this.name === ProductSearchMode.ChipplyProducts) {
            this.filters.sortBy = 10;
        } else {
            this.filters.sortBy = 0;
        }
    }
}

export class ProductSearchDealerTemplateProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 5;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.DealerTemplateProducts, "My Templates");
        this.isTemplateMode = true;
    }

    public clearFilters(): void {
        //do not clear the delear id
        const orignalDealerId = this.filters.dealerId;
        super.clearFilters();
        this.filters.dealerId = orignalDealerId;
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyDealerTemplateProducts(input);
    }
}

export class ProductSearchChipplyTemplateProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 4;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.ChipplyTemplateProducts, "Chipply Templates");
        this.isTemplateMode = true;
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyChipplyTemplateProducts(input);
    }
}

export class ProductSearchChipplyProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 3;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.ChipplyProducts, "Chipply Products");
        this.filters.storeId = ChipplyConstants.CHIPPLY_EVENT_ID;
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyChipplyProducts(input);
    }

    public clearFilters(): void {
        super.clearFilters();
        this.filters.storeId = ChipplyConstants.CHIPPLY_EVENT_ID;
    }

    public get calCategoryIds() {
        return [];
    }
}

export class ProductSearchDealerProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 2;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.DealerProducts, "My Products");
    }

    public clearFilters(): void {
        //do not clear the delear id
        const orignalDealerId = this.filters.dealerId;
        super.clearFilters();
        this.filters.dealerId = orignalDealerId;
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyDealerProducts(input);
    }
}

export class ProductSearchFeedProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 1;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.FeedProducts, "Product Information");
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyFeedProducts(input);
    }
}

export class ProductSearchGeneralProductsMode<T, R> extends ProductSearchMode<T, R> {
    public id = 0;
    constructor() {
        super(ProductSearchDealerTemplateProductsMode.GeneralProducts, "Select Product");
    }

    public clearFilters(): void {
        //do not clear the store id
        const orignalStoreId = this.filters.storeId;
        super.clearFilters();
        this.filters.storeId = orignalStoreId;
    }

    public apply(funcInstance: IProductSearchModeFunction<T, R>, input: T) {
        return funcInstance.applyCommonProducts(input, this.filters.storeId);
    }
}
