

































import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import StoreFeeViewModel from "@/chipply/event/StoreFeeViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { Watch } from "vue-property-decorator";
import TaxTypeSelection from "@/components/products/TaxTypeSelection.vue";
import CMoney from "@/components/ui/CMoney.vue";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";

@Component({
    components: {
        CDecisionCard,
        TaxTypeSelection,
        CMoney,
    },
    props: {
        fee: Object,
    },
})
export default class FeeEditor extends mixins(FormValidationMixin) {
    public isValid = false;
    public fee!: StoreFeeViewModel<any>;
    protected Validation = Validation;
    protected OrganizationStoreFeeViewModel = OrganizationStoreFeeViewModel;

    @Watch("fee")
    protected feeChanged() {
        this.validate();
    }
}
