import IFeeType from "@/chipply/event/IFeeType";
import IStoreSettingsModel from "@/chipply/store/settings/IStoreSettingsModel";
import { ITextValueDisabled } from "chipply-common";
import { ITextValue } from "chipply-common";
import IEventShippingMethodModel from "@/chipply/interface/IEventShippingMethodModel";

export default class StoreSettingsPageModel {
    public feeTypes: IFeeType[] = [];
    public organizations: ITextValueDisabled<number>[] = [];
    public organizationBranches: ITextValueDisabled<number>[] = [];
    public organizationContacts: ITextValueDisabled<number>[] = [];
    public salespersons: ITextValueDisabled<number>[] = [];
    public groupPickups: ITextValueDisabled<number>[] = [];
    public shippingMethods: IEventShippingMethodModel<number>[] = [];
    public store: IStoreSettingsModel | null = null;
    public processes: ITextValue<number>[] = [];
}
