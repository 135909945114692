import IFilterItemViewModel from "@/chipply/view-model/IFilterItemViewModel";
import { ITextValue } from "chipply-common";

export default class FilterItemMultiSelectViewModel implements IFilterItemViewModel {
    public selected: ITextValue<any>[] = [];
    public value = null;
    public prependText = "";
    public appendText = "";

    public constructor(prependText?: string, appendText?: string) {
        if (prependText) {
            this.prependText = prependText;
        }
        if (appendText) {
            this.appendText = appendText;
        }
    }

    public getFilterDisplayValues(): string[] {
        const displayValues: string[] = [];
        for (const currentItem of this.selected) {
            if (currentItem.text) {
                displayValues.push(this.prependText + currentItem.text.toString() + this.appendText);
            }
        }
        return displayValues;
    }

    public getFilterValue<T>(): T {
        const values = [];
        for (const currentItem of this.selected) {
            if (currentItem.value) {
                values.push(currentItem.value);
            }
        }
        return <T>(<unknown>values);
    }
}
