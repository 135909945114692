import { IListArgs } from "../data-access/IListArgs";
import IVendorListFilters from "./IVendorListFilters";

export interface IVendorDto {
    vendorID: number;
    dealerName: string;
    enabled: boolean;
    chipplyApproved: boolean;
    archived: boolean;
    hasInventory: boolean;
    vendorName: string;
    dealerID: number;
    sizeChart: string;
    isRestricted: boolean;
    allowedDealerIds: number[];

    update(src: IVendorDto): void;
}

export class VendorDto implements IVendorDto {
    vendorID!: number;
    dealerName = "";
    enabled = false;
    chipplyApproved = false;
    archived = false;
    hasInventory = false;
    vendorName = "";
    dealerID!: number;
    sizeChart = "";
    isRestricted = false;
    allowedDealerIds: number[] = [];

    public update(src: IVendorDto) {
        this.dealerID = src.dealerID;
        this.archived = src.archived;
        this.chipplyApproved = src.chipplyApproved;
        this.dealerName = src.dealerName;
        this.enabled = src.enabled;
        this.hasInventory = src.hasInventory;
        this.sizeChart = src.sizeChart;
        this.vendorID = src.vendorID;
        this.vendorName = src.vendorName;
        this.isRestricted = src.isRestricted;
        this.allowedDealerIds = src.isRestricted ? src.allowedDealerIds : [];
    }
}

export interface IVendorListArgs extends IListArgs {
    filters: IVendorListFilters;
}

export interface IVendorListResults {
    results: IVendorDto[];
    lastPage: boolean;
    totalRows: number;
}
