import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, stahlsShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "StahlsPurchaseOrderSettings" })
export default class StahlsPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public carrier = "";
    public service = "";
    public vendorName = "stahls";
}
