









































import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import { IContactViewModel } from "@/chipply/IContactViewModel";
import OrganizationContactEditViewModel from "@/chipply/organization/OrganizationContactEditViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import CCard from "@/components/ui/CCard.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import CPhone from "@/components/ui/CPhone.vue";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";
import { mask } from "vue-the-mask";

@Component({
    components: {
        CCard,
        CPhone,
    },
    directives: {
        mask,
    },
})
export default class EditContact extends Vue {
    @Prop({
        type: Object,
    })
    public vm!: IContactViewModel;
    @Prop({
        type: Boolean,
    })
    public required!: boolean;

    @Prop({
        type: Boolean,
        default: true,
    })
    public showIsPrimary!: boolean;

    @Prop({
        type: Boolean,
        default: true,
    })
    public showExtension!: boolean;

    protected Validation = Validation;
}
