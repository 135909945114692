import _ from "lodash";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import VendorPurchaseOrderArgs from "./VendorPurchaseOrderArgs";
import PurchasingConstants from "./PurchasingConstants";
import { SimpleAsyncInteractionViewModel, Utils, ITextValue, AsyncInteractionViewModel } from "chipply-common";
import { WebHelper } from "chipply-common";
import { PurchasingType } from "./PurchasingConstants";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import { Serializer, typeDependencies } from "chipply-common";
import IPurchasingViewModel from "./IPurchasingViewModel";
import BuildArtworkPurchaseOrderResults from "./BuildArtworkPurchaseOrderResults";
import ArtworkPurchaseOrder from "./ArtworkPurchaseOrder";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import CheckArtworkAvailabilityResults from "./CheckArtworkAvailabilityResults";
import SubmitArtworkPurchaseOrderResults from "./SubmitArtworkPurchaseOrderResults";
import CheckArtworkAvailabilityArgs from "./CheckArtworkAvailabilityArgs";
import ArtworkPurchaseOrderArgs from "./ArtworkPurchaseOrderArgs";
import SubmitArtworkPurchaseOrderArgs from "./SubmitArtworkPurchaseOrderArgs";
import ListArtworkPurchaseOrderArgs from "./ListArtworkPurchaseOrderArgs";
import AbstractPurchaseOrderViewModel from "./AbstractPurchaseOrderViewModel";
import StahlsPurchaseOrderSettings from "./Stahls/StahlsPurchaseOrderSettings";
import StahlsSettings from "./Stahls/StahlsSettings";
import ArtworkSelectorViewModel from "../event/artworks/ArtworkSelectorViewModel";
import ArtworkVariationSelectorViewModel from "../event/artworks/ArtworkVariationSelectorViewModel";
import ManualAddArtworkItemArgs from "./ManualAddArtworkItemArgs";
import ManualAddArtworkItemResults from "./ManualAddArtworkItemResults";
import PurchaseOrderHelper from "./PurchaseOrderHelper";
@typeDependencies({
    types: {
        StahlsPurchaseOrderSettings,
    },
})
export default class ArtworkPurchaseOrderViewModel
    extends AbstractPurchaseOrderViewModel
    implements IPurchasingViewModel
{
    public artworkSelectorViewModel: ArtworkSelectorViewModel | null = null;
    public artworkVariationSelectorViewModel: ArtworkVariationSelectorViewModel | null = null;
    public confirmViewModel: AsyncInteractionViewModel | null = null;
    public groupBy = "saleOrder";
    public hasManualAddItems = false;
    public lines: IArtworkPurchasingLineItem[] = [];
    public selectedLines: IArtworkPurchasingLineItem[] = [];
    public isEmailWindowVisible = false;
    public isViewingDetails = false;
    public currentItem: IArtworkPurchasingLineItem | null = null;
    public printOrEmailDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public purchasingVendors: ITextValue<number>[] = [];
    public artworkPurchasingVendors: Array<{ text: string; value: number }> = [];
    public isArtworkPurchasingEnabled = false;

    public headers = [
        { text: "", value: "imageUrl" },
        { text: "Vendor", value: "vendorName" },
        { text: "Artwork Name", value: "artworkName" },
        { text: "Variation Name", value: "variationName" },
        { text: "Variation ID", value: "externalId" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
    ];

    public stahlsShipCarriers = [
        { carrier: "UPS", description: "UPS" },
        { carrier: "FedEx", description: "Fedex" },
    ];

    public stahlsShipServices = [
        { service: "UPSGR", description: "UPS Ground" },
        { service: "UPS2D", description: "UPS 2nd Day Air" },
        { service: "UPSND", description: "UPS Next Day" },
        { service: "UPSNDPM", description: "UPS Next Day Air Saver" },
        { service: "FEDEXNDAM", description: "FedEx Priority Overnight" },
        { service: "FEDEX2D", description: "FedEx 2 Day" },
        { service: "FEDEXND", description: "FedEx Standard Overnight" },
        { service: "FEDEXGR", description: "FedEx Ground" },
    ];

    public computedStahlsShipServices(): { service: string; description: string }[] {
        if (!(this.vendorSettings?.vendorName === "stahls")) {
            return [];
        }
        const settings = <StahlsSettings>this.vendorSettings;
        if (!settings.carrier) {
            return [];
        }
        return this.stahlsShipServices.filter((x) =>
            x.service.toLocaleLowerCase().includes(settings.carrier.toLowerCase())
        );
    }

    protected processHeader = { text: "Process", value: "processBatchKey", sortable: true };

    public constructor(purchasingViewModel: IPurchasingParentViewModel) {
        super();
        this.purchasingViewModel = purchasingViewModel;
        this.isStoreView = purchasingViewModel.isStoreView;
        this.salesOrderInfo = purchasingViewModel.salesOrderInfo;
    }

    public async add(): Promise<void> {
        this.artworkVariationSelectorViewModel = new ArtworkVariationSelectorViewModel();
        this.artworkSelectorViewModel = new ArtworkSelectorViewModel(this.artworkVariationSelectorViewModel);
        const results = await this.purchasingViewModel.selectAdditionalArtworkVariations();
        this.artworkVariationSelectorViewModel = null;
        this.artworkSelectorViewModel = null;
        if (results.length <= 0) {
            return;
        }
        const args = new ManualAddArtworkItemArgs();
        args.items.push(...results);
        if (this.lines.length > 0) {
            // Currently we only allow a singe vendor for artwork purchasing
            // Use the vendor id from any line.
            args.vendorId = this.lines[0].vendorId;
        } else if (this.filters.vendorIds.length === 1) {
            args.vendorId = this.filters.vendorIds[0];
        } else {
            throw new Error("Unable to determine the vendor id for the current purchase order.");
        }
        this.filters.vendorIds[0];
        const manualaddResults = await this.getManualAddItems(args);

        this.lines.push(...manualaddResults.lines);
        this.selectedLines.push(...manualaddResults.lines);
        this.hasManualAddItems = true;
    }

    public isDigitalOrderingAvailable() {
        if (this.selectedLines.length === 0) {
            return false;
        }
        for (const line of this.selectedLines) {
            if (!line.externalId && !line.transientExternalId) {
                return false;
            }
            if (line.vendorId === PurchasingConstants.inHouseVendorId) {
                return false;
            }
            if (line.transientPurchasingVendorId === PurchasingConstants.inHousePurchasingVendorId) {
                return false;
            }
            if (line.vendorId === 0 && !line.transientPurchasingVendorId) {
                return false;
            }
        }
        return true;
    }

    public isSaveAvailable() {
        if (this.selectedLines.length === 0) {
            return false;
        }
        for (const line of this.selectedLines) {
            if (line.transientExternalId || line.transientPurchasingVendorId) {
                return true;
            }
        }
        return false;
    }

    public async save() {
        const serviceArgs = new ArtworkPurchaseOrderArgs();
        serviceArgs.filters = this.filters;
        serviceArgs.purchaseOrder = this.toPurchaseOrder();
        try {
            this.purchasingViewModel.isLoading = true;
            const results = await WebHelper.postJson<ArtworkPurchaseOrder>(
                "/api/purchasing/saveartworkvariations",
                serviceArgs
            );
            for (const line of this.lines) {
                if (!(line.transientPurchasingVendorId || line.transientExternalId)) {
                    continue;
                }
                const matchingLine = this.getMatchingLine(results.lines, line);
                if (!matchingLine) {
                    continue;
                }
                if (line.transientPurchasingVendorId) {
                    line.vendorId = matchingLine.vendorId;
                    if (!this.filters.vendorIds.includes(matchingLine.vendorId)) {
                        this.filters.vendorIds.push(matchingLine.vendorId);
                    }
                    line.transientPurchasingVendorId = null;
                    line.vendorName = matchingLine.vendorName;
                }
                if (line.transientExternalId) {
                    line.externalId = matchingLine.externalId;
                    line.transientExternalId = null;
                }
            }
            return results;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    protected getMatchingLine(
        linesToSearch: IArtworkPurchasingLineItem[],
        line: IArtworkPurchasingLineItem
    ): IArtworkPurchasingLineItem | null {
        const matchinglines = linesToSearch.filter((x) => x.id == line.id);
        if (matchinglines.length > 0) {
            return matchinglines[0];
        }
        return null;
    }

    protected async getManualAddItems(args: ManualAddArtworkItemArgs): Promise<ManualAddArtworkItemResults> {
        const results = await WebHelper.postJson<ManualAddArtworkItemResults>("/api/purchasing/manualaddartwork", args);
        return results;
    }

    public async buildPurchaseOrder(): Promise<void> {
        const baseUrl = "/api/purchasing/buildartworkpurchaseorder";
        let rowLimitExceeded = false;
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            const results = await WebHelper.postJson<BuildArtworkPurchaseOrderResults>(baseUrl, serviceArgs);
            if (results) {
                if (results.purchaseOrder) {
                    if (results.purchaseOrder.exceedsRowLimit) {
                        rowLimitExceeded = true;
                    }
                    this.toViewModel(results.purchaseOrder);
                }
                if (results.countries) {
                    this.countries = results.countries;
                }
                if (results.states) {
                    this.states = results.states;
                }
                this.stores.splice(0);
                this.stores.push(...results.stores);
                this.processes.splice(0);
                this.processes.push(...results.processes);
                this.purchasingVendors.splice(0);
                this.purchasingVendors.push(...results.purchasingVendors);
                this.vendors.splice(0);
                this.vendors.push(...results.vendors);
                this.salesReps.splice(0);
                this.salesReps.push(...results.salesReps);
                this.selectedPurchaseOrderType = PurchasingConstants.autoPurchaseType;
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (rowLimitExceeded) {
            this.purchasingViewModel.isLoading = false;
            setTimeout(async () => {
                await this.handleLargePurchaseOrder();
            });
            return;
        }
    }

    public async showPurchasingDialogOrCheckAvailabilityAsNeeded(type: PurchasingType): Promise<void> {
        if (this.isPurchaseOrderInfoRequiredForPresubmit) {
            await this.showPurchaseOrderDialog(type);
        } else {
            this.selectedPurchaseOrderType = type;
            await this.checkAvailability();
        }
    }

    public async showPurchaseOrderDialog(type: PurchasingType): Promise<void> {
        this.selectedPurchaseOrderType = type;
        if (!(await this.getDefaultVendorSettings())) {
            // Don't allow check availability if this false for some reason.
            return;
        }
        this.isPurchaseOrderDialogVisible = true;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.autoPurchaseType:
                this.purchaseOrderDialogTitle = "Order Digitally";
                break;
            case PurchasingConstants.checkPurchaseType:
                this.purchaseOrderDialogTitle = "Check Availability";
                break;
            case PurchasingConstants.manualArtworkPurchaseType:
                this.purchaseOrderDialogTitle = "Manually Order";
                break;
            case PurchasingConstants.pulledPurchaseType:
                this.purchaseOrderDialogTitle = "Pull from Stock";
                break;
        }
    }

    public async refresh(shouldMaintainAvailabilityStatus?: boolean): Promise<void> {
        this.lastPurchaseOrderId = 0;
        const previousLines: IArtworkPurchasingLineItem[] = [];
        if (shouldMaintainAvailabilityStatus) {
            previousLines.push(...this.lines);
        }
        await this.list();
    }

    public async list(): Promise<void> {
        const baseUrl = this.getListUrl();
        try {
            this.selectedLines.splice(0);
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ListArtworkPurchaseOrderArgs();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            // We can only show one group at a time so we prefer the process group.
            if (this.isGroupingByProcess) {
                PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.fulfillmentHeader);
                this.groupBy = "processBatchKey";
                PurchaseOrderHelper.addItemToArrayIfNotExists(this.headers, this.processHeader);
            } else {
                this.groupBy = "storeId";
                PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.processHeader);
                if (!this.filters.shouldExcludeFulfilledItems && this.canGroupByFulfillmentStatus) {
                    this.groupBy = "isFulfilled";
                    PurchaseOrderHelper.addItemToArrayIfNotExists(this.headers, this.fulfillmentHeader);
                } else {
                    PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.fulfillmentHeader);
                }
            }
            if (this.eventId) {
                PurchaseOrderHelper.addItemToArrayIfNotExists(this.filters.storeIds, this.eventId);
            }
            //serviceArgs.shouldGroupLikeItems = true;
            if (this.purchaseOrderId) {
                this.filters.purchaseOrderId = this.purchaseOrderId;
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as ArtworkPurchaseOrder;
            if (results) {
                this.toViewModel(results);
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async checkAvailability(): Promise<void> {
        const baseUrl = "/api/purchasing/checkartworkavailability";
        let errorMessage = "";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new CheckArtworkAvailabilityArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as CheckArtworkAvailabilityResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    errorMessage = results.message;
                    this.purchasingViewModel.errorMessage = errorMessage;
                    this.hasCheckedAvailability = false;
                    return;
                }
                this.hasCheckedAvailability = true;
                this.applyLines(results.lines);
                this.applySort();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
    }

    public async getDefaultVendorSettings(): Promise<boolean> {
        const baseUrl = "/api/purchasing/getartworkvendorsettings";
        try {
            const previousVendorSettings = this.vendorSettings;
            this.vendorSettings = null;
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ArtworkPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = Serializer.deserialize(resultsText) as IVendorPurchaseOrderSettings;
            if (results && previousVendorSettings && previousVendorSettings.vendorName === results.vendorName) {
                this.vendorSettings = previousVendorSettings;
            } else {
                this.shipMethodHint = "";
                this.vendorSettings = results;
            }
            if (this.vendorSettings) {
                this.vendorName = this.vendorSettings.vendorName;
            }
            return true;
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return false;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
        }
    }

    public async submitPurchaseOrder(purchaseOrderType: PurchasingType) {
        this.purchasingViewModel.errorMessage = "";
        this.selectedPurchaseOrderType = purchaseOrderType;
        const baseUrl = "/api/purchasing/submitartworkpurchaseorder";
        if (this.isSaveAvailable()) {
            await this.save();
        }
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new SubmitArtworkPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as SubmitArtworkPurchaseOrderResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    this.purchasingViewModel.errorMessage = results.message;
                    return;
                }
                this.applyLines(results.lines);
                this.lastPurchaseOrderNumber = this.purchaseOrderNumber;
                this.lastPurchaseOrderId = results.purchaseOrderId;
                this.purchaseOrderNumber = "";
                this.lastPurchaseOrderType = this.selectedPurchaseOrderType;
                this.showOrderCompleteDialog();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.selectedPurchaseOrderType = null;
            this.hasManualAddItems = false;
        }
    }

    protected applySort() {
        if (this.sortBy.length === 0) {
            this.sortBy = ["availability", "vendorName", "style", "color", "size"];
            this.sortDesc = [true, false, false, false, false];
        }
    }

    public async showOrderCompleteDialog(): Promise<void> {
        try {
            this.printOrEmailDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.isPrintEmailDialogVisible = true;
            const dialogResult = await this.printOrEmailDialogViewModel.interact();
            if (dialogResult !== "accept") {
                return;
            }
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        } finally {
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        }
    }

    protected toPurchaseOrder(): ArtworkPurchaseOrder {
        const purchaseOrder = new ArtworkPurchaseOrder();
        PurchaseOrderHelper.updatePurchaseOrder(purchaseOrder, this);
        purchaseOrder.lines = this.selectedLines;
        return purchaseOrder;
    }

    protected getListUrl(): string {
        const baseUrl = "/api/purchasing/listvariations";
        return baseUrl;
    }

    protected async handleLargePurchaseOrder(): Promise<void> {
        const vm = new SimpleAsyncInteractionViewModel();
        vm.headerText = "Select Stores";
        vm.text = `The configured purchase order exceeds the configured size limit.  Please select a smaller number of stores or processes.`;
        this.dismissDialogViewModel = vm;
        await this.dismissDialogViewModel.interact();
        this.dialogViewModel = null;
        this.back();
    }
}
