var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.vm.itemEditorViewModel !== null),callback:function ($$v) {_vm.$set(_vm.vm, "itemEditorViewModel !== null", $$v)},expression:"vm.itemEditorViewModel !== null"}},[(_vm.vm.itemEditorViewModel)?_c('c-list-item-editor',{attrs:{"vm":_vm.vm.itemEditorViewModel},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('th',[_vm._v("Enabled")])]},proxy:true},{key:"row",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('v-checkbox',{attrs:{"dense":""},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}}],null,false,202757038)}):_vm._e()],1),_c('v-dialog',{attrs:{"width":"300"},model:{value:(_vm.vm.addFieldViewModel !== null),callback:function ($$v) {_vm.$set(_vm.vm, "addFieldViewModel !== null", $$v)},expression:"vm.addFieldViewModel !== null"}},[_c('add-field-card',{attrs:{"vm":_vm.vm.addFieldViewModel}})],1),(_vm.vm)?_c('edit-layout',{attrs:{"show-back":true,"no-scroll":""},on:{"back":function($event){return _vm.vm.done('cancel')}},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("Additional Information Fields")]},proxy:true},{key:"toolbar",fn:function(){return [_c('c-toolbar-btn',{attrs:{"icon":"$iconSave","label":"Save","color":"#347E38","disabled":!_vm.vm.isValid},on:{"click":function($event){return _vm.vm.done('accept')}}})]},proxy:true}],null,false,3342276877)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-form',{ref:"form",model:{value:(_vm.vm.isValid),callback:function ($$v) {_vm.$set(_vm.vm, "isValid", $$v)},expression:"vm.isValid"}},[_c('v-row',{staticClass:"ml-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('c-control-label',{attrs:{"bold":""}},[_vm._v("Fields")]),_c('c-add-button',{staticStyle:{"padding-left":"10px"},attrs:{"tooltip":"Add Field","disabled":_vm.vm.fields.length > 9},on:{"click":_vm.addField}})],1),_c('v-data-table',{ref:"table",attrs:{"items":_vm.vm.fields,"headers":_vm.vm.fieldHeaders,"disable-filtering":"","disable-pagination":"","disable-sort":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.handle",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"dragHandle"},[_c('v-icon',[_vm._v(_vm._s(_vm.chipplyIcons.mdiDragVertical))])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"rules":[_vm.Validation.requireValue]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})]}},{key:"item.required",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.required),callback:function ($$v) {_vm.$set(item, "required", $$v)},expression:"item.required"}})]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.typeName)+" ")]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [(item instanceof _vm.ListFieldViewModel)?_c('div',[_c('c-edit-button',{attrs:{"tooltip":"Edit Items"},on:{"click":function($event){return _vm.vm.editItems(item)}}})],1):_c('div',[_vm._v("N/A")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.inUse)?_c('c-delete-button',{on:{"click":function($event){return _vm.vm.remove(item)}}}):_vm._e()]}}],null,false,846501003)})],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }