import IFilterItemViewModel from "@/chipply/view-model/IFilterItemViewModel";

export default class FilterItemSimpleViewModel implements IFilterItemViewModel {
    public value: any = null;
    public selected = null;
    public prependText = "";
    public appendText = "";

    public constructor(prependText?: string, appendText?: string) {
        if (prependText) {
            this.prependText = prependText;
        }
        if (appendText) {
            this.appendText = appendText;
        }
    }

    public getFilterDisplayValues(): string[] {
        const displayValues: string[] = [];
        if (this.value) {
            displayValues.push(this.prependText + this.value.toString() + this.appendText);
        }
        return displayValues;
    }

    public getFilterValue<T>(): T {
        return <T>this.value;
    }
}
