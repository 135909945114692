





































import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component, { mixins } from "vue-class-component";
import FormValidationMixin from "@/components/FormValidationMixin";
import Validation from "@/validation";
import { Prop, Watch } from "vue-property-decorator";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import ViewHeader from "../utility/ViewHeader.vue";
import CancelSaveButtonBarMobile from "../utility/CancelSaveButtonBarMobile.vue";
import CancelSaveButtonBarDesktop from "../utility/CancelSaveButtonBarDesktop.vue";
import { ChangeAccountPasswordViewModel } from "@/chipply/dealer/ChangeAccountPasswordViewModel";

@Component({
    components: {
        CDecisionCard,
        ViewHeader,
        CancelSaveButtonBarMobile,
        CancelSaveButtonBarDesktop,
    },
})
export default class ChangeAccountPassword extends mixins(FormValidationMixin) {
    public icons = chipplyIcons;
    public name = "ChangeAccountPassword";
    public Validation = Validation;
    public showNewPassword!: boolean;
    public showConfirmNewPassword!: boolean;

    @Prop({
        type: Object,
    })
    public viewModel!: ChangeAccountPasswordViewModel;

    @Prop({
        type: Boolean,
        default: true,
    })
    public isShowHeader!: boolean;

    @Prop({
        type: String,
        default: "300px",
    })
    public formHeight!: string;

    @Watch("viewModel")
    protected viewModelChanged() {
        if (this.viewModel) {
            this.validate();
        }
    }

    @Watch("isValid")
    protected updateViewModelIsValid() {
        this.viewModel.isValidAccountPassword = this.isValid;
    }

    public data() {
        return {
            showNewPassword: false,
            showConfirmNewPassword: false,
        };
    }

    public async close(accepted: boolean) {
        if (accepted == true) {
            if (this.viewModel.newPassword != this.viewModel.confirmNewPassword) {
                this.isValid = false;
                return;
            } else {
                this.isValid = true;
            }
            await this.viewModel.done("accept");
        } else {
            await this.viewModel.done("cancel");
        }
    }

    public requireConfirmNewPassword(value: string) {
        if (this.viewModel!.confirmNewPassword !== this.viewModel.newPassword) {
            return "Passwords do not match";
        }
        return Validation.requireValue(value);
    }
}
