






























































































































































































































































































































































import Component, { mixins } from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import CApiAutoComplete from "@/components/ui/CApiAutoComplete.vue";
import FormValidationMixin from "@/components/FormValidationMixin";
import { Autocompletes } from "@/chipply/Autocompletes";
import EditProductDetailViewModel from "@/chipply/products/edit/EditProductDetailViewModel";
import { Prop, Watch } from "vue-property-decorator";
import HtmlTextField from "@/components/HtmlTextField.vue";
import CMoney from "@/components/ui/CMoney.vue";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";
import EditProductDetailSizes from "./EditProductDetailSizes.vue";
import EditProductDetailColors from "./EditProductDetailColors.vue";
import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import Validation from "@/validation";
import CAddButton from "@/components/ui/CAddButton.vue";
import SpreadsheetProductCategorySelector from "@/components/products/SpreadsheetProductCategorySelector.vue";
import { Utils } from "chipply-common";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
import CHtmlViewEdit from "@/components/ui/CHtmlViewEdit.vue";
import { EventBus } from "@/chipply/EventBus";
import { mask } from "vue-the-mask";

@Component({
    components: {
        CAddButton,
        CDecisionCard,
        EventProductColorSizeGrid,
        CMoney,
        HtmlTextField,
        CApiAutoComplete,
        CToolbarBtn,
        EditLayout,
        EditProductDetailSizes,
        EditProductDetailColors,
        SpreadsheetProductCategorySelector,
        CSaveDialog,
        CHtmlViewEdit,
    },
    directives: { mask },
})
export default class EditProductDetail extends mixins(ScrollableContentMixin, FormValidationMixin) {
    public Validation = Validation;
    public Autocompletes = Autocompletes;
    public Utils = Utils;

    public $refs!: {
        descriptionField: HtmlTextField;
        shortDescriptionField: HtmlTextField;
        sizeChartField: HtmlTextField;
        shipInstructionsField: HtmlTextField;
        form: any;
    };

    @Prop({
        default: new EditProductDetailViewModel(),
        type: Object,
    })
    public vm!: EditProductDetailViewModel;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    public async created() {
        EventBus.$off("requestEditProductDetailFormatted");
        EventBus.$on("requestEditProductDetailFormatted", () => {
            this.alreadyBackupInitialDescription = false;
            this.alreadyBackupInitialShortDescription = false;
            this.alreadyBackupInitialShipInstructions = false;
            this.alreadyBackupInitialSizeChart = false;
        });

        await this.vm.getProduct();
        this.vm.subscribeCloseColorSizeGridEvent();
        this.validate();
    }

    public updated() {
        //this.backupInitialFormattedHtmlProperties();
    }

    private alreadyBackupInitialDescription = false;

    public descriptionFieldEditorSetContent(evt: any) {
        if (evt.initial || this.alreadyBackupInitialDescription || !evt.target || !evt.target.initialized) {
            return;
        }
        const contentHasValue = evt.content && evt.content.length > 0;
        setTimeout(() => {
            const formattedDescription = this.$refs.descriptionField.formattedEditorValue;
            if (!formattedDescription || (formattedDescription.length === 0 && contentHasValue)) {
                return;
            }

            this.vm.originalFormattedDescription = formattedDescription;
            this.vm.description = formattedDescription;
            this.alreadyBackupInitialDescription = true;
        }, 500);
    }

    private alreadyBackupInitialShortDescription = false;
    public shortDescriptionFieldEditorSetContent(evt: any) {
        if (evt.initial || this.alreadyBackupInitialShortDescription || !evt.target || !evt.target.initialized) {
            return;
        }

        const contentHasValue = evt.content && evt.content.length > 0;
        setTimeout(() => {
            const formattedShortDescription = this.$refs.shortDescriptionField.formattedEditorValue;
            if (!formattedShortDescription || (formattedShortDescription.length === 0 && contentHasValue)) {
                return;
            }

            this.vm.originalFormattedShortDescription = formattedShortDescription;
            this.vm.shortDescription = formattedShortDescription;
            this.alreadyBackupInitialShortDescription = true;
        }, 500);
    }

    private alreadyBackupInitialSizeChart = false;
    public sizeChartFieldEditorSetContent(evt: any) {
        if (evt.initial || this.alreadyBackupInitialSizeChart || !evt.target || !evt.target.initialized) {
            return;
        }
        const contentHasValue = evt.content && evt.content.length > 0;
        setTimeout(() => {
            const formattedSizeChart = this.$refs.sizeChartField.formattedEditorValue;
            if (!formattedSizeChart || (formattedSizeChart.length === 0 && contentHasValue)) {
                return;
            }

            this.vm.originalFormattedSizeChart = formattedSizeChart;
            this.vm.sizeChart = formattedSizeChart;
            this.alreadyBackupInitialSizeChart = true;
        }, 500);
    }

    private alreadyBackupInitialShipInstructions = false;
    public shipInstructionsFieldEditorSetContent(evt: any) {
        if (evt.initial || this.alreadyBackupInitialShipInstructions || !evt.target || !evt.target.initialized) {
            return;
        }
        const contentHasValue = evt.content && evt.content.length > 0;
        setTimeout(() => {
            const formattedShipInstructions = this.$refs.shipInstructionsField.formattedEditorValue;
            if (!formattedShipInstructions || (formattedShipInstructions.length === 0 && contentHasValue)) {
                return;
            }

            this.vm.originalFormattedShipInstructions = formattedShipInstructions;
            this.vm.shipInstructions = formattedShipInstructions;
            this.alreadyBackupInitialShipInstructions = true;
        }, 500);
    }

    public beforeDestroy() {
        this.vm.unsubscribeCloseColorSizeGridEvent();
    }

    public numberFocused(event: FocusEvent) {
        if (!event) {
            return;
        }
        (event.currentTarget as HTMLInputElement).select();
    }

    public requireVendorSelected(vendorId: number) {
        return vendorId > 0 ? true : "You must choose a vendor.";
    }
}
