import { ITextValueDisabled } from "chipply-common";
import { ITextValue } from "chipply-common";

export default class ListUtils {
    /**
     * Filters out disabled values from a list if the list items id is not part of the dependencies
     * @param list
     * @param dependencies
     */
    public static filterUnusedDisabledValues(list: Array<ITextValueDisabled<number>>, ...dependencies: number[]) {
        return list.filter((value, index, array) => {
            if (!value.disabled) {
                return true;
            }
            return dependencies.indexOf(value.value) !== -1;
        });
    }

    /**
     * Filters out items in idsToRemove from list
     * @param list
     * @param idsToRemove
     */
    public static filterListValues<T extends ITextValue<number>>(list: Array<T>, ...idsToRemove: number[]): T[] {
        return list.filter((value, index, array) => {
            return idsToRemove.indexOf(value.value) === -1;
        });
    }
}
