import { AsyncInteractionViewModel, Utils, WebHelper } from "chipply-common";
import IBatchedCopyEventArgs from "@/chipply/event/IBatchedCopyEventArgs";
import IServerStatus from "@/chipply/IServerStatus";

export class EventCopierDialogViewModel extends AsyncInteractionViewModel {
    public selectedEventId = 0;
    public selectedEventName = "";
    public selectedEventType = "0";
    public showStoreOptionsDialog = false;
    public showCopyingDialog = false;
    public statusMessage = "";
    public selectedOrderId = 0;

    public optionsAccepted = async (accepted: { accepted: boolean; eventType: string; storeName: string }) => {
        if (accepted.accepted) {
            this.selectedEventType = accepted.eventType;
            this.selectedEventName = accepted.storeName;
            await this.copyStore();
            this.done("accept");
        } else {
            this.showStoreOptionsDialog = false;
            this.done("cancel");
        }
    };
    public async copyEvent(copyArgs: IBatchedCopyEventArgs): Promise<IBatchedCopyEventArgs> {
        if (!copyArgs.serverStatus) {
            this.statusMessage = "Copying store...";
        } else {
            if (copyArgs.serverStatus.workCompleted || copyArgs.serverStatus.errorOccurred) {
                return copyArgs;
            }
            this.statusMessage = copyArgs.serverStatus.statusMessage;
            if (!Utils.hasValue(copyArgs.serverStatus.statusMessage)) {
                this.statusMessage = "Copying store...";
            }
        }
        const jsonResponse = await WebHelper.postJsonData(`/api/Events/CopyEvent`, copyArgs);
        copyArgs = JSON.parse(jsonResponse);
        return await this.copyEvent(copyArgs);
    }

    public async copyStore() {
        this.statusMessage = "Copying...";
        const newStoreName = this.selectedEventName;
        const newEventType = Number(this.selectedEventType);
        const copyFromEventId = this.selectedEventId;
        const copyFromOrderId = Number(this.selectedOrderId);

        this.selectedEventId = 0;

        const copyArgs: IBatchedCopyEventArgs = {
            copyFromEventId,
            copyToEventType: newEventType,
            newEventName: newStoreName,
            copyFromOrderId,
        };
        await this.beginCopyEvent(copyArgs);
    }

    public async beginCopyEvent(copyArgs: IBatchedCopyEventArgs) {
        this.showCopyingDialog = true;
        const returnedArgs = await this.copyEvent(copyArgs);
        const serverStatus = returnedArgs.serverStatus as IServerStatus;

        if (serverStatus.errorMessage) {
            this.statusMessage = Utils.ServerErrorMessage;
            return;
        }
        if (serverStatus.workCompleted) {
            this.statusMessage = serverStatus.statusMessage;

            document.location.href = "/ng/settings.html?eventid=" + returnedArgs.copyToEventId;
        }
    }
}
