import { ProductSearchConstants } from "./ProductSearchConstants";
import { IProductSearchModeFunction } from "./IProductSearchModeFunction";
import ChipplyConstants from "@/chipply/ChipplyConstants";

export class ProductAddApplyFilterVisibilityAction implements IProductSearchModeFunction<string, boolean> {
    applyDealerTemplateProducts(t: string): boolean {
        const fields = [
            ProductSearchConstants.templateId,
            ProductSearchConstants.templateCategoryIds,
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return fields.indexOf(t) > -1;
    }
    applyChipplyTemplateProducts(t: string): boolean {
        const fields = [
            ProductSearchConstants.templateId,
            ProductSearchConstants.templateCategoryIds,
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return fields.indexOf(t) > -1;
    }
    applyChipplyProducts(t: string): boolean {
        const fields = [
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return fields.indexOf(t) > -1;
    }
    applyDealerProducts(t: string): boolean {
        const fields = [
            ProductSearchConstants.organizationId,
            ProductSearchConstants.storeId,
            ProductSearchConstants.storeCategoryIds,
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return fields.indexOf(t) > -1;
    }

    applyFeedProducts(t: string): boolean {
        const fields = [
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return fields.indexOf(t) > -1;
    }

    applyCommonProducts(t: string, eventId: number | null): boolean {
        const fields = [
            ProductSearchConstants.styleNameKeywords,
            ProductSearchConstants.exactMatch,
            ProductSearchConstants.vendorId,
        ];
        return (
            fields.indexOf(t) > -1 ||
            ((t === ProductSearchConstants.processId || t === ProductSearchConstants.additionalFilter) &&
                eventId !== ChipplyConstants.CHIPPLY_EVENT_ID)
        );
    }
}
