








































































































































































































































































































































































import ScrollableContentMixin from "@/components/ScrollableContentMixin";
import Component, { mixins } from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import ErrorReporter from "@/components/utility/ErrorReporter.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { Prop, Watch } from "vue-property-decorator";
import CChildPane from "@/components/ui/CChildPane.vue";
import NavigationSide from "@/components/navigation/NavigationSide.vue";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { EventBus } from "@/chipply/EventBus";
@Component({
    components: {
        TextHeading,
        LongRunningOperationDialog,
        ErrorReporter,
        CChildPane,
        NavigationSide,
    },
    props: {
        errorMessage: String,
        isChildPaneVisible: Boolean,
        isLoading: Boolean,
        statusMessage: String,
        noScroll: Boolean,
        isOptionsButtonVisible: Boolean,
    },
})
export default class EditLayout extends mixins(ScrollableContentMixin) {
    public name = "EditLayout";
    public chipplyIcons = chipplyIcons;
    public errorMessage!: string;
    public errorReporterOpened = false;
    public isChildPaneVisible!: boolean;
    public fab = false;
    public isSpeedDialOpen = false;
    public isNavigationSideExpanded = true;
    public isNavigationSideCollapsing = false;

    @Prop({
        type: Function,
    })
    public optionsAccepted!: () => void;

    @Prop({
        type: Function,
    })
    public optionsCanceled!: () => void;

    @Prop({
        type: Function,
    })
    public optionsDrawerOpened!: () => void;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isNavigationSideVisible!: boolean;

    @Prop({
        type: Object,
    })
    public vm: PageViewModel | undefined;

    @Prop({
        default: true,
    })
    public declare allowScroll: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public hasNoTopNavigation!: boolean;
    public noTopNavigation = false;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isOptionsButtonVisible!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isSpeedDialVisible!: boolean;

    @Prop({
        type: Number,
    })
    public optionsDrawerWidth!: number;

    @Prop({
        type: Boolean,
        default: true,
    })
    public showButtonsOnDrawer!: boolean;

    @Prop({
        type: String,
        default: chipplyIcons.mdiListStatus,
    })
    public optionsIcon!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBack!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isHeaderVisible!: boolean;

    @Prop({
        type: Number,
    })
    public containerHeight: number | undefined;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isNavSideInitiallyOpen!: boolean;

    public raiseBack() {
        this.$emit("back");
    }

    public created() {
        if (this.$vuetify.breakpoint.smAndDown) {
            this.isNavigationSideExpanded = false;
        }
        this.noTopNavigation = this.hasNoTopNavigation;
    }

    @Watch("hasNoTopNavigation")
    public hasNoTopNavigationChanged() {
        this.noTopNavigation = this.hasNoTopNavigation;
    }

    public data() {
        return {};
    }

    protected getParentHeight(): number {
        if (this.containerHeight) {
            return this.containerHeight;
        }
        return this.parentHeight;
    }

    public getCloseIcon() {
        if (this.showBack) {
            return "$iconArrowBack";
        } else {
            return "$iconDashboardSolid";
        }
    }

    public getMobileCloseIcon() {
        if (this.showBack) {
            return "$iconArrowBack";
        } else {
            return "$iconClose";
        }
    }

    @Watch("errorMessage")
    protected onErrorMessageChanged() {
        if (this.errorMessage) {
            this.errorReporterOpened = true;
        } else {
            this.errorReporterOpened = false;
        }
    }

    // We changed the mobile layout to a more robust approach so the mobile header offset is no longer desired.
    protected hasMobileHeader = false;

    public constructor() {
        super();
        this.isNavigationSideExpanded = this.isNavSideInitiallyOpen;
        EventBus.$on("navigation-side-close", (navigationSide: NavigationSide) => {
            this.toggleNavigationSide();
        });
    }

    protected errorReporterChange(nv: boolean) {
        this.$emit("error-reporter-change", nv);
    }

    public closeClick() {
        if (this.showBack || this.showBackOnly) {
            this.raiseBack();
        }
        (document.activeElement as HTMLElement).blur();
    }

    public toggleNavigationSide() {
        this.isNavigationSideExpanded = !this.isNavigationSideExpanded;
        if (this.vm) {
            this.vm.isNavigationSideExpanded = this.isNavigationSideExpanded;
        }
    }

    public toggleOptions() {
        if (this.vm) {
            this.vm.isOptionsDrawerVisible = !this.vm.isOptionsDrawerVisible;
            if (this.vm.isOptionsDrawerVisible) {
                if (this.optionsDrawerOpened) {
                    this.optionsDrawerOpened();
                }
            }
        }
    }

    public optionsAccept() {
        if (this.vm) {
            this.vm.isOptionsDrawerVisible = false;
        }
        if (this.optionsAccepted) {
            this.optionsAccepted();
        }
    }

    public optionsCancel() {
        if (this.vm) {
            this.vm.isOptionsDrawerVisible = false;
        }
        if (this.optionsCanceled) {
            this.optionsCanceled();
        }
    }
}
