import { SimpleAsyncInteractionViewModel, WebHelper } from "chipply-common";
import IProductDetailSizeModel, { IProductDetailSaveSizesArgs } from "@/chipply/products/edit/IProductDetailSizeModel";
import IProductDetailQuickSizesModel from "./IProductDetailQuickSizesModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import IProductDetailStandardSizesModel from "./IProductDetailStandardSizesModel";

export default class EditProductDetailSizesViewModel extends PageViewModel {
    public eventId = 0;
    public productId = 0;
    public baseProductId = 0;
    public isAdminUser = false;
    public title = "Manage Product Sizes";
    public isHeaderVisible = false;
    public allowDelete = true;

    public loading = false;
    public isDirty = false;

    public deleteDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public saveConfirmViewModel = new SimpleAsyncInteractionViewModel();
    public showSaveConfirmDialog = false;

    public newSizes = "";
    public existingSizes: IProductDetailSizeModel[] = [];

    public oneSizeName = "";
    public oneSizeAdditionalCost = 0;

    public standardSizes: IProductDetailStandardSizesModel[] = [];

    public isSelectAll = false;

    public async quickAddSizes() {
        const newSizes = this.newSizes.split("\n").filter((x) => x.trim().length > 0);
        if (newSizes.length == 0) {
            return;
        }

        this.statusMessage = "Adding...";
        this.loading = true;
        const args = {
            productId: this.productId,
            storeId: this.eventId,
            newSizes,
        } as IProductDetailQuickSizesModel;

        const addedSizes = (await WebHelper.postJson(`/api/Product/QuickAddSizes`, args)) as IProductDetailSizeModel[];
        this.existingSizes.push(...addedSizes);

        this.newSizes = "";

        this.loading = false;
    }

    public toSaveSizesArgs() {
        let newSortOrder = 1;
        const args = {
            storeId: this.eventId,
            eventProductId: this.productId,
            sizes: [],
        } as IProductDetailSaveSizesArgs;

        this.existingSizes.forEach((item) => {
            args.sizes.push({
                eventProductId: this.productId,
                eventProductSizeId: item.eventProductSizeId,
                sizeName: item.sizeName,
                additionalCost: item.additionalCost,
                sortOrder: newSortOrder,
                standardSizeId: item.standardSizeId,
                isSelected: undefined,
            });

            newSortOrder++;
        });

        return args;
    }

    public async saveProductSizes(reloadAfterSave = true) {
        this.statusMessage = "Saving...";
        this.loading = true;

        try {
            await WebHelper.postJsonData(`/api/Product/SaveSizes`, this.toSaveSizesArgs());
            this.newSizes = "";
            this.isDirty = false;
            if (reloadAfterSave) {
                await this.getProductDetailSizes();
            }
        } catch (err) {
            const error = err as any;
            if (err && error.ExceptionType === "System.ArgumentException") {
                this.errorMessage = error.ExceptionMessage;
            } else {
                this.errorMessage = "An error occurred while saving data.";
            }
        } finally {
            this.loading = false;
        }
    }

    public async getProductDetailSizes() {
        const baseUrl = `/api/Product/GetSizes?eventId=${this.eventId}&productId=${this.productId}&baseProductId=${this.baseProductId}`;

        try {
            this.statusMessage = this.loadingMessage;
            this.loading = true;
            const results = (await WebHelper.getJsonData(baseUrl)) as IProductDetailSizeModel[];
            if (results.length == 1 && results[0].sizeName === "One Size") {
                this.existingSizes = [];
            } else {
                this.existingSizes = results.map((x) => {
                    x.isSelected = false;
                    return x;
                });
            }
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.loading = false;
        }
    }

    public checkAllChanged() {
        for (const item of this.existingSizes) {
            item.isSelected = this.isSelectAll;
        }
    }

    public check(item: IProductDetailSizeModel) {
        this.setSelectAllStatus();
    }

    protected setSelectAllStatus() {
        this.isSelectAll =
            this.existingSizes &&
            this.existingSizes.length > 0 &&
            this.existingSizes.filter((item) => !item.isSelected).length == 0;
    }

    public async getStandardSizes() {
        const baseUrl = `/api/Product/StandardSizes`;

        try {
            this.statusMessage = "loading standard sizes";
            this.loading = true;
            this.standardSizes = (await WebHelper.getJsonData(baseUrl)) as IProductDetailStandardSizesModel[];
        } catch {
            this.errorMessage = "An error occurred while loading the sizes.";
        } finally {
            this.loading = false;
        }
    }

    public async deleteSizes() {
        const deleteDialog = new SimpleAsyncInteractionViewModel();
        deleteDialog.text = `Are you sure you would like to delete the selected size(s)?`;
        this.deleteDialogViewModel = deleteDialog;
        const result = await this.deleteDialogViewModel.interact();
        this.deleteDialogViewModel = null;
        if (result === "cancel") {
            return;
        }

        const selected = this.existingSizes.filter((x) => x.isSelected);
        for (const item of selected) {
            this.existingSizes.splice(this.existingSizes.indexOf(item), 1);
        }
        await this.saveProductSizes();
    }

    public async deleteSize(item: IProductDetailSizeModel) {
        const deleteDialog = new SimpleAsyncInteractionViewModel();
        deleteDialog.text = `Are you sure you would like to delete the size ${item.sizeName}?`;
        this.deleteDialogViewModel = deleteDialog;
        const result = await this.deleteDialogViewModel.interact();
        this.deleteDialogViewModel = null;
        if (result === "cancel") {
            return;
        }

        this.existingSizes.splice(this.existingSizes.indexOf(item), 1);
        await this.saveProductSizes();
    }

    public async saveConfirm() {
        if (!this.isDirty) return true;

        this.showSaveConfirmDialog = true;
        const result = await this.saveConfirmViewModel.interact();
        this.showSaveConfirmDialog = false;
        if (result === "accept") {
            await this.saveProductSizes(false);
            return true;
        }

        if (result === "continue") {
            return true;
        }

        return false;
    }
}
