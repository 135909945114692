












































import Vue from "vue";
import chipplyIcons from "@/chipply/ImportIcons";
import Component from "vue-class-component";
import CCopyButton from "@/components/ui/CCopyButton.vue";
import { Prop } from "vue-property-decorator";
import SalesOrderInfo from "@/chipply/event/SalesOrderInfo";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
@Component({
    components: { CToolbarBtn, CCopyButton },
    props: {
        menuData: Object,
    },
})
export default class CInformationVariant extends Vue {
    public name = "CInformationVariant";
    public chipplyIcons = chipplyIcons;
    public dismiss = false;
    @Prop()
    public closeOnContentClick!: boolean;
    @Prop()
    public salesOrderInfo!: object;
}
