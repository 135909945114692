import * as Vuex from "vuex";
import { userInfoStore } from "@/store/UserInfoStore";
import { environmentStore } from "@/store/EnvironmentStore";
import { sessionStore } from "@/store/SessionStore";
import ISessionState from "@/store/ISessionState";
import { IEnvironmentState } from "./IEnvironmentState";

export interface IRootState {
    userInfo: IUserInfoState;
    envInfo: IEnvironmentState;
    sessionState: ISessionState;
}

export default new Vuex.Store<IRootState>({
    modules: {
        envInfo: environmentStore,
        sessionState: sessionStore,
        userInfo: userInfoStore,
    },
});
