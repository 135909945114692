





















import chipplyIcons from "@/chipply/ImportIcons";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { AsyncInteractionWithDataViewModel, SimpleAsyncInteractionWithDataViewModel } from "chipply-common";

@Component({
    components: {
        CDecisionCard,
    },
    props: {
        viewModel: Object,
    },
})
export default class CSuccessOrFailureCard extends Vue {
    public chipplyIcons = chipplyIcons;
    public viewModel!:
        | AsyncInteractionWithDataViewModel<boolean>
        | SimpleAsyncInteractionWithDataViewModel<boolean>
        | null;
}
