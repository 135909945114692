import { StoreCheckoutFieldsViewModel } from "@/chipply/fields/StoreCheckoutFieldsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import StoreSettingsViewModel from "@/chipply/store/settings/StoreSettingsViewModel";

export class RequestorStoreSettingsViewModel extends StoreSettingsViewModel {
    public constructor(model: StoreSettingsPageModel, pageVm: StoreSettingsPageViewModel) {
        super(model);
    }
}
