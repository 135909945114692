import { ProductAddApplyFilterVisibilityAction } from "./ProductAddApplyFilterVisibilityAction";
import {
    ProductSearchChipplyProductsMode,
    ProductSearchChipplyTemplateProductsMode,
    ProductSearchFeedProductsMode,
    ProductSearchGeneralProductsMode,
    ProductSearchMode,
    ProductSearchDealerProductsMode,
    ProductSearchDealerTemplateProductsMode,
} from "./ProductSearchMode";
import { AsyncInteractionViewModel, DateUtils, ITextValue, WebHelper } from "chipply-common";
import ITreeItem from "@/chipply/interface/i-tree-item";
import { ProductSearchConstants } from "./ProductSearchConstants";
import ChipplyConstants from "@/chipply/ChipplyConstants";
import { DateTime } from "luxon";
import IProcessCost from "@/chipply/process/IProcessCost";

export class ProductSearchModeSelectorViewModel {
    protected readonly loadingMessage = "Loading...";
    public readonly loadingErrorMessage = "An error occurred while loading the data.";

    public loading = false;
    public statusMessage: string | null = this.loadingMessage;
    public errorMessage: string | null = null;
    public isFlatCard = true;
    public isShowAllModes = false;

    public allSearchModes!: ProductSearchMode<string, boolean>[];
    public searchMode!: ProductSearchMode<string, boolean>;

    public filterVisibilityAction = new ProductAddApplyFilterVisibilityAction();

    //Used as placeholder to notify parent.
    public interactionViewModel = new AsyncInteractionViewModel();

    public templateCategories: ITreeItem[] = [];
    public storeCategories: ITreeItem[] = [];
    public processes: Array<ITextValue<number>> = [];
    public processCosts: { [processId: number]: IProcessCost } = {};
    public dealerId = 0;
    public storeId = 0;
    public clientDateTime: Date | null = null;

    public taxTypes = [
        { text: "All", value: null },
        { text: "Auto", value: 0 },
        { text: "Flat Percentage", value: 1 },
        { text: "Tax Exempt", value: 2 },
        { text: "Custom", value: 3 },
    ];

    public canChangeView = false;
    public showAdditionalFiltersDialog = false;

    public constructor() {
        this.allSearchModes = [
            new ProductSearchChipplyProductsMode<string, boolean>(),
            new ProductSearchDealerProductsMode<string, boolean>(),
            new ProductSearchChipplyTemplateProductsMode<string, boolean>(),
            new ProductSearchDealerTemplateProductsMode<string, boolean>(),
        ];
        this.searchMode = this.allSearchModes[0];
        this.allSearchModes[0].isSelected = true;
        this.allSearchModes[2].isSelected = true;
    }

    public initialize(storeId: number, dealerId: number, clientDateTime: Date | null) {
        if ((this.storeId! = storeId)) {
            this.dealerId = dealerId;
            this.storeId = storeId;
            this.clientDateTime = clientDateTime;
            if (this.storeId == ChipplyConstants.CHIPPLY_EVENT_ID) {
                this.allSearchModes = [new ProductSearchFeedProductsMode<string, boolean>()];
                this.searchMode = this.allSearchModes[0];
                this.searchMode.isSelected = true;
            }
        }
    }

    public refreshData() {
        if (this.searchMode && this.searchMode.calcStoreId > 0) {
            if (this.isFilterVisible(this.searchMode, ProductSearchConstants.templateCategoryIds)) {
                this.getTemplateCategories(this.searchMode.calcStoreId);
            } else if (this.isFilterVisible(this.searchMode, ProductSearchConstants.storeCategoryIds)) {
                this.getStoreCategories(this.searchMode.calcStoreId);
            }
        }
    }

    public toggleSearchMode(modeName: string) {
        this.searchMode = this.allSearchModes.find((mode) => mode.name === modeName) || this.allSearchModes[0];
    }

    public isFilterVisible(searchMode: ProductSearchMode<string, boolean>, filterName: string) {
        return searchMode.apply(this.filterVisibilityAction, filterName);
    }

    public getDate() {
        return this.clientDateTime;
    }

    public getPanelHeaderText(isTemplateMode: boolean) {
        const selected = this.allSearchModes.filter((x) => x.isTemplateMode == isTemplateMode && x.isSelected);
        if (selected.length == 0) {
            return this.allSearchModes.filter((x) => x.isTemplateMode == isTemplateMode)[0].display;
        }
        return selected[0].display;
    }

    public selectSearchModel(newlySelected: ProductSearchMode<string, boolean>, isTemplateMode: boolean) {
        const filtered = this.allSearchModes.filter((x) => x.isTemplateMode == isTemplateMode);
        filtered.forEach((item) => {
            if (item == newlySelected) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        this.searchMode = newlySelected;
        this.clear();
    }

    public clear() {
        this.templateCategories = [];
        this.storeCategories = [];
        this.searchMode.clearFilters();
    }

    public async getTemplateCategories(eventId: number | null) {
        if (eventId && eventId > 0) {
            this.templateCategories = await this.getCategories(eventId);
        } else {
            this.templateCategories = [];
            this.searchMode.filters.templateCategoryIds = [];
        }
    }

    public async getStoreCategories(eventId: number | null) {
        if (eventId && eventId > 0) {
            this.storeCategories = await this.getCategories(eventId);
        } else {
            this.storeCategories = [];
        }
    }

    protected async getCategories(eventId: number) {
        try {
            this.errorMessage = null;
            this.loading = true;
            this.statusMessage = this.loadingMessage;
            const excludeNoCategory = false;
            const jsonResults = await WebHelper.getJsonData(
                `/api/ProductSpreadsheet/GetEventCategories/${eventId}/${excludeNoCategory}`
            );
            const results = jsonResults.categories;
            if (results.length == 0) {
                const emptyCategory: ITreeItem = {
                    id: ProductSearchConstants.allProductsCategoryId,
                    name: ProductSearchConstants.allProductsCategoryDisplay,
                    children: [],
                };
                return [emptyCategory];
            }
            return results;
        } catch (ex) {
            console.log(ex);
            this.errorMessage = this.loadingErrorMessage;
        } finally {
            this.loading = false;
        }
    }
}
