



















































































import Vue from "vue";
import Component from "vue-class-component";
import EventProductColorSizeGrid from "@/components/products/EventProductColorSizeGrid.vue";
import EditLayout from "@/components/layout/EditLayout.vue";
import { Prop } from "vue-property-decorator";
import { Utils } from "chipply-common";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import EditProductDetailColorEditViewModel from "@/chipply/products/edit/EditProductDetailColorEditViewModel";
import ColorTextField from "@/components/input/ColorTextField.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import CImageInput from "@/components/ui/CImageInput.vue";

@Component({
    computed: {
        chipplyIcons() {
            return chipplyIcons;
        },
        Utils() {
            return Utils;
        },
    },
    components: { CImageInput, ColorTextField, EditLayout, EventProductColorSizeGrid, CDecisionCard },
})
export default class EditProductDetailColorEditor extends Vue {
    @Prop({ type: Object })
    public vm!: EditProductDetailColorEditViewModel;
}
