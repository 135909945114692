import EcomOrderListFilters from "./EcomOrderListFilters";

export default class CreateShippingIntegrationOrdersArgs {
    public orderIds: number[] = [];

    public shouldSelectAll = false;

    public filters: EcomOrderListFilters = new EcomOrderListFilters();

    public shippingStoreId: number | null = null;
}
