import { IAddress } from "chipply-common";

export class EditInfoModel {
    public active: boolean | null = null;
    public companyName = "";
    public primaryBranchId: number | null = null;
    public orgContactId: number | null = null;
    public primarySalespersonId: number | null = null;
    public taxExempt: boolean | null = null;
    public addressType: number | null = null;
    public billingPoBox = "";
    public billingPhone = "";
    public billingFax = "";
    public billingEmail = "";
    public billingAddress: IAddress | null = null;
    public timeZone?: number | null = null;
    public website?: string = "";
    public phone?: string = "";
    public dealerEmail?: string = "";
    public websitePrefix?: string = "";
    public billingStatements?: boolean | null = null;
    public mainLogo?: string = "";
    public saleOrderPrefix?: string = "";
    public taxExemptId?: string = "";
    public federalTaxId?: string = "";
    public taxExemptCert?: string = "";
    public dealerNote = "";
    public dealerSourceId: number | null = null;
    public dealerGroupId: number | null = null;
}
