











import { AccountingSummaryPageViewModel } from "@/chipply/event/AccountingSummaryPageViewModel";
import Component, { mixins } from "vue-class-component";
import NavigationArea from "@/chipply/NavigationArea";
import NavigationHost from "@/components/navigation/NavigationHost.vue";
import AccountingSummary from "@/components/events/AccountingSummary.vue";
import StorePageMixin from "@/components/page/StorePageMixin";
import CatalogBatchPageMixin from "@/components/page/CatalogBatchPageMixin";
import AccountingSummaryViewModel from "@/chipply/event/AccountingSummaryViewModel";

@Component({
    components: { NavigationHost, AccountingSummary },
})
export default class AccountingSummaryPage extends mixins(StorePageMixin, CatalogBatchPageMixin) {
    public NavigationArea = NavigationArea;
    public vm: AccountingSummaryPageViewModel | null = null;
    public async created() {
        this.vm = new AccountingSummaryPageViewModel(this.eventId!, this.catalogBatchId!);
    }

    public beforeDestroy() {
        this.vm?.dispose();
    }
}
