













































































































import { ProductLayoutPageViewModel } from "@/chipply/process/ProductLayoutPageViewModel";
import ProcessLayoutCanvas from "@/components/process/ProcessLayoutCanvas.vue";
import CAddButton from "@/components/ui/CAddButton.vue";
import CDeleteButton from "@/components/ui/CDeleteButton.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

@Component({
    components: {
        ProcessLayoutCanvas,
        CAddButton,
        CDeleteButton,
    },
})
export default class ProductLayoutEditor extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    public vm!: ProductLayoutPageViewModel;
}
