import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, augustaShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "AugustaPurchaseOrderSettings" })
export default class AugustaPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public vendorName = "augusta";
    public tipNumber = "";

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return augustaShipMethods;
    }
}
