import { AsyncInteractionViewModel } from "chipply-common";

export class ChangeAccountPasswordViewModel extends AsyncInteractionViewModel {
    public userId = 0;
    public newPassword = "";
    public newPasswordTitle = "New Password";
    public confirmNewPassword = "";
    public confirmNewPasswordTitle = "Confirm New Password";
    public shouldValidateOriginalPassword = true;
    public title = "Change Password";
    public isValidAccountPassword = false;
}
