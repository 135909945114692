import { OrganizationDistributionTypeEnum } from "@/chipply/event/OrganizationDistributionTypeEnum";
import { EventBus } from "@/chipply/EventBus";
import { ITextValueDisabled } from "chipply-common";
import ListUtils from "@/chipply/ListUtils";
import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import { IDistributionStoreModel } from "@/chipply/store/settings/IDistributionStoreModel";
import { IStoreSettingsDistributionModel } from "@/chipply/store/settings/IStoreSettingsDistributionModel";
import { StoreOrganizationDistributionViewModel } from "@/chipply/store/settings/StoreOrganizationDistributionViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { WebHelper } from "chipply-common";
import IEventShippingMethodModel from "@/chipply/interface/IEventShippingMethodModel";

export class StoreDistributionViewModel {
    public isValid = false;
    public groupPickups: Array<ITextValueDisabled<number>> = [];
    public shippingMethods: Array<IEventShippingMethodModel<number>> = [];

    public orgBranches: Array<ITextValueDisabled<number>> = [];
    public allowGroupPickup = false;
    public organizationDistribution: StoreOrganizationDistributionViewModel;

    public allowInstorePickup = false;
    public instorePickupIds: number[] = [];
    public instorePickups: Array<ITextValueDisabled<number>> = [];

    public allowShipping = false;
    public allowInternationalShipping = false;
    public allowMilitaryShipping = false;
    public shippingMethodId = 0;
    public pageVm: StoreSettingsPageViewModel;

    public showMilitaryShippingDialog = false;

    public constructor(model: StoreSettingsPageModel, parentVm: StoreSettingsPageViewModel) {
        this.pageVm = parentVm;
        const distributionStore = model.store as IDistributionStoreModel;
        const distribution = distributionStore.distribution;

        this.allowShipping = distribution.allowShipping;
        this.allowInternationalShipping = distribution.allowInternationalShipping;
        this.allowMilitaryShipping = distribution.allowMilitaryShipping;
        this.shippingMethodId = distribution.shippingMethodId;

        this.allowGroupPickup = distribution.allowGroupPickup;
        this.organizationDistribution = new StoreOrganizationDistributionViewModel(
            distribution.organizationDistribution,
            this
        );

        this.allowInstorePickup = distribution.allowInstorePickup;
        this.instorePickupIds = distribution.instorePickupIds;
        this.instorePickups = ListUtils.filterUnusedDisabledValues(model.groupPickups, ...this.instorePickupIds);

        this.groupPickups = ListUtils.filterUnusedDisabledValues(
            model.groupPickups,
            this.organizationDistribution?.dealerBranchId ?? 0
        );
        this.shippingMethods = this.filterUnusedDisabledValues(model.shippingMethods, this.shippingMethodId);
        this.updateMilitaryShippingMethods();

        this.setOrgBranchesList(model.organizationBranches);
    }

    public filterUnusedDisabledValues(list: Array<IEventShippingMethodModel<number>>, ...dependencies: number[]) {
        return list.filter((value, index, array) => {
            if (!value.disabled) {
                return true;
            }
            return dependencies.indexOf(value.value) !== -1;
        });
    }

    public updateMilitaryShippingMethods() {
        this.shippingMethods.forEach((item) => {
            if (item.serviceCode && item.serviceCode.split("_")[0] != "usps") {
                item.disabled = this.allowMilitaryShipping;
                if (this.allowMilitaryShipping && item.value == this.shippingMethodId) {
                    this.shippingMethodId = 0; //clear selected method if it is non-usps
                }
            }
        });
    }

    protected setOrgBranchesList(branches: ITextValueDisabled<number>[]) {
        const includeDisabledOrgBranches = [];
        if (this.organizationDistribution?.organizationBranchId) {
            includeDisabledOrgBranches.push(this.organizationDistribution.organizationBranchId);
        }
        if (this.organizationDistribution?.customerDistribution) {
            includeDisabledOrgBranches.push(...this.organizationDistribution.customerDistribution.orgPickupBranchIds);
            includeDisabledOrgBranches.push(this.organizationDistribution.customerDistribution.singleOrgPickupBranchId);
        }
        this.orgBranches = ListUtils.filterUnusedDisabledValues(branches, ...includeDisabledOrgBranches);
    }

    public toModel(): IStoreSettingsDistributionModel {
        return {
            allowInstorePickup: this.allowInstorePickup,
            allowGroupPickup: this.allowGroupPickup,
            allowShipping: this.allowShipping,
            allowInternationalShipping: this.allowInternationalShipping,
            allowMilitaryShipping: this.allowMilitaryShipping,
            shippingMethodId: this.shippingMethodId,
            instorePickupIds: this.instorePickupIds,
            organizationDistribution: this.organizationDistribution.toModel(),
        };
    }

    public validateDistribution = () => {
        const agp = this.allowGroupPickup;
        const aip = this.allowInstorePickup;
        const as = this.allowShipping;

        const valid = agp || aip || as;

        return valid ? true : "You must select a distribution option";
    };

    public validateDistributionCheckbox = () => {
        return this.validateDistribution() === true ? true : "";
    };

    public validateCheckboxNotDisabled = (disabled: boolean, value: number) => {
        return (pickupIds: number[]) => {
            if (!disabled) {
                return true;
            }
            return pickupIds.indexOf(value) > -1 ? "The selected value has been disabled" : true;
        };
    };

    public async addOrganizationBranch() {
        const editResult = await this.pageVm.organizationHelper!.performEditOrganizationBranch(false);
        if (editResult.canceled) {
            return;
        }
        this.organizationDistribution.organizationBranchId = editResult.orgBranchId!;
        this.organizationDistribution.branchModel = editResult.model!;
    }

    public async editOrganizationBranch() {
        const editResult = await this.pageVm.organizationHelper!.performEditOrganizationBranch(
            true,
            this.organizationDistribution.organizationBranchId
        );
        if (editResult.canceled) {
            return;
        }
        this.organizationDistribution.organizationBranchId = editResult.orgBranchId!;
        this.refreshCards(editResult.model!);
    }

    public async removeOrganizationBranch() {
        this.organizationDistribution.organizationBranchId = 0;
        this.organizationDistribution.branchModel = null;
    }

    public async addSingleCustomerPickupBranch() {
        const editResult = await this.pageVm.organizationHelper!.performEditOrganizationBranch(false);
        if (editResult.canceled) {
            return;
        }
        this.organizationDistribution.customerDistribution.singleOrgPickupBranchId = editResult.orgBranchId!;
        this.organizationDistribution.customerDistribution.branchModels = [];
        this.organizationDistribution.customerDistribution.branchModels.push(editResult.model!);
    }

    public async addMultipleCustomerPickupBranch() {
        const editResult = await this.pageVm.organizationHelper!.performEditOrganizationBranch(false);
        if (editResult.canceled) {
            return;
        }
        this.organizationDistribution.customerDistribution.orgPickupBranchIds.push(editResult.orgBranchId!);
        this.organizationDistribution.customerDistribution.branchModels.push(editResult.model!);
    }

    public async editCustomerPickupBranch(branchId: number) {
        const editResult = await this.pageVm.organizationHelper!.performEditOrganizationBranch(true, branchId);
        if (editResult.canceled) {
            return;
        }
        this.refreshCards(editResult.model!);
    }

    public async removeCustomerPickupBranch(branch: IOrganizationBranchEditModel) {
        const orgBranchIds = this.organizationDistribution.customerDistribution.orgPickupBranchIds;
        const index = orgBranchIds.indexOf(branch.orgBranchId);
        orgBranchIds.splice(index, 1);
        const branchModels = this.organizationDistribution.customerDistribution.branchModels;
        const branchModelIndex = branchModels.indexOf(branch);
        branchModels.splice(branchModelIndex, 1);
        this.organizationDistribution.customerDistribution.singleOrgPickupBranchId = 0;
    }

    public requireShippingMethod = (value: number) => {
        const methodId = this.shippingMethodId;
        if (!value) {
            return true;
        }
        return methodId > 0 ? true : "You must select a Shipping Method";
    };

    public requireInstorePickup = (value: boolean) => {
        const pickupIds = this.instorePickupIds;
        if (!value) {
            return true;
        }
        return pickupIds.length > 0 ? true : "You must select an In-store Pickup location";
    };

    public organizationChanged() {
        this.organizationDistribution.organizationBranchId = 0;
        this.organizationDistribution.branchModel = null;
        const customerDist = this.organizationDistribution.customerDistribution;
        if (customerDist != null) {
            customerDist.singleOrgPickupBranchId = 0;
            customerDist.orgPickupBranchIds = [];
            customerDist.branchModels = [];
        }

        this.setOrgBranchesList(this.pageVm.organizationHelper!.branches);
    }

    public allowInStorePickupChanged = (newValue: boolean) => {
        if (!newValue) {
            return;
        }
        if (this.groupPickups.length === 1 && this.instorePickupIds.length === 0) {
            this.instorePickupIds.push(this.groupPickups[0].value);
        }
    };

    protected refreshCards(model: IOrganizationBranchEditModel) {
        if (
            this.organizationDistribution.branchModel !== null &&
            this.organizationDistribution.branchModel.orgBranchId === model.orgBranchId
        ) {
            this.organizationDistribution.branchModel = model;
        }

        const custModels = this.organizationDistribution.customerDistribution.branchModels;
        let indexToReplace = -1;
        for (let i = 0; i < custModels.length; i++) {
            const branch = custModels[i];
            if (branch.orgBranchId === model.orgBranchId) {
                indexToReplace = i;
                break;
            }
        }

        if (indexToReplace > -1) {
            custModels.splice(indexToReplace, 1, model);
        }
    }

    public async orgDistBranchChanged() {
        this.pageVm.loading = true;
        const requestUri = `/api/Organization/GetOrganizationBranchEdit/?ids=${this.organizationDistribution.organizationBranchId}`;
        const branches = await WebHelper.getJsonData(requestUri);

        this.organizationDistribution.branchModel = branches[0];
        this.pageVm.loading = false;
    }

    public async custBranchChanged() {
        if (this.organizationDistribution.customerDistribution.singleOrgPickupBranchId === 0) {
            this.organizationDistribution.customerDistribution.branchModels = [];
            return;
        }

        this.pageVm.loading = true;
        const requestUri = `/api/Organization/GetOrganizationBranchEdit/?ids=${this.organizationDistribution.customerDistribution.singleOrgPickupBranchId}`;
        const branches = await WebHelper.getJsonData(requestUri);

        this.organizationDistribution.customerDistribution.branchModels = branches;
        this.pageVm.loading = false;
    }

    public async multipleCustBranchChanged(clear?: boolean) {
        if (clear || this.organizationDistribution.customerDistribution.orgPickupBranchIds.length === 0) {
            this.organizationDistribution.customerDistribution.branchModels = [];
            return;
        }

        this.pageVm.loading = true;
        const idsCopy = [];
        idsCopy.push(...this.organizationDistribution.customerDistribution.orgPickupBranchIds);
        let requestUri = `/api/Organization/GetOrganizationBranchEdit/?ids=${idsCopy.splice(0, 1)[0]}`;
        for (const id of idsCopy) {
            requestUri += `&ids=${id}`;
        }
        const branches = await WebHelper.getJsonData(requestUri);

        this.organizationDistribution.customerDistribution.branchModels = branches;
        this.pageVm.loading = false;
    }

    public showMilitaryDialog() {
        if (this.allowMilitaryShipping) {
            this.showMilitaryShippingDialog = true;
        }
        return;
    }
}
