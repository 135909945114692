import { AsyncInteractionViewModel, SimpleAsyncInteractionViewModel, Utils, WebHelper } from "chipply-common";
import IProductDetailColorModel from "./IProductDetailColorModel";
import IProductDetailQuickColorsModel from "./IProductDetailQuickColorsModel";
import EditProductDetailColorEditViewModel, {
    EditProductDetailColorImageItemViewModel,
} from "./EditProductDetailColorEditViewModel";
import IProductDetailStandardColorsModel from "./IProductDetailStandardColorsModel";
import _ from "lodash";

export default class EditProductDetailColorsViewModel {
    public storeId = 0;
    public baseProductId = 0;
    public productId = 0;
    public isAdminUser = false;
    public isHeaderVisible = false;
    public allowDelete = true;
    public loading = false;
    public errorMessage = "";

    public formData: FormData | null = null;

    public title = "Manage Product Colors";

    public readonly loadingMessage = "loading...";
    public readonly savingMessage = "saving...";
    public readonly loadingErrorMessage = "An error ocurred while loading the data.";
    public statusMessage = this.loadingMessage;
    public editColorViewModel: EditProductDetailColorEditViewModel | null = null;

    public deleteDialogViewModel: SimpleAsyncInteractionViewModel | null = null;

    public saveConfirmViewModel = new SimpleAsyncInteractionViewModel();
    public showSaveConfirmDialog = false;

    public newColors = "";
    public existingColors: IProductDetailColorModel[] = [];
    public originalColors: IProductDetailColorModel[] = [];
    public standardColors: IProductDetailStandardColorsModel[] = [];
    public selectedColors: IProductDetailColorModel[] = [];

    public delete1 = false;
    public delete2 = false;
    public delete3 = false;
    public delete4 = false;
    public blankColor1 = false;
    public blankColor2 = false;
    public blankColor3 = false;
    public blankColor4 = false;

    public readonly deletePropertyNames = ["delete1", "delete2", "delete3", "delete4"];
    public readonly blankColorPropertyNames = ["blankColor1", "blankColor2", "blankColor3", "blankColor4"];

    public oneColor: IProductDetailColorModel | null = null;

    public get showEditOneColor() {
        return this.editColorViewModel != null;
    }

    public async editColor(item: IProductDetailColorModel, isAdminUser: boolean, isEditOneColor = false) {
        const vm = new EditProductDetailColorEditViewModel();
        vm.storeId = this.storeId;
        vm.productId = this.productId;
        vm.productColorId = item.eventProductColorId;
        vm.colorName = item.colorName;
        vm.hexCode1 = item.hexCode1;
        vm.hexCode2 = item.hexCode2;

        vm.images.push(new EditProductDetailColorImageItemViewModel(1, item.image1Url));
        vm.images.push(new EditProductDetailColorImageItemViewModel(2, item.image2Url));
        vm.images.push(new EditProductDetailColorImageItemViewModel(3, item.image3Url));
        vm.images.push(new EditProductDetailColorImageItemViewModel(4, item.image4Url));

        vm.standardColorId = item.standardColorId;
        vm.standardColors = this.standardColors;
        vm.isAdminUser = isAdminUser;
        vm.isEditOneColor = isEditOneColor;

        this.editColorViewModel = vm;
        const result = await vm.interact();

        if (result == "cancel") {
            this.editColorViewModel = null;
            return;
        }
        try {
            this.statusMessage = "Saving...";
            this.loading = true;
            const savedColor = await this.editColorViewModel.save();
            Object.assign(item, savedColor);
            const originalColor = this.originalColors.find((c) => c.eventProductColorId === item.eventProductColorId);
            if (originalColor) Object.assign(originalColor, savedColor);
            this.setColorPropertyValueSelectAll();
        } catch {
            this.errorMessage = "An error occurred while saving the data.";
        } finally {
            this.loading = false;
            this.editColorViewModel = null;
        }
    }

    public async quickAddColors() {
        const newColors = this.newColors.split("\n").filter((x) => x.trim().length > 0);
        if (newColors.length == 0) {
            return;
        }

        this.statusMessage = "Adding...";
        this.loading = true;

        const args = {
            productId: this.productId,
            newColors,
            storeId: this.storeId,
        } as IProductDetailQuickColorsModel;

        const addedColors = (await WebHelper.postJson(
            `/api/Product/QuickAddColors`,
            args
        )) as IProductDetailColorModel[];
        this.existingColors.push(...addedColors);
        this.originalColors.push(...JSON.parse(JSON.stringify(addedColors)));
        if (this.oneColor) {
            this.oneColor = null;
        }
        this.setColorPropertyValueSelectAll();

        this.newColors = "";

        this.loading = false;
    }

    public toModelGrid() {
        const model: IProductDetailColorModel[] = [];

        this.existingColors.forEach(function (value) {
            model.push(value);
        });

        return model;
    }

    public async saveProductColorsFromGrid(reloadAfterSave = true) {
        try {
            this.statusMessage = "Saving...";
            this.loading = true;

            const saveResult = await WebHelper.postJsonData(`/api/Product/SaveColorsFromGrid`, {
                storeId: this.storeId,
                eventProductId: this.productId,
                colors: this.toModelGrid(),
            });
            if (reloadAfterSave) {
                await this.getColors();
            }
        } catch (err) {
            const error = err as any;
            if (err && error.ExceptionType === "System.ArgumentException") {
                this.errorMessage = error.ExceptionMessage;
            } else {
                this.errorMessage = "An error occurred while saving colors.";
            }
        } finally {
            this.loading = false;
        }
    }

    public async getColors() {
        const baseUrl = `/api/Product/GetColors?eventId=${this.storeId}&productId=${this.productId}&baseProductId=${this.baseProductId}`;
        try {
            this.statusMessage = this.loadingMessage;
            this.loading = true;

            const results = (await WebHelper.getJsonData(baseUrl)) as IProductDetailColorModel[];
            if (results.length == 1 && results[0].colorName === "One Color") {
                this.oneColor = results[0];
                this.existingColors = [];
                this.originalColors = [];
            } else {
                this.existingColors = results;
                this.originalColors = JSON.parse(JSON.stringify(results));
            }
            this.setColorPropertyValueSelectAll();
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.loading = false;
        }
    }

    public async deleteColor(item: IProductDetailColorModel) {
        const deleteDialog = new SimpleAsyncInteractionViewModel();
        deleteDialog.text = `Are you sure you would like to delete the color ${item.colorName}?`;
        this.deleteDialogViewModel = deleteDialog;
        const result = await this.deleteDialogViewModel.interact();
        this.deleteDialogViewModel = null;
        if (result === "cancel") {
            return;
        }
        this.existingColors.splice(this.existingColors.indexOf(item), 1);
        this.originalColors.splice(
            this.originalColors.findIndex((c) => c.eventProductColorId === item.eventProductColorId),
            1
        );

        await this.saveProductColorsFromGrid();
    }

    public async getStandardColors() {
        try {
            this.statusMessage = "loading standard colors...";
            this.loading = true;
            const baseUrl = "/api/Product/StandardColors";
            const results = (await WebHelper.getJsonData(baseUrl)) as IProductDetailStandardColorsModel[];
            this.standardColors = results;
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.loading = false;
        }
    }

    public toggleAllColorPropertyValue(propertyName: string) {
        if (
            this.deletePropertyNames.indexOf(propertyName) == -1 &&
            this.blankColorPropertyNames.indexOf(propertyName) == -1
        ) {
            throw new Error("Invalid property name");
        }
        this.existingColors.forEach((item) => {
            (item as any)[propertyName] = (this as any)[propertyName];
        });
    }

    setColorPropertyValueSelectAll(propertyName?: string) {
        if (!propertyName) {
            this.blankColor1 = this.existingColors.length > 0 && this.existingColors.every((c) => c.blankColor1);
            this.blankColor2 = this.existingColors.length > 0 && this.existingColors.every((c) => c.blankColor2);
            this.blankColor3 = this.existingColors.length > 0 && this.existingColors.every((c) => c.blankColor3);
            this.blankColor4 = this.existingColors.length > 0 && this.existingColors.every((c) => c.blankColor4);
            this.delete1 = this.existingColors.length > 0 && this.existingColors.every((c) => c.delete1);
            this.delete2 = this.existingColors.length > 0 && this.existingColors.every((c) => c.delete2);
            this.delete3 = this.existingColors.length > 0 && this.existingColors.every((c) => c.delete3);
            this.delete4 = this.existingColors.length > 0 && this.existingColors.every((c) => c.delete4);
        } else {
            if (
                this.deletePropertyNames.indexOf(propertyName) == -1 &&
                this.blankColorPropertyNames.indexOf(propertyName) == -1
            ) {
                throw new Error("Invalid property name");
            }
            this.existingColors.forEach((item) => {
                (this as any)[propertyName] = this.existingColors.every((c) => (c as any)[propertyName]);
            });
        }
    }

    public async saveConfirm() {
        if (_.isEqual(this.existingColors, this.originalColors)) return true;

        this.showSaveConfirmDialog = true;
        const result = await this.saveConfirmViewModel.interact();
        this.showSaveConfirmDialog = false;
        if (result === "accept") {
            await this.saveProductColorsFromGrid(false);
            return true;
        }
        if (result === "continue") {
            return true;
        }

        return false;
    }
}
