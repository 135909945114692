var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mb-4"},[_c('v-tabs',{attrs:{"color":"accent"},model:{value:(_vm.vm.currentTab),callback:function ($$v) {_vm.$set(_vm.vm, "currentTab", $$v)},expression:"vm.currentTab"}},[_c('v-tab',[_vm._v("Image 1")]),_c('v-tab',[_vm._v("Image 2")]),_c('v-tab',[_vm._v("Image 3")]),_c('v-tab',[_vm._v("Image 4")])],1)],1),_c('v-tabs-items',{model:{value:(_vm.vm.currentTab),callback:function ($$v) {_vm.$set(_vm.vm, "currentTab", $$v)},expression:"vm.currentTab"}},_vm._l((_vm.vm.imageLayoutViewModels),function(imageVm){return _c('v-tab-item',{key:imageVm.imageNumber,staticStyle:{"background-color":"var(--v-secondary-base)"},attrs:{"eager":""}},[(imageVm.imageData)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-text',{staticClass:"text--primary"},[_c('v-row',{staticStyle:{"padding-bottom":"10px"},attrs:{"align":"center","justify":"center","dense":""}},[_c('div',{staticClass:"text-h6"},[_vm._v("Image "+_vm._s(imageVm.imageNumber)+" Layout")]),_c('c-add-button',{staticStyle:{"padding-left":"10px"},attrs:{"disabled":_vm.vm.unplacedBoxes.length === 0},on:{"click":function($event){return _vm.vm.add(imageVm.imageNumber)}}})],1),_c('v-select',{attrs:{"dense":"","items":_vm.vm.colors,"label":"Product Color","item-value":"colorId"},on:{"change":function($event){return _vm.vm.productColorChanged()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('div',{staticClass:"colorBox",style:({
                                                backgroundColor: item.hexCode1,
                                            })}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(item.text)+" ")])])]}},{key:"item",fn:function(ref){
                                            var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[_c('div',{staticClass:"colorBox",style:({
                                                backgroundColor: item.hexCode1,
                                            })}),_c('div',{staticClass:"align-self-center text--primary body-2",staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(item.text)+" ")])])]}}],null,true),model:{value:(_vm.vm.selectedColorId),callback:function ($$v) {_vm.$set(_vm.vm, "selectedColorId", $$v)},expression:"vm.selectedColorId"}})],1)],1),_vm._l((imageVm.imageData.boxes),function(processItem,i){return _c('v-card',{key:i,staticClass:"mt-2"},[_c('v-card-title',[_vm._v(_vm._s(processItem.artworkName)+" "),_c('v-row',{attrs:{"justify":"end"}},[_c('c-delete-button',{on:{"click":function($event){return _vm.vm.deleteItem(processItem, imageVm.imageNumber, i)}}})],1)],1),_c('v-card-text',{staticClass:"text--primary"},[_c('p',{staticClass:"text-body-1"},[_vm._v(" Location: "+_vm._s(processItem.artworkLocationName)+" ")]),(
                                    _vm.vm.optionArtworkVariations[processItem.artworkId] &&
                                    _vm.vm.optionArtworkVariations[processItem.artworkId].length > 1
                                )?_c('v-select',{attrs:{"label":"Artwork Variation","items":_vm.vm.optionArtworkVariations[processItem.artworkId],"item-text":"variationName","item-value":"eventArtworkVariationId"},on:{"change":function($event){return _vm.vm.artworkVariationChanged(processItem)}},model:{value:(processItem.artworkVariationId),callback:function ($$v) {_vm.$set(processItem, "artworkVariationId", $$v)},expression:"processItem.artworkVariationId"}}):_c('p',{staticClass:"text-body-1"},[_vm._v(" Artwork Variation: "+_vm._s(processItem.artworkVariationName)+" ")])],1)],1)})],2),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"d-flex justify-center flex-wrap",staticStyle:{"width":"250px"}}),_c('process-layout-canvas',{attrs:{"view-model":imageVm}})],1)],1):_vm._e()],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }