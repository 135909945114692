export default class MergeFields {
    public static getAbandonedCartBodyMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Store URL", value: "STOREURL" },
            {
                text: "Complete Order URL",
                value: "COMPLETEORDERURL|Complete Your Order",
            },
            {
                text: "Webstore Closing Information",
                value: "WEBSTORECLOSEINFO",
            },
        ];
    }

    public static getAbandonedCartSubjectMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Store Name", value: "STORENAME" },
        ];
    }

    public static getSmsAbandonedCartMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Store URL", value: "STOREURL" },
        ];
    }
    public static getStoreTriggerBodyMergeFields() {
        return [
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            {
                text: "Organization Contact URL",
                value: "ORGANIZATIONCONTACTURL",
            },
            {
                text: "Organization Contact Password",
                value: "ORGANIZATIONCONTACTPASSWORD",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Store URL", value: "STOREURL" },
            { text: "Store Summary", value: "STORESUMMARY" },
            { text: "Sales Order", value: "SALESORDER" },
            { text: "Number of Orders", value: "NUMBEROFORDERS" },
            { text: "Number of Products Sold", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
            { text: "Fundraising", value: "FUNDRAISING" },
        ];
    }

    public static getStoreTriggerSubjectMergeFields() {
        return [
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Sales Order", value: "SALESORDER" },
            { text: "Number of Orders", value: "NUMBEROFORDERS" },
            { text: "Number of Products Sold", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
            { text: "Fundraising", value: "FUNDRAISING" },
        ];
    }

    public static getSmsStoreTriggerMergeFields() {
        return [
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            {
                text: "Organization Contact URL",
                value: "ORGANIZATIONCONTACTURL",
            },
            {
                text: "Organization Contact Password",
                value: "ORGANIZATIONCONTACTPASSWORD",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Store URL", value: "STOREURL" },
            { text: "Sales Order", value: "SALESORDER" },
            { text: "Number of Orders", value: "NUMBEROFORDERS" },
            { text: "Number of Products Sold", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
            { text: "Fundraising", value: "FUNDRAISING" },
        ];
    }

    public static getCustomerMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Order Number", value: "ORDERNUMBER" },
            { text: "Order Summary", value: "ORDERSUMMARY" },
        ];
    }

    public static getCustomerSubjectMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Order Number", value: "ORDERNUMBER" },
        ];
    }

    public static getSmsCustomerMergeFields() {
        return [
            { text: "Customer First Name", value: "CUSTOMERFIRSTNAME" },
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            {
                text: "Organization Contact Name",
                value: "ORGANIZATIONCONTACTNAME",
            },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store Password", value: "STOREPASSWORD" },
            { text: "Order Number", value: "ORDERNUMBER" },
        ];
    }

    public static getEcertMergeFields() {
        return [
            { text: "Customer Name", value: "CUSTOMERNAME" },
            { text: "E-Cert Code", value: "ECERTCODE" },
            { text: "Amount Issued", value: "AMOUNTISSUED" },
            { text: "Amount Used", value: "AMOUNTUSED" },
            { text: "Amount Remaining", value: "AMOUNTREMAINING" },
            { text: "Store Name", value: "STORENAME" },
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            { text: "Sales Order", value: "SALESORDER" },
        ];
    }
    public static getEcertHTMLBodyMergeFields() {
        return [
            { text: "Customer Name", value: "CUSTOMERNAME" },
            { text: "E-Cert Code", value: "ECERTCODE" },
            { text: "Amount Issued", value: "AMOUNTISSUED" },
            { text: "Amount Used", value: "AMOUNTUSED" },
            { text: "Amount Remaining", value: "AMOUNTREMAINING" },
            { text: "Store Name", value: "STORENAME" },
            { text: "Store URL", value: "STOREURL" },
            { text: "Dealer Name", value: "DEALERNAME" },
            { text: "Salesperson Name", value: "SALESPERSONNAME" },
            { text: "Sales Order", value: "SALESORDER" },
        ];
    }

    public static getBatchCreationSubjectMergeFields() {
        return [
            { text: "Store Name", value: "STORENAME" },
            { text: "Number Of Orders", value: "NUMBEROFORDERS" },
            { text: "Number Of Products", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
        ];
    }
    public static getBatchCreationBodyMergeFields() {
        return [
            { text: "Store Name", value: "STORENAME" },
            { text: "Number Of Orders", value: "NUMBEROFORDERS" },
            { text: "Number Of Products", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
        ];
    }
    public static getSmsBatchCreationMergeFields() {
        return [
            { text: "Store Name", value: "STORENAME" },
            { text: "Number Of Orders", value: "NUMBEROFORDERS" },
            { text: "Number Of Products", value: "NUMBEROFPRODUCTS" },
            { text: "Total Dollars in Sales", value: "TOTALDOLLARS" },
        ];
    }

    public static formatMergeField(mergeField: string) {
        const formattedMergeField = `[${mergeField}] `;
        return formattedMergeField;
    }
}
