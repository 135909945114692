import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import PageState from "@/chipply/PageState";
import { ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { ProcessItemType, WebHelper } from "chipply-common";
import { ArtworkVariationItemViewModel } from "./ArtworkVariationItemViewModel";
import { IStoreArtworkDto } from "./IStoreArtworkDto";
import { IGetArtworkWithVariationsArgs, IGetArtworkWithVariationsResults } from "./IStoreArtworkVariationModel";
import { IArtworkVariationStandardColor } from "@/chipply/event/artworks/IArtworkVariationStandardColor";
import ArtworkSelectorViewModel from "./ArtworkSelectorViewModel";

export default class ArtworkVariationSelectorViewModel extends ListPageViewModel {
    public storeArtworkID = 0;
    public storeId!: number;
    public artworkId!: number;
    public artworkName!: string;
    public artworkTypeCode!: string;
    public artworkTypeDisplay = "";
    public dataTableItemKey = "id";
    public dataTableLoading = false;
    public errorMessage = "";
    public readonly loadingErrorMessage = "An error occurred while loading the data.";
    public statusMessage = this.loadingMessage;
    public standardColors: IArtworkVariationStandardColor[] = [];
    public items: ArtworkVariationItemViewModel[] = [];
    public data!: IStoreArtworkDto;
    public artworkTypeOption!: ProcessItemType;
    public purchasingVendorId: number | null = null;
    public selectedItem: ArtworkVariationItemViewModel | null = null;
    public artworkSelectorViewModel: ArtworkSelectorViewModel | null = null;
    public wasCanceled = false;

    public artworkTypeOptions = [
        { text: "Artwork / Logo", code: "Art", value: ProcessItemType.ArtworkLogo },
        { text: "Option - Name (Text Field)", code: "Name", value: ProcessItemType.OptionName },
        { text: "Option - Number (Text Field)", code: "Number", value: ProcessItemType.OptionNumber },
        { text: "Option - Option Group (Drop Down List)", code: "Option", value: ProcessItemType.OptionGroup },
        {
            text: "Option - Exclusive Option Group (Drop Down List)",
            code: "Exclusive",
            value: ProcessItemType.OptionExclusiveGroup,
        },
    ];
    public dataTableHeaders = [
        { text: "", sortable: false, value: "variationImg", width: "50px", height: "30px" },
        { text: "Variation Name", sortable: false, value: "displayName", width: "100px" },
        { text: "Variation ID", sortable: false, value: "externalId", width: "150px" },
        { text: "Quantity to Order", sortable: false, value: "additionalQuantity", width: "80px" },
    ];

    public pageState!: PageState;

    public pagination: IVuetifyTableOptions = {
        itemsPerPage: 100,
        page: 1,
        sortDesc: [false],
        sortBy: ["artName"],
    };

    public constructor() {
        super();
        this.pageState = new PageState();
    }

    public accept() {
        const results = this.items.filter((x) => x.additionalQuantity > 0);
        this.done(results);
    }

    public back() {
        this.done([]);
    }

    public cancel() {
        //this.wasCanceled = true;
        this.done([]);
    }

    protected _interactionResolve: null | ((result: ArtworkVariationItemViewModel[]) => void) = null;

    public interact(): Promise<ArtworkVariationItemViewModel[]> {
        this.wasCanceled = false;
        return new Promise<ArtworkVariationItemViewModel[]>((resolve) => {
            this._interactionResolve = resolve;
        });
    }

    public isAcceptEnabled() {
        if (this.items.length === 0) {
            return false;
        }
        for (const item of this.items) {
            if (item.additionalQuantity > 0) {
                return true;
            }
        }
        return false;
    }

    public done(accepted: ArtworkVariationItemViewModel[]) {
        this._interactionResolve!(accepted);
    }

    public async list(isNewSearch?: boolean): Promise<any> {
        await this.refreshData(isNewSearch);
    }

    public buildArgs(): IGetArtworkWithVariationsArgs {
        const args: IGetArtworkWithVariationsArgs = {
            pageState: this.pageState,
            artworkId: this.artworkId,
            sortProperties: [],
        };
        return args;
    }

    public async onArtworkIdChanged(): Promise<void> {
        await Promise.all([this.refreshData(true)]);
    }

    public async refreshData(isNewSearch?: boolean): Promise<void> {
        if (this.dataTableLoading) {
            return;
        }
        if (!this.artworkId) return;

        if (isNewSearch) {
            this.items.splice(0);
            this.pageState.reset();
        }
        if (this.pageState.wasLastPageSelected) {
            return;
        }
        this.dataTableLoading = true;
        const listResult = await this.getArtworkWithVariations(this.buildArgs());
        this.fromModel(listResult);
    }

    protected async getArtworkWithVariations(
        listArgs: IGetArtworkWithVariationsArgs
    ): Promise<IGetArtworkWithVariationsResults> {
        const baseUrl = `/api/Store/GetArtworkWithVariations/${this.storeId}`;
        try {
            this.statusMessage = this.loadingMessage;
            this.dataTableLoading = true;
            const jsonResult = await WebHelper.postJsonData(baseUrl, listArgs);
            const listResult = JSON.parse(jsonResult) as IGetArtworkWithVariationsResults;

            return listResult;
        } catch (e) {
            this.errorMessage = this.loadingErrorMessage;
            throw e;
        } finally {
            this.dataTableLoading = false;
        }
    }

    protected fromModel(results: IGetArtworkWithVariationsResults) {
        this.artworkName = results.artworkName;
        this.artworkTypeCode = results.artworkTypeCode;
        this.standardColors = results.standardColors;
        this.purchasingVendorId = results.purchasingVendorId;
        const filteredArtworkOptions = this.artworkTypeOptions.filter((x) => x.code == results.artworkType);
        if (filteredArtworkOptions.length > 0) {
            this.artworkTypeOption = filteredArtworkOptions[0].value;
            this.artworkTypeDisplay = filteredArtworkOptions[0].text;
        }
        const items: ArtworkVariationItemViewModel[] = [];
        results.variations.forEach((variations) => {
            const itemViewModel = new ArtworkVariationItemViewModel(variations);
            const standardColorNames = [];
            for (const standardColorId of itemViewModel.standardColorIds) {
                const filteredStandardColors = this.standardColors.filter((x) => x.standardColorId === standardColorId);
                if (filteredStandardColors.length > 0) {
                    standardColorNames.push(filteredStandardColors[0].colorName);
                }
            }
            itemViewModel.standardColorDisplay = standardColorNames.join(" ");
            items.push(itemViewModel);
        });
        this.items.push(...items);
        if (this.items.length > 0) {
            this.selectedItem = this.items[0];
        }

        this.pageState.next(results.variations.length);
        this.serverItemsLength = results.totalRowsOfVarations;
    }

    public select(item: ArtworkVariationItemViewModel) {
        this.selectedItem = item;
    }

    public openImagePreview(imageUrl: string) {
        window.open(imageUrl, "_blank");
    }
}
