import { typeHint } from "chipply-common";

@typeHint({ typeHint: "CatalogBatchDetailsMenuData" })
export class CatalogBatchDetailsMenuData {
    public name = "";
    public catalogBatchId = 0;
    public eventId = 0;
    public storeName = "";
    public salesOrder = "";
}
