import { EventBus } from "@/chipply/EventBus";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class ScrollableContentMixin extends Vue {
    public contentSize: object = {};

    public height: number | null = null;
    public noTopNavigation = false;
    @Prop({
        default: true,
    })
    public allowScroll!: boolean;

    protected maxContentHeight = 0;

    protected hasMobileHeader = true;

    protected get mobileHeadroom(): number {
        // 100 accounts for toolbar on bottom and other misc padding
        return this.hasMobileHeader ? 110 + this.topNavigationHeadroom : this.topNavigationHeadroom;
    }

    public beforeDestroy() {
        EventBus.$off("resize-content", this.resizeCallback);
    }

    protected resizeCallback = () => {
        // This nextTick call is here on purpose
        // calling $nextTick once in delayResize doesn't seem to be
        // the correct time to recalculate the screen
        this.$nextTick(() => {
            this.delayResize();
        });
    };

    public mounted() {
        EventBus.$on("resize-content", this.resizeCallback);
    }

    /**
     * Set whether or not the speed dial feature is being used on the page.
     * Can be removed entirely if the speed dial feature is used everywhere
     *
     * @returns {boolean} - True if the speed dial feature is being used, false otherwise.
     * @protected
     */
    protected get usesSpeedDial(): boolean {
        return false;
    }

    protected get topNavigationHeadroom(): number {
        const chipplyTopNavigationSize = 50;
        return this.noTopNavigation ? 0 : chipplyTopNavigationSize;
    }

    protected get excludedContentSize(): number {
        return this.$vuetify.breakpoint.smAndDown ? this.mobileHeadroom : this.topNavigationHeadroom;
    }

    /**
     * Retrieves the height of the parent element or the window if not found
     * NOTE: This is not a computed property because Vue cache's computed values
     * and window.innerHeight cannot be reacted to
     * @protected
     * @return {number} The height of the parent element or the window
     */
    protected getParentHeight(): number {
        return this.parentHeight;
    }

    protected get parentHeight(): number {
        return this.height || window.innerHeight;
    }

    protected getMaxContentHeight() {
        return this.getParentHeight() - this.excludedContentSize - this.getVariableHeadroom();
    }

    /**
     * Retrieves the amount of room being taken up by static portions of the screen.
     * Meant to be overridden by the user of the ScrollableContentMixin
     *
     * @protected
     * @override
     * @returns {number} The headroom of the variable.
     */
    protected getVariableHeadroom(): number {
        return 0;
    }

    @Watch("$vuetify.breakpoint.name")
    protected delayResize() {
        this.$nextTick(() => this.resizeContent());
    }

    /**
     * Primary call to reset sizing variables. Needs to be used with a
     * v-resize observer on an element or called manually when things need to be
     * adjusted
     * @protected
     */
    protected resizeContent() {
        //this.height = window.innerHeight;
        // This value may change based on the host.
        // I couldn't figure out a better way to make this work, open to improvement!
        // Without this the table will scroll past it's flex-basis and relative flex
        this.maxContentHeight = this.getMaxContentHeight();
        if (this.allowScroll) {
            this.contentSize = {
                maxHeight: this.maxContentHeight + "px",
                overflow: "auto",
            };
        } else {
            this.contentSize = {
                maxHeight: this.maxContentHeight + "px",
                overflow: "hidden",
            };
        }
    }
}
