import IFilterItemViewModel from "@/chipply/view-model/IFilterItemViewModel";
import { ITextValue } from "chipply-common";

export default class FilterItemSingleSelectViewModel implements IFilterItemViewModel {
    public selected: ITextValue<any> | null = null;
    public value = null;
    public prependText = "";
    public appendText = "";

    public constructor(prependText?: string, appendText?: string) {
        if (prependText) {
            this.prependText = prependText;
        }
        if (appendText) {
            this.appendText = appendText;
        }
    }

    public getFilterDisplayValues(): string[] {
        const displayValues: string[] = [];
        if (this.selected) {
            displayValues.push(this.prependText + this.selected.text.toString() + this.appendText);
        }
        return displayValues;
    }

    public getFilterValue<T>(): T {
        if (this.selected) {
            return <T>this.selected?.value;
        }
        return <T>(<unknown>null);
    }
}
