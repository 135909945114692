export class Autocompletes {
    public static get StoreNameAutocomplete(): AutocompleteType {
        return { path: "/api/Autocomplete/StoreList" };
    }

    public static get OrganizationAutoComplete(): AutocompleteType {
        return { path: "/api/Autocomplete/OrganizationsList" };
    }

    public static get SalesRepAutoComplete(): AutocompleteType {
        return { path: "/api/Autocomplete/SalesRepList" };
    }

    public static get VendorAutocomplete(): AutocompleteType {
        return { path: "/api/Autocomplete/VendorList" };
    }

    public static get SaleOrderAutocomplete(): AutocompleteType {
        return { path: "/api/Autocomplete/SaleOrderList" };
    }

    public static get OrderAutocomplete(): AutocompleteType {
        return { path: "/api/Autocomplete/OrderList" };
    }

    public static get DealersAutocomplete(): AutocompleteType {
        return { path: "/api/Autocomplete/DealerList" };
    }
}

export interface AutocompleteType {
    path: string;
}
