import { IRelatedArtworkModel } from "@/chipply/event/artworks/IRelatedArtworkModel";
import { DateUtils } from "chipply-common";
import { IArtworkCopyArgs } from "@/chipply/event/artworks/IArtworkCopyArgs";
import { EventBus } from "@/chipply/EventBus";

export class RelatedArtworkViewModel {
    public orgName = "";
    public storeId = 0;
    public storeName = "";
    public saleOrder = "";
    public openDate = "";
    public closeDate = "";
    public storeArtworkId = 0;
    public typeCode = "";
    public artType = "";
    public artName = "";
    public artworkCount = 0;
    public childImageUrl = "";

    public constructor(model: IRelatedArtworkModel) {
        if (!model) {
            return;
        }
        this.orgName = model.orgName;
        this.storeId = model.storeId;
        this.storeName = model.storeName;
        this.saleOrder = model.saleOrder;
        this.openDate = DateUtils.parseDateString(model.openDate).dateString;
        this.closeDate = DateUtils.parseDateString(model.closeDate).dateString;
        this.storeArtworkId = model.storeArtworkId;
        this.typeCode = model.typeCode;
        this.artType = model.artType;
        this.artName = model.artName;
        this.artworkCount = model.artworkCount;
        this.childImageUrl = model.childImageUrl;
    }

    public import = () => {
        const args: IArtworkCopyArgs = {
            artworkId: this.storeArtworkId,
            artworkName: this.artName,
            isCopy: false,
            canCopyExternalVariationIds: true,
        };
        EventBus.$emit("copy-artwork", args);
    };
}
