











































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import chipplyIcons from "@/chipply/ImportIcons";
import PurchaseOrderViewModel from "@/chipply/purchasing/PurchaseOrderViewModel";
import EditLayout from "@/components/layout/EditLayout.vue";
import Validation from "@/validation";
import AddressEditor from "@/components/ui/CAddressEditor.vue";
import CMoney from "../ui/CMoney.vue";
import PurchasingFiltersEditor from "@/components/purchasing/PurchasingFiltersEditor.vue";
import EmailReminder from "@/components/emails/EmailReminder.vue";
import CChildPane from "@/components/ui/CChildPane.vue";
import CYesNoDialog from "@/components/dialogs/CYesNoDialog.vue";
import { Prop, Watch } from "vue-property-decorator";
import { CDismissDialog } from "chipply-common-vue";
import IOrderItemHistoryDto from "@/chipply/purchasing/IOrderItemHistoryDto";
@Component({
    components: {
        EditLayout,
        AddressEditor,
        CMoney,
        PurchasingFiltersEditor,
        EmailReminder,
        CChildPane,
        CYesNoDialog,
        CDismissDialog,
    },
    computed: {
        computedTotals() {
            const me = this as any;
            return me.vm.getTotals();
        },
    },
})
export default class OrderItemDetails extends Vue {
    public eventId!: number;
    public chipplyIcons = chipplyIcons;

    @Prop({ type: Object })
    public vm!: PurchaseOrderViewModel;

    public $refs!: {
        details: Vue;
    };

    protected Validation = Validation;

    private isSelectingNextPage = false;

    @Watch("vm.selectedDetails")
    public selectedDetailsChanged() {
        this.vm.selectedDetailsChanged();
    }

    public checkNextPage(args: any) {
        if (!args.target) {
            return;
        }
        if (this.isSelectingNextPage) {
            return;
        }
        if (Math.ceil(args.target.scrollTop) + args.target.clientHeight >= args.target.scrollHeight) {
            this.selectNextPage();
        }
    }

    protected getDetailsListRef(): Vue {
        return this.$refs.details;
    }

    protected async selectNextPageCore(): Promise<any> {
        if (this.vm.details.length <= 0) {
            return;
        }
        await this.vm.listDetails();
    }

    protected getColor(item: IOrderItemHistoryDto): string {
        switch (item.action) {
            case "Sent Email":
                return "pink";
            case "Note":
                return "amber lighten-1";
            case "Quantity Updated":
                return "green lighten-1";
            case "Substitution":
                return "red lighten=1";
            default:
                return "amber lighten-1";
        }
    }

    protected async selectNextPage(): Promise<any> {
        this.isSelectingNextPage = true;
        try {
            await this.selectNextPageCore();
        } finally {
            this.isSelectingNextPage = false;
        }
    }
}
