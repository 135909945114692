import { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import NavigationArea from "@/chipply/NavigationArea";
import Component from "vue-class-component";

/**
 * Page Mixin for Page components that need to support a Store Details page
 * Designed for use with other mixins in case the page works in multiple scenarios
 * @class
 * @mixes UserInfoMixin
 */
@Component
export default class StorePageMixin extends mixins(UserInfoMixin) {
    public NavigationArea = NavigationArea;

    public eventId: number | null = null;
    public storeId: number | null = null;
    public menuArgs: any | null = null;
    public init = false;
    public area: NavigationArea | null = null;

    public async created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("eventid")) {
            this.area = NavigationArea.StoreDetails;
            this.eventId = Number(urlParams.get("eventid"));
            this.storeId = this.eventId;
            this.menuArgs = {
                $type: "Chipply.Service.Menu.StoreDetailsMenuArgs, Chipply.Service",
                eventId: this.eventId,
            };
            this.init = true;
        }
    }
}
