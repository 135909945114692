













































































































































































































import { Vue, Component, Prop } from "vue-property-decorator";
import CInformationVariant from "@/components/ui/CInformationVariant.vue";
import NavigationSideHeader from "@/components/navigation/NavigationSideHeader.vue";
import NavigationSideButton from "@/components/navigation/NavigationSideButton.vue";
import { CatalogBatchDetailsMenuData } from "@/chipply/navigation/CatalogBatchDetailsMenuData";
import { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import UserLevel from "@/chipply/UserLevel";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import BatchSettingsNavigationDialog from "@/components/store/batch/BatchSettingsNavigationDialog.vue";
import { BatchSettingsNavigationDialogViewModel } from "@/chipply/store/batch/BatchSettingsNavigationDialogViewModel";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "@/chipply/EventBus";

@Component({
    components: { BatchSettingsNavigationDialog, NavigationSideButton, NavigationSideHeader, CInformationVariant },
})
export default class CatalogBatchNavigationSide extends mixins(UserInfoMixin) {
    @Prop({ default: {}, type: Object })
    public menuData!: CatalogBatchDetailsMenuData;
    @Prop({ default: "", type: String })
    public stringStoreListState!: string;
    @Prop({ type: Object })
    storeListReferralSettings!: IPageReferrerSettings;

    public showBatchSettingsNavigationDialog = false;
    public confirmViewModel: BatchSettingsNavigationDialogViewModel | null = null;

    UserLevel = UserLevel;

    public mounted() {
        EventBus.$on("catalog-batch-navigation-started", this.catalogItemClicked);
    }
    public unmounted() {
        EventBus.$off("catalog-batch-navigation-started", this.catalogItemClicked);
    }
    private getCatalogBatchDetailNavigationLink(storeLink: string, additionalParams?: string[]) {
        let baseLink = storeLink + "?batchid=" + (this.menuData as CatalogBatchDetailsMenuData).catalogBatchId;
        baseLink += `&v=${Math.floor(Math.random() * 100000)}`;

        if (this.stringStoreListState) {
            baseLink += "&storeliststate=" + this.stringStoreListState;
        }

        additionalParams = additionalParams || [];
        for (const param of additionalParams) {
            baseLink += `&${param}`;
        }

        return baseLink;
    }

    private getStoreDetailNavigationLink(storeLink: string, additionalParams?: string[]) {
        let baseLink = storeLink + "?eventid=" + this.menuData.eventId;
        baseLink += `&v=${Math.floor(Math.random() * 100000)}`;

        if (this.stringStoreListState) {
            baseLink += "&storeliststate=" + this.stringStoreListState;
        }

        additionalParams = additionalParams || [];
        for (const param of additionalParams) {
            baseLink += `&${param}`;
        }

        return baseLink;
    }

    public async catalogItemClicked(location: string, eventArgs: IAsyncEventArgs) {
        this.showBatchSettingsNavigationDialog = true;
        const promise = new Promise<boolean | void>(async (resolve, reject) => {
            this.confirmViewModel = new BatchSettingsNavigationDialogViewModel();
            const result = await this.confirmViewModel.interact();
            if (result === "cancel") {
                eventArgs.cancel = true;
                resolve(eventArgs.cancel);
                this.showBatchSettingsNavigationDialog = false;
            } else if (result === "accept") {
                this.showBatchSettingsNavigationDialog = false;
                resolve(eventArgs.cancel);
            } else if (result === "continue") {
                this.showBatchSettingsNavigationDialog = false;
                resolve();
            }
        });
        eventArgs.promises.push(promise);
    }
}
