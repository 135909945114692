export interface IVendorShipMethod {
    method: string;
    description: string;
}
export const augustaShipMethods: IVendorShipMethod[] = [
    { method: "901", description: "UPS Standard Ground" },
    { method: "902", description: "UPS 1 DAY" },
    { method: "903", description: "UPS 2 DAY" },
    { method: "904", description: "UPS 3 DAY Ground" },
];

export const founderShipMethods: IVendorShipMethod[] = [
    { method: "U01", description: "UPS Ground" },
    { method: "U02", description: "UPS 3 Day Select" },
    { method: "U03", description: "UPS 2 Day Air" },
    { method: "U04", description: "UPS 2nd Day Air A.M." },
    { method: "U06", description: "UPS Next Day Air Saver" },
    { method: "U07", description: "UPS Next Day Air" },
    { method: "U11", description: "UPS International" },
    { method: "F01", description: "FedEx Ground" },
    { method: "F02", description: "FedEx 3 Day" },
    { method: "F05", description: "FedEx Std Overnight" },
    { method: "F06", description: "FedEx Priority Overnight" },
    { method: "F11", description: "FedEx International Economy" },
];

export const sanMarShipMethods: IVendorShipMethod[] = [
    { method: "UPS", description: "UPS Standard Ground" },
    { method: "UPS 2ND DAY", description: "UPS 2ND DAY" }, // UPS 2nd business day delivery end of day"
    { method: "UPS 2ND DAY AM", description: "UPS 2ND DAY AM" }, //UPS 2nd business day delivery 10:30 a.m.
    { method: "UPS 3RD DAY", description: "UPS 3RD DAY" }, //UPS 3rd business day delivery end of day
    { method: "UPS NEXT DAY", description: "UPS NEXT DAY" }, //UPS next day delivery 10:30 a.m.
    { method: "UPS NEXT DAY EA", description: "UPS NEXT DAY EA" }, //UPS next business day delivery 8:00 a.m.
    { method: "UPS NEXT DAY SV", description: "UPS NEXT DAY SV" }, //UPS next day delivery 3:00 pm
    { method: "UPS SATURDAY", description: "UPS SATURDAY" }, //UPS Extends business day calculation to include Saturday
    { method: "USPS PP", description: "USPS PP" }, //United States Postal Service Parcel Post
    { method: "USPS APP", description: "USPS APP(Air Parcel Post)" },
    { method: "PSST", description: "PSST (Pack Separately, Ship Together program)" },
    { method: "TRUCK", description: "Truck (Based on the destination zip code)" },
];

export const ssActivewearShipMethods: IVendorShipMethod[] = [
    { method: "1", description: "Ground" },
    { method: "2", description: "UPS Next Day Air" },
    { method: "3", description: "UPS 2nd Day Air" },
    { method: "16", description: "UPS 3 Day Select" },
    { method: "6", description: "Will Call Or PickUp" },
    { method: "8", description: "Messenger Pickup Or PickUp" },
    { method: "54", description: "Misc Cheapest" },
    { method: "17", description: "UPS Next Day Air Early AM" },
    { method: "21", description: "UPS Next Day Air Saver" },
    { method: "19", description: "UPS Saturday" },
    { method: "20", description: "UPS Saturday Early" },
    { method: "22", description: "UPS 2nd Day Air AM" },
    { method: "14", description: "FedEx Ground" },
    { method: "27", description: "FedEx Next Day Standard" },
    { method: "26", description: "FedEx Next Day Priority" },
    { method: "40", description: "UPS Ground" },
    { method: "48", description: "FedEx 2nd Day Air" },
];

export const alphabroderShipMethods: IVendorShipMethod[] = [
    { method: "UPS-Blue", description: "UPS 2 Day" },
    { method: "UPS-Orange 3day", description: "UPS 3 Day" },
    { method: "UPS-Red", description: "UPS 1 Day" },
    { method: "UPS-RED.8:30am", description: "UPS 1 Day 8:30 AM" },
    { method: "UPS-Sat.Dlvry.", description: "UPS Saturday" },
    { method: "UPS-Surface", description: "UPS Ground" },
];

export const stahlsShipMethods: IVendorShipMethod[] = [
    { method: "UPSGR", description: "UPS Ground" },
    { method: "UPS2D", description: "UPS 2nd Day Air" },
    { method: "UPSND", description: "UPS Next Day" },
    { method: "UPSNDPM", description: "UPS Next Day Air Saver" },
    { method: "FEDEXNDAM", description: "FedEx Priority Overnight" },
    { method: "FEDEX2D", description: "FedEx 2 Day" },
    { method: "FEDEXND", description: "FedEx Standard Overnight" },
    { method: "FEDEXGR", description: "FedEx Ground" },
];
