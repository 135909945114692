























































































import Component, { mixins } from "vue-class-component";
import EditLayout from "@/components/layout/EditLayout.vue";
import CToolbarBtn from "@/components/ui/CToolbarBtn.vue";
import EditAccountProfileViewModel from "@/chipply/dealer/EditAccountProfileViewModel";
import { Prop, Watch } from "vue-property-decorator";
import chipplyIcons from "@/chipply/ImportIcons";
import ChangeAccountPassword from "./ChangeAccountPassword.vue";
import FormValidationMixin from "../FormValidationMixin";
import Validation from "@/validation";
import CPhone from "../ui/CPhone.vue";
import EditUserViewModel from "@/chipply/dealer/EditUserViewModel";
import { EventBus } from "@/chipply/EventBus";
import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import CSaveDialog from "@/components/dialogs/CSaveDialog.vue";
import UserLevel from "@/chipply/UserLevel";

@Component({
    components: {
        CToolbarBtn,
        EditLayout,
        ChangeAccountPassword,
        CPhone,
        CSaveDialog,
    },
    computed: {
        UserLevel() {
            return UserLevel;
        },
        computedTitle() {
            const me = this as EditAccountProfile;
            return me.vm instanceof EditUserViewModel ? me.vm.title : "Edit Your Profile - " + me.vm.userName;
        },
        isUserViewModel() {
            const me = this as EditAccountProfile;
            return me.vm instanceof EditUserViewModel;
        },
        isEditModel() {
            const me = this as EditAccountProfile;
            return me.vm instanceof EditAccountProfileViewModel && me.vm.userId > 0;
        },
    },
})
export default class EditAccountProfile extends mixins(FormValidationMixin) {
    public name = "EditAccountProfile";
    public chipplyIcons = chipplyIcons;
    public Validation = Validation;

    @Prop({
        type: Object,
        default: null,
    })
    public vm!: EditAccountProfileViewModel;

    public showOriginalPassword = false;
    public showNewPassword = false;
    public showConfirmNewPassword = false;
    public showPassword = false;
    declare computedTitle: string;
    declare isUserViewModel: boolean;
    declare isEditModel: boolean;

    @Prop({
        type: Number,
    })
    public userId!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    public showBackOnly!: boolean;

    public data() {
        return {
            icon: "$iconCalendar",
            show: false,
        };
    }
    public computedIcon(isShow: boolean) {
        if (isShow) {
            return "$iconEye";
        }
        return "$iconEyeOff";
    }

    public async created() {
        this.vm.assignEventHandlers();
        await this.initialize();
    }

    public beforeDestroy() {
        this.vm.removeEventHandlers();
    }

    @Watch("userId")
    public async onUserIdChanged() {
        await this.initialize();
    }

    @Watch("vm")
    public async onViewModelChanged() {
        await this.initialize();
    }

    public async initialize() {
        if (!this.userId || !this.vm) {
            return;
        }
        this.vm.userId = this.userId;
        await this.vm.getAccount();
    }

    public async back() {
        if (this.vm.showChild) {
            await this.vm.back();
        } else if (this.vm.allowBackToParent) {
            const result = await this.vm.showSaveDialogAsNeeded();
            if (!result) {
                return;
            }
            this.$emit("back");
        }
    }

    public validateQuotationMarks(value: string) {
        if (/[/"]/.test(value)) {
            return "Value cannot contain quotation marks";
        }
        return true;
    }
}
