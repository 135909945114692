















import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
    props: {
        icon: Boolean,
        tooltip: String,
    },
})
export default class CRemoveButton extends Vue {
    public chipplyIcons = chipplyIcons;
    public tooltip!: string | null;

    protected computedTooltip() {
        return this.tooltip || "Remove";
    }
}
