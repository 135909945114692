































import chipplyIcons from "@/chipply/ImportIcons";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        href: String,
        outlined: Boolean,
        disabled: Boolean,
        icon: String,
        label: String,
        loading: Boolean,
        tooltip: String,
        target: String,
    },
})
export default class CToolbarBtn extends Vue {
    public chipplyIcons = chipplyIcons;

    @Prop({
        default: "primary",
        type: String,
    })
    public color!: string;

    @Prop({
        default: "",
        type: String,
    })
    public label!: string;

    @Prop({
        type: Boolean,
        default: true,
    })
    public fab!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public hideBottomText!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    public truncate!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    public small!: boolean;

    @Prop({
        default: "",
        type: String,
    })
    public automationLabel!: string;
}
