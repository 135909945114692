import { IListDataTableHeaderItem, ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import { SimpleAsyncInteractionViewModel, WebHelper } from "chipply-common";
import { IProcessListArgs, IProcessListResults, IProcessListFilters, IStoreProcessDto } from "./IStoreProcessDto";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import _ from "lodash";

import SortProperty from "../data-access/SortProperty";
import PageState from "../PageState";
import { EventBus } from "../EventBus";
import { StoreProcessItemViewModel } from "./StoreProcessItemViewModel";
import { ICopyProcessWithArtworkArgs } from "./ICopyProcessWithArtworkArgs";

export class SearchStoreProcessesViewModel extends ListPageViewModel {
    public dataTableItemKey = "id";
    public static STATE_KEY = "searchProcessesState";
    public dataTableHeaders: IListDataTableHeaderItem[] = [
        { text: "Organization", sortable: false, value: "organizationName", width: "150px" },
        { text: "Sale Order", sortable: false, value: "saleOrder", width: "50px" },
        { text: "Store", sortable: false, value: "storeName", width: "120px" },
        { text: "Open Date", sortable: false, value: "openDate", width: "60px" },
        { text: "Close Date", sortable: false, value: "closeDate", width: "60px" },
        { text: "Process", sortable: false, value: "processName", width: "150px" },
        { text: "Contains", sortable: false, value: "artSummary", width: "200px" },
        { text: "", sortable: false, value: "importProcess", width: "50px" },
        { text: "", sortable: false, value: "placeholder" },
    ];

    public dealerId!: number;
    public storeId!: number;

    public pageState!: PageState;

    public get pagination() {
        return this._pagination;
    }
    public set pagination(value: IVuetifyTableOptions) {
        if (!_.isEqual(this._pagination, value)) {
            this._pagination = value;
        }
    }

    protected _pagination: IVuetifyTableOptions;
    public dataTableLoading = false;
    public serverItemsLength = 0;
    public items: StoreProcessItemViewModel[] = [];
    public filters: IProcessListFilters = this.getDefaultFilters();

    public storeOpenDateFilter: string[] = [];
    public storeCloseDateFilter: string[] = [];

    public errorMessage = "";
    public readonly loadingErrorMessage = "An error occurred while loading the data.";
    public statusMessage = this.loadingMessage;

    public showAddProcessViewModel = false;
    public addProcessViewModel: SimpleAsyncInteractionViewModel | null = null;
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public showEmptyView = true;

    public constructor() {
        super();
        this._pagination = {
            itemsPerPage: 20,
            page: 1,
            sortDesc: [],
            sortBy: ["EventID", "ProcessName"],
        };
        this.pageState = new PageState(this._pagination);
        this.dataTableDisableSort = true;
    }

    public clear() {
        this.filters = this.getDefaultFilters();
        this.storeCloseDateFilter = [];
        this.storeOpenDateFilter = [];
    }
    public getDefaultFilters() {
        const filters: IProcessListFilters = {
            dealerID: this.dealerId,
            storeIDToExclude: this.storeId,
            storeID: null,
            storeName: null,
            processName: "",
            shouldExcludeIsNoneProcess: true,
            storeOpenDateFrom: null,
            storeOpenDateTo: null,
            storeCloseDateFrom: null,
            storeCloseDateTo: null,
            saleOrder: null,
            artworkNameContains: null,
        };

        return filters;
    }

    public buildFilters(): IProcessListFilters {
        this.filters.dealerID = this.dealerId;
        this.filters.storeIDToExclude = this.storeId;
        this.filters.storeOpenDateFrom =
            this.storeOpenDateFilter.length > 0 ? new Date(this.storeOpenDateFilter[0]) : null;
        this.filters.storeOpenDateTo =
            this.storeCloseDateFilter.length > 1 ? new Date(this.storeCloseDateFilter[1]) : null;
        this.filters.storeCloseDateFrom =
            this.storeCloseDateFilter.length > 0 ? new Date(this.storeCloseDateFilter[0]) : null;
        this.filters.storeCloseDateTo =
            this.storeCloseDateFilter.length > 0 ? new Date(this.storeCloseDateFilter[1]) : null;

        return this.filters;
    }

    public buildArgs(): IProcessListArgs {
        const args: IProcessListArgs = {
            filters: this.buildFilters(),
            pageState: this.pageState,
            sortProperties: [new SortProperty(this.pagination.sortBy[0])],
            shouldDisplayProductCount: false,
        };

        return args;
    }

    public async list(isNewSearch?: boolean): Promise<any> {
        await this.refreshData(isNewSearch);
    }

    public async onStoreIdChanged(): Promise<void> {
        //await Promise.all([this.refreshData(true)]);
    }

    protected async ensureDate(): Promise<boolean> {
        const isOpenDateInvalid =
            this.filters.storeOpenDateFrom &&
            this.filters.storeOpenDateTo &&
            this.filters.storeOpenDateTo < this.filters.storeOpenDateFrom;
        const isCloseDateInvalid =
            this.filters.storeCloseDateFrom &&
            this.filters.storeCloseDateTo &&
            this.filters.storeCloseDateTo < this.filters.storeCloseDateFrom;
        if (isOpenDateInvalid || isCloseDateInvalid) {
            const vm = new SimpleAsyncInteractionViewModel();
            const temp = isOpenDateInvalid ? "open date" : "close date";

            vm.headerText = "Invalid Date Filter";

            vm.text = `The store ${temp} to should be greater than the ${temp} date from.`;

            this.dismissDialogViewModel = vm;
            await this.dismissDialogViewModel.interact();
            this.dismissDialogViewModel = null;
            return false;
        }
        return true;
    }

    public async refreshData(isNewSearch?: boolean): Promise<void> {
        const isDateValid = await this.ensureDate();
        if (!isDateValid) {
            return;
        }

        if (this.dataTableLoading) {
            return;
        }
        if (!this.storeId || !this.dealerId) return;

        this.showEmptyView = false;
        if (isNewSearch) {
            this.items = [];
            this.pageState.reset();
        }
        if (this.pageState.wasLastPageSelected) {
            return;
        }

        this.dataTableLoading = true;

        await this.listProcesses(this.buildArgs());
    }

    protected async listProcesses(listArgs: IProcessListArgs): Promise<void> {
        const baseUrl = `/api/Process/List`;
        try {
            this.statusMessage = this.loadingMessage;
            this.dataTableLoading = true;
            const jsonResult = await WebHelper.postJsonData(baseUrl, listArgs);
            const listResult = JSON.parse(jsonResult) as IProcessListResults;
            const items: StoreProcessItemViewModel[] = [];
            listResult.result.forEach((process) => {
                const itemViewModel = new StoreProcessItemViewModel(listResult.hasProductCount, process);
                items.push(itemViewModel);
            });
            this.items.push(...items);
            this.pageState.next(listResult.result.length);
            this.serverItemsLength = listResult.totalRows;
        } catch {
            this.errorMessage = this.loadingErrorMessage;
        } finally {
            this.dataTableLoading = false;
        }
    }

    public validateStoreCloseDate(): boolean {
        if (
            this.filters.storeCloseDateFrom &&
            this.filters.storeCloseDateTo &&
            this.filters.storeCloseDateTo < this.filters.storeCloseDateFrom
        ) {
            return false;
        }
        return true;
    }

    public validateStoreOpenDate(): boolean {
        if (
            this.filters.storeOpenDateFrom &&
            this.filters.storeOpenDateTo &&
            this.filters.storeOpenDateTo < this.filters.storeOpenDateFrom
        ) {
            return false;
        }
        return true;
    }

    public async importProcess(item: StoreProcessItemViewModel): Promise<void> {
        try {
            const addProcessViewModel = new SimpleAsyncInteractionViewModel();
            addProcessViewModel.headerText = "Import Process";
            addProcessViewModel.text = item.processName;
            this.addProcessViewModel = addProcessViewModel;
            this.showAddProcessViewModel = true;
            const dialogResult = await this.addProcessViewModel.interact();
            const newProcessName = this.addProcessViewModel.text ? this.addProcessViewModel.text : "";
            this.showAddProcessViewModel = false;
            this.addProcessViewModel = null;
            if (dialogResult === "cancel") {
                return;
            }

            this.dataTableLoading = true;
            this.statusMessage = "Import process...";
            const importProcessArgs: ICopyProcessWithArtworkArgs = {
                copyFromDealerId: this.dealerId,
                copyFromProcessId: item.id,
                copyToDealerId: this.dealerId,
                copyToEventId: this.storeId,
                newProcessName: newProcessName,
            };
            const data = await WebHelper.postJsonData(`/api/Process/Import/${this.storeId}`, importProcessArgs);
            const storeProcessDto = JSON.parse(data) as IStoreProcessDto;
            await this.refreshData(true);
            EventBus.$emit("refresh-store-processes", storeProcessDto.storeProcessID);
        } finally {
            this.dataTableLoading = false;
        }
    }
}
