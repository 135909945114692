import { AsyncInteractionViewModel } from "chipply-common";
import { IPurchaseOrderItemWarehouseOption } from "./IPurchaseOrderLineItem";

export class PurchaseOrderSelectWarehouseViewModel extends AsyncInteractionViewModel {
    public warehouseOptions: {
        warehouseName: string;
        originalQuantityToOrder: number;
        newQuantityToOrder: number;
        stockQuantity: number;
    }[] = [];
    public warehouse = "";
    public quantityToOrder = 0;
    public isFormValid = false;
    public errorMessage = "";
    public headerText = "";

    public headers = [
        { text: "Warehouse", value: "warehouse" },
        { text: "Original Quantity", value: "originalQuantityToOrder" },
        { text: "New Quantity", value: "newQuantityToOrder" },
    ];
}
