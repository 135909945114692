import { StoreCheckoutFieldsViewModel } from "@/chipply/fields/StoreCheckoutFieldsViewModel";
import { ICatalogStoreSettingsModel } from "@/chipply/store/settings/ICatalogStoreSettingsModel";
import { IGroupStoreSettingsModel } from "@/chipply/store/settings/IGroupStoreSettingsModel";
import { IStoreFulfillmentViewModel } from "@/chipply/event/IStoreFulfillmentViewModel";
import { StoreDistributionViewModel } from "@/chipply/store/settings/StoreDistributionViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import StoreSettingsViewModel from "@/chipply/store/settings/StoreSettingsViewModel";

export class GroupStoreSettingsViewModel extends StoreSettingsViewModel implements IStoreFulfillmentViewModel {
    public hideBulkOrdering = false;
    public distribution: StoreDistributionViewModel;

    public constructor(model: StoreSettingsPageModel, parentVm: StoreSettingsPageViewModel) {
        super(model);
        const groupModel = model.store as IGroupStoreSettingsModel;
        this.hideBulkOrdering = groupModel.hideBulkOrdering;
        this.distribution = new StoreDistributionViewModel(model, parentVm);
    }

    public toModel(): IGroupStoreSettingsModel {
        const model = super.toModel() as IGroupStoreSettingsModel;
        model.distribution = this.distribution.toModel();
        model.$typeHint = "GroupStoreSettingsModel";
        model.hideBulkOrdering = this.hideBulkOrdering;
        return model;
    }

    public async organizationChanged() {
        await super.organizationChanged();
        this.distribution.organizationChanged();
    }
}
