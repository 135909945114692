import { ITextValue, ViewModel } from "chipply-common";

export default class ProcessInfo extends ViewModel {
    public process: ITextValue<number>;
    public date: string;
    public checked = true;
    public processDescription = "";

    constructor(process: ITextValue<number>, date: string, processDescription: string) {
        super();
        this.process = process;
        this.date = date;
        this.processDescription = processDescription;
    }

    public toModel() {
        return {
            process: this.process,
            date: this.date,
            checked: this.checked,
            processDescription: this.processDescription,
        };
    }
}
