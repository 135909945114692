import { render, staticRenderFns } from "./ResponsiveDetailView.vue?vue&type=template&id=7df22529&scoped=true"
import script from "./ResponsiveDetailView.vue?vue&type=script&lang=ts"
export * from "./ResponsiveDetailView.vue?vue&type=script&lang=ts"
import style0 from "./ResponsiveDetailView.vue?vue&type=style&index=0&id=7df22529&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df22529",
  null
  
)

export default component.exports