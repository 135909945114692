import { render, staticRenderFns } from "./NavigationHost.vue?vue&type=template&id=3b59f346&scoped=true"
import script from "./NavigationHost.vue?vue&type=script&lang=ts"
export * from "./NavigationHost.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b59f346",
  null
  
)

export default component.exports