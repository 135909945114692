










































import Component from "vue-class-component";
import Vue from "vue";
import { Watch } from "vue-property-decorator";

@Component({
    computed: {
        swatchStyle() {
            const { fieldColor, menu } = this as any;
            return {
                backgroundColor: fieldColor,
                border: "1px solid #767676",
                borderRadius: menu ? "50%" : "4px",
                cursor: "pointer",
                height: "30px",
                transition: "border-radius 200ms ease-in-out",
                width: "30px",
            };
        },
    },
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        label: {
            default: "",
            type: String,
        },
        value: {
            default: "",
            type: String,
        },
    },
})
export default class ColorTextField extends Vue {
    public fieldColor: string | undefined;
    public value!: string | undefined;
    public data() {
        return {
            defaultSwatches: [
                ["#000000", "#7A0036", "#D2B268", "#742A90"],
                ["#58585B", "#F16521", "#FEC10D", "#001644"],
                ["#A7A9AB", "#ED1C24", "#004724", "#17479E"],
                ["#C0C0C0", "#FF69B4", "#007732", "#698DB6"],
                ["#A25C34", "#C41134", "#C0D746", "#FFFFFF"],
            ],
            fieldColor: "",
            mask: "!#XXXXXX",
            menu: false,
        };
    }

    public colorPickerUpdate(nv: any) {
        this.fieldColor = nv.hex;
    }

    public created() {
        this.fieldColor = this.value;
    }

    @Watch("value")
    protected onValueChanged(nv: string) {
        this.fieldColor = nv;
    }

    @Watch("fieldColor")
    protected fieldColorChanged(nv: string) {
        if (nv && !nv.startsWith("#")) {
            this.fieldColor = "#" + nv;
        }
        this.$emit("change", nv);
    }
}
