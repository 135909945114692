import {
    Address,
    ICountryDto,
    IStateDto,
    ITextValue,
    SimpleAsyncInteractionViewModel,
    TextUtils,
    WebHelper,
} from "chipply-common";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import SalesOrderInfo from "../event/SalesOrderInfo";
import IPurchaseOrderTotals from "./IPurchaseOrderTotals";
import PurchaseOrderTotals from "./PurchaseOrderTotals";
import PurchasingConstants, { PurchasingType } from "./PurchasingConstants";
import PurchasingFilters from "./PurchasingFilters";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import ListDealerBranchResults from "./ListDealerBranchResults";
import { ValidatePurchaseOrderArgs } from "./ValidatePurchaseOrderArgs";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";
import ISubmitPurchaseOrder from "./ISubmitPurchaseOrder";
import PurchaseOrderHelper from "./PurchaseOrderHelper";
import IPurchasingLineItem from "./IPurchasingLineItem";
import { IPurchaseOrderLineItem } from "./IPurchaseOrderLineItem";
import IArtworkPurchasingLineItem from "./IArtworkPurchasingLineItem";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import PurchaseOrderingShippingOptions from "./PurchaseOrderingShippingOptions";
import { IPurchaseOrderWarehouse } from "./IPurchaseOrderWarehouse";

export default abstract class AbstractPurchaseOrderViewModel implements ISubmitPurchaseOrder {
    public attention = "";
    public branches: ITextValue<number>[] = [];
    public canCheckAvailability = false;
    public canExportPurchaseOrder = false;
    public canSelectWarehouse = false;
    public canCustomerPickup = false;
    public isCustomerPickup = false;
    public customerPickupWarehouseId: number | null = null;
    public orderingShippingOptions: PurchaseOrderingShippingOptions = 0;
    public warehouses: IPurchaseOrderWarehouse[] = [];
    public selectedWarehouseIds: number[] = [];
    public isSelectAllWarehouseIds = false;
    public isExpandWarehouseOptions = false;
    public canOrderDigitally = false;
    protected canGroupByFulfillmentStatus = true;
    public countries: ICountryDto[] = [];
    public currentStep = 1;
    public customerNumber = "";
    public dealerId = 0;
    public dealerBranchId: number | null = null;
    public dialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public eventId = 0;
    public catalogBatchId = 0;
    public filters: PurchasingFilters = new PurchasingFilters();
    public hasCheckedAvailability = false;
    public hasInitialized = false;
    public isFormValid = false;
    public isGroupingByProcess = false;
    public isStoreView = false;
    public isPrintEmailDialogVisible = false;
    public isPurchaseOrderDialogVisible = false;
    protected isPurchaseOrderInfoRequiredForPresubmit = false;
    //public isSubmitDialogVisible = false;
    public lastPurchaseOrderNumber = "";
    public lastPurchaseOrderId = 0;
    public lastPurchaseOrderType: PurchasingType | null = null;
    public panels: number[] = [1];
    public poNumberValidation = {
        valid: true,
        loading: false,
    };
    public processes: Array<{ text: string; value: number }> = [];
    public purchaseOrderDialogTitle = "";
    public purchaseOrderHelper = new PurchaseOrderHelper();
    public purchaseOrderId: number | null = null;
    public purchaseOrderNumber = "";
    public purchasingViewModel!: IPurchasingParentViewModel;
    public salesOrderInfo: SalesOrderInfo | null = null;
    public salesReps: Array<ITextValue<number>> = [];
    public selectedPurchaseOrderType: PurchasingType | null = null;
    public shouldShipToBranch = true;
    public shipEmail = "";
    public shipPhone = "";
    public shipMethodHint = "";
    public shipTo: Address = new Address();
    public sortBy: string[] = [];
    public sortDesc: boolean[] = [];
    public states: IStateDto[] = [];
    public stores: Array<{ text: string; value: number }> = [];
    public storeName = "";
    public totals: IPurchaseOrderTotals = new PurchaseOrderTotals();
    public type = "";
    protected vendorErrorMessage =
        "An unexpected error has ocurred while communicating with the vendor, please try again later.";
    public vendorName = "";
    public vendors: Array<{ text: string; value: number }> = [];
    public vendorSettings: IVendorPurchaseOrderSettings | null = null;
    protected fulfillmentHeader = {
        text: "Is Fulfilled",
        value: "isFulfilled",
        sortable: false,
    };
    public lines: IPurchasingLineItem[] = [];
    public selectedLines: IPurchasingLineItem[] = [];

    public async acceptPurchaseOrderDialog(): Promise<void> {
        if (!this.selectedPurchaseOrderType) {
            return;
        }

        const checkPoNumberResults = await this.checkPoNumber();
        if (!checkPoNumberResults) {
            return;
        }

        this.isPurchaseOrderDialogVisible = false;
        switch (this.selectedPurchaseOrderType) {
            case PurchasingConstants.checkPurchaseType:
                await this.checkAvailability();
                break;
            default:
                await this.submitPurchaseOrder(this.selectedPurchaseOrderType);
                break;
        }
    }

    public back() {
        this.purchasingViewModel.back();
    }

    public abstract buildPurchaseOrder(): Promise<void>;

    abstract checkAvailability(): Promise<void>;

    public async checkPoNumber() {
        if (!this.purchaseOrderNumber) {
            return false;
        }
        this.poNumberValidation.loading = true;
        const validateArgs = new ValidatePurchaseOrderArgs();
        validateArgs.purchaseOrderNumber = this.purchaseOrderNumber;
        const resultsText = await WebHelper.postJsonData("/api/Purchasing/validateponumber", validateArgs);
        const deserializedResults = JSON.parse(resultsText);
        this.poNumberValidation.valid = deserializedResults.isValid;
        this.poNumberValidation.loading = false;
        return this.poNumberValidation.valid;
    }

    public closePurchaseOrderDialog(): void {
        this.isPurchaseOrderDialogVisible = false;
        this.selectedPurchaseOrderType = null;
    }

    public computedShippingStates(): IStateDto[] {
        if (this.shipTo.country) {
            return this.states.filter((x) => x.country === this.shipTo.country);
        }
        return this.states;
    }

    public async getBranches(): Promise<void> {
        try {
            this.purchasingViewModel.isLoading = true;
            const results = await this.purchaseOrderHelper.getBranches();
            if (results) {
                this.branches = results.branches;
                if (results.primaryBranchId) {
                    this.dealerBranchId = results.primaryBranchId;
                }
            }
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public getTotals() {
        let lines = this.lines;
        if (this.selectedLines.length > 0) {
            lines = this.selectedLines;
        }
        const totals = lines.reduce(
            (previousValue: any, currentValue: any) => {
                previousValue.quantity += currentValue.quantity;
                previousValue.quantityOrdered += currentValue.quantityOrdered;
                previousValue.quantityToOrder += parseInt(currentValue.quantityToOrder, 10);
                previousValue.totalCost += currentValue.totalCost;
                return previousValue;
            },
            {
                quantity: 0,
                quantityOrdered: 0,
                quantityToOrder: 0,
                totalCost: 0,
            }
        );
        return totals;
    }

    public async initialize(): Promise<void> {
        if (!this.hasInitialized) {
            this.hasInitialized = true;
            await this.initializeCore();
        }
    }

    protected async initializeCore() {
        await this.getBranches();
        await this.buildPurchaseOrder();
    }

    public productCostChanged(line: IPurchasingLineItem) {
        line.totalCost = line.quantityToOrder * line.productCost;
    }

    public getPoNumberErrors(): string {
        if (!this.purchaseOrderNumber) {
            return "PO Number is required";
        }
        if (
            this.vendorName &&
            this.vendorName.toLowerCase() === "sanmar" &&
            !TextUtils.isAlphaNumeric(this.purchaseOrderNumber)
        ) {
            return "Only letters or numbers are allowed";
        }
        return this.poNumberValidation.valid ? "" : `Number is already in use, please choose a different number`;
    }

    public getCustomerPickupWarehouseErrors(): string {
        if (!this.customerPickupWarehouseId) {
            return "Warehouse is required";
        }
        return "";
    }

    abstract submitPurchaseOrder(purchaseOrderType: PurchasingType): Promise<void>;

    protected abstract getListUrl(): string;
    protected abstract handleLargePurchaseOrder(): Promise<void>;

    protected applyPurchaseOrderHeader(purchaseOrder: VendorPurchaseOrderHeader) {
        this.vendorName = purchaseOrder.vendorName;
        this.purchaseOrderNumber = purchaseOrder.purchaseOrderNumber;
        this.attention = purchaseOrder.attention;
        this.shipTo = purchaseOrder.shipTo;
        this.shipEmail = purchaseOrder.shipEmail;
        this.shipPhone = purchaseOrder.shipPhone;
        this.isPurchaseOrderInfoRequiredForPresubmit = purchaseOrder.isPurchaseOrderInfoRequiredForPresubmit;
        this.canCheckAvailability = purchaseOrder.canCheckAvailability;
        this.canOrderDigitally = purchaseOrder.canOrderDigitally;
        this.canExportPurchaseOrder = purchaseOrder.canExportPurchaseOrder;
        this.orderingShippingOptions = purchaseOrder.orderingShippingOptions;
    }

    protected toViewModel(purchaseOrder: IVendorPurchaseOrder<IPurchasingLineItem>) {
        PurchaseOrderHelper.replaceArray(this.lines, purchaseOrder.lines);
        this.applyPurchaseOrderHeader(purchaseOrder);
        PurchaseOrderHelper.replaceArray(this.selectedLines, this.lines);
        this.totals = purchaseOrder.totals;
        if (purchaseOrder.type) {
            this.type = purchaseOrder.type;
        }
    }

    protected applyLines(lines: IPurchasingLineItem[]) {
        const fulfilledItems: IPurchasingLineItem[] = [];
        for (const sourceLineItem of lines) {
            for (const matchingLine of this.lines) {
                if (matchingLine.id !== sourceLineItem.id) {
                    continue;
                }
                this.applyLineItem(sourceLineItem, matchingLine);
                if (matchingLine.hasError || matchingLine.availability === "Unavailable") {
                    PurchaseOrderHelper.removeItemFromArrayIfExists(this.selectedLines, matchingLine);
                }
                if (sourceLineItem.isFulfilled) {
                    fulfilledItems.push(matchingLine);
                }
                if (matchingLine.isFulfilled) {
                    PurchaseOrderHelper.removeItemFromArrayIfExists(this.selectedLines, matchingLine);
                }
            }
        }
        for (const fulfilledItem of fulfilledItems) {
            PurchaseOrderHelper.removeItemFromArrayIfExists(this.lines, fulfilledItem);
        }
    }

    protected applyLineItem(sourceLineItem: IPurchasingLineItem, matchingLine: IPurchasingLineItem) {
        PurchaseOrderHelper.updatePurchasingLineItem(sourceLineItem, matchingLine);
    }
}
