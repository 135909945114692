import OrderStoreSettingsPageModel from "@/chipply/store/settings/OrderStoreSettingsPageModel";
import { OrderStoreSettingsViewModel } from "@/chipply/store/settings/OrderStoreSettingsViewModel";
import RequestorStoreSettingsPageModel from "@/chipply/store/settings/RequestorStoreSettingsPageModel";
import { RequestorStoreSettingsViewModel } from "@/chipply/store/settings/RequestorStoreSettingsViewModel";
import StoreSettingsPageModel from "@/chipply/store/settings/StoreSettingsPageModel";
import { StoreSettingsPageViewModel } from "@/chipply/store/settings/StoreSettingsPageViewModel";
import { Serializer } from "chipply-common";

export default class OrderStoreSettingsPageViewModel extends StoreSettingsPageViewModel {
    public store: OrderStoreSettingsViewModel | null = null;

    protected initializeSettingsViewModel(model: StoreSettingsPageModel): void {
        this.store = new OrderStoreSettingsViewModel(model);
    }
}
