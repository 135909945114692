import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, ssActivewearShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "SSActivewearPurchaseOrderSettings" })
export default class SSActivewearPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public vendorName = "ssactivewear";

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return ssActivewearShipMethods;
    }
}
