import { WebHelper } from "chipply-common";
import IDealerEcomBrandingDto from "../interface/i-dealer-ecom-branding-dto";
import IStoreBrandingDefaults from "../interface/i-store-branding-defaults";

export default class DealerBrandingViewModel {
    public dealerBranding: IDealerEcomBrandingDto = {} as any;
    public responseBranding: IDealerEcomBrandingDto = {} as any;
    public storeBrandingDefaults: IStoreBrandingDefaults = {} as any;
    public isLoading = false;
    public errorMessage = "";
    public dealerLogosImageFormData: FormData | null = null;
    public slideImageFormData: FormData | null = null;
    public slideLogoImageFormData: FormData | null = null;
    public loadingMessage = "loading...";
    public savingMessage = "saving...";
    public statusMessage = this.loadingMessage;
    public logoImageFileName = "";
    public slideLogoImageFileName = "";
    public fileNameSuffix = "";
    public slideImageFileName = "";
    public isReadingFile: string | boolean = false;

    public branding = {
        slideImageFileName: "",
        dealerSkinId: "",
        slideLogoImageFileName: "",
        slideLogoImagePath: "",
    };

    public canUploadSlideImage() {
        return this.slideImageFileName !== "" && !this.isReadingFile; // && !this.isLoading;
    }

    public canUploadSlideLogoImage() {
        return this.slideLogoImageFileName !== "" && !this.isReadingFile; // && !this.isLoading;
    }

    public isDeleteSlideImageDisabled() {
        return this.dealerBranding.slideImage ? false : true;
    }

    public isDeleteSlideLogoImageDisabled() {
        return this.dealerBranding.slideLogo ? false : true;
    }

    public async deleteSlideImage(): Promise<void> {
        this.dealerBranding.slideImage = "";
        await this.saveDealerBrandingData();
        await this.getDealerBrandingInfo();
    }

    public async deleteSlideLogoImage(): Promise<void> {
        this.dealerBranding.slideLogo = "";
        await this.saveDealerBrandingData();
        await this.getDealerBrandingInfo();
    }

    public async deleteLogos(): Promise<void> {
        // switch (type) {
        //     case "main":
        //         this.dealerBranding.mainLogo = "";
        //         break;
        //     case "alt1":
        //         this.dealerBranding.altLogo1 = "";
        //         break;
        //     case "alt2":
        //         this.dealerBranding.altLogo2 = "";
        //         break;
        // }

        try {
            const resultJson = await WebHelper.postJsonData("/api/Dealer/DeleteDealerLogos", this.dealerBranding);
        } catch (e) {
            this.errorMessage = "An error occurred while deleting logo data.  Please refresh and try again.";
        }
    }

    public async getDealerBrandingInfo(): Promise<void> {
        try {
            this.isLoading = true;
            const resultJson = (await WebHelper.getJsonData(
                "/api/Dealer/GetEcomBrandingData",
                true
            )) as IDealerEcomBrandingDto;

            if (resultJson) {
                this.dealerBranding = resultJson;
            }
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.isLoading = false;
        }
    }

    public async getStoreBrandingDefaults(): Promise<void> {
        try {
            this.isLoading = true;
            const resultJson = (await WebHelper.getJsonData(
                "/api/Dealer/GetStoreBrandingDefaults",
                true
            )) as IStoreBrandingDefaults;

            if (resultJson) {
                this.storeBrandingDefaults = resultJson;
            }
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
        } finally {
            this.isLoading = false;
        }
    }

    public async saveStoreBrandingDefaults() {
        const resultJson = await WebHelper.postJsonData(
            "/api/Dealer/SaveStoreBrandingDefaults",
            this.storeBrandingDefaults
        );
    }

    public async saveDealerBrandingData() {
        let main = this.dealerBranding.mainLogo;
        let alt1 = this.dealerBranding.altLogo1;
        let alt2 = this.dealerBranding.altLogo2;

        /* eslint-disable */
        if (main) {
            main = main.replace(/^.*[\\\/]/, "");
        }

        if (alt1) {
            alt1 = alt1.replace(/^.*[\\\/]/, "");
        }

        if (alt2) {
            alt2 = alt2.replace(/^.*[\\\/]/, "");
        }
        /* eslint-enable */

        try {
            this.isLoading = true;
            this.statusMessage = this.savingMessage;
            await this.deleteLogos();
            await this.uploadDealerLogos();
            this.dealerBranding.mainLogo = this.responseBranding.mainLogo;
            this.dealerBranding.altLogo1 = this.responseBranding.altLogo1;
            this.dealerBranding.altLogo2 = this.responseBranding.altLogo2;

            const resultJson = await WebHelper.postJsonData("/api/Dealer/GetEcomBrandingData", this.dealerBranding);
        } catch (e) {
            this.errorMessage = "An error occurred while saving branding data.  Please refresh and try again.";
        } finally {
            this.isLoading = false;
            this.statusMessage = this.loadingMessage;
        }
    }

    public async uploadDealerSlideImage() {
        const options = {
            body: this.slideImageFormData,
            method: "POST",
        };
        try {
            this.statusMessage = this.savingMessage;
            this.isLoading = true;
            const response = await fetch("/api/Dealer/UploadSlideImage", options);
            const resultsText = await response.text();
            const results = JSON.parse(resultsText);
            this.dealerBranding.slideContentImagePath = results.directoryPath;
            this.dealerBranding.slideImage = results.imageFileName;
        } catch {
            this.errorMessage = "An error occurred while saving slide image data.  Please refresh and try again.";
        } finally {
            this.isLoading = false;
            this.slideImageFileName = "";
            this.fileNameSuffix = "";
            this.slideImageFormData = null;
        }
    }

    public async uploadSlideLogoImage() {
        const options = {
            body: this.slideLogoImageFormData,
            method: "POST",
        };
        try {
            this.statusMessage = this.savingMessage;
            this.isLoading = true;
            const response = await fetch("/api/Dealer/UploadSlideLogo", options);
            const resultsText = await response.text();
            const results = JSON.parse(resultsText);
            this.dealerBranding.slideContentLogoPath = results.directoryPath;
            this.dealerBranding.slideLogo = results.imageFileName;
        } catch {
            this.errorMessage = "An error occurred while saving slide logo data. Please refresh and try again.";
        } finally {
            this.isLoading = false;
            this.slideLogoImageFileName = "";
            this.fileNameSuffix = "";
            this.slideLogoImageFormData = null;
        }
    }

    public async uploadDealerLogos(): Promise<void> {
        const options = {
            body: this.dealerLogosImageFormData,
            method: "POST",
        };
        try {
            const response = await fetch("/api/Dealer/UploadLogoInfo", options);
            const resultsText = await response.text();
            const results = JSON.parse(resultsText);
            this.responseBranding = results;
        } catch {
            this.errorMessage = "An error ocurred while saving the data.  Please refresh and try again.";
        } finally {
            //this.isLoading = false;
            this.logoImageFileName = "";
            this.fileNameSuffix = "";
        }
    }

    public onLogoImagePicked = async (e: File, type: string) => {
        if (e !== undefined && e !== null) {
            const fileName = e.name;
            if (!fileName || fileName.lastIndexOf(".") <= 0) {
                return;
            }

            switch (type) {
                case "main":
                    this.dealerBranding.mainLogo = fileName;
                    break;
                case "alt1":
                    this.dealerBranding.altLogo1 = fileName;
                    break;
                case "alt2":
                    this.dealerBranding.altLogo2 = fileName;
                    break;
            }

            try {
                this.isReadingFile = "accent";
                const fileReader = new FileReader();

                fileReader.readAsDataURL(e);
                fileReader.addEventListener("load", async () => {
                    this.isReadingFile = false;

                    if (this.dealerLogosImageFormData == null) {
                        this.dealerLogosImageFormData = new FormData();
                    }

                    this.dealerLogosImageFormData.set(type, e, e.name);
                });
            } catch {
                this.isReadingFile = false;
            }
        } else {
            switch (type) {
                case "main":
                    this.dealerBranding.mainLogo = "";
                    break;
                case "alt1":
                    this.dealerBranding.altLogo1 = "";
                    break;
                case "alt2":
                    this.dealerBranding.altLogo2 = "";
                    break;
            }
            this.fileNameSuffix = "";
        }
    };

    public onSlideImagePicked = (e: File) => {
        if (e !== undefined) {
            const fileName = e.name;
            if (!fileName || fileName.lastIndexOf(".") <= 0) {
                return;
            }
            try {
                this.isReadingFile = "accent";
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e);
                fileReader.addEventListener("load", async () => {
                    this.isReadingFile = false;
                    this.slideImageFileName = e.name;
                    this.slideImageFormData = new FormData();
                    this.slideImageFormData.append("file", e, this.slideImageFileName);
                });
            } catch {
                this.isReadingFile = false;
            }
        } else {
            this.fileNameSuffix = "";
        }
    };

    public onSlideLogoImagePicked = (e: File) => {
        if (e !== undefined) {
            const fileName = e.name;
            if (!fileName || fileName.lastIndexOf(".") <= 0) {
                return;
            }
            try {
                this.isReadingFile = "accent";
                const fileReader = new FileReader();
                fileReader.readAsDataURL(e);
                fileReader.addEventListener("load", async () => {
                    this.isReadingFile = false;
                    this.slideLogoImageFileName = e.name;
                    this.slideLogoImageFormData = new FormData();
                    this.slideLogoImageFormData.append("file", e, this.slideLogoImageFileName);
                });
            } catch {
                this.isReadingFile = false;
            }
        } else {
            this.fileNameSuffix = "";
        }
    };
}
