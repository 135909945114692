import { render, staticRenderFns } from "./NavigationTopButton.vue?vue&type=template&id=3f789b8e&scoped=true"
import script from "./NavigationTopButton.vue?vue&type=script&lang=ts"
export * from "./NavigationTopButton.vue?vue&type=script&lang=ts"
import style0 from "./NavigationTopButton.vue?vue&type=style&index=0&id=3f789b8e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f789b8e",
  null
  
)

export default component.exports