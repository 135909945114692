import { IListStoreArtworkFilters } from "./IStoreArtworkDto";
import SearchArtworkViewModel from "./SearchArtworkViewModel";
import { SearchStoreArtworksViewModel } from "./SearchStoreArtworksViewModel";
import ArtworkVariationSelectorViewModel from "./ArtworkVariationSelectorViewModel";
import { ArtworkVariationItemViewModel } from "./ArtworkVariationItemViewModel";
import ManualAddArtworkVariationItem from "@/chipply/purchasing/ManualAddArtworkVariationItem";

export default class ArtworkSelectorViewModel extends SearchStoreArtworksViewModel {
    public isSelectingVariations = false;
    protected canSelectVariations = false;

    protected _interactionResolve: null | ((result: SearchArtworkViewModel | null) => void) = null;

    protected _variationsSelectedResult: null | ((result: ManualAddArtworkVariationItem[]) => void) = null;

    public variationSelector: ArtworkVariationSelectorViewModel | null = null;

    public selectedVariations: ManualAddArtworkVariationItem[] = [];

    public constructor(variationSelector?: ArtworkVariationSelectorViewModel) {
        super();
        if (variationSelector) {
            this.variationSelector = variationSelector;
        }
        this.isArtworkPurchasingEnabled = true;
    }

    public async itemSelected(item: SearchArtworkViewModel) {
        if (!this.variationSelector) {
            this.done(item);
        } else {
            try {
                this.isSelectingVariations = true;
                this.variationSelector.artworkId = item.storeArtworkId;
                this.variationSelector.storeId = item.storeId;
                await this.variationSelector.refreshData(true);
                const results = await this.variationSelector.interact();
                this.isSelectingVariations = false;
                for (const result of results) {
                    const matchingVariations = this.selectedVariations.filter(
                        (x) => x.artworkVariationId === result.id
                    );
                    if (matchingVariations.length > 0) {
                        if (result.additionalQuantity >= 0) {
                            matchingVariations[0].quantity += result.additionalQuantity;
                        }
                    } else {
                        const manualItem = new ManualAddArtworkVariationItem();
                        if (result.additionalQuantity <= 0) {
                            continue;
                        }
                        manualItem.quantity = result.additionalQuantity;
                        manualItem.artworkVariationId = result.id;
                        this.selectedVariations.push(manualItem);
                    }
                }
            } finally {
                this.isSelectingVariations = false;
            }
        }
    }

    public isArtworkSelector(): boolean {
        return true;
    }

    public cancel() {
        this.variationsSelected(this.selectedVariations);
    }

    public interact(): Promise<SearchArtworkViewModel | null> {
        return new Promise<SearchArtworkViewModel | null>((resolve) => {
            this._interactionResolve = resolve;
        });
    }

    public selectVariations(): Promise<ManualAddArtworkVariationItem[]> {
        return new Promise<ManualAddArtworkVariationItem[]>((resolve) => {
            this._variationsSelectedResult = resolve;
        });
    }

    public done(accepted: SearchArtworkViewModel | null) {
        this._interactionResolve!(accepted);
    }

    public variationsSelected(accepted: ManualAddArtworkVariationItem[]) {
        this._variationsSelectedResult!(accepted);
    }

    public async refreshData(isNewSearch?: boolean): Promise<void> {
        if (this.dataTableLoading) {
            return;
        }

        if (isNewSearch) {
            this.items = [];
            this.pageState.reset();
        }
        if (this.pageState.wasLastPageSelected) {
            return;
        }
        this.dataTableLoading = true;
        await this.searchArtworks(this.buildSearchArgs());
    }

    public async onStoreIdChanged(): Promise<void> {
        this.buildFilters();
        this.filters.storeID = this.storeId;
        await Promise.all([this.refreshData(true)]);
    }

    public buildFilters(): IListStoreArtworkFilters {
        //this.filters.storeID = this.storeId;
        return this.filters;
    }
}
