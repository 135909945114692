















































































































import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { Vue, Component, Prop } from "vue-property-decorator";
import { ReportHeaderViewModel } from "@/chipply/reports/ReportHeaderViewModel";
import CDate from "@/components/ui/CDate.vue";
import chipplyIcons from "@/chipply/ImportIcons";
import { WorkOrderProductsViewModel } from "@/chipply/reports/WorkOrder/WorkOrderProductsViewModel";
import { WorkOrderReportOptionsViewModel } from "@/chipply/reports/WorkOrder/WorkOrderReportOptionsViewModel";
@Component({
    components: { CDecisionCard, CDate },
})
export default class ReportHeader extends Vue {
    @Prop({ type: Object })
    public vm!: ReportHeaderViewModel;
    @Prop({ type: Boolean, default: false })
    public isWorkOrderHeader!: boolean;
    @Prop({ type: String })
    public processName!: string;
    @Prop({ type: Number })
    public totalProducts!: number;
    @Prop({ type: Number })
    public totalQty!: number;
    @Prop({ type: String })
    public sectionHeader!: string;
    @Prop({ type: Object })
    public options!: WorkOrderReportOptionsViewModel;

    public chipplyIcons = chipplyIcons;
}
