import { EmailTemplateTriggerTypeEnum } from "@/chipply/emails/EmailTemplateTriggerTypeEnum";
import AutomatedMergeTemplateViewModel from "@/chipply/merge/AutomatedMergeTemplateViewModel";
import { EventType } from "@/chipply/event/EventType";
import { IDealerStoreMergeTemplate } from "@/chipply/merge/IDealerStoreMergeTemplate";
import MergeFields from "@/chipply/merge/MergeFields";
import { ITextValue } from "chipply-common";
import { StoreMergeTemplateViewModelBase } from "@/chipply/merge/StoreMergeTemplateViewModelBase";
import IDealerEcertMergeTemplate from "@/chipply/merge/IDealerEcertMergeTemplate";
import { MergeTemplateType } from "@/chipply/merge/MergeTemplateType";

export default class AutomatedDealerStoreMergeTemplateViewModel extends StoreMergeTemplateViewModelBase {
    public applyCatalog = false;
    public applyGroup = false;
    public storeTypes: EventType[] = [];
    public includeStoreFlyer = false;
    public templateType = MergeTemplateType.StoreAutomated;
    public templateTypeDisplay = "Store Automated";
    public created(dto: IDealerStoreMergeTemplate) {
        this.setTemplate(dto);
    }

    public setTemplate(dto?: IDealerStoreMergeTemplate) {
        super.setTemplate(dto);
        this.supportsSms = true;
        this.copyToStore = true;
        this.includeStoreFlyer = dto ? dto.includeStoreFlyer : false;

        if (dto) {
            this.storeTypes = dto.storeTypes;
            this.applyCatalog = dto.storeTypes.indexOf(EventType.Catalog) > -1;
            this.applyGroup = dto.storeTypes.indexOf(EventType.Store) > -1;
        } else {
            this.storeTypes = [EventType.Store];
            this.applyGroup = true;
            this.applyCatalog = false;
        }
    }

    public toModel(): IDealerStoreMergeTemplate {
        const baseModel = super.toModel();
        const storeTypes: Array<EventType> = [];
        if (this.applyGroup) storeTypes.push(EventType.Store);
        if (this.applyCatalog) storeTypes.push(EventType.Catalog);

        const model = {
            ...baseModel,
            $typeHint: "AutomatedDealerStoreMergeTemplate",
            includeStoreFlyer: this.includeStoreFlyer,
            storeTypes: storeTypes,
        };

        this.setDate(model);
        return model;
    }

    public clone(): AutomatedDealerStoreMergeTemplateViewModel {
        const clone = new AutomatedDealerStoreMergeTemplateViewModel();
        clone.setTemplate(this.toModel());
        return clone;
    }
}
