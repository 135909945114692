import { AsyncInteractionViewModel, Utils, WebHelper } from "chipply-common";
import { ChangeAccountPasswordViewModel } from "./ChangeAccountPasswordViewModel";
import UserLevel from "../UserLevel";
import ICompanyUserModel from "./ICompanyUserModel";
import _ from "lodash";
import { EventBus } from "../EventBus";
import { PageViewModel } from "../view-model/PageViewModel";

export default class EditAccountProfileViewModel extends PageViewModel {
    public isLoading = false;
    public errorMessage: string | null = null;
    public statusMessage = this.loadingMessage;
    public userId = 0;
    public userName = "";
    public firstName = "";
    public lastName = "";
    public email = "";
    public email2 = "";
    public formattedPhone = "";
    public formattedPhone2 = "";
    public recoveryEmail = "";
    public billingContact = false;
    public recoveryMobile = "";
    public formattedRecoveryPhone = "";
    public originalPassword = "";
    public newPassword = "";
    public confirmNewPassword = "";
    public companyId = 0;
    public dealerId = 0;
    public userLevelId!: number;
    public loggedInUserLevelId!: number;
    declare defaultUserLevels: any[]; // override in derived class
    public showChild = false;
    public changePasswordViewModel: ChangeAccountPasswordViewModel | null = null;
    public allowChangePassword = true;
    public enabled = true;
    public allowBackToParent = false;
    public shouldHideLoginFields = false;
    public originalData: ICompanyUserModel | null = null;
    public confirmViewModel: AsyncInteractionViewModel | null = null;
    public isSaveDialogVisible = false;
    public isValidAccountPassword = false;
    public isLoggedInUser = false;
    public isDealerAdminScreen = false;

    public async getAccount() {
        if (!this.userId) {
            console.error("User Id is required");
            return;
        }
        const baseUrl = `/api/Company/CompanyUser/${this.userId}`;
        try {
            this.statusMessage = this.loadingMessage;
            this.isLoading = true;
            this.errorMessage = null;
            const results = (await WebHelper.getJsonData(baseUrl)) as ICompanyUserModel;
            if (results) {
                this.originalData = results;
                this.update(results);
            }
        } catch {
            this.errorMessage = this.loadingErrorMessage;
        } finally {
            this.isLoading = false;
            this.statusMessage = "";
        }
    }

    public async saveAccount() {
        const baseUrl = `/api/Company/CompanyUser/`;
        return await this.saveAccountCore(baseUrl);
    }

    protected async saveAccountCore(baseUrl: string) {
        try {
            this.statusMessage = this.loadingMessage;
            this.isLoading = true;
            this.errorMessage = null;
            const saveDto = this.toDto();
            const userLevelHasChanged = this.originalData?.userLevelId != saveDto.userLevelId;
            const resultsText = await WebHelper.postJsonData(baseUrl, saveDto);

            const handleResult = WebHelper.handleServerProblemDetails(resultsText);
            if (handleResult.hasError) {
                this.errorMessage = handleResult.errorMessage;
                return null;
            }
            const results = JSON.parse(resultsText) as ICompanyUserModel;
            if (results) {
                this.originalData = results;
                this.update(results);
                if (userLevelHasChanged && this.isLoggedInUser) {
                    // relogin user if they change their own userlevel
                    await this.autoLogin(results.userId);
                }
            }
            return results;
        } catch {
            this.errorMessage = Utils.ServerErrorMessage;
            return null;
        } finally {
            this.statusMessage = "";
            this.isLoading = false;
        }
    }

    public async autoLogin(userId: number) {
        try {
            this.statusMessage = this.loadingMessage;
            this.errorMessage = null;
            const args = {
                userId: userId,
            };
            const serverResults = await WebHelper.postJsonData(`/api/Login/CreateAutoLogin`, args);
            const results = await JSON.parse(serverResults);
            location.assign("/AutoLogin.aspx?guid=" + results.id);
        } catch (error) {
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.statusMessage = "";
        }
    }

    protected createChangePasswordViewModel() {
        const changePasswordViewModel = new ChangeAccountPasswordViewModel();
        changePasswordViewModel.userId = this.userId;
        return changePasswordViewModel;
    }

    protected hasChanges() {
        const newData = this.toDto();
        const isEqual = _.isEqual(newData, this.originalData);
        if (isEqual) {
            return false;
        }
        const difference = _.reduce(
            this.originalData,
            (result: string[], value, key) => {
                //shouldHideLoginField is calculated on the fly. It is not a real change
                if (_.isEqual(value, (newData as any)[key]) || key == "shouldHideLoginField") {
                    return result;
                }
                if ((this.originalData as any)[key] == null && (newData as any)[key] == "") {
                    return result;
                }
                if ((this.originalData as any)[key] == "" && (newData as any)[key] == null) {
                    return result;
                }
                return result.concat(key);
            },
            []
        );
        return difference.length > 0;
    }

    public assignEventHandlers() {
        EventBus.$off("top-navigation-started", this.handlePageNavigation);
        EventBus.$on("top-navigation-started", this.handlePageNavigation);
    }

    public removeEventHandlers() {
        EventBus.$off("top-navigation-started", this.handlePageNavigation);
    }

    public async save(): Promise<boolean> {
        const result = await this.showSaveDialogAsNeeded();
        return result;
    }

    public async changePassword() {
        this.showChild = true;
        this.changePasswordViewModel = this.createChangePasswordViewModel();
        let hasError = false;
        do {
            const result = await this.changePasswordViewModel!.interact();
            if (result === "cancel") {
                this.changePasswordViewModel = null;
                this.showChild = false;
                break;
            }
            try {
                this.errorMessage = null;
                this.isLoading = true;
                const args = {
                    userID: this.userId,
                    newPassword: this.changePasswordViewModel!.newPassword,
                    shouldValidateOriginalPassword: this.changePasswordViewModel!.shouldValidateOriginalPassword,
                };

                this.statusMessage = this.loadingMessage;
                const response = await WebHelper.postJsonData("/api/Company/CompanyUser/ChangePassword", args);
                const handleResult = WebHelper.handleServerProblemDetails(response);
                if (handleResult.hasError) {
                    this.errorMessage = handleResult.errorMessage;
                    hasError = true;
                    continue;
                }
                this.changePasswordViewModel = null;
                this.showChild = false;
                break;
            } catch {
                this.errorMessage = Utils.ServerErrorMessage;
            } finally {
                this.statusMessage = "";
                this.isLoading = false;
            }
        } while (hasError);
    }

    public async back() {
        const result = await this.showSaveDialogAsNeeded();
        if (!result) {
            return;
        }
        this.changePasswordViewModel = null;
        this.showChild = false;
    }

    public async showSaveDialogAsNeeded() {
        if (this.showChild) {
            // skip prompt for password
            return true;
        }
        if (!this.hasChanges()) {
            return true;
        }
        this.isSaveDialogVisible = true;
        this.confirmViewModel = new AsyncInteractionViewModel();
        const result = await this.confirmViewModel.interact();
        this.isSaveDialogVisible = false;
        this.confirmViewModel = null;
        if (result === "cancel") {
            return false;
        } else if (result === "accept") {
            await this.saveAccount();
            return true;
        } else if (result === "continue") {
            return true;
        }
        return true;
    }

    protected update(dto: ICompanyUserModel) {
        this.userName = dto.userName;
        this.firstName = dto.firstName;
        this.lastName = dto.lastName;
        this.email = dto.email;
        this.email2 = dto.email2;
        this.formattedPhone = Utils.formatPhone(dto.phone);
        this.formattedPhone2 = Utils.formatPhone(dto.phone2);
        this.recoveryEmail = dto.recoveryEmail;
        this.formattedRecoveryPhone = Utils.formatPhone(dto.recoveryPhone);
        this.companyId = dto.companyId;
        this.dealerId = dto.dealerId;
        this.userLevelId = dto.userLevelId;
        this.enabled = dto.enabled;
        //Do not allow change password for admin
        this.allowChangePassword = this.userLevelId !== UserLevel.Admin;
        this.isLoggedInUser = dto.isLoggedInUser;
        this.billingContact = dto.billingContact;
    }

    protected toDto() {
        const dto: ICompanyUserModel = {
            userId: this.userId,
            userName: this.userName,
            userLevelId: this.userLevelId,
            companyId: this.companyId,
            dealerId: this.dealerId,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            email2: this.email2,
            phone: Utils.stripPhoneFormatting(this.formattedPhone),
            phone2: Utils.stripPhoneFormatting(this.formattedPhone2),
            enabled: this.enabled,
            recoveryEmail: this.recoveryEmail,
            recoveryPhone: Utils.stripPhoneFormatting(this.formattedRecoveryPhone),
            shouldHideLoginField: this.shouldHideLoginFields,
            createdOn: null,
            updatedOn: null,
            createdBy: null,
            updatedBy: null,
            isLoggedInUser: this.isLoggedInUser,
            billingContact: this.billingContact,
        };

        return dto;
    }
}
