var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{attrs:{"fluid":""}},[(!_vm.taxSettings)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"red","indeterminate":""}})],1):_vm._e(),_c('v-form',{ref:"form",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[(_vm.taxSettings)?_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('text-heading',[_vm._v("Store Taxes")])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('c-toolbar-btn',{staticStyle:{"padding-right":"24px"},attrs:{"label":"Save","fab":"","icon":"$iconSave","color":"#347E38","loading":_vm.saving,"disabled":!_vm.isValid},on:{"click":_vm.save}})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v(" Default Product Tax Type ")])])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tax-type-selection',{attrs:{"enable-custom":"","value":{
                                    taxType: _vm.taxSettings.selectedTaxType,
                                    taxRate: _vm.taxSettings.taxRate,
                                }},on:{"input":_vm.selectedTaxSettingsChanged}})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v("Shipping")])])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tax-type-selection',{attrs:{"enable-custom":"","value":{
                                    taxType: _vm.taxSettings.shippingTaxMethod,
                                    taxRate: _vm.taxSettings.shippingTaxRate,
                                }},on:{"input":_vm.selectedShippingTaxSettingsChanged}})],1)],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"heading font-weight-bold",staticStyle:{"padding-top":"20px"}},[_vm._v("Handling")])])],1),_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tax-type-selection',{attrs:{"enable-custom":"","value":{
                                    taxType: _vm.taxSettings.handlingTaxMethod,
                                    taxRate: _vm.taxSettings.handlingTaxRate,
                                }},on:{"input":_vm.selectedHandlingTaxSettingsChanged}})],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }