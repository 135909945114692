


























import Component, { mixins } from "vue-class-component";
import UserInfoMixin from "@/components/page/UserInfoMixin";
import EditOrderNotesViewModel from "@/chipply/manage-orders/EditOrderNotesViewModel";
import { Prop } from "vue-property-decorator";

@Component({})
export default class InternalNotes extends mixins(UserInfoMixin) {
    @Prop()
    public orderId!: number;
    @Prop()
    public vm!: EditOrderNotesViewModel;

    public name = "InternalNotes";

    public async created() {
        if (!this.orderId) {
            return;
        }
        await this.vm.getInternalNotes();
    }
}
