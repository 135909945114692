import {
    AsyncInteractionWithDataViewModel,
    ProcessItemType,
    SimpleAsyncInteractionViewModel,
    UrlUtils,
    WebHelper,
} from "chipply-common";
import _ from "lodash";
import SortProperty from "../../data-access/SortProperty";
import { StoreArtworkItemViewModel } from "./StoreArtworkItemViewModel";
import {
    ICreateArtworkArgs,
    IListStoreArtworkArgs,
    IListStoreArtworkFilters,
    IListStoreArtworkResults,
} from "./IStoreArtworkDto";
import { SearchStoreArtworksViewModel } from "./SearchStoreArtworksViewModel";
import IPageReferrerSettings from "@/chipply/interface/IPageReferrerSettings";
import { EventBus } from "@/chipply/EventBus";
import PageState from "@/chipply/PageState";

import { IListDataTableHeaderItem, ListPageViewModel } from "@/chipply/view-model/ListPageViewModel";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { ICopyEventArtworkArgs } from "@/chipply/event/artworks/ICopyEventArtworkArgs";
import { IArtworkCopyArgs } from "@/chipply/event/artworks/IArtworkCopyArgs";

export class EditStoreArtworksViewModel extends ListPageViewModel {
    public dealerId!: number;
    public storeId!: number;

    public dataTableItemKey = "id";
    public static STATE_KEY = "editArtworksState";
    public isTemplateStore = false;
    public isRequestorStore = false;
    public items: StoreArtworkItemViewModel[] = [];
    public deleteViewModel: SimpleAsyncInteractionViewModel | null = null;
    public addViewModel: AsyncInteractionWithDataViewModel<ProcessItemType> | null = null;
    public readonly loadingErrorMessage = "An error occurred while loading the data.";
    public importArtworkDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public filters: IListStoreArtworkFilters = this.getDefaultFilters();

    //<editor-fold desc="Options and Headers">
    public addArtworkOptions = [
        { text: "Artwork / Logo", code: "Art", value: ProcessItemType.ArtworkLogo },
        { text: "Option - Name (Text Field)", code: "Name", value: ProcessItemType.OptionName },
        { text: "Option - Number (Text Field)", code: "Number", value: ProcessItemType.OptionNumber },
        { text: "Option - Option Group (Drop Down List)", code: "Option", value: ProcessItemType.OptionGroup },
        {
            text: "Option - Exclusive Option Group (Drop Down List)",
            code: "Exclusive",
            value: ProcessItemType.OptionExclusiveGroup,
        },
    ];
    public dataTableHeaders: IListDataTableHeaderItem[] = [
        { text: "", sortable: false, value: "img", width: "2%" },
        { text: "Type", sortable: true, value: "artType", width: "5%" },
        { text: "Type Code", sortable: true, value: "typeCode", width: "5%" },
        { text: "Name", sortable: true, value: "artName", width: "30%" },
        { text: "Process Count", sortable: false, value: "processItemsCount", width: "2%" },
        { text: "", sortable: false, value: "placeholder", width: "30%" },
        { text: "", sortable: false, value: "copyBtn", width: "2%" },
        { text: "", sortable: false, value: "editBtn", width: "2%" },
        { text: "", sortable: false, value: "deleteBtn", width: "2%" },
        { text: "", sortable: false, value: "placeholder", width: "10%" },
    ];
    //</editor-fold>

    public getDefaultFilters() {
        const filters: IListStoreArtworkFilters = {
            organizationID: null,
            storeIDToExclude: this.storeId,
            storeID: null,
            storeName: null,
            saleOrder: null,
            artName: "",
            storeOpenDateFrom: null,
            storeOpenDateTo: null,
            storeCloseDateFrom: null,
            storeCloseDateTo: null,
            externalId: null,
            artworkVendor: null,
        };

        return filters;
    }

    public buildFilters(): IListStoreArtworkFilters {
        this.filters.storeIDToExclude = this.storeId;
        return this.filters;
    }
    public pageState!: PageState;
    public get pagination() {
        return this._pagination;
    }
    public set pagination(value: IVuetifyTableOptions) {
        if (!this.isPaginationEquals(this._pagination, value)) {
            this._pagination = value;
            this.refreshData();
        }
    }
    protected _pagination: IVuetifyTableOptions = {
        itemsPerPage: 20,
        page: 1,
        sortDesc: [],
        sortBy: [],
    };
    public constructor() {
        super();
        this.pageState = new PageState();
        EventBus.$on("refresh-store-artworks", () => {
            this.refreshData(true);
        });
        EventBus.$on("copy-artwork", this.copyArtwork);
    }

    public dispose() {
        EventBus.$off("copy-artwork", this.copyArtwork);
    }

    public getPageState(): IPageReferrerSettings {
        return {
            description: "EditArtworks",
            filters: {},
            pagination: this.pagination,
            url: location.pathname + "?readstate=1",
        };
    }

    public getPageStateUrl(pageUrl: string) {
        const serializedValue = JSON.stringify(this.getPageState());
        const initialParameterJoin = pageUrl.indexOf("?") === -1 ? "?" : "&";
        let pageStateUrl = `${pageUrl}${initialParameterJoin}&eventid=${this.storeId}&v=${Math.floor(
            Math.random() * 100000
        )}`;

        const urlParams = new URLSearchParams(window.location.search);
        const editProcessesKey = EditStoreArtworksViewModel.STATE_KEY;
        if (urlParams.has(editProcessesKey)) {
            pageStateUrl += `?${editProcessesKey}=${encodeURIComponent(urlParams.get(editProcessesKey) as string)}`;
        }

        pageStateUrl += `&${SearchStoreArtworksViewModel.STATE_KEY}=${encodeURIComponent(serializedValue)}`;
        return pageStateUrl;
    }

    public buildArgs(): IListStoreArtworkArgs {
        const filters: IListStoreArtworkFilters = {
            storeIDToExclude: null,
            storeID: this.storeId,
            storeName: null,
            saleOrder: null,
            artName: "",
            storeOpenDateFrom: null,
            storeOpenDateTo: null,
            storeCloseDateFrom: null,
            storeCloseDateTo: null,
            externalId: null,
            artworkVendor: null,
        };

        const args: IListStoreArtworkArgs = {
            filters: filters,
            pageState: this.pageState,
            storeID: this.storeId,
            sortProperties: [new SortProperty(this.pagination.sortBy[0], this.pagination.sortDesc[0])],
            shouldReturnOrganizations: false,
            shouldReturnProcessItemsCount: true,
        };
        return args;
    }

    public async onStoreIdChanged(): Promise<void> {
        await Promise.all([this.getStoreInfo(), this.refreshData(true)]);
    }

    public async getStoreInfo(): Promise<void> {
        const baseUrl = `/api/Events/GetInfo/${this.storeId}`;
        const results = await WebHelper.getJsonData(baseUrl);
        this.isRequestorStore = results.isRequestorStore;
        this.isTemplateStore = results.isTemplateStore;
    }

    public async list(isNewSearch?: boolean): Promise<any> {
        await this.refreshData(isNewSearch);
    }

    public async refreshData(isNewSearch?: boolean): Promise<void> {
        if (this.dataTableLoading) {
            return;
        }
        if (!this.storeId) return;

        if (isNewSearch) {
            this.items = [];
            this.pageState.reset();
        }
        if (this.pageState.wasLastPageSelected) {
            return;
        }
        this.dataTableLoading = true;

        const pageUrl = "/ng/edit-store-artworks.html";
        history.replaceState(this.getPageState(), "", this.getPageStateUrl(pageUrl));
        await this.listArtworks(this.buildArgs());
    }
    protected async listArtworks(listArgs: IListStoreArtworkArgs): Promise<void> {
        const baseUrl = `/api/Store/Artworks`;
        try {
            this.statusMessage = this.loadingMessage;
            this.dataTableLoading = true;
            const jsonResult = await WebHelper.postJsonData(baseUrl, listArgs);
            const listResult = JSON.parse(jsonResult) as IListStoreArtworkResults;
            const items: StoreArtworkItemViewModel[] = [];
            listResult.artworks.forEach((artwork) => {
                const itemViewModel = new StoreArtworkItemViewModel(artwork);
                items.push(itemViewModel);
            });
            this.items.push(...items);
            this.pageState.next(listResult.artworks.length);
            this.serverItemsLength = listResult.totalRows;
        } catch {
            this.errorMessage = this.loadingErrorMessage;
        } finally {
            this.dataTableLoading = false;
        }
    }

    public async deleteArtwork(item: StoreArtworkItemViewModel) {
        this.deleteViewModel = new SimpleAsyncInteractionViewModel();
        this.deleteViewModel.text = `Are you certain you want to delete this item: ${item.name}? All related items will be removed.`;
        const result = await this.deleteViewModel.interact();
        this.deleteViewModel = null;
        if (result === "cancel") {
            return;
        }
        try {
            const artworkId = item.id;
            this.dataTableLoading = true;
            await WebHelper.postJsonData(`/api/Store/DeleteArtwork/${this.storeId}/${artworkId}`);
            this.items = [];
            await this.listArtworks(this.buildArgs());
        } finally {
            this.dataTableLoading = false;
        }
    }

    public async addArtwork(): Promise<void> {
        const addViewModel = new AsyncInteractionWithDataViewModel<ProcessItemType>();
        addViewModel.data = ProcessItemType.ArtworkLogo;
        this.addViewModel = addViewModel;

        const result = await this.addViewModel!.interact();
        if (result.result === "cancel" || this.addViewModel.data == null) {
            this.addViewModel = null;
            return;
        }

        const data = this.addViewModel.data;
        this.addViewModel = null;
        const baseUrl = `/api/Store/CreateEventArtwork/${this.storeId}`;
        try {
            this.statusMessage = this.savingMessage;
            this.dataTableLoading = true;
            const createArgs: ICreateArtworkArgs = {
                eventId: this.storeId,
                type: data!,
                name: "",
            };
            const resultsText = await WebHelper.postJsonData(baseUrl, createArgs);
            const newArtworkId = Number.parseInt(resultsText);
            window.location.href = UrlUtils.getVersionedLink(
                `/ng/edit-artwork.html?eventid=${this.storeId}&artworkId=${newArtworkId}`
            );
        } catch {
            this.errorMessage = this.loadingErrorMessage;
        } finally {
            this.dataTableLoading = false;
        }
    }

    public async editArtwork(item: StoreArtworkItemViewModel) {
        try {
            window.location.href = UrlUtils.getVersionedLink(
                `/ng/edit-artwork.html?eventid=${this.storeId}&artworkId=${item.id}`
            );
        } catch (e) {
            console.log(e);
        }
    }
    public copyArtwork = async (args: IArtworkCopyArgs) => {
        try {
            const dialogViewModel = new SimpleAsyncInteractionViewModel();
            dialogViewModel.text = `Are you sure you want to ${args.isCopy ? "copy" : "import"} artwork: ${
                args.artworkName
            }?`;
            this.importArtworkDialogViewModel = dialogViewModel;
            const dialogResult = await this.importArtworkDialogViewModel.interact();
            this.importArtworkDialogViewModel = null;
            if (dialogResult === "cancel") {
                return;
            }

            this.dataTableLoading = true;
            this.statusMessage = `Copying artwork...`;
            const copyArtworkArgs: ICopyEventArtworkArgs = {
                canCopyExternalVariationIds: args.canCopyExternalVariationIds === true ? true : false,
                copyFromEventArtworkId: args.artworkId,
                copyToEventId: this.storeId,
                multipleArtworks: false,
                artworkNamePrefix: "",
            };
            await WebHelper.postJsonData(`/api/Store/CopyArtwork/${this.storeId}`, copyArtworkArgs);
        } finally {
            this.dataTableLoading = false;
            await this.refreshData(true);
        }
    };
    public openImagePreview(imageUrl: string) {
        window.open(imageUrl, "_blank");
    }
}
