






















































import Component from "vue-class-component";
import chipplyIcons from "@/chipply/ImportIcons";
import CustomerEmailsViewModel from "@/chipply/emails/CustomerEmailsViewModel";
import { Utils } from "chipply-common";
import ListLayout from "@/components/layout/ListLayout.vue";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { EventBus } from "@/chipply/EventBus";
import EmailViewer from "./EmailViewer.vue";

@Component({
    components: {
        ListLayout,
        EmailViewer,
    },
})
export default class CustomerEmails extends Vue {
    public name = "CustomerEmails";
    public chipplyIcons = chipplyIcons;
    public Utils = Utils;

    public vm = new CustomerEmailsViewModel();

    @Prop()
    public storeId!: number;

    @Prop()
    public ecomOrderId!: number;

    @Prop()
    public isEmailsFromSingleOrder!: boolean;

    @Prop()
    public catalogBatchId!: number;

    // @ts-ignore
    public $refs!: {
        iframeRef: HTMLIFrameElement;
    };

    public get hasDetailsViewModel() {
        return this.vm && this.vm.selectedItem != null;
    }

    @Watch("storeId")
    public async onStoreIdChanged() {
        await this.initial();
    }

    @Watch("ecomOrderId")
    public async onEcomOrderIdChanged() {
        await this.initial();
    }

    private async initial() {
        if (!(this.storeId > 0 || this.ecomOrderId > 0 || this.catalogBatchId > 0)) {
            return;
        }
        if (this.storeId > 0) {
            this.vm.storeId = this.storeId;
        }
        if (this.ecomOrderId > 0) {
            this.vm.ecomOrderId = this.ecomOrderId;
        }
        if (this.catalogBatchId > 0) {
            this.vm.catalogBatchId = this.catalogBatchId;
        }

        this.vm.isEmailsFromSingleOrder = this.isEmailsFromSingleOrder;
        await this.vm.list(true);
    }

    public async created() {
        await this.initial();
        EventBus.$on("customer-emails-view-details", this.handleCustomerEmailsViewDetails);
    }

    public handleCustomerEmailsViewDetails = () => {
        setTimeout(() => {
            const iframeRef = this.$refs.iframeRef;
            if (iframeRef.contentWindow) {
                var doc = iframeRef.contentWindow.document;
                if (this.vm && this.vm.emailDetailsViewModel && this.vm.emailDetailsViewModel.html) {
                    const html = this.vm.emailDetailsViewModel.html;
                    doc.open().write(html);
                    doc.close();
                } else {
                    const defaultHtml = `<html><body></body></html>`;
                    doc.open().write(defaultHtml);
                    doc.close();
                }
            }
        });
    };
}
