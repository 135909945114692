import { CustomerDistributionTypeEnum } from "@/chipply/event/CustomerDistributionTypeEnum";
import IOrganizationBranchEditModel from "@/chipply/organization/IOrganizationBranchEditModel";
import { IStoreSettingsCustomerDistributionModel } from "@/chipply/store/settings/IStoreSettingsCustomerDistributionModel";

export class CustomerDistributionViewModel {
    public type: CustomerDistributionTypeEnum | null = null;
    public singleOrgPickupBranchId = 0;
    public orgPickupBranchIds: number[] = [];
    public customEcomDisplay = "";
    public branchModels: IOrganizationBranchEditModel[] = [];

    public constructor(model?: IStoreSettingsCustomerDistributionModel) {
        if (!model) {
            return;
        }

        this.customEcomDisplay = model.customEcomDisplay;
        if (model.pickupBranchIds.length > 1) {
            this.type = CustomerDistributionTypeEnum.Multiple;
            this.orgPickupBranchIds = model.pickupBranchIds;
            this.branchModels = model.branchModels!;
        } else {
            this.type = CustomerDistributionTypeEnum.Single;
            this.singleOrgPickupBranchId = model.pickupBranchIds[0];
            this.branchModels = model.branchModels!;
        }
    }

    public toModel(): IStoreSettingsCustomerDistributionModel {
        let pickupBranchIds = this.orgPickupBranchIds;
        if (this.type === CustomerDistributionTypeEnum.Single) {
            pickupBranchIds = [];
            pickupBranchIds.push(this.singleOrgPickupBranchId);
        }

        return {
            pickupBranchIds,
            customEcomDisplay: this.customEcomDisplay,
        };
    }

    public customerDistributionChanged = () => {
        this.customEcomDisplay = "";
        this.orgPickupBranchIds = [];
        this.singleOrgPickupBranchId = 0;
        this.branchModels = [];
    };
}
