



























































































import chipplyIcons from "@/chipply/ImportIcons";
import IDealerSizeUpchargesDto from "@/chipply/interface/i-dealer-size-upcharges-dto";
import { WebHelper } from "chipply-common";
import CToolbarBtn from "../ui/CToolbarBtn.vue";
import Vue from "vue";
import Component from "vue-class-component";
import TextHeading from "@/components/utility/TextHeading.vue";
@Component({
    components: { TextHeading, CToolbarBtn },
})
export default class DealerSizeUpchargeGrid extends Vue {
    public chipplyIcons = chipplyIcons;

    public name = "DealerSizeUpchargeGrid";
    public sizeUpcharges!: IDealerSizeUpchargesDto;
    public saving!: boolean;
    public dealerId!: number | undefined;
    // @ts-ignore
    public $refs: {
        errorMessage: HTMLDivElement;
    };

    public data() {
        return {
            errorMessage: "",
            headers: [
                { text: " ", sortable: false },
                { text: "Size Name", value: "sizeName", sortable: false },
                {
                    text: "Additional Charge",
                    value: "additionalCharge",
                    sortable: false,
                },
            ],
            isFormValid: true,
            saving: false,
            sizeUpcharges: undefined,
        };
    }

    public async created() {
        const resultJson = await WebHelper.getJsonData(`/api/Dealer/DealerSizeUpcharges`, true);
        const serverSizeUpcharges: IDealerSizeUpchargesDto = resultJson;
        this.sizeUpcharges = serverSizeUpcharges;
    }

    protected requireValue(value: string) {
        if (value.length > 0) {
            return true;
        }
        return "You must enter a value";
    }

    protected validateAdditionalCharge(value: string) {
        if (value.split(".").length > 2) {
            return "Please enter a valid number";
        }
        if (!isNaN(Number.parseFloat(value))) {
            return true;
        }
        return "Please enter a valid number";
    }

    protected additionalChargeChanged(e: KeyboardEvent) {
        if (e.key === ".") {
            return;
        }
        if (!isNaN(Number.parseInt(e.key, 10))) {
            return;
        }

        e.preventDefault();
    }

    protected async save() {
        this.saving = true;
        try {
            const resultJson = await WebHelper.postJsonData(`/api/Dealer/DealerSizeUpcharges`, this.sizeUpcharges);
            const serverSizeUpcharges: IDealerSizeUpchargesDto = JSON.parse(resultJson);
            this.sizeUpcharges = serverSizeUpcharges;
            this.saving = false;
        } catch (e) {
            this.$refs.errorMessage.style.display = "";
            this.$data.errorMessage = e.message;
            this.$data.saving = false;
        }
    }
    protected addNewUpcharge() {
        this.sizeUpcharges.sizeUpcharges.splice(0, 0, {
            upchargeID: null,
            sizeName: "",
            additionalCharge: "",
        });
    }

    protected deleteRow(index: number) {
        this.sizeUpcharges.sizeUpcharges.splice(index, 1);
    }
}
