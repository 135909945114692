import { ISelectableListItem } from "@/chipply/view-model/ISelectableListItem";
import { AsyncInteractionViewModel, Utils } from "chipply-common";
import { IStoreArtworkDto } from "./IStoreArtworkDto";
import { IArtworkCopyArgs } from "@/chipply/event/artworks/IArtworkCopyArgs";
import { EventBus } from "@/chipply/EventBus";

export class StoreArtworkItemViewModel extends AsyncInteractionViewModel implements ISelectableListItem {
    id = 0;
    typeCode = "";
    dealerID = 0;
    artType = "";
    name = "";
    storeID = 0;
    storeName = "";
    orgID = 0;
    orgName = "";
    maxVariationName = "";
    maxVariationImage = "";
    maxImageUrl = "";
    processItemsCount = 0;
    disabled = false;
    selected = false;
    isExpanded = false;
    get enabled(): boolean {
        return !this.disabled;
    }
    set enabled(value: boolean) {
        if (this.disabled != !value) {
            this.disabled = !value;
        }
    }

    constructor(dto?: IStoreArtworkDto) {
        super();
        if (Utils.isNullOrUndefined(dto)) {
            return;
        }
        this.id = dto!.storeArtworkID;
        this.typeCode = dto!.typeCode;
        this.dealerID = dto!.dealerID;
        this.artType = dto!.artType;
        this.name = dto!.artName;
        this.storeID = dto!.storeID;
        this.storeName = dto!.storeName;
        this.orgID = dto!.orgID;
        this.orgName = dto!.orgName;
        this.maxVariationName = dto!.maxVariationName;
        this.maxVariationImage = dto!.maxVariationImage;
        this.processItemsCount = dto!.processItemsCount;
        this.maxImageUrl = dto!.maxImageUrl;
    }

    public copy = () => {
        const args: IArtworkCopyArgs = {
            artworkId: this.id,
            artworkName: this.name,
            isCopy: true,
        };
        EventBus.$emit("copy-artwork", args);
    };
}
