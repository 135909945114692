import { render, staticRenderFns } from "./EditEcertCard.vue?vue&type=template&id=05b6e016&scoped=true"
import script from "./EditEcertCard.vue?vue&type=script&lang=ts"
export * from "./EditEcertCard.vue?vue&type=script&lang=ts"
import style0 from "./EditEcertCard.vue?vue&type=style&index=0&id=05b6e016&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05b6e016",
  null
  
)

export default component.exports