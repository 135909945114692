import OrderItemOptionListItemViewModel from "@/chipply/ecom-orders/OrderItemOptionListItemViewModel";
import { ITextValue } from "chipply-common";
import { IOrderItemOption, ProcessItemType, Utils } from "chipply-common";

export default class OrderItemOptionViewModel {
    public eventProcessItemId!: number;
    public eventProcessOptionId!: number | undefined;
    public isRequired!: boolean;
    public optionName!: string;
    public optionDisplay!: string;
    public optionValueDisplay!: string | null;
    public optionValue!: string;
    public cost!: number;
    public optionsList!: OrderItemOptionListItemViewModel[];
    public orderItemAttributeId!: number;
    public processTypeId!: ProcessItemType;

    constructor(option: IOrderItemOption) {
        Object.assign(this, option);

        this.optionDisplay =
            this.cost === 0 || this.isRequired
                ? this.optionName
                : `${this.optionName} (+${Utils.getCurrencyValue(this.cost, false, true)})`;

        const optionsList = [];
        for (const optionItem of this.optionsList) {
            optionsList.push(new OrderItemOptionListItemViewModel(optionItem));
        }
        this.optionsList = optionsList;

        if (this.eventProcessOptionId) {
            for (const optionItem of this.optionsList) {
                if (optionItem.optionId !== this.eventProcessOptionId) {
                    continue;
                }
                this.optionValueDisplay = optionItem.optionName;
            }
        } else if (this.optionValue) {
            this.optionValueDisplay = this.optionValue;
        }
    }

    public toModel(): IOrderItemOption {
        const optionsList = [];
        for (const option of this.optionsList) {
            optionsList.push(option.toModel());
        }

        return {
            cost: this.cost,
            eventProcessItemId: this.eventProcessItemId,
            eventProcessOptionId: this.eventProcessOptionId,
            isRequired: this.isRequired,
            optionName: this.optionName,
            optionValue: this.optionValue,
            optionsList,
            orderItemAttributeId: this.orderItemAttributeId,
            processTypeId: this.processTypeId,
        };
    }
}
