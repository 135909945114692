import {
    AsyncInteractionViewModel,
    AsyncInteractionWithDataViewModel,
    SimpleAsyncInteractionViewModel,
    Utils,
    WebHelper,
} from "chipply-common";
import IProductDetailModel from "@/chipply/products/edit/IProductDetailModel";
import { PageViewModel } from "@/chipply/view-model/PageViewModel";
import { VendorDto } from "@/chipply/vendor/VendorDto";
import EditProductDetailSizesViewModel from "./EditProductDetailSizesViewModel";
import EditProductDetailColorsViewModel from "./EditProductDetailColorsViewModel";
import ITreeItem from "@/chipply/interface/i-tree-item";
import ChipplyConstants from "@/chipply/ChipplyConstants";
import IProcessCost from "@/chipply/process/IProcessCost";
import { EventBus } from "@/chipply/EventBus";
import _ from "lodash";
import Vue from "vue";

export default class EditProductDetailViewModel extends PageViewModel {
    public loading = false;
    public errorMessage = "";
    public isHeaderVisible = true;
    public title = "Manage Product";

    //public readonly loadingErrorMessage = "An error ocurred while loading the data.";
    public statusMessage = this.loadingMessage;
    public showChild = false;

    public categories: ITreeItem[] = [];
    public categoryIds: number[] = [];

    public showEventProductColorSizeDialog = false;

    public showEventProductColorsDialog = false;
    public editProductColorsViewModel: EditProductDetailColorsViewModel | null = null;

    public showEventProductSizesDialog = false;
    public editProductSizesViewModel: EditProductDetailSizesViewModel | null = null;

    public addVendorDialogViewModel: AsyncInteractionWithDataViewModel<string> | null = null;

    public eventId = 0;
    public productId = 0;
    public baseProductId = 0;

    public enabled = false;
    public required = false;
    public hidePersonalization = false;
    public hideVendor = false;
    public hideStyle = false;

    public vendorId = 0;
    public vendorAutoCompletePreload = true;
    public vendorDummyFilter = false;

    public sku = "";
    public productName = "";
    public minimumQtyLimit = 1;
    public maximumQtyLimit = 10000;
    public productCost = 0;
    public msrp = 0;
    public productPrice = 0;
    public processPrice = 0;
    public combinedPrice = 0;
    public fundraisingAmount = 0;
    public fundraisingPercentDisplay = "0";
    public sellPrice = 0;
    public processCost: IProcessCost = {
        artworkAndRequiredOptionsPrice: 0,
        artworkPrice: 0,
        requiredOptionsPrice: 0,
    };
    public description = "";
    public shortDescription = "";
    public sizeChart = "";
    public shipInstructions = "";

    public weight = 0;
    public isAdminUser = false;

    public orderCount = 0;
    public hasChildren = false;

    public dealerID = 0;
    public dealerName = "";
    public saveDisabled = false;
    public productInfoButtonsDisabled = false;
    //Used as placeholder to notify parent.
    public dummyInteractionViewModel = new AsyncInteractionViewModel();
    public saveConfirmViewModel: AsyncInteractionViewModel | null = null;
    public originalProductModel: IProductDetailModel | null = null;

    public originalFormattedDescription = "";
    public originalFormattedShortDescription = "";
    public originalFormattedSizeChart = "";
    public originalFormattedShipInstructions = "";
    public isChipplyProduct = false;

    constructor() {
        super();
    }

    public calculateTitle(): string {
        if (this.showChild) {
            if (this.editProductColorsViewModel != null) {
                return this.editProductColorsViewModel.title;
            }
            if (this.editProductSizesViewModel != null) {
                return this.editProductSizesViewModel.title;
            }
        }
        return this.title;
    }

    public get isSaveVisible() {
        return !this.showEventProductColorSizeDialog;
    }

    public canSaveCheck() {
        if (this.sku.length === 0 || this.productName.length === 0 || !this.vendorId) {
            this.saveDisabled = true;
            return;
        }
        if (this.minimumQtyLimit <= 0 || !this.minimumQtyLimit) {
            this.saveDisabled = true;
            return;
        }
        if (this.maximumQtyLimit > 15000 || !this.maximumQtyLimit || this.minimumQtyLimit <= 0) {
            this.saveDisabled = true;
            return;
        }
        this.saveDisabled = false;
    }

    public toModel() {
        return {
            eventId: this.eventId,
            productId: this.productId,
            baseProductId: this.baseProductId,
            isChipplyProduct: this.isChipplyProduct,

            enabled: this.enabled,
            required: this.required,
            hidePersonalization: this.hidePersonalization,
            hideVendor: this.hideVendor,
            hideStyle: this.hideStyle,

            vendorId: this.vendorId,

            sku: this.sku,
            productName: this.productName,
            minimumQtyLimit: this.minimumQtyLimit,
            maximumQtyLimit: this.maximumQtyLimit,
            productCost: this.productCost,
            msrp: this.msrp,
            productPrice: this.productPrice,
            processPrice: this.processPrice,
            combinedPrice: this.combinedPrice,
            fundraisingAmount: this.fundraisingAmount,
            fundraisingPercentage: this.fundraisingPercentDisplay,
            sellPrice: this.sellPrice,

            description: this.description,
            shortDescription: this.shortDescription,
            sizeChart: this.sizeChart,
            shipInstructions: this.shipInstructions,

            weight: this.weight,
            isAdminUser: this.isAdminUser,
            categoryIds: this.categoryIds,
        } as IProductDetailModel;
    }

    public async subscribeCloseColorSizeGridEvent() {
        EventBus.$on("event-product-color-size-grid-close-action", this.closeEventProductSizeDialog);
    }

    public async unsubscribeCloseColorSizeGridEvent() {
        EventBus.$off("event-product-color-size-grid-close-action", this.closeEventProductSizeDialog);
    }

    public async back(needConfirmation = false) {
        if (!this.showChild) {
            const shouldClose = await this.saveConfirm();
            if (shouldClose) {
                this.dummyInteractionViewModel.done("cancel");
            }
        } else {
            if (this.showEventProductColorSizeDialog) {
                if (needConfirmation) {
                    EventBus.$emit("event-product-color-size-grid-save-confirm");
                } else {
                    this.closeEventProductSizeDialog();
                }
            }
            if (this.showEventProductColorsDialog) {
                const shouldClose = needConfirmation ? await this.editProductColorsViewModel?.saveConfirm() : true;
                if (shouldClose) {
                    this.showEventProductColorsDialog = false;
                    this.editProductColorsViewModel = null;
                    this.showChild = false;
                }
            }
            if (this.showEventProductSizesDialog) {
                const shouldClose = needConfirmation ? await this.editProductSizesViewModel?.saveConfirm() : true;
                if (shouldClose) {
                    this.showEventProductSizesDialog = false;
                    this.editProductSizesViewModel = null;
                    this.showChild = false;
                }
            }
        }
    }

    private closeEventProductSizeDialog = () => {
        this.showEventProductColorSizeDialog = false;
        this.showChild = false;
    };

    private async saveConfirm() {
        if (!this.hasValidModification()) {
            return true;
        }

        this.saveConfirmViewModel = new AsyncInteractionViewModel();
        const result = await this.saveConfirmViewModel.interact();
        this.saveConfirmViewModel = null;
        if (result === "accept") {
            await this.save();
            return true;
        }
        if (result === "continue") {
            return true;
        }

        return false;
    }

    private hasValidModification() {
        const newModel = this.toModel() as any;
        const originalModel = this.originalProductModel as any;
        if (originalModel == null) return !this.saveDisabled;

        const simplePropertyList = [
            "baseProductId",
            "enabled",
            "required",
            "hidePersonalization",
            "hideVendor",
            "hideStyle",
            "vendorId",
            "sku",
            "productName",
            "minimumQtyLimit",
            "maximumQtyLimit",
            "productCost",
            "msrp",
            "productPrice",
            "processPrice",
            "combinedPrice",
            "fundraisingAmount",
            "sellPrice",
            "weight",
            "categoryIds",
        ];
        const anySimplePropertyDifference = simplePropertyList.some((property) => {
            const recognizeAsEqual =
                _.isEqual(originalModel[property], newModel[property]) ||
                (originalModel[property] &&
                    newModel[property] &&
                    _.isEqual(originalModel[property].toString(), newModel[property].toString()));

            if (
                !recognizeAsEqual &&
                property == "weight" &&
                originalModel[property] == null &&
                newModel[property] == 0
            ) {
                return false;
            }
            return !recognizeAsEqual;
        });

        if (anySimplePropertyDifference) return true;

        const htmlEditorPropertyDictionary: { [key: string]: any } = {
            description: this.originalFormattedDescription,
            shortDescription: this.originalFormattedShortDescription,
            sizeChart: this.originalFormattedSizeChart,
            shipInstructions: this.originalFormattedShipInstructions,
        };

        const anyHtmlDifference = Object.keys(htmlEditorPropertyDictionary).some((key) => {
            const originalValue = htmlEditorPropertyDictionary[key];
            const newValue = newModel[key];

            if (originalValue == newValue) return false;
            return true;
        });
        return anyHtmlDifference;
    }

    public sellPriceChanged = (newValue: number) => {
        this.sellPrice = newValue;
        const fundraisingDollars = this.sellPrice - this.productPrice - this.processPrice;
        this.fundraisingAmount = fundraisingDollars;
        const fundraisingPercent = fundraisingDollars / (this.productPrice + this.processPrice);
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercent * 100);
    };

    public priceChanged(recalculateFundraisingDollars?: boolean) {
        const combinedPrice = this.productPrice + this.processPrice;
        let fundraisingDollar = this.fundraisingAmount;
        let fundraisingPercent = fundraisingDollar / combinedPrice;
        if (recalculateFundraisingDollars) {
            fundraisingPercent = Utils.getNumberFromString(this.fundraisingPercentDisplay) / 100;
            fundraisingDollar = combinedPrice * fundraisingPercent;
        }

        this.fundraisingAmount = fundraisingDollar;
        this.fundraisingPercentDisplay = Utils.getCurrencyValue(fundraisingPercent * 100);
        this.combinedPrice = combinedPrice;
        this.sellPrice = combinedPrice + fundraisingDollar;
    }

    public hideOptionsChanged = (newValue: boolean) => {
        let processCost = 0;
        if (newValue) {
            processCost = this.processCost.artworkPrice;
        } else {
            processCost = this.processCost.artworkAndRequiredOptionsPrice;
        }

        this.processPrice = processCost;
        this.priceChanged();
    };

    public recalculatePrices = (newValue: string) => {
        this.priceChanged();
    };

    public fundraisingPercentDisplayChanged = (newValue: string) => {
        this.fundraisingPercentDisplay = newValue;
        this.priceChanged(true);
    };

    public showColorSizeGrid() {
        this.showChild = true;
        this.showEventProductColorSizeDialog = true;
        this.showEventProductSizesDialog = false;
        this.showEventProductColorsDialog = false;
    }

    public showManageSizes() {
        const editProductSizesViewModel = new EditProductDetailSizesViewModel();
        editProductSizesViewModel.eventId = this.eventId;
        editProductSizesViewModel.productId = this.productId;
        editProductSizesViewModel.baseProductId = this.baseProductId;
        editProductSizesViewModel.isAdminUser = this.isAdminUser;
        editProductSizesViewModel.title = `Manage Product Sizes - ${this.productName}`;
        editProductSizesViewModel.allowDelete = this.orderCount == 0;
        editProductSizesViewModel.isHeaderVisible = this.isHeaderVisible;
        this.editProductSizesViewModel = editProductSizesViewModel;
        this.showChild = true;
        this.showEventProductSizesDialog = true;
    }

    public showManageColors() {
        const editProductColorsViewModel = new EditProductDetailColorsViewModel();
        editProductColorsViewModel.storeId = this.eventId;
        editProductColorsViewModel.productId = this.productId;
        editProductColorsViewModel.baseProductId = this.baseProductId;
        editProductColorsViewModel.title = `Manage Product Colors - ${this.productName}`;
        editProductColorsViewModel.isHeaderVisible = this.isHeaderVisible;
        editProductColorsViewModel.isAdminUser = this.isAdminUser;
        editProductColorsViewModel.allowDelete = this.orderCount == 0;
        this.editProductColorsViewModel = editProductColorsViewModel;
        this.showChild = true;
        this.showEventProductColorsDialog = true;
    }

    public async save() {
        if (this.showChild) {
            if (this.editProductSizesViewModel) {
                await this.editProductSizesViewModel?.saveProductSizes();
            }
            if (this.editProductColorsViewModel) {
                await this.editProductColorsViewModel?.saveProductColorsFromGrid();
            }
        } else {
            await this.saveProduct();
        }
        return true;
    }

    protected async saveProduct() {
        try {
            this.statusMessage = "Saving...";
            this.loading = true;
            const saveResult = await WebHelper.postJsonData(`/api/Product/SaveDetail`, this.toModel());

            if (this.productId === 0) {
                this.productId = parseInt(saveResult, 10);
            }

            this.originalFormattedDescription = this.description;
            this.originalFormattedShipInstructions = this.shipInstructions;
            this.originalFormattedShortDescription = this.shortDescription;
            this.originalFormattedSizeChart = this.sizeChart;

            await this.getProduct();
            this.productInfoButtonsDisabled = false;
        } finally {
            this.loading = false;
        }
    }

    public async getProduct(): Promise<void> {
        if (this.productId === 0) {
            this.enabled = true;
            this.vendorId = await this.getNoneVendorId();
        } else {
            const baseUrl = `/api/Product/GetDetail?eventId=${this.eventId}&productId=${this.productId}&baseProductId=${this.baseProductId}`;
            try {
                this.statusMessage = this.loadingMessage;
                this.loading = true;
                const results = (await WebHelper.getJsonData(baseUrl)) as IProductDetailModel;
                this.originalProductModel = results;

                if (results) {
                    this.isChipplyProduct = results.isChipplyProduct;
                    this.baseProductId = results.baseProductId ?? 0;
                    this.dealerID = results.dealerId ?? 0;
                    this.enabled = results.enabled == true;
                    this.required = results.required == true;
                    this.hidePersonalization = results.hidePersonalization == true;
                    this.hideVendor = results.hideVendor == true;
                    this.hideStyle = results.hideStyle == true;

                    this.combinedPrice = results.combinedPrice ?? 0;
                    this.fundraisingAmount = results.fundraisingAmount ?? 0;
                    this.productName = results.productName;
                    this.title = `Manage Product - ${this.productName}`;

                    this.vendorId = results.vendorId ?? 0;

                    this.sku = results.sku;
                    this.minimumQtyLimit = results.minimumQtyLimit ?? 0;
                    this.maximumQtyLimit = results.maximumQtyLimit ?? 0;

                    this.productCost = results.productCost ?? 0;
                    this.msrp = results.msrp ?? 0;

                    this.productPrice = results.productPrice ?? 0;
                    this.processPrice = results.processPrice ?? 0;
                    this.combinedPrice = results.combinedPrice ?? 0;

                    this.fundraisingAmount = results.fundraisingAmount ?? 0;
                    this.fundraisingPercentDisplay = Utils.getCurrencyValue(
                        (this.fundraisingAmount / this.combinedPrice) * 100
                    );
                    this.sellPrice = results.sellPrice ?? 0;
                    this.processCost = results.processCost ?? {
                        artworkAndRequiredOptionsPrice: 0,
                        artworkPrice: 0,
                        requiredOptionsPrice: 0,
                    };

                    this.description = results.description;
                    this.shortDescription = results.shortDescription;
                    this.sizeChart = results.sizeChart;
                    this.shipInstructions = results.shipInstructions;

                    this.isAdminUser = results.isAdminUser == true;
                    this.orderCount = results.orderCount ?? 0;
                    this.hasChildren = results.hasChildren == true;
                    this.categoryIds = results.categoryIds;
                    this.weight = results.weight ?? 0;

                    EventBus.$emit("requestEditProductDetailFormatted");
                }
            } catch {
                this.errorMessage = "An error occurred while loading the data.";
            } finally {
                this.loading = false;
            }
        }

        if (this.productName.length === 0 || this.sku.length === 0) {
            this.saveDisabled = true;
            this.productInfoButtonsDisabled = true;
        } else {
            this.saveDisabled = false;
            this.productInfoButtonsDisabled = this.hasChildren;
        }
    }

    public async addVendor() {
        const addVendorDialogViewModel = new AsyncInteractionWithDataViewModel<string>();
        this.addVendorDialogViewModel = addVendorDialogViewModel;
        const results = await addVendorDialogViewModel.interact();
        this.addVendorDialogViewModel = null;
        if (results.result === "accept") {
            try {
                this.loading = true;
                this.statusMessage = "Saving Vendor...";
                const saveResult = await WebHelper.postJson<VendorDto>(
                    `/api/vendor`,
                    this.convertToVendorModel(addVendorDialogViewModel.data!)
                );

                this.vendorAutoCompletePreload = false;
                //Toggle to refresh the vendor list auto complete
                this.vendorDummyFilter = !this.vendorDummyFilter;

                // //Wait for the vendor list to refresh and then set the vendorId
                setTimeout(() => {
                    this.vendorId = saveResult.vendorID;
                    this.vendorAutoCompletePreload = true;
                }, 500);
            } finally {
                this.loading = false;
            }
        }
    }

    protected convertToVendorModel(vendorName: string): VendorDto {
        const dto = new VendorDto();
        dto.vendorID = 0;
        dto.dealerID = this.dealerID;
        dto.dealerName = this.dealerName;
        dto.enabled = true;
        dto.chipplyApproved = this.eventId == ChipplyConstants.CHIPPLY_EVENT_ID ? true : false;
        dto.archived = false;
        dto.hasInventory = false;
        dto.vendorName = vendorName;
        return dto;
    }

    protected async getNoneVendorId(): Promise<number> {
        const baseUrl = "/api/vendor/nonevendor";
        try {
            this.loading = true;
            const results = (await WebHelper.getJsonData(baseUrl)) as { vendorID: number };
            return results.vendorID;
        } catch {
            this.errorMessage = "An error occurred while loading the data.";
            return 0;
        } finally {
            this.loading = false;
        }
    }
}
