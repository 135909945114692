import IArtworkImageModel from "./IArtworkImageModel";
import { ImageReviewViewModel } from "./ImageReviewViewModel";

export default class ArtworkReviewImageViewModel extends ImageReviewViewModel implements IArtworkImageModel {
    eventArtworkId!: number;

    public constructor(model: IArtworkImageModel, hexCode1: string) {
        super();
        Object.assign(this, model);
        this.isArtwork = true;
        this.hexCode1 = hexCode1;
    }
}
