

























































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import CSaveButton from "@/components/ui/CSaveButton.vue";
import EditLayout from "@/components/layout/EditLayout.vue";
import { Autocompletes } from "@/chipply/Autocompletes";
import Validation from "@/validation";
import { EventType } from "@/chipply/event/EventType";
import { ProductSearchConstants } from "@/chipply/products/add/ProductSearchConstants";
import { ProductSearchMode } from "@/chipply/products/add/ProductSearchMode";
import TreeSelector from "@/components/input/TreeSelector.vue";
import CApiAutoComplete from "@/components/ui/CApiAutoComplete.vue";
import { ProductSearchModeSelectorViewModel } from "@/chipply/products/add/ProductSearchModeSelectorViewModel";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import CControlLabel from "@/components/ui/CControlLabel.vue";
import ChipplyConstants from "@/chipply/ChipplyConstants";

@Component({
    components: {
        EditLayout,
        CSaveButton,
        TreeSelector,
        CApiAutoComplete,
        CControlLabel,
        LongRunningOperationDialog,
    },
})
export default class ProductSearchModeSelector extends Vue {
    @Prop({ default: {}, type: Object })
    public vm!: ProductSearchModeSelectorViewModel;

    @Prop({ default: false, type: Boolean })
    public isTemplateMode!: boolean;

    @Prop({ default: 1, type: Number })
    public columns!: number;

    @Prop({ default: "400px", type: String })
    public minHeight!: string;

    @Prop({
        default: false,
    })
    public canChangeView!: boolean;

    @Prop({
        default: true,
        type: Boolean,
    })
    public isHeaderVisible!: boolean;

    public Validation = Validation;
    public Autocompletes = Autocompletes;
    public EventType = EventType;
    public ProductSearchConstants = ProductSearchConstants;
    public ProductSearchMode = ProductSearchMode;
    public isOverlay = false;

    public chipplyConstants = ChipplyConstants;
    public created() {
        if (this.vm) {
            this.onSearchModeChanged();
        }
    }

    public get containedModes() {
        const filtered = this.vm.allSearchModes.filter((x) => x.isTemplateMode == this.isTemplateMode);
        return filtered;
    }

    public get showBorder() {
        return this.vm.isShowAllModes && this.containedModes.includes(this.vm.searchMode);
    }

    public get containedSelectedMode() {
        const filtered = this.containedModes.filter((x) => x.isSelected);
        const searchMode = filtered.length > 0 ? filtered[0] : this.containedModes[0];
        return searchMode;
    }

    public closeOverlay() {
        const filtered = this.containedModes.filter((x) => x.isSelected);
        this.vm.searchMode = filtered.length > 0 ? filtered[0] : this.containedModes[0];
        this.isOverlay = false;
    }

    @Watch("vm.searchMode")
    protected onSearchModeChanged() {
        this.updateOverlay();
    }

    @Watch("isTemplateMode")
    protected onTemplateModeChanged() {
        this.updateOverlay();
    }

    private updateOverlay() {
        const filtered = this.containedModes;
        if (filtered.indexOf(this.vm.searchMode) == -1) {
            this.isOverlay = true;
        } else {
            this.isOverlay = false;
        }
    }

    public search() {
        this.$emit("search");
    }

    public clear() {
        this.vm.clear();
        if (!this.vm.isShowAllModes) {
            this.search();
        }
    }

    public toggleTableMode() {
        this.$emit("toggleTableMode");
    }
}
