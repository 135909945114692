import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { IListViewModel } from "@/chipply/view-model/IListViewModel";
import PageState from "@/chipply/PageState";
import IMenuData from "@/chipply/IMenuData";
import { EventBus } from "@/chipply/EventBus";

export abstract class ListViewModel implements IListViewModel {
    public dataTableFixedHeader = true;
    public dataTableDisableSort = false;
    public dataTableMultiSort = false;
    public abstract dataTableHeaders: object[];
    public abstract dataTableItemKey: string;
    public dataTableLoading = false;
    // dataTableShowExpand must be set to true when using @click=expand(!isExpand)
    public dataTableShowExpand = false;
    public dataTableShowSelect = false;
    public hideDefaultFooter = true;
    public isDetailViewVisible = false;
    public dataTableDisablePagination = true;
    public abstract items: object[];
    public dataTableExpandedItems: object[] = [];
    public abstract pagination: IVuetifyTableOptions;
    public selectedItems: object[] = [];
    public serverItemsLength: number | null = null;
    public hideDefaultHeader = false;
    public dataTableNoDataText = "";
    public singleExpand = false;
    public abstract pageState: PageState;
    public abstract list(isNewSearch?: boolean): Promise<any>;

    public menuData: IMenuData = { area: 0 };
    public init = true;
    public area = 0;

    public constructor() {
        EventBus.$on("menu-data-complete", (menuData: IMenuData) => {
            this.menuData = menuData;
            this.area = (<any>menuData).area;
            this.init = true;
        });
    }
}
