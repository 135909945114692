import { IGetProcessLayoutResults } from "@/chipply/process/IGetProcessLayoutResults";
import { SaveProcessLayoutArgs } from "@/chipply/process/ISaveProcessLayoutArgs";
import { ProcessLayoutCanvasViewModel } from "@/chipply/process/ProcessLayoutCanvasViewModel";
import { ProcessLayoutPageViewModelBase } from "@/chipply/process/ProcessLayoutPageViewModelBase";
import ChipplyConstants from "@/chipply/ChipplyConstants";
import { WebHelper, AsyncInteractionViewModel } from "chipply-common";

export class ProcessLayoutPageViewModel extends ProcessLayoutPageViewModelBase {
    public saveWarningViewModel: AsyncInteractionViewModel | null = null;
    public storeId = 0;
    public processId = 0;

    public async get(storeId: number, processId: number) {
        this.statusMessage = "Loading...";
        this.loading = true;
        this.storeId = storeId;
        this.processId = processId;
        const data = (await WebHelper.getJsonData(
            `/api/ProcessLayout/${this.storeId}/${this.processId}`
        )) as IGetProcessLayoutResults;

        for (const imageNumber of Object.keys(data.imageDatas)) {
            const vm = new ProcessLayoutCanvasViewModel();
            vm.imageNumber = Number(imageNumber);
            vm.imageData = data.imageDatas[vm.imageNumber];
            vm.gutters = this.gutters;
            this.imageLayoutViewModels.push(vm);
        }

        this.unplacedBoxes = data.unplacedBoxes;
        this.loading = false;
    }

    public async save(): Promise<boolean> {
        const warningVm = new AsyncInteractionViewModel();
        this.saveWarningViewModel = warningVm;
        const warningResult = await this.saveWarningViewModel.interact();
        this.saveWarningViewModel = null;

        if (warningResult === "cancel") {
            return false;
        }

        const saveArgs = new SaveProcessLayoutArgs();
        saveArgs.processId = this.processId;
        saveArgs.boxes = {};
        for (const layout of this.imageLayoutViewModels) {
            saveArgs.boxes[layout.imageNumber] = layout.imageData!.boxes;
        }

        this.statusMessage = "Saving...";
        this.loading = true;
        try {
            await WebHelper.postJsonData("/api/ProcessLayout/", saveArgs);
        } catch {
            this.errorMessage = ChipplyConstants.UNKNOWN_ERROR;
            this.showErrorMessage = true;
            return false;
        } finally {
            this.loading = false;
        }

        return true;
    }
}
