import { typeHint } from "chipply-common";
import VendorPurchaseOrderSettings from "../VendorPurchaseOrderSettings";
import { IVendorShipMethod, alphabroderShipMethods } from "../VendorShipMethods";

@typeHint({ typeHint: "AlphabroderPurchaseOrderSettings" })
export default class AlphabroderPurchaseOrderSettings extends VendorPurchaseOrderSettings {
    public vendorName = "alphabroder";

    public getAvailableShipMethods(): IVendorShipMethod[] {
        return alphabroderShipMethods;
    }
}
