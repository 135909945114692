









































import { Prop, Watch } from "vue-property-decorator";
import Component from "vue-class-component";
import Vue from "vue";
import { ITextValue, WebHelper } from "chipply-common";
import _, { Cancelable } from "lodash";
import { AutocompleteType } from "@/chipply/Autocompletes";

@Component({
    model: {
        event: "change",
        prop: "value",
    },
    props: {
        rules: Array,
    },
})
export default class CApiAutoComplete extends Vue {
    @Prop({ type: Boolean, default: false })
    dense!: boolean;

    @Prop({ type: Boolean, default: false })
    smallChips!: boolean;

    @Prop({ type: Boolean, default: false })
    preload!: boolean;

    @Prop({ type: Object, default: null })
    filters!: object;

    @Prop({ type: Boolean, default: false })
    multiple!: boolean;
    @Prop({ type: String, default: "" })
    label!: string;

    @Prop({ type: Boolean, default: true })
    pagination!: boolean;

    @Prop({ type: Boolean, default: true })
    clearable!: boolean;

    @Prop({ type: Boolean, default: false })
    clearInputOnSelect!: boolean;

    @Prop({ type: Boolean, default: false })
    returnObject!: boolean;

    @Prop({
        type: Object,
        default: null,
    })
    type!: AutocompleteType;

    @Prop({
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    @Prop({ type: [String, Number, Object, Array] })
    public value!: string | number | object | null;

    public name = "CApiAutoComplete";
    public items: Array<ITextValue<number>> = [];
    public selectedItem: string | number | object | number[] | null = null;
    declare searchInput: string | null;
    public loading = false;
    protected lastServerList: Array<ITextValue<number>> = [];
    protected lastFilters: object | null = null;
    protected isPreloaded = false;

    public data() {
        return {
            searchInput: null,
        };
    }

    public async created() {
        if (this.preload) {
            await this.initializePreload();
        }
        this.initializeServerSearch();

        this.onValueChanged();
    }

    protected searchInputThrottle: ((() => Promise<void>) & Cancelable) | null = null;
    protected filtersChangedThrottle: ((() => Promise<void>) & Cancelable) | null = null;

    public get noResultsFound() {
        return this.lastServerList.length === 0 && this.searchInput;
    }

    public get emptyText() {
        return this.noResultsFound ? "No results found." : "Please enter characters to search...";
    }
    @Watch("searchInput")
    public async onSearchInputChanged() {
        await this.searchInputThrottle!();
    }

    @Watch("filters", { deep: true })
    public async onFiltersChanged() {
        if (this.preload) {
            return;
        }
        if (_.isEqual(this.filters, this.lastFilters)) {
            return;
        }
        if (!this.filters) {
            return;
        }
        if (this.filtersChangedThrottle) {
            await this.filtersChangedThrottle!();
        }
    }

    @Watch("value")
    public onValueChanged() {
        this.selectedItem = this.value;
        if (this.clearInputOnSelect) {
            this.searchInput = "";
        }
    }

    @Watch("selectedItem")
    public onSelectedChanged() {
        if (this.selectedItem === this.value) {
            return;
        }
        const item = this.items.find((i) => i.value === this.selectedItem);
        this.$emit("change", this.selectedItem, item);
    }

    protected async searchCore() {
        this.loading = true;
        const args = {
            filters: this.filters,
            searchInput: this.searchInput,
            disablePaging: !this.pagination,
        };
        const response = await WebHelper.postJsonData(this.type.path, args);
        const responseJSON = JSON.parse(response);
        let newList = [] as ITextValue<number>[];
        if (this.multiple) {
            if (!this.selectedItem) {
                newList = responseJSON.items;
            }
            const selectedItems = this.items.filter(
                (i) => ((this.selectedItem as number[]) || []).indexOf(i.value) > -1
            );
            newList.push(...responseJSON.items);
            newList.push(...selectedItems);
        } else {
            newList = responseJSON.items;
            if (newList.filter((item) => item.value == this.selectedItem).length == 0) {
                this.selectedItem = null;
            }
        }
        this.lastServerList = responseJSON.items;
        this.items = newList;
        this.loading = false;
    }

    public initializeServerSearch() {
        this.searchInputThrottle = _.throttle(
            async () => {
                if (!this.searchInput) {
                    this.loading = false;
                    return;
                }
                if (this.selectedItem && !this.multiple) {
                    this.loading = false;
                    return;
                }

                await this.searchCore();
            },
            500,
            { trailing: true }
        );

        this.filtersChangedThrottle = _.throttle(
            async () => {
                this.lastFilters = this.filters;
                await this.searchCore();
            },
            500,
            { trailing: true }
        );
    }

    public async initializePreload() {
        if (this.isPreloaded) return;
        this.loading = true;
        const args = {
            filters: this.filters,
            searchInput: this.searchInput,
            disablePaging: this.preload,
        };
        this.lastFilters = this.filters;
        const response = await WebHelper.postJsonData(this.type.path, args);
        const responseJSON = JSON.parse(response);
        this.items = responseJSON.items;
        this.loading = false;
    }
    @Watch("preload")
    protected preloadChanged() {
        this.initializePreload();
    }
}
