

































import OrganizationStoreFeeViewModel from "@/chipply/event/OrganizationStoreFeeViewModel";
import OrganizationContactAddViewModel, {
    OrganizationContactAddType,
} from "@/chipply/organization/OrganizationContactAddViewModel";
import OrganizationEditViewModel from "@/chipply/organization/OrganizationEditViewModel";
import FormValidationMixin from "@/components/FormValidationMixin";
import EditOrganizationContact from "@/components/EditContact.vue";
import CDecisionCard from "@/components/ui/CDecisionCard.vue";
import { Prop, Watch } from "vue-property-decorator";
import Validation from "@/validation";
import Component, { mixins } from "vue-class-component";

@Component({
    components: {
        EditOrganizationContact,
        CDecisionCard,
    },
})
export default class AddOrganizationContact extends mixins(FormValidationMixin) {
    public OrganizationContactAddType = OrganizationContactAddType;
    public isValid = false;

    @Prop({
        type: Object,
    })
    public vm!: OrganizationContactAddViewModel;
    protected Validation = Validation;

    @Watch("vm")
    protected vmChanged() {
        this.validate();
    }
}
