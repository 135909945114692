import { DateUtils, Serializer, Utils, WebHelper } from "chipply-common";
import { IRelatedArtworkModel } from "@/chipply/event/artworks/IRelatedArtworkModel";
import { ISearchArtworkModel } from "@/chipply/event/artworks/ISearchArtworkModel";
import { ListViewModel } from "@/chipply/view-model/ListViewModel";
import IVuetifyTableOptions from "@/chipply/interface/IVuetifyTableOptions";
import { IListStoreArtworkFilters, IRelatedArtworkResults } from "@/chipply/event/artworks/IStoreArtworkDto";
import { RelatedArtworkViewModel } from "@/chipply/event/artworks/RelatedArtworkViewModel";
import PageState from "@/chipply/PageState";
import { EventBus } from "@/chipply/EventBus";
import { IArtworkCopyArgs } from "@/chipply/event/artworks/IArtworkCopyArgs";

export default class SearchArtworkViewModel extends ListViewModel {
    public storeArtworkId = 0;
    public typeCode = "";
    public artType = "";
    public artName = "";
    public orgName = "";
    public storeId = 0;
    public storeName = "";
    public saleOrder = "";
    public openDate = "";
    public closeDate = "";
    public artworkCount = 0;
    public imageUrl = "";
    public isLoaded = false;
    public isLoading = false;
    public dataTableItemKey = "storeArtworkId";
    public items: IRelatedArtworkModel[] = [];
    public pagination!: IVuetifyTableOptions;
    public pageState!: PageState;
    public filters: IListStoreArtworkFilters | null = null;

    public dataTableHeaders: object[] = [
        { text: "", sortable: false, value: "childImageUrl", width: "2%" },
        { text: "Name", sortable: false, value: "artName", width: "15%" },
        { text: "Type", sortable: false, value: "artType", width: "5%" },
        { text: "Type Code", sortable: false, value: "typeCode", width: "5%" },
        { text: "Organization", sortable: false, value: "orgName" },
        { text: "Sale Order", sortable: false, value: "saleOrder" },
        { text: "Store", sortable: false, value: "storeName", width: "10%" },
        { text: "Open Date", sortable: false, value: "openDate", width: "10%" },
        { text: "Close Date", sortable: false, value: "closeDate", width: "10%" },
        { text: "", sortable: false, value: "importArtwork", width: "15%" },
    ];
    constructor(model?: ISearchArtworkModel) {
        super();
        this.pageState = new PageState();
        this.pageState.itemsPerPage = 5;
        if (!model) {
            return;
        }
        this.storeArtworkId = model.storeArtworkId;
        this.typeCode = model.typeCode;
        this.artType = model.artType;
        this.artName = model.artName;
        this.storeId = model.storeId;
        this.storeName = model.storeName;
        this.orgName = model.orgName;
        this.saleOrder = model.saleOrder;
        this.openDate = DateUtils.parseDateString(model.openDate).dateString;
        this.closeDate = DateUtils.parseDateString(model.closeDate).dateString;
        this.artworkCount = model.artworkCount;
        this.imageUrl = model.imageUrl;
    }

    public async list(isNewSearch?: boolean): Promise<any> {
        this.isLoading = true;

        if (this.pageState.wasLastPageSelected) {
            return;
        }
        const args = {
            artworkId: this.storeArtworkId,
            filters: this.filters,
            pageState: this.pageState,
        };
        const results = await WebHelper.postJsonData(`/api/Store/GetRelatedArtworks`, args);
        const jsonResults = Serializer.deserialize<IRelatedArtworkResults>(results);

        for (const r of jsonResults.relatedArtworks) {
            this.items.push(new RelatedArtworkViewModel(r));
        }
        this.pageState.next(jsonResults.relatedArtworks.length);
        this.serverItemsLength = jsonResults.totalRows;
        this.isLoaded = true;
        this.isLoading = false;
    }

    public import = () => {
        const args: IArtworkCopyArgs = {
            artworkId: this.storeArtworkId,
            artworkName: this.artName,
            isCopy: false,
            canCopyExternalVariationIds: true,
        };
        EventBus.$emit("copy-artwork", args);
    };
}
