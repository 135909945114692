import { ITextValue } from "chipply-common";
import _ from "lodash";
import EmailTemplateMode from "@/chipply/emails/EmailTemplateMode";
import CheckAvailabilityArgs from "./CheckAvailabilityArgs";
import CheckAvailabilityResults from "./CheckAvailabilityResults";
import {
    IPurchaseOrderItemWarehouseOption,
    IPurchaseOrderLineItem,
    PurchaseOrderWarehouseJoinChar,
} from "./IPurchaseOrderLineItem";
import IVendorPurchaseOrder from "./IVendorPurchaseOrder";
import SubmitPurchaseOrderArgs from "./SubmitPurchaseOrderArgs";
import SubmitPurchaseOrderResults from "./SubmitPurchaseOrderResults";
import VendorPurchaseOrder from "./VendorPurchaseOrder";
import VendorPurchaseOrderArgs from "./VendorPurchaseOrderArgs";
import { BuildPurchaseOrderResults } from "./BuildPurchaseOrderResults";
import ListVendorPurchaseOrderArgs from "./ListVendorPurchaseOrderArgs";
import PurchasingConstants from "./PurchasingConstants";
import PurchasingViewModel from "./PurchasingViewModel";
import PurchasingFilters from "./PurchasingFilters";
import PurchaseOrderReportArgs from "./PurchaseOrderReportArgs";
import PurchasingMergeTemplateViewModel from "../merge/PurchasingMergeTemplateViewModel";
import { DateTime } from "luxon";
import { SendPurchasingEmailArgs } from "./SendPurchasingEmailArgs";
import IPurchasingMergeTemplate from "../merge/IPurchasingMergeTemplate";
import { SimpleAsyncInteractionViewModel, Utils, TextUtils } from "chipply-common";
import { WebHelper } from "chipply-common";
import GetPurchaseOrderItemDetailsArgs from "./GetPurchaseOrderItemDetailsArgs";
import { IGetPurchaseOrderItemDetailsResults } from "./IGetPurchaseOrderItemDetailsResults";
import IPurchaseOrderItemDetail from "./IPurchaseOrderItemDetail";
import { PurchasingType } from "./PurchasingConstants";
import ProductColorSizeSelectorViewModel from "../event/ProductColorSizeSelectorViewModel";
import PurchasingColorAssignmentPageViewModel from "./PurchasingColorAssignmentPageViewModel";
import ISubstituteProductResults from "./ISubstituteProductResults";
import SaveOrderItemDetailsArgs from "./SaveOrderItemDetailsArgs";
import SortProperty from "../data-access/SortProperty";
import IPurchasingParentViewModel from "./IPurchasingParentViewModel";
import PageState from "../PageState";
import BulkUpdateNoteArgs from "./BulkUpdateNoteArgs";
import { PurchasingReportType } from "./PurchasingReportType";
import IOrderItemHistoryDto from "./IOrderItemHistoryDto";
import ListOrderItemHistoryArgs from "./ListOrderItemHistoryArgs";
import IListOrderItemHistoryResults from "./IListOrderItemHistoryResults";
import SalesOrderInfo from "@/chipply/event/SalesOrderInfo";
import IVendorPurchaseOrderSettings from "./IVendorPurchaseOrderSettings";
import SanMarPurchaseOrderSettings from "./SanMar/SanMarPurchaseOrderSettings";
import { Serializer, typeDependencies } from "chipply-common";
import FounderPurchaseOrderSettings from "./Founder/FounderPurchaseOrderSettings";
import AugustaPurchaseOrderSettings from "./Augusta/AugustaPurchaseOrderSettings";
import SSActivewearPurchaseOrderSettings from "./SSActivewear/SSActivewearPurchaseOrderSettings";
import IPurchasingViewModel from "./IPurchasingViewModel";
import AlphabroderPurchaseOrderSettings from "./Alphabroder/AlphabroderPurchaseOrderSettings";
import ManualAddPurchasingItemArgs from "./ManualAddPurchasingItemArgs";
import { ManualAddPurchasingItemResults } from "./ManualAddPurchasingItemResults";
import ManualAddPurchasingItem from "./ManualAddPurchasingItem";
import AbstractPurchaseOrderViewModel from "./AbstractPurchaseOrderViewModel";
import IPurchasingLineItem from "./IPurchasingLineItem";
import PurchaseOrderHelper from "./PurchaseOrderHelper";
import PurchaseOrderingShippingOptions from "./PurchaseOrderingShippingOptions";
import VendorPurchaseOrderHeader from "./VendorPurchaseOrderHeader";
import { IPurchaseOrderWarehouse } from "./IPurchaseOrderWarehouse";
import { PurchaseOrderSelectWarehouseViewModel } from "./PurchaseOrderSelectWarehouseViewModel";
//import { ArrayUtils } from "chipply-common";
@typeDependencies({
    types: {
        AlphabroderPurchaseOrderSettings,
        SanMarPurchaseOrderSettings,
        FounderPurchaseOrderSettings,
        AugustaPurchaseOrderSettings,
        SSActivewearPurchaseOrderSettings,
    },
})
export default class PurchaseOrderViewModel extends AbstractPurchaseOrderViewModel implements IPurchasingViewModel {
    public bulkNote = "";
    public dismissDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public groupBy = "";
    public isBulkNoteVisible = false;
    public isTimelineVisible = false;
    public currentHistory: IOrderItemHistoryDto[] = [];
    public lines: IPurchaseOrderLineItem[] = [];
    public selectedLines: IPurchaseOrderLineItem[] = [];
    public reportTypes: Array<ITextValue<string>> = [
        { text: "Purchase Order Detail", value: "Details" },
        { text: "Purchase Order Summary", value: "Summary" },
    ];
    public reportType: PurchasingReportType = "Details";
    public purchasingViewModel: IPurchasingParentViewModel;
    public isEmailWindowVisible = false;
    public emailTemplate!: PurchasingMergeTemplateViewModel;
    public EventTemplateMode = EmailTemplateMode;
    public isPrintChecked = true;
    public isEmailChecked = false;
    public isViewingDetails = false;
    public details: IPurchaseOrderItemDetail[] = [];
    public selectedDetails: IPurchaseOrderItemDetail[] = [];
    public currentItem: IPurchaseOrderLineItem | null = null;
    public currentItemImageUrl = "";
    public productColorSizeSelectorViewModel: ProductColorSizeSelectorViewModel | null = null;
    public colorAssignmentViewModel: PurchasingColorAssignmentPageViewModel | null = null;
    public isEditNoteVisible = false;
    public currentDetails: IPurchaseOrderItemDetail | null = null;
    public isSelectAllDetails = false;
    public isSelectAllDetailsIndeterminate = false;
    public printOrEmailDialogViewModel: SimpleAsyncInteractionViewModel | null = null;
    public salesOrderInfo: SalesOrderInfo | null = null;
    public shouldIncludePrice = true;
    public shouldIncludeUpc = true;
    public selectWarehouseViewModel: PurchaseOrderSelectWarehouseViewModel | null = null;
    public artworkPurchasingVendors: Array<{ text: string; value: number }> = [];
    public isArtworkPurchasingEnabled = false;

    public detailsSortProperties: SortProperty[] = [
        { displayName: "Order date (older)", propertyName: "OrderDate", isDescending: false },
        { displayName: "Order date (recent)", propertyName: "OrderDate", isDescending: true },
        { displayName: "Store", propertyName: "StoreName", isDescending: false },
        { displayName: "Process", propertyName: "ProcessName", isDescending: false },
    ];
    public selectedDetailsSortProperties: SortProperty[] = [this.detailsSortProperties[0]];

    public headers = [
        { text: "Vendor", value: "vendorName" },
        { text: "Style", value: "style" },
        { text: "Product Name", value: "productName" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Cost", value: "productCost" },
        { text: "Total Cost", value: "totalCost" },
        { text: "Availability", value: "availability" },
        { text: "Warehouse", value: "warehouse", sortable: false },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public allHeaders = [
        { text: "Vendor", value: "vendorName" },
        { text: "Style", value: "style" },
        { text: "Product Name", value: "productName" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Cost", value: "productCost" },
        { text: "Total Cost", value: "totalCost" },
        { text: "Availability", value: "availability" },
        { text: "Warehouse", value: "warehouse", sortable: false },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public simpleHeaders = [
        { text: "Style", value: "style" },
        { text: "Color", value: "color" },
        { text: "Size", value: "size" },
        { text: "Quantity Needed", value: "quantity" },
        { text: "Quantity Ordered", value: "quantityOrdered" },
        { text: "Quantity to Order", value: "quantityToOrder" },
        { text: "Order Details", value: "actions", sortable: false },
    ];

    public augustaShipMethods = [
        { method: "901", description: "UPS Standard Ground" },
        { method: "902", description: "UPS 1 DAY" },
        { method: "903", description: "UPS 2 DAY" },
        { method: "904", description: "UPS 3 DAY Ground" },
    ];

    public founderShipMethods = [
        { method: "U01", description: "UPS Ground" },
        { method: "U02", description: "UPS 3 Day Select" },
        { method: "U03", description: "UPS 2 Day Air" },
        { method: "U04", description: "UPS 2nd Day Air A.M." },
        { method: "U06", description: "UPS Next Day Air Saver" },
        { method: "U07", description: "UPS Next Day Air" },
        { method: "U11", description: "UPS International" },
        { method: "F01", description: "FedEx Ground" },
        { method: "F02", description: "FedEx 3 Day" },
        { method: "F05", description: "FedEx Std Overnight" },
        { method: "F06", description: "FedEx Priority Overnight" },
        { method: "F11", description: "FedEx International Economy" },
    ];

    public sanMarShipMethods = [
        { method: "UPS", description: "UPS Standard Ground" },
        { method: "UPS 2ND DAY", description: "UPS 2nd business day delivery end of day" },
        { method: "UPS 2ND DAY AM", description: "UPS 2nd business day delivery 10:30 a.m." },
        { method: "UPS 3RD DAY", description: "UPS 3rd business day delivery end of day" },
        { method: "UPS NEXT DAY", description: "UPS next day delivery 10:30 a.m." },
        { method: "UPS NEXT DAY EA", description: "UPS Next business day delivery 8:00 a.m." },
        { method: "UPS NEXT DAY SV", description: "UPS next day delivery 3:00 pm" },
        { method: "UPS SATURDAY", description: "UPS Extends business day calculation to include Saturday" },
        { method: "USPS PP", description: "United States Postal Service Parcel Post" },
        { method: "USPS APP", description: "USPS Air Parcel Post" },
        { method: "PSST", description: "Pack Separately, Ship Together program" },
        { method: "TRUCK", description: "Truck carrier services are based on the destination zip code" },
    ];

    public ssShipMethods = [
        { method: "1", description: "Ground" },
        { method: "2", description: "UPS Next Day Air" },
        { method: "3", description: "UPS 2nd Day Air" },
        { method: "16", description: "UPS 3 Day Select" },
        { method: "6", description: "Will Call Or PickUp" },
        { method: "8", description: "Messenger Pickup Or PickUp" },
        { method: "54", description: "Misc Cheapest" },
        { method: "17", description: "UPS Next Day Air Early AM" },
        { method: "21", description: "UPS Next Day Air Saver" },
        { method: "19", description: "UPS Saturday" },
        { method: "20", description: "UPS Saturday Early" },
        { method: "22", description: "UPS 2nd Day Air AM" },
        { method: "14", description: "FedEx Ground" },
        { method: "27", description: "FedEx Next Day Standard" },
        { method: "26", description: "FedEx Next Day Priority" },
        { method: "40", description: "UPS Ground" },
        { method: "48", description: "FedEx 2nd Day Air" },
    ];

    public alphabroderShipMethods = [
        { method: "UPS-Blue", description: "UPS 2 Day" },
        { method: "UPS-Orange 3day", description: "UPS 3 Day" },
        { method: "UPS-Red", description: "UPS 1 Day" },
        { method: "UPS-RED.8:30am", description: "UPS 1 Day 8:30 AM" },
        { method: "UPS-Sat.Dlvry.", description: "UPS Saturday" },
        { method: "UPS-Surface", description: "UPS Ground" },
    ];

    public a4ShipMethods = [
        { method: "flatrate_flatrate", description: "Flat Rate - Fixed" },
        { method: "freeshipping_freeshipping", description: "Free Shipping - Free" },
        { method: "ups_03", description: "UPS Ground" },
        { method: "ups_12", description: "UPS Three-Day Select" },
        { method: "usps_0_FCLE", description: "United States Postal Service - First-Class Mail Large Envelope" },
        { method: "usps_7", description: "United States Postal Service - Library Mail Parcel" },
        { method: "usps_16", description: "United States Postal Service - Priority Mail Flat Rate Envelope" },
        { method: "usps_3", description: "United States Postal Service - Priority Mail Express 1-Day" },
        { method: "fedex_FEDEX_GROUND", description: "Federal Express Ground" },
        { method: "fedex_FEDEX_2_DAY", description: "Federal Express 2 Day" },
        { method: "fedex_FEDEX_2_DAY_AM", description: "Federal Express 2 Day AM" },
        { method: "fedex_FEDEX_EXPRESS_SAVER", description: "Federal Express - Express Saver" },
    ];

    public dialogTitleCollection: { [key: string]: string } = {};
    protected detailsPageState = new PageState();
    public lastPurchasingReportType: PurchasingReportType | null = null;
    protected processHeader = { text: "Process", value: "processHeader", sortable: true };

    public constructor(purchasingViewModel: IPurchasingParentViewModel) {
        super();
        this.purchasingViewModel = purchasingViewModel;
        this.isStoreView = purchasingViewModel.isStoreView;
        this.emailTemplate = new PurchasingMergeTemplateViewModel();
        this.emailTemplate.setTemplate();
        this.emailTemplate.loadEditData();
        this.salesOrderInfo = purchasingViewModel.salesOrderInfo;

        const dialogTitleCollection: { [key: string]: string } = {};
        dialogTitleCollection[PurchasingConstants.autoPurchaseType] = "Order Digitally";
        dialogTitleCollection[PurchasingConstants.checkPurchaseType] = "Check Availability";
        dialogTitleCollection[PurchasingConstants.manualPurchaseType] = "Manually Order";
        dialogTitleCollection[PurchasingConstants.pulledPurchaseType] = "Pull from Stock";
        this.dialogTitleCollection = dialogTitleCollection;
    }

    protected async initializeCore() {
        this.getDefaultVendorSettings();
        await super.initializeCore();
    }

    public async buildPurchaseOrder(): Promise<void> {
        const baseUrl = "/api/purchasing/buildpurchaseorder";
        let rowLimitExceeded = false;
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as BuildPurchaseOrderResults;
            if (results) {
                if (results.purchaseOrder) {
                    if (results.purchaseOrder.exceedsRowLimit) {
                        rowLimitExceeded = true;
                    }
                    this.toViewModel(results.purchaseOrder);
                }
                if (results.countries) {
                    this.countries = results.countries;
                }
                if (results.states) {
                    this.states = results.states;
                }
                PurchaseOrderHelper.replaceArray(this.stores, results.stores);
                PurchaseOrderHelper.replaceArray(this.processes, results.processes);
                PurchaseOrderHelper.replaceArray(this.vendors, results.vendors);
                PurchaseOrderHelper.replaceArray(this.salesReps, results.salesReps);
                this.selectedPurchaseOrderType = PurchasingConstants.autoPurchaseType;
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (rowLimitExceeded) {
            setTimeout(async () => {
                await this.handleLargePurchaseOrder();
            });
            return;
        }
    }

    public async showPurchasingDialogOrCheckAvailabilityAsNeeded(type: PurchasingType): Promise<void> {
        if (this.isPurchaseOrderInfoRequiredForPresubmit) {
            await this.showPurchaseOrderDialog(type);
        } else {
            this.selectedPurchaseOrderType = type;
            await this.checkAvailability();
        }
    }

    public itemQuantityToOrderChanged(item: IPurchaseOrderLineItem) {
        if (item.previousQuantityToOrder !== item.quantityToOrder) {
            const increased = item.previousQuantityToOrder < item.quantityToOrder;
            item.previousQuantityToOrder = item.quantityToOrder;
            if (this.hasCheckedAvailability && increased) {
                this.hasCheckedAvailability = false;
            }
        }
    }

    public async showPurchaseOrderDialog(type: PurchasingType): Promise<void> {
        this.selectedPurchaseOrderType = type;
        if (!(await this.getDefaultVendorSettings())) {
            // Don't allow check availability if this false for some reason.
            return;
        }
        this.isPurchaseOrderDialogVisible = true;
        this.purchaseOrderDialogTitle = this.dialogTitleCollection[this.selectedPurchaseOrderType];
    }

    public async refresh(shouldMaintainAvailabilityStatus?: boolean): Promise<void> {
        this.lastPurchaseOrderId = 0;
        const previousLines: IPurchaseOrderLineItem[] = [];
        const manualLines: IPurchaseOrderLineItem[] = [];
        for (const currentLine of this.lines) {
            if (shouldMaintainAvailabilityStatus) {
                previousLines.push(currentLine);
            }
            if (currentLine.wasManuallyOrdered === true) {
                manualLines.push(currentLine);
            }
        }
        await this.list();
        this.lines.push(...manualLines);
        if (shouldMaintainAvailabilityStatus) {
            for (const line of this.lines) {
                let matchingLine: IPurchaseOrderLineItem | undefined;
                if (!line.chipplyProductColorSizeId) {
                    continue;
                }
                if (this.isGroupingByProcess) {
                    if (line.processId && line.processId <= 0) {
                        continue;
                    }
                    matchingLine = previousLines.find(
                        (x) =>
                            x.chipplyProductColorSizeId === line.chipplyProductColorSizeId &&
                            x.processId === x.processId
                    );
                } else {
                    matchingLine = previousLines.find(
                        (x) => x.chipplyProductColorSizeId === line.chipplyProductColorSizeId
                    );
                }
                if (matchingLine) {
                    line.availability = matchingLine.availability;
                    line.message = matchingLine.message;
                    line.hasError = matchingLine.hasError;
                    line.warehouse = matchingLine.warehouse;
                }
            }
        }
    }

    public async list(): Promise<void> {
        const baseUrl = this.getListUrl();
        try {
            this.selectedLines.splice(0);
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new ListVendorPurchaseOrderArgs();
            serviceArgs.shouldGroupByProcess = this.isGroupingByProcess;
            // We can only show one group at a time so we prefer the process group.
            if (this.isGroupingByProcess) {
                PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.fulfillmentHeader);
                this.groupBy = "processHeader";
                PurchaseOrderHelper.addItemToArrayIfNotExists(this.headers, this.processHeader);
            } else {
                this.groupBy = "";
                PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.processHeader);
                if (!this.filters.shouldExcludeFulfilledItems && this.canGroupByFulfillmentStatus) {
                    this.groupBy = "isFulfilled";
                    PurchaseOrderHelper.addItemToArrayIfNotExists(this.headers, this.fulfillmentHeader);
                } else {
                    PurchaseOrderHelper.removeItemFromArrayIfExists(this.headers, this.fulfillmentHeader);
                }
            }
            if (this.eventId) {
                PurchaseOrderHelper.addItemToArrayIfNotExists(this.filters.storeIds, this.eventId);
            }
            serviceArgs.shouldGroupLikeItems = true;
            if (this.purchaseOrderId) {
                this.filters.purchaseOrderId = this.purchaseOrderId;
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as VendorPurchaseOrder;
            if (results) {
                this.toViewModel(results);
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async selectWarehouse(item: IPurchaseOrderLineItem) {
        const viewModel = new PurchaseOrderSelectWarehouseViewModel();
        viewModel.quantityToOrder = item.quantityToOrder;
        viewModel.warehouse = item.warehouse;
        viewModel.headerText = `${item.vendorName} &#8226; ${item.style} &#8226; ${item.color} &#8226; ${item.size}`;

        let originalOneWarehouse: IPurchaseOrderItemWarehouseOption | undefined;
        if (Utils.hasValue(item.warehouse)) {
            //By default, when there is only one warehouse select,
            //in this case, we don't save the quantity to order in warehouse options,
            //only display info for select warehouse popup.
            //
            //If it can find, it indicates there is none multiple warehouse
            originalOneWarehouse = item.warehouseOptions.find((x) => x.warehouseName == item.warehouse);
            if (originalOneWarehouse) {
                originalOneWarehouse.quantityToOrder = item.quantityToOrder;
            }
        }
        for (const warehouse of item.warehouseOptions) {
            const option = {
                warehouseName: warehouse.warehouseName,
                stockQuantity: warehouse.stockQuantity,
                originalQuantityToOrder: warehouse.quantityToOrder,
                newQuantityToOrder: 0,
            };

            viewModel.warehouseOptions.push(option);
        }

        this.selectWarehouseViewModel = viewModel;
        const result = await this.selectWarehouseViewModel.interact();
        this.selectWarehouseViewModel = null;
        if (result === "cancel") {
            return;
        }

        item.warehouseOptions.forEach((element) => {
            const index = viewModel.warehouseOptions.findIndex((x) => x.warehouseName === element.warehouseName);
            element.quantityToOrder = viewModel.warehouseOptions[index].newQuantityToOrder;
        });

        item.warehouse = item.warehouseOptions
            .filter((x) => x.quantityToOrder > 0)
            .map((x) => x.warehouseName)
            .join(PurchaseOrderWarehouseJoinChar);

        if (item.warehouseOptions.filter((x) => x.quantityToOrder > 0).length == 1) {
            //only select one warehouse, clean the existing quantity to order
            item.warehouseOptions.every((x) => {
                x.quantityToOrder = 0;
                return true;
            });
        }
    }

    public async checkAvailability(): Promise<void> {
        const baseUrl = "/api/purchasing/checkavailability";
        let errorMessage = "";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new CheckAvailabilityArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as CheckAvailabilityResults;
            if (results) {
                if (results.wasSuccessful === false) {
                    errorMessage = results.message;
                    this.purchasingViewModel.errorMessage = errorMessage;
                    if (results.lines.length <= 0) {
                        this.hasCheckedAvailability = false;
                    }
                    return;
                }
                this.hasCheckedAvailability = true;
                this.canOrderDigitally = this.canOrderDigitally && results.hasConfiguredSystemIntegration;
                this.applyLines(results.lines);
                this.applySort();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
    }

    public async getDefaultVendorSettings(): Promise<boolean> {
        const baseUrl = "/api/purchasing/getvendorsettings";
        try {
            const previousVendorSettings = this.vendorSettings;
            this.vendorSettings = null;
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new VendorPurchaseOrderArgs();
            if (this.eventId) {
                PurchaseOrderHelper.addItemToArrayIfNotExists(this.filters.storeIds, this.eventId);
            }
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            serviceArgs.filters = this.filters;
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = Serializer.deserialize(resultsText) as IVendorPurchaseOrderSettings;
            if (results && previousVendorSettings && previousVendorSettings.vendorName === results.vendorName) {
                this.vendorSettings = previousVendorSettings;
                this.applyVendorSettings(false);
            } else {
                this.shipMethodHint = "";
                this.vendorSettings = results;
                this.applyVendorSettings(true);
            }
            if (this.vendorSettings) {
                this.vendorName = this.vendorSettings.vendorName;
            }
            return true;
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return false;
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.isPurchaseOrderDialogVisible = false;
        }
    }

    protected applyVendorSettings(isVendorChanged: boolean) {
        if (this.vendorSettings instanceof SanMarPurchaseOrderSettings) {
            this.canSelectWarehouse = this.vendorSettings.canSelectWarehouse;
            if (this.canSelectWarehouse) {
                if (this.orderingShippingOptions == PurchaseOrderingShippingOptions.None) {
                    this.orderingShippingOptions = PurchaseOrderingShippingOptions.Consolidated;
                }
            } else {
                this.orderingShippingOptions = PurchaseOrderingShippingOptions.None;
            }
            if (isVendorChanged) {
                this.warehouses = this.vendorSettings.warehouses;
                this.isSelectAllWarehouseIds = true;
                this.selectedWarehouseIds = this.warehouses.map((x) => x.warehouseId);
                this.canCustomerPickup = this.vendorSettings.canCustomerPickup;
            }
        }
    }

    public async submitPurchaseOrder(purchaseOrderType: PurchasingType) {
        this.purchasingViewModel.errorMessage = "";
        //this.isSubmitDialogVisible = false;
        this.selectedPurchaseOrderType = purchaseOrderType;
        const baseUrl = "/api/purchasing/submitpurchaseorder";
        try {
            this.purchasingViewModel.isLoading = true;
            const serviceArgs = new SubmitPurchaseOrderArgs();
            if (this.eventId && !this.filters.storeIds.includes(this.eventId)) {
                this.filters.storeIds.push(this.eventId);
            }
            serviceArgs.filters = this.filters;
            serviceArgs.purchaseOrder = this.toPurchaseOrder();
            const resultsText = await WebHelper.postJsonData(baseUrl, serviceArgs);
            const results = JSON.parse(resultsText) as SubmitPurchaseOrderResults;
            if (results) {
                if (results.wasSuccessful === false && results.lines.length <= 0) {
                    this.purchasingViewModel.errorMessage = results.message;
                    return;
                }
                this.applyLines(results.lines);
                //this.isSubmitDialogVisible = false;
                this.lastPurchaseOrderNumber = this.purchaseOrderNumber;
                this.lastPurchaseOrderId = results.purchaseOrderId;
                this.purchaseOrderNumber = "";
                this.lastPurchaseOrderType = this.selectedPurchaseOrderType;
                this.printOrEmail();
            }
        } catch {
            this.purchasingViewModel.errorMessage = this.vendorErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
            //this.isSubmitDialogVisible = false;
            this.selectedPurchaseOrderType = null;
        }
        if (this.currentItem) {
            await this.refreshDetails();
        }
    }

    public async printOrEmail(): Promise<void> {
        try {
            this.printOrEmailDialogViewModel = new SimpleAsyncInteractionViewModel();
            this.isPrintEmailDialogVisible = true;
            const dialogResult = await this.printOrEmailDialogViewModel.interact();
            if (dialogResult !== "accept") {
                return;
            }
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;

            // Currently used by email since it is not async all the way through,
            // TODO:  Refactor email process to be async.
            this.lastPurchasingReportType = this.reportType;
            if (this.isPrintChecked) {
                const args = new PurchaseOrderReportArgs();
                args.shouldIncludePrice = this.shouldIncludePrice;
                args.shouldIncludeUpc = this.shouldIncludeUpc;
                args.reportType = this.reportType;
                await this.print(args);
            }
            if (this.isEmailChecked) {
                await this.email();
            } else {
                this.lastPurchasingReportType = null;
            }
        } finally {
            this.printOrEmailDialogViewModel = null;
            this.isPrintEmailDialogVisible = false;
        }
    }

    public async substituteProduct(): Promise<void> {
        let selectedLine: IPurchaseOrderLineItem | null = null;
        if (this.currentItem) {
            selectedLine = this.currentItem;
        } else if (this.selectedLines.length > 0) {
            selectedLine = this.selectedLines[0];
        }
        const imageUrl = await this.getImageUrl();
        this.purchasingViewModel.productsSelectorImage = imageUrl ? imageUrl : "";
        if (selectedLine) {
            this.purchasingViewModel.productsSelectorHeading =
                `Replacing ${selectedLine.vendorName}  &#8226; ${selectedLine.style}  &#8226; ${selectedLine.color}` +
                `${this.selectedLines.length > 1 ? "" : `&#8226; ${selectedLine.size}`}`;
        } else {
            this.purchasingViewModel.productsSelectorHeading = "";
        }
        //this.purchasingViewModel.isProductsSelectorVisible = true;
        const result = await this.purchasingViewModel.selectProducts();

        if (!result) {
            this.purchasingViewModel.productsSelectorHeading = "";
            this.purchasingViewModel.productsSelectorImage = "";
            this.purchasingViewModel.isProductsSelectorVisible = false;
            return;
        }
        const selectedEventProductId = result;
        let isRefreshNeeded = false;
        let isDetailsRefreshNeeded = false;
        this.productColorSizeSelectorViewModel = new ProductColorSizeSelectorViewModel();
        this.productColorSizeSelectorViewModel.toReplaceProductImage = this.purchasingViewModel.productsSelectorImage;
        this.productColorSizeSelectorViewModel.eventProductId = selectedEventProductId;

        if (this.currentItem) {
            this.productColorSizeSelectorViewModel.updateOriginalProductInfo(this.currentItem);
        } else if (this.selectedLines.length > 0) {
            this.productColorSizeSelectorViewModel.updateOriginalProductInfo(this.selectedLines[0]);
            if (this.selectedLines.length > 1) {
                // Allow the user to sub multiple lines at once if they are of the same vendor/style/color
                this.productColorSizeSelectorViewModel.initializeSizeMappings(this.selectedLines);
            }
        }
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        try {
            await this.productColorSizeSelectorViewModel.getColorSizeInfo(selectedEventProductId);
            this.purchasingViewModel.isProductsSelectorVisible = false;
            this.purchasingViewModel.isProductColorSizeSelectorVisible = true;
            this.purchasingViewModel.isLoading = false;

            const results = await this.productColorSizeSelectorViewModel.edit();
            if (!results.canceled) {
                const args = this.productColorSizeSelectorViewModel.toSubstituteProductArgs();
                args.filters = this.filters;
                args.purchaseOrder = this.toPurchaseOrder();
                if (this.isViewingDetails) {
                    args.ecomOrderItemIds = this.selectedDetails.map((x) => x.orderItemId);
                }
                if (args.replacementChipplyProductColorSizeId !== 0 || args.sizeMappings.length > 0) {
                    this.purchasingViewModel.statusMessage = "Substituting product...";
                    this.purchasingViewModel.isLoading = true;
                    const replacementResults = await WebHelper.postJsonData("/api/purchasing/substituteproduct", args);
                    const deserializedResults = JSON.parse(replacementResults) as ISubstituteProductResults;
                    this.purchasingViewModel.isLoading = false;
                    isRefreshNeeded = true;
                    if (this.isViewingDetails) {
                        isDetailsRefreshNeeded = true;
                    }
                    if (deserializedResults.newlyAddedProducts.length > 0) {
                        this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
                        this.productColorSizeSelectorViewModel = null;
                        const vm = new SimpleAsyncInteractionViewModel();
                        vm.headerText = "Color Assignments";
                        const eventIds = deserializedResults.newlyAddedProducts.map((x) => x.eventId);
                        const uniqueEventIds: number[] = _.uniq(eventIds);
                        if (uniqueEventIds.length > 1) {
                            vm.text = `A substitute product has been added to ${uniqueEventIds.length} stores, would you like to perform color assignments?`;
                        } else {
                            vm.text = `A substitute product has been added to your store, would you like to perform color assignments?`;
                        }
                        this.dialogViewModel = vm;
                        const dialogResults = await this.dialogViewModel.interact();
                        this.dialogViewModel = null;
                        if (dialogResults === "continue") {
                            await this.performColorAssignments(deserializedResults);
                        }
                    }
                }
                this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
                this.productColorSizeSelectorViewModel = null;
            } else {
                this.back();
                this.purchasingViewModel.productsSelectorHeading = "";
                this.purchasingViewModel.productsSelectorImage = "";
            }
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        if (isRefreshNeeded) {
            await this.refresh(true);
        }
        if (isDetailsRefreshNeeded) {
            await this.refreshDetails();
            if (this.details.length === 0 && this.isViewingDetails) {
                this.closeDetails();
            }
        }
    }

    public async getImageUrl(): Promise<string> {
        if (this.selectedLines.length < 1) {
            return "";
        }
        const args = new GetPurchaseOrderItemDetailsArgs();
        args.filters = new PurchasingFilters(this.filters);
        args.pageState = this.detailsPageState;
        const line = this.selectedLines[0];
        args.filters.chipplyProductColorSizeId = line.chipplyProductColorSizeId as number;
        if (!args.filters.chipplyProductColorSizeId && this.currentItem?.eventProductColorSizeId) {
            // Handle custom products
            args.filters.eventProductColorSizeId = this.currentItem?.eventProductColorSizeId;
        } else if (!args.filters.chipplyProductColorSizeId && this.currentItem?.orderItemId) {
            // Handle bad data where there is no chipplyProductColorSizeId
            args.filters.orderItemId = this.currentItem?.orderItemId;
        }
        args.sortProperties = this.selectedDetailsSortProperties;
        const results = await WebHelper.postJsonData("/api/purchasing/listorderitemdetails", args);
        const deserializedResults = JSON.parse(results) as IGetPurchaseOrderItemDetailsResults;
        return deserializedResults.imageUrl;
    }

    public async productsSelectorClosed(accepted: boolean, selectedEventProductId: number): Promise<void> {}

    public getFileExportButtonName() {
        return `Export ${this.vendorName} File`;
    }

    public async createPurchaseOrderFile(): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        const url = "/api/reports/purchaseorder/export";
        const filename = "PurchaseOrder.csv";

        try {
            const args = new PurchaseOrderReportArgs();
            if (this.lastPurchaseOrderId) {
                args.purchaseOrderIds.push(this.lastPurchaseOrderId);
            }
            if (this.purchaseOrderId) {
                args.purchaseOrderIds.push(this.purchaseOrderId);
            }
            args.filters = this.filters;
            args.purchaseOrder = this.toPurchaseOrder();
            args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
            const attachment = await WebHelper.postJsonDataBlob(url, args);
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async exportCsv(details: boolean): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        const url = "/api/Reports/PurchaseOrder/csv";
        const filename = details ? "PurchaseOrderDetail.csv" : "PurchaseOrder.csv";
        try {
            const args = this.createOrUpdatePurchaseOrderReportArgs();
            args.reportType = details ? "Details" : "Summary";
            const attachment = await WebHelper.postJsonDataBlob(url, args);
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    private createOrUpdatePurchaseOrderReportArgs(args?: PurchaseOrderReportArgs) {
        if (!args) {
            args = new PurchaseOrderReportArgs();
            args.reportType = this.getPurchasingReportType();
        }
        if (this.lastPurchaseOrderId) {
            args.purchaseOrderIds.push(this.lastPurchaseOrderId);
        }
        if (this.purchaseOrderId) {
            args.purchaseOrderIds.push(this.purchaseOrderId);
        }

        args.filters = this.filters;
        args.purchaseOrder = this.toPurchaseOrder();
        args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
        return args;
    }

    public async print(args?: PurchaseOrderReportArgs): Promise<void> {
        this.purchasingViewModel.errorMessage = "";
        this.purchasingViewModel.statusMessage = "Loading...";
        this.purchasingViewModel.isLoading = true;
        try {
            args = this.createOrUpdatePurchaseOrderReportArgs(args);
            const attachment = await WebHelper.postJsonDataBlob("/api/Reports/PurchaseOrder", args);
            let filename = "PurchaseOrder.pdf";
            switch (args.reportType) {
                case "Details":
                    filename = "PurchaseOrderDetail.pdf";
                    break;
                case "Pulled":
                    filename = "PullFromStock.pdf";
                    break;
                default:
                    filename = "PurchaseOrder.pdf";
                    break;
            }
            WebHelper.downloadAsAttachment(attachment, filename);
        } catch {
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public email() {
        this.purchasingViewModel.isEmailWindowVisible = true;
    }

    public emailClose = (accepted: boolean, emailTemplate: PurchasingMergeTemplateViewModel) => {
        this.sendEmail(accepted, emailTemplate);
    };

    public async viewDetails(item: IPurchaseOrderLineItem): Promise<any> {
        this.harvestSelectedDetailsAsNeeded();
        if (this.currentItem === item) {
            this.closeDetails();
            return;
        }
        this.currentItem = item;
        this.isViewingDetails = true;
        PurchaseOrderHelper.replaceArray(this.headers, this.simpleHeaders);
        await this.refreshDetails();
    }

    public getWarehouseOptionsTooltip(warehouseOptions: IPurchaseOrderItemWarehouseOption[], warehouse: string) {
        if (!warehouseOptions) return warehouse;
        const warehouseInfo = warehouseOptions
            .filter((x) => x.quantityToOrder > 0)
            .map((x) => `${x.warehouseName}(${x.quantityToOrder})`)
            .join(", ");
        return warehouseInfo;
    }

    public editNote(item: IPurchaseOrderItemDetail) {
        this.currentDetails = item;
        this.isEditNoteVisible = true;
    }

    public async saveDetails(): Promise<void> {
        try {
            if (!this.currentDetails) {
                return;
            }
            this.isEditNoteVisible = false;
            this.purchasingViewModel.statusMessage = "Saving notes...";
            this.purchasingViewModel.isLoading = true;
            const args = new SaveOrderItemDetailsArgs();
            if (this.purchaseOrderId) {
                // We are updating an existing purchase order
                args.purchaseOrderId = this.purchaseOrderId;
            }
            args.details.push(this.currentDetails);
            const results = await WebHelper.postJsonData("/api/purchasing/saveorderitemdetails", args);
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
        await this.refresh(true);
        await this.refreshDetails();
    }

    public async bulkUpdateNote(): Promise<void> {
        try {
            if (!this.selectedLines && !this.selectedDetails) {
                return;
            }
            if (!this.bulkNote) {
                return;
            }
            this.isBulkNoteVisible = false;
            this.purchasingViewModel.statusMessage = "Saving notes...";
            this.purchasingViewModel.isLoading = true;
            const args = new BulkUpdateNoteArgs();
            args.purchaseOrder = this.toPurchaseOrder();
            args.filters = this.filters;
            args.note = this.bulkNote;
            const results = await WebHelper.postJsonData("/api/purchasing/bulkupdatenote", args);
        } finally {
            this.purchasingViewModel.isLoading = false;
            this.bulkNote = "";
        }
        if (this.currentItem) {
            await this.refreshDetails();
        }
    }

    public selectedDetailsChanged() {
        if (this.selectedDetails.length === 0) {
            this.isSelectAllDetails = false;
            this.isSelectAllDetailsIndeterminate = false;
        } else if (this.selectedDetails.length === this.details.length) {
            this.isSelectAllDetails = true;
            this.isSelectAllDetailsIndeterminate = false;
        } else {
            this.isSelectAllDetails = false;
            this.isSelectAllDetailsIndeterminate = true;
        }
        if (this.currentItem) {
            if (this.selectedDetails.length === 0 || this.selectedDetails.length >= this.details.length) {
                this.currentItem.quantityToOrder = Math.max(
                    this.currentItem.quantity - this.currentItem.quantityOrdered,
                    0
                );
            } else {
                let quantityToOrder = 0;
                for (const selectedDetail of this.selectedDetails) {
                    quantityToOrder += selectedDetail.quantityToOrder;
                }
                if (quantityToOrder > 0) {
                    this.currentItem.quantityToOrder = quantityToOrder;
                }
            }
        }
        if (this.selectedDetails.length > 0 && this.currentItem && !this.selectedLines.includes(this.currentItem)) {
            // If we are selecting details the current line needs to be selected
            // so the current line (and its selected details) can be sent to the server
            // for purchasing or bulk operations
            this.selectedLines.push(this.currentItem);
        }
    }

    public selectAllDetails() {
        this.selectedDetails.splice(0);
        if (this.isSelectAllDetails) {
            for (const item of this.details) {
                this.selectedDetails.push(item);
            }
        }
    }

    public async listDetails(): Promise<void> {
        try {
            if (this.detailsPageState.wasLastPageSelected) {
                return;
            }
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const args = new GetPurchaseOrderItemDetailsArgs();
            args.filters = new PurchasingFilters(this.filters);
            args.pageState = this.detailsPageState;
            args.filters.chipplyProductColorSizeId = this.currentItem?.chipplyProductColorSizeId as number;
            if (!args.filters.chipplyProductColorSizeId && this.currentItem?.eventProductColorSizeId) {
                // Handle custom products
                args.filters.eventProductColorSizeId = this.currentItem?.eventProductColorSizeId;
            } else if (!args.filters.chipplyProductColorSizeId && this.currentItem?.orderItemId) {
                // Handle bad data where there is no chipplyProductColorSizeId
                args.filters.orderItemId = this.currentItem?.orderItemId;
            }

            if (this.currentItem?.processId && this.currentItem.processId > 0) {
                args.filters.selectedProcessIds = [this.currentItem.processId];
            }

            if (this.currentItem?.catalogBatchId && this.currentItem.catalogBatchId > 0) {
                args.filters.catalogBatchIds = [this.currentItem.catalogBatchId];
            }

            args.sortProperties = this.selectedDetailsSortProperties;
            const results = await WebHelper.postJsonData("/api/purchasing/listorderitemdetails", args);
            const deserializedResults = JSON.parse(results) as IGetPurchaseOrderItemDetailsResults;
            this.currentItemImageUrl = deserializedResults.imageUrl;
            this.details.push(...deserializedResults.details);
            this.detailsPageState.next(deserializedResults.details.length);

            if (this.currentItem && this.currentItem.selectedDetails && this.currentItem.selectedDetails.length > 0) {
                for (const detail of this.details) {
                    for (const selectedDetail of this.currentItem.selectedDetails) {
                        if (detail.orderItemId === selectedDetail.orderItemId) {
                            this.selectedDetails.push(detail);
                        }
                    }
                }
            }
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public async showOrderItemTimeline(item: IPurchaseOrderItemDetail): Promise<void> {
        this.currentHistory.splice(0);
        await this.listOrderItemHistory(item);
        this.isTimelineVisible = true;
    }

    public hideOrderItemTimeline() {
        this.isTimelineVisible = false;
        this.currentHistory.splice(0);
    }

    public isSubstituteProductEnabled(): boolean {
        if (this.selectedLines.length === 0) {
            return false;
        }
        for (const currentLine of this.selectedLines) {
            if (currentLine.wasManuallyOrdered) {
                return false;
            }
        }

        if (this.selectedLines.length === 1) {
            return true;
        }

        const firstLine = this.selectedLines[0];
        if (!firstLine.chipplyProductColorSizeId) {
            return false;
        }
        for (const line of this.selectedLines) {
            if (!line.chipplyProductColorSizeId) {
                return false;
            }
            if (
                line.vendorId !== firstLine.vendorId ||
                line.style !== firstLine.style ||
                line.color !== firstLine.color
            ) {
                return false;
            }
        }
        return true;
    }

    public async listOrderItemHistory(item: IPurchaseOrderItemDetail): Promise<void> {
        try {
            this.purchasingViewModel.statusMessage = "Loading...";
            this.purchasingViewModel.isLoading = true;
            const args = new ListOrderItemHistoryArgs();
            args.orderItemId = item.orderItemId;
            const results = await WebHelper.postJsonData("/api/purchasing/listorderitemhistory", args);
            const deserializedResults = JSON.parse(results) as IListOrderItemHistoryResults;
            this.currentHistory.push(...deserializedResults.history);
        } finally {
            this.purchasingViewModel.isLoading = false;
        }
    }

    public shipMethodChange() {
        this.setSanMarHintAsNeeded();
    }

    public customerPickupChanged() {
        if (this.isCustomerPickup) {
            this.orderingShippingOptions = PurchaseOrderingShippingOptions.None;
            this.isExpandWarehouseOptions = false;
            if (this.customerPickupWarehouseId! > 0) {
                const pickupWarehouse = this.warehouses.filter(
                    (x) => x.warehouseId == this.customerPickupWarehouseId
                )[0];
                this.selectedLines.forEach((x) => {
                    x.warehouse = pickupWarehouse.warehouseName;
                });
            }
        } else {
            this.orderingShippingOptions = PurchaseOrderingShippingOptions.Consolidated;
            this.selectedLines.forEach((x) => {
                if (x.consolidatedWarehouseId > 0) {
                    x.warehouse = this.warehouses.find(
                        (w) => w.warehouseId == x.consolidatedWarehouseId
                    )!.warehouseName;
                } else {
                    x.warehouse = "";
                }
            });
        }
    }

    public async add(): Promise<void> {
        this.purchasingViewModel.productsSelectorHeading = "";

        const result = await this.purchasingViewModel.selectProducts();
        if (!result) {
            return;
        }
        const selectedEventProductId = result;
        this.productColorSizeSelectorViewModel = new ProductColorSizeSelectorViewModel();
        this.productColorSizeSelectorViewModel.eventProductId = selectedEventProductId;
        this.purchasingViewModel.isProductColorSizeSelectorVisible = true;

        this.purchasingViewModel.isLoading = true;
        await this.productColorSizeSelectorViewModel.getColorSizeInfo(selectedEventProductId);

        this.purchasingViewModel.isLoading = false;
        this.productColorSizeSelectorViewModel.isPerformingManualAdd = true;
        const results = await this.productColorSizeSelectorViewModel.edit();
        if (results.canceled) {
            this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
            this.productColorSizeSelectorViewModel = null;
            return;
        }
        const mappings = this.productColorSizeSelectorViewModel.toManualSizeMappings();

        const args = new ManualAddPurchasingItemArgs();
        for (const mapping of mappings) {
            if (mapping.chipplyProductColorSizeId && mapping.quantity > 0) {
                const item = new ManualAddPurchasingItem();
                item.eventProductColorSizeId = mapping.chipplyProductColorSizeId;
                item.quantity = mapping.quantity;
                args.items.push(item);
            }
        }

        const manualAddResults = await this.getManualAddItems(args);
        this.purchasingViewModel.isProductColorSizeSelectorVisible = false;
        this.productColorSizeSelectorViewModel = null;
        this.lines.push(...manualAddResults.lines);
        this.selectedLines.push(...manualAddResults.lines);
    }

    public toggleShippingOptions() {
        if (this.orderingShippingOptions == PurchaseOrderingShippingOptions.Closest) {
            this.orderingShippingOptions = PurchaseOrderingShippingOptions.Consolidated;
        } else if (this.orderingShippingOptions == PurchaseOrderingShippingOptions.Consolidated) {
            this.orderingShippingOptions = PurchaseOrderingShippingOptions.Closest;
        } else if (this.orderingShippingOptions == PurchaseOrderingShippingOptions.None) {
            this.orderingShippingOptions = PurchaseOrderingShippingOptions.Consolidated;
        }

        this.lines.forEach((line) => {
            const warehouseId =
                this.orderingShippingOptions == PurchaseOrderingShippingOptions.Closest
                    ? line.closestWarehouseId
                    : line.consolidatedWarehouseId;
            if (warehouseId > 0) {
                line.warehouse = this.calculateWarehouse(warehouseId, line);
                //clean other options quantity to order when toggle shipping mode
                line.warehouseOptions.every((x) => {
                    if (x.warehouseId == warehouseId) {
                        x.quantityToOrder = line.quantityToOrder;
                    } else {
                        x.quantityToOrder = 0;
                    }
                    return true;
                });
            }
        });
    }

    protected async getManualAddItems(args: ManualAddPurchasingItemArgs): Promise<ManualAddPurchasingItemResults> {
        const results = await WebHelper.postJson<ManualAddPurchasingItemResults>("/api/purchasing/manualadd", args);
        return results;
    }

    protected setSanMarHintAsNeeded() {
        if (this.vendorSettings instanceof SanMarPurchaseOrderSettings) {
            const shipMethod = this.vendorSettings.shipMethod;
            const item = this.vendorSettings.getAvailableShipMethods().find((x) => x.method === shipMethod);
            this.shipMethodHint = item ? item.description : "";
        } else {
            this.shipMethodHint = "";
        }
    }

    protected applySort() {
        if (this.sortBy.length === 0) {
            this.sortBy = ["availability", "vendorName", "style", "color", "size"];
            this.sortDesc = [true, false, false, false, false];
        }
    }

    protected async performColorAssignments(substituteProductResults: ISubstituteProductResults): Promise<void> {
        this.colorAssignmentViewModel = new PurchasingColorAssignmentPageViewModel(
            this.purchasingViewModel as PurchasingViewModel
        );
        this.colorAssignmentViewModel.newlyAddedProducts = substituteProductResults.newlyAddedProducts;
        this.colorAssignmentViewModel.total = substituteProductResults.newlyAddedProducts.length;
        this.purchasingViewModel.isColorAssignmentVisible = true;
        await this.colorAssignmentViewModel.next();
    }

    protected async refreshDetails(): Promise<void> {
        this.detailsPageState.reset();
        this.details.splice(0);
        this.selectedDetails.splice(0);

        const refreshCurrentItem = (item: IPurchaseOrderLineItem) => {
            if (this.isGroupingByProcess) {
                if (
                    this.currentItem!.processId === item.processId &&
                    this.currentItem!.catalogBatchId === item.catalogBatchId
                ) {
                    this.currentItem = item;
                }
            } else {
                this.currentItem = item;
            }
        };

        for (const item of this.lines) {
            if (item.chipplyProductColorSizeId) {
                if (this.currentItem && this.currentItem.chipplyProductColorSizeId === item.chipplyProductColorSizeId) {
                    refreshCurrentItem(item);
                    break;
                }
            } else if (item.eventProductColorSizeId) {
                if (this.currentItem && this.currentItem.eventProductColorSizeId === item.eventProductColorSizeId) {
                    refreshCurrentItem(item);
                    break;
                }
            }
        }
        await this.listDetails();
    }

    protected async sendEmail(accepted: boolean, emailTemplate: PurchasingMergeTemplateViewModel) {
        this.purchasingViewModel.isEmailWindowVisible = false;
        this.purchasingViewModel.statusMessage = "Saving...";
        this.purchasingViewModel.isLoading = true;
        emailTemplate.shouldIncludeUpc = this.shouldIncludeUpc;
        emailTemplate.shouldIncludePrice = this.shouldIncludePrice;
        try {
            await this.saveEmailTemplate(emailTemplate.toModel());
        } catch {
            this.purchasingViewModel.isLoading = false;
            this.purchasingViewModel.errorMessage = Utils.ServerErrorMessage;
            return;
        }
        this.purchasingViewModel.isLoading = false;
        const scheduledDate = emailTemplate.getDate(emailTemplate.staticDate!, emailTemplate.staticTime!);
        let vm: SimpleAsyncInteractionViewModel | null = null;
        if (DateTime.fromISO(scheduledDate) <= DateTime.local()) {
            vm = new SimpleAsyncInteractionViewModel();
            vm.headerText = "Message Sent";
            vm.text = "The message has successfully been sent.";
        } else {
            vm = new SimpleAsyncInteractionViewModel();
            vm.headerText = "Message Scheduled";
            vm.text = "The message has successfully been scheduled to be sent.";
        }
        this.dismissDialogViewModel = vm;
        await this.dismissDialogViewModel.interact();
        this.dismissDialogViewModel = null;
    }

    protected async saveEmailTemplate(template: IPurchasingMergeTemplate): Promise<any> {
        const purchaseOrderIds: number[] = [];
        if (this.lastPurchaseOrderId) {
            purchaseOrderIds.push(this.lastPurchaseOrderId);
        }
        if (this.purchaseOrderId) {
            purchaseOrderIds.push(this.purchaseOrderId);
        }
        const args = new SendPurchasingEmailArgs(
            this.toPurchaseOrder(),
            this.filters,
            template,
            purchaseOrderIds,
            this.lastPurchasingReportType != null ? this.lastPurchasingReportType : this.getPurchasingReportType()
        );
        args.purchaseOrder.purchaseOrderId = this.lastPurchaseOrderId;
        if (this.purchaseOrderId) {
            args.purchaseOrder.purchaseOrderId = this.purchaseOrderId;
        }
        await WebHelper.postJsonData("/api/purchasing/sendemail", args);
    }

    protected getPurchasingReportType(): PurchasingReportType {
        let reportType = this.reportType;
        if (this.lastPurchaseOrderType === "Pulled") {
            reportType = "Pulled";
        } else if (this.isGroupingByProcess) {
            reportType = "Details";
        } else {
            reportType = "Summary";
        }
        return reportType;
    }

    protected applyLineItem(sourceLineItem: IPurchaseOrderLineItem, matchingLine: IPurchaseOrderLineItem) {
        PurchaseOrderHelper.updatePurchasingLineItem(sourceLineItem, matchingLine);
        matchingLine.warehouse = sourceLineItem.warehouse;
        matchingLine.productCost = sourceLineItem.productCost;
        matchingLine.totalCost = sourceLineItem.totalCost;

        if (sourceLineItem.warehouseOptions && sourceLineItem.warehouseOptions.length > 0) {
            matchingLine.warehouseOptions = sourceLineItem.warehouseOptions;
            matchingLine.consolidatedWarehouseId = sourceLineItem.consolidatedWarehouseId;
            matchingLine.closestWarehouseId = sourceLineItem.closestWarehouseId;
        }
    }

    public calculateWarehouse(warehouseId: number, lineItem: IPurchaseOrderLineItem) {
        if (warehouseId > 0) {
            const option = lineItem.warehouseOptions.find((x) => x.warehouseId == warehouseId);
            if (option) {
                return option.warehouseName;
            }
        }
        return "";
    }

    protected toPurchaseOrder(): VendorPurchaseOrder {
        this.harvestSelectedDetailsAsNeeded();
        const purchaseOrder = new VendorPurchaseOrder();
        purchaseOrder.lines = this.selectedLines;
        purchaseOrder.orderingShippingOptions = this.orderingShippingOptions;
        purchaseOrder.isCustomerPickup = this.isCustomerPickup;
        purchaseOrder.excludedWarehouseIds = this.warehouses
            .map((x) => x.warehouseId)
            .filter((x) => this.selectedWarehouseIds.indexOf(x) == -1);

        PurchaseOrderHelper.updatePurchaseOrder(purchaseOrder, this);

        if (purchaseOrder.type == PurchasingConstants.pulledPurchaseType) {
            purchaseOrder.lines.forEach((x) => {
                x.warehouse = "";
                if (x.warehouseOptions) {
                    x.warehouseOptions.forEach((w) => (w.quantityToOrder = 0));
                }
            });
        } else if (this.isCustomerPickup && this.customerPickupWarehouseId! > 0) {
            const pickupWarehouse = this.warehouses.filter((x) => x.warehouseId == this.customerPickupWarehouseId)[0];
            purchaseOrder.lines.forEach((x) => {
                x.warehouse = "";
                if (x.warehouseOptions) {
                    x.warehouseOptions.forEach((w) => (w.quantityToOrder = 0));
                }
                x.warehouse = pickupWarehouse.warehouseName;
            });
            /**
             * When customer choose to pick up from warehouse, the ship to address is not in use.
             * However, the sanmar api requires address info,
             * Place dealer branch as the ship to address to workaround sanmar API validation.
             *
             * Due to this, the ship to field in the Check Availability & Order Digitally is hidden.
             */
            if (this.dealerBranchId && this.dealerBranchId > 0) {
                purchaseOrder.dealerBranchId = this.dealerBranchId;
            } else {
                purchaseOrder.dealerBranchId = this.branches[0].value;
            }
        }

        return purchaseOrder;
    }

    protected toViewModel(purchaseOrder: IVendorPurchaseOrder<IPurchaseOrderLineItem>) {
        super.toViewModel(purchaseOrder);
        if (this.isGroupingByProcess) {
            purchaseOrder.lines.every((x) => (x.processHeader = `${x.saleOrder} ${x.processName}`));
        }
        this.isCustomerPickup = purchaseOrder.isCustomerPickup;
    }

    protected harvestSelectedDetailsAsNeeded() {
        if (this.currentItem) {
            // If there are selected details, track them on the parent item for later processing
            this.currentItem.selectedDetails = [];
            if (this.selectedDetails.length > 0) {
                this.currentItem.selectedDetails.push(...this.selectedDetails);
            }
        }
    }

    public chooseWarehouse(warehouseOption: IPurchaseOrderItemWarehouseOption, item: IPurchaseOrderLineItem) {
        item.warehouse = warehouseOption.warehouseName;
        //clean warehouse options quantity for single warehouse
        item.warehouseOptions.every((x) => {
            x.quantityToOrder = 0;
            return true;
        });
    }

    public chooseWarehouseById(warehouseId: number, item: IPurchaseOrderLineItem) {
        if (warehouseId > 0) {
            const warehouse = this.calculateWarehouse(warehouseId, item);
            item.warehouse = warehouse;
            //clean warehouse options quantity for single warehouse
            item.warehouseOptions.every((x) => {
                x.quantityToOrder = 0;
                return true;
            });
        }
    }

    protected getListUrl(): string {
        const baseUrl = "/api/purchasing/list";
        return baseUrl;
    }

    protected async handleLargePurchaseOrder(): Promise<void> {
        const vm = new SimpleAsyncInteractionViewModel();
        vm.headerText = "Select Stores";
        vm.text = `The configured purchase order exceeds the configured size limit.  Please select a smaller number of stores or processes.`;
        this.dismissDialogViewModel = vm;
        const dialogResults = await this.dismissDialogViewModel.interact();
        this.dialogViewModel = null;
        this.back();
    }

    private closeDetails(): void {
        this.currentItem = null;
        this.isViewingDetails = false;
        this.headers.splice(0);
        this.headers.push(...this.allHeaders);
    }

    protected allowDigitalOrder() {
        for (const line of this.selectedLines) {
            if (line.availability !== "Available") {
                return false;
            }
        }
        return true;
    }
}
