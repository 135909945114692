export default class PurchasingConstants {
    public static readonly artworkPurchaseType: PurchasingType = "Artwork";
    public static readonly autoPurchaseType: PurchasingType = "Auto";
    public static readonly manualPurchaseType: PurchasingType = "Manual";
    public static readonly clearedPurchaseType: PurchasingType = "Cleared";
    public static readonly manualArtworkPurchaseType: PurchasingType = "ManualArtwork";
    public static readonly pulledArtworkPurchaseType: PurchasingType = "PulledArtwork";
    public static readonly clearedArtworkPurchaseType: PurchasingType = "ClearedArtwork";
    public static readonly pulledPurchaseType: PurchasingType = "Pulled";
    public static readonly checkPurchaseType: PurchasingType = "Check";

    public static readonly inHouseVendorId = 1600;

    public static readonly inHousePurchasingVendorId = 8;

    public static readonly purchaseTypeValueTextMap: Map<PurchasingType, string> = new Map<PurchasingType, string>([
        [PurchasingConstants.autoPurchaseType, "Digital Order"],
        [PurchasingConstants.manualPurchaseType, "Manual Order"],
        [PurchasingConstants.pulledPurchaseType, "Pulled From Stock"],
        [PurchasingConstants.artworkPurchaseType, "Digital Artwork Order"],
        [PurchasingConstants.manualArtworkPurchaseType, "Manual Artwork Order"],
        [PurchasingConstants.pulledArtworkPurchaseType, "Pulled Artwork"],
        [PurchasingConstants.clearedArtworkPurchaseType, "Ignored Artwork"],
        [PurchasingConstants.clearedPurchaseType, "Cleared"],
    ]);
}

export type PurchasingType =
    | "Auto"
    | "Artwork"
    | "Manual"
    | "Pulled"
    | "Cleared"
    | "Check"
    | "ManualArtwork"
    | "PulledArtwork"
    | "ClearedArtwork";
