import ChipplyConstants from "@/chipply/ChipplyConstants";
import { AsyncInteractionViewModel, SimpleAsyncInteractionWithDataViewModel, WebHelper } from "chipply-common";
import { IPrintavoCustomer } from "./IPrintavoCustomer";
import { IPrintavoOrderStatus } from "./IPrintavoOrderStatus";
import { IPrintavoUser } from "./IPrintavoUser";
import { PrintavoExportArguments } from "./PrintavoExportArguments";

export default class PrintavoExportViewModel extends AsyncInteractionViewModel {
    public loading = false;
    public listLoading = false;
    public customers: IPrintavoCustomer[] = [];
    public orderStatuses: IPrintavoOrderStatus[] = [];
    public users: IPrintavoUser[] = [];
    public searchTerm = "";
    public eventId = 0;
    public catalogBatchId = 0;
    public eventProcessIds: number[] = [];
    public orderStatusId = 0;
    public resultViewModel: SimpleAsyncInteractionWithDataViewModel<boolean> | null = null;
    public printavoPageViewModel = false;

    public async init() {
        await this.listUsers();
        await this.listOrderStatuses();
    }
    public _userId = 0;
    public get userId() {
        return this._userId;
    }
    public set userId(v: number) {
        this._userId = v;
    }
    public _customerId = 0;
    public get customerId() {
        return this._customerId;
    }
    public set customerId(v) {
        this._customerId = v;
    }
    public get enablePrintavoAccept() {
        const customerSelected = this.customerId !== 0 && this.customerId !== null;
        const userSelected = this.userId !== 0 && this.userId !== null;
        return customerSelected && userSelected;
    }
    public async searchCustomers(value: string): Promise<void> {
        if (!this.searchTerm) {
            return;
        }
        try {
            this.listLoading = true;
            this.loading = true;
            const url = "/api/Printavo/ListCustomers";
            const responseText = await WebHelper.postJsonData(url, {
                query: this.searchTerm,
            });
            const response = JSON.parse(responseText);
            this.customers.splice(0);
            this.customers.push(...response.data);
        } catch (e) {
            this.resultViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.resultViewModel.data = false;
            this.resultViewModel.text = ChipplyConstants.UNKNOWN_ERROR;
            await this.resultViewModel.interact();
            this.resultViewModel = null;
        } finally {
            this.listLoading = false;
            this.loading = false;
        }
    }

    public async listOrderStatuses(): Promise<void> {
        try {
            this.loading = true;
            const url = "/api/Printavo/ListOrderStatuses";
            const responseText = await WebHelper.postJsonData(url, {});
            const response = JSON.parse(responseText);
            this.orderStatuses.splice(0);
            this.orderStatuses.push(...response.data);
            return response.data;
        } catch (e) {
            this.resultViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.resultViewModel.data = false;
            this.resultViewModel.text = ChipplyConstants.UNKNOWN_ERROR;
            await this.resultViewModel.interact();
            this.resultViewModel = null;
        } finally {
            this.loading = false;
        }
    }

    public async listUsers(): Promise<void> {
        try {
            this.loading = true;
            const url = "/api/Printavo/ListUSers";
            const responseText = await WebHelper.postJsonData(url, {});
            const response = JSON.parse(responseText);
            this.users.splice(0);
            this.users.push(...response.data);
            return response.data;
        } catch (e) {
            this.resultViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.resultViewModel.data = false;
            this.resultViewModel.text = ChipplyConstants.UNKNOWN_ERROR;
            await this.resultViewModel.interact();
            this.resultViewModel = null;
        } finally {
            this.loading = false;
        }
    }

    public async exportToPrintavo(): Promise<void> {
        try {
            this.loading = true;
            const url = "/api/Printavo/ExportToPrintavo";
            const args = new PrintavoExportArguments();
            args.customerId = this.customerId;
            args.eventId = this.eventId;
            args.catalogBatchId = this.catalogBatchId;
            args.eventProcessIds = this.eventProcessIds;
            args.orderStatusId = this.orderStatusId;
            args.userId = this.userId;
            const responseText = await WebHelper.postJsonData(url, args);

            this.resultViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.resultViewModel.data = true;
            this.resultViewModel.text = "The export to Printavo completed successfully.";
            if (this.printavoPageViewModel) {
                await this.resultViewModel.interact();
                this.resultViewModel = null;
            }
            this.done("continue");
        } catch (e) {
            this.resultViewModel = new SimpleAsyncInteractionWithDataViewModel<boolean>();
            this.resultViewModel.data = false;
            this.resultViewModel.text = ChipplyConstants.UNKNOWN_ERROR;
            if (this.printavoPageViewModel) {
                await this.resultViewModel.interact();
                this.resultViewModel = null;
            }
        } finally {
            this.loading = false;
        }
    }
}
