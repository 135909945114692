export class ImageReviewViewModel {
    public imageUrl: string | null = null;
    public isVisible = false;
    public sortOrder = 0;
    public isPrimary = false;
    public isArtwork!: boolean;
    public hexCode1!: string;

    public openImagePreview() {
        if (this.imageUrl) {
            window.open(this.imageUrl, "_blank");
        }
    }
}
