import { DateTime } from "luxon";
import ICompanyUserModel from "./ICompanyUserModel";

export default class CompanyUserItemViewModel {
    public userId = 0;
    public userName = "";
    public userLevelId!: number;
    public companyId = 0;
    public dealerId = 0;
    public firstName = "";
    public lastName = "";
    public email = "";
    public email2 = "";
    public phone = "";
    public phone2 = "";
    public enabled = true;
    public canLogin = false;
    public recoveryEmail = "";
    public recoveryPhone = "";
    public createdOn: string | null = null;
    public updatedOn: string | null = null;
    public createdBy: string | null = null;
    public updatedBy: string | null = null;

    public constructor(dto?: ICompanyUserModel) {
        if (dto) {
            this.userId = dto.userId;
            this.userName = dto.userName;
            this.userLevelId = dto.userLevelId;
            this.companyId = dto.companyId;
            this.dealerId = dto.dealerId;
            this.firstName = dto.firstName;
            this.lastName = dto.lastName;
            this.email = dto.email;
            this.email2 = dto.email2;
            this.phone = dto.phone;
            this.phone2 = dto.phone2;
            this.enabled = dto.enabled;
            this.recoveryEmail = dto.recoveryEmail;
            this.recoveryPhone = dto.recoveryPhone;
            this.createdOn = dto.createdOn;
            this.updatedOn = dto.updatedOn;
            this.createdBy = dto.createdBy;
            this.updatedBy = dto.updatedBy;
        }
    }
}
