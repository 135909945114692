









































































































import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import ITreeItem from "@/chipply/interface/i-tree-item";

@Component({
    props: {
        ids: Array,
        treeItems: Array,
        disabled: Boolean,
    },
    model: {
        prop: "ids",
        event: "change",
    },
})
export default class SpreadsheetProductCategorySelector extends Vue {
    public ids!: number[];
    public showMenu = false;
    public selectedItemsDisplay = "Categories";
    public treeItems!: ITreeItem[];
    public selectedIds!: number[];
    public treeIds: number[] = [];
    public disabled!: boolean;
    public selectAllChecked = false;

    public checkAll(checked: boolean) {
        const allCats = [];

        if (checked) {
            for (const cat of this.treeItems) {
                allCats.push(cat.id);

                for (const subcat of cat.children) {
                    allCats.push(subcat.id);
                }
            }
        }

        this.treeIds = allCats;
    }

    public updateSelectAll() {
        let checkAll = true;
        for (const cat of this.treeItems) {
            if (cat.children && cat.children.length > 0) {
                for (const subcat of cat.children) {
                    if (!this.treeIds.includes(subcat.id)) {
                        checkAll = false;
                    }
                }
            } else if (!this.treeIds.includes(cat.id)) {
                checkAll = false;
            }
        }

        this.selectAllChecked = checkAll;
    }

    public onAccept() {
        this.selectedIds = this.treeIds;
        this.updateSelectedItemsDisplay();
        this.$emit("change", this.selectedIds);
        this.closeMenu();
    }

    public onCancel() {
        this.treeIds = this.selectedIds;
        this.closeMenu();
    }

    public closeMenu() {
        this.showMenu = false;
    }

    public updateSelectedItemsDisplay() {
        if (this.selectedIds.length === 0) {
            this.selectedItemsDisplay = "Categories";
        } else {
            let categoryNames: string[] = [];
            for (const cat of this.treeItems) {
                if (this.selectedIds.includes(cat.id)) {
                    categoryNames.push(cat.name);
                }

                for (const subcat of cat.children) {
                    if (this.selectedIds.includes(subcat.id)) {
                        categoryNames.push(subcat.name);
                    }
                }
            }
            this.selectedItemsDisplay = categoryNames.join(", ");
        }
    }

    public created() {
        this.onIdsChanged();
    }

    @Watch("ids")
    public onIdsChanged() {
        this.selectedIds = this.ids;
        const treeIds: number[] = [];
        treeIds.push(...this.ids);
        this.treeIds = treeIds;
        this.updateSelectedItemsDisplay();
        this.updateSelectAll();
    }
}
