import { IEventArtworkVariation } from "@/chipply/interface/i-event-artwork-variation";
import { IGetProcessLayoutResults } from "@/chipply/process/IGetProcessLayoutResults";
import { IProcessArtworkBox } from "@/chipply/process/IProcessArtworkBox";
import { IProcessImageData } from "@/chipply/process/IProcessImageData";
import { ProcessLayoutCanvasViewModel } from "@/chipply/process/ProcessLayoutCanvasViewModel";
import { ProcessLayoutPageViewModelBase } from "@/chipply/process/ProcessLayoutPageViewModelBase";
import { IGetProductLayoutResults } from "@/chipply/products/IGetProductLayoutResults";
import { IProductColorArtworkVariation } from "@/chipply/products/IProductColorArtworkVariation";
import { WebHelper } from "chipply-common";

export class ProductLayoutPageViewModel extends ProcessLayoutPageViewModelBase {
    public colors: { text: string; hexCode1: string; colorId: number }[] = [];

    public colorArtworkVariations: IProductColorArtworkVariation[] = [];
    public optionArtworkVariations: {
        [artworkId: number]: IEventArtworkVariation[];
    } = {};

    public selectedColorId = 0;
    public style = "";
    public vendorName = "";
    public productId = 0;

    public async get(productId: number) {
        this.statusMessage = "Loading...";
        this.loading = true;
        const data = (await WebHelper.getJsonData(`/api/ProductLayout/${productId}`)) as IGetProductLayoutResults;

        this.productId = productId;
        this.colorArtworkVariations = data.colorArtworkVariations;
        this.vendorName = data.vendorName;
        this.style = data.style;
        this.optionArtworkVariations = data.optionArtworkVariations;

        this.populateColors();
        this.selectedColorId = data.displayImageColorId > 0 ? data.displayImageColorId : this.colors[0].colorId;
        for (const imageNumber of Object.keys(data.imageDatas)) {
            const vm = new ProcessLayoutCanvasViewModel();
            vm.imageNumber = Number(imageNumber);
            vm.imageData = data.imageDatas[vm.imageNumber];
            this.setArtwork(vm, this.selectedColorId);
            vm.gutters = this.gutters;
            this.imageLayoutViewModels.push(vm);
        }

        this.unplacedBoxes = data.unplacedBoxes;
        this.loading = false;
    }

    public async save(): Promise<boolean> {
        this.statusMessage = "Saving...";
        this.loading = true;
        const boxes: { [imageNumber: number]: IProcessArtworkBox[] } = {};

        for (const vm of this.imageLayoutViewModels) {
            boxes[vm.imageNumber] = vm.imageData!.boxes;
        }

        await WebHelper.postJsonData("/api/ProductLayout", {
            productId: this.productId,
            boxes,
            colorArtworkVariations: this.colorArtworkVariations,
        });

        this.raiseClose();
        this.loading = false;
        return true;
    }

    public async add(imageNumber: number) {
        await super.add(imageNumber);
        if (this.colorArtworkVariations.length > 1) {
            setTimeout(() => {
                //Force refresh of artwork selected incase it has more than 1 variations.
                this.productColorChanged();
            });
        }
    }

    public productColorChanged() {
        const viewModels: ProcessLayoutCanvasViewModel[] = [];
        for (const vm of this.imageLayoutViewModels) {
            this.setArtwork(vm, this.selectedColorId);
            viewModels.push(vm);
        }

        this.imageLayoutViewModels = [];

        // Need the setTimeout for vue change detection to work properly
        setTimeout(() => {
            this.imageLayoutViewModels = viewModels;
        });
    }

    public artworkVariationChanged(box: IProcessArtworkBox) {
        this.isModified = true;
        let existingVariationFound = false;

        for (const optionVariation of this.optionArtworkVariations[box.artworkId]) {
            if (optionVariation.eventArtworkVariationId !== box.artworkVariationId) {
                continue;
            }
            box.artworkPath = optionVariation.imageUrl;
            box.artworkVariationId = optionVariation.eventArtworkVariationId;
            box.artworkVariationName = optionVariation.variationName;

            for (const variation of this.colorArtworkVariations) {
                if (variation.eventProductColorId !== this.selectedColorId) {
                    continue;
                }
                if (variation.eventArtworkId !== box.artworkId) {
                    continue;
                }
                existingVariationFound = true;
                variation.variationImage = box.artworkPath;
                variation.decorationColor = box.artworkVariationName;
                variation.eventArtworkVariationId = box.artworkVariationId;
                variation.isChanged = true;
                break;
            }
        }

        if (!existingVariationFound) {
            this.defaultNewVariation(box);
        }

        const viewModels: ProcessLayoutCanvasViewModel[] = [];
        for (const vm of this.imageLayoutViewModels) {
            viewModels.push(vm);
        }

        this.imageLayoutViewModels = [];

        // Need the setTimeout for vue change detection to work properly
        setTimeout(() => {
            this.imageLayoutViewModels = viewModels;
        });
    }

    public cancel() {
        this.raiseClose();
    }

    protected raiseClose() {
        window.parent.postMessage("edit-product-layout-close", "*");
    }

    protected populateColors() {
        this.colors = [];
        const colorIds: number[] = [];
        for (const artworkVariation of this.colorArtworkVariations) {
            if (colorIds.indexOf(artworkVariation.eventProductColorId) > -1) {
                continue;
            }
            colorIds.push(artworkVariation.eventProductColorId);
            this.colors.push({
                text: artworkVariation.colorName,
                hexCode1: artworkVariation.hexCode1,
                colorId: artworkVariation.eventProductColorId,
            });
        }
    }

    private setArtwork(vm: ProcessLayoutCanvasViewModel, colorId: number) {
        const imageData = vm.imageData!;

        for (const box of imageData.boxes) {
            for (const variation of this.colorArtworkVariations) {
                if (variation.eventProductColorId !== colorId) {
                    continue;
                }
                if (variation.eventArtworkId !== box.artworkId) {
                    continue;
                }
                box.artworkPath = variation.variationImage;
                box.artworkVariationName = variation.decorationColor;
                box.artworkVariationId = variation.eventArtworkVariationId;
                break;
            }
        }

        // Need separate loop in case image has no boxes
        for (const variation of this.colorArtworkVariations) {
            if (variation.eventProductColorId !== colorId) {
                continue;
            }

            this.setCanvasImage(vm.imageNumber, imageData, variation);
            break;
        }
    }

    private setCanvasImage(
        imageNumber: number,
        imageData: IProcessImageData,
        variation: IProductColorArtworkVariation
    ) {
        switch (imageNumber) {
            case 1:
                imageData.canvasImageUrl = variation.productImage1LargeUrl;
                break;
            case 2:
                imageData.canvasImageUrl = variation.productImage2LargeUrl;
                break;
            case 3:
                imageData.canvasImageUrl = variation.productImage3LargeUrl;
                break;
            case 4:
                imageData.canvasImageUrl = variation.productImage4LargeUrl;
                break;
            default:
                throw new Error("Not Implemented");
        }

        if (!imageData.canvasImageUrl) {
            imageData.canvasBackgroundColor = variation.hexCode1;
        }
    }

    private defaultNewVariation(box: IProcessArtworkBox) {
        let colorPropertiesTemplate: IProductColorArtworkVariation | null = null;
        for (const variation of this.colorArtworkVariations) {
            if (variation.eventProductColorId !== this.selectedColorId) {
                continue;
            }

            colorPropertiesTemplate = variation;
        }

        if (colorPropertiesTemplate == null) {
            throw new Error(`Could not default, color id ${this.selectedColorId} is not present`);
        }

        const newVariation: IProductColorArtworkVariation = {
            isChanged: true,
            decorationColor: box.artworkVariationName,
            variationImage: box.artworkPath,
            hexCode1: colorPropertiesTemplate.hexCode1,
            eventArtworkVariationId: box.artworkVariationId,
            eventArtworkId: box.artworkId,
            productImage1LargeUrl: colorPropertiesTemplate.productImage1LargeUrl,
            productImage2LargeUrl: colorPropertiesTemplate.productImage2LargeUrl,
            productImage3LargeUrl: colorPropertiesTemplate.productImage3LargeUrl,
            productImage4LargeUrl: colorPropertiesTemplate.productImage4LargeUrl,
            eventProductColorId: colorPropertiesTemplate.eventProductColorId,
            colorName: colorPropertiesTemplate.colorName,
            artName: box.artworkName,
            artType: "",
            eventProductId: colorPropertiesTemplate.eventProductId,
            productName: colorPropertiesTemplate.productName,
            eventArtworkVariationColorLinkId: 0,
        };

        this.colorArtworkVariations.push(newVariation);
    }
}
