







































import { Utils } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
    props: {
        initialFundraisingDollars: Number,
        initialFundraisingPercent: Number,
        value: Object,
    },
})
export default class FundraisingSelection extends Vue {
    @Prop({
        default: false,
        type: Boolean,
    })
    public singleLine!: boolean;

    public name = "FundraisingSelection";

    public initialFundraisingDollars!: number | undefined;
    public initialFundraisingPercent!: number | undefined;

    public fundraisingDollars!: number | undefined;
    public fundraisingPercent!: number | undefined;

    public data() {
        return {
            Utils,
            fundraisingDollars: this.initialFundraisingDollars,
            fundraisingPercent: this.initialFundraisingPercent,
        };
    }

    protected fundraisingDollarsChanged($event: KeyboardEvent) {
        this.fundraisingPercent = undefined;
        Utils.preventNonNumericInput($event, this.fundraisingDollars);
    }

    protected fundraisingPercentChanged($event: KeyboardEvent) {
        this.fundraisingDollars = undefined;
        Utils.preventNonNumericInput($event, this.fundraisingPercent);
    }

    protected updateModel() {
        this.$emit("input", {
            fundraisingDollars: this.fundraisingDollars,
            fundraisingPercent: this.fundraisingPercent,
        });
    }
}
