import { ListPageViewModel } from "../view-model/ListPageViewModel";
import { WebHelper, Serializer, Address, Utils } from "chipply-common";
import { ITextValueDisabled } from "chipply-common";
import DealerBranchModel from "./DealerBranchModel";
import ListDealerBranchesResults from "./ListDealerBranchesResults";
import OrganizationContactEditViewModel from "../organization/OrganizationContactEditViewModel";
import DealerBranchSaveArgs from "./DealerBranchSaveArgs";
import PageState from "../PageState";
import IVuetifyTableOptions from "../interface/IVuetifyTableOptions";
import OrganizationBranchEditViewModel from "../organization/OrganizationBranchEditViewModel";
import { UrlUtils } from "chipply-common";
import NavigationArea from "../NavigationArea";
import OrganizationContactSaveArgs from "../organization/OrganizationContactSaveArgs";
import StoreOrganizationContactModel from "../organization/StoreOrganizationContactModel";
import * as _ from "lodash";
import { AsyncInteractionViewModel } from "chipply-common";
import { NexusTaxMessageCardViewModel } from "@/chipply/dealer/NexusTaxMessageCardViewModel";
import { IDealerBranchesSaveResults } from "@/chipply/dealer/IDealerBranchesSaveResults";

export default class DealerBranchesViewModel extends ListPageViewModel {
    public items: DealerBranchModel[] = [];
    public isDetailViewVisible = false;
    public isAdmin = false;
    public disableGutters = true;
    public currentItem: DealerBranchModel | null = null;
    public originalItem: DealerBranchModel | null = null;
    public saving = false;
    public sameAsBranchInfo = false;
    public addressTypes = [
        { value: 1, text: "Commercial" },
        { value: 2, text: "Residential" },
    ];
    public dataTableHeaders = [
        { text: "Branch Name", value: "branchName", width: "25%" },
        { text: "Primary User", value: "primaryUser" },
        { text: "Phone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Active", value: "enabled", width: "8%" },
    ];

    public dataTableItemKey = "branchName";
    public dataTableShowSelect = false;
    public dataTableLoading = false;
    public dataTableShowExpand = false;
    public hideDefaultFooter = true;
    public dataTableDisableSort = false;
    public pageState: PageState;
    public serverItemsLength: number | null = null;
    private _pagination: IVuetifyTableOptions = {
        sortDesc: [false],
        sortBy: ["branchName"],
        page: 1,
        itemsPerPage: 50,
    };

    public addContact = false;
    public addNew = false;
    public newContact: OrganizationContactEditViewModel = new OrganizationContactEditViewModel();
    public showDialog = true;
    public companyUsers: Array<ITextValueDisabled<number>> = [];
    public companyId: number | null = null;
    public orgId: number | null = null;
    public addOrganizationBranchViewModel: OrganizationBranchEditViewModel | null = null;
    public confirmViewModel: AsyncInteractionViewModel | null = null;

    public branchNameValidation = {
        valid: true,
        loading: false,
    };
    public nexusTaxMessageViewModel: NexusTaxMessageCardViewModel | null = null;

    public constructor() {
        super();
        this.pageState = new PageState(this.pagination);
    }

    public get showAddBranchDialog() {
        return this.addOrganizationBranchViewModel != null;
    }

    public get pagination(): IVuetifyTableOptions {
        return this._pagination;
    }

    public set pagination(value: IVuetifyTableOptions) {
        if (!this.isPaginationEquals(this._pagination, value)) {
            this._pagination = value;
        }
    }

    public async list(isSearch?: boolean): Promise<void> {
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/listadmindealerbranches?companyId=" + this.companyId;
        } else {
            if (this.companyId) {
                baseUrl = "/api/dealer/listorganizationbranches?companyId=" + this.companyId;
            } else {
                baseUrl = "/api/dealer/listdealerbranches";
            }
        }

        try {
            this.statusMessage = this.loadingMessage;
            this.loading = true;
            const results = (await WebHelper.getJsonData(baseUrl)) as ListDealerBranchesResults;
            if (results) {
                this.items = results.branches;
                this.companyUsers = results.companyUsers;
            }
        } finally {
            this.loading = false;
        }
    }

    public updateSameAsBranchInfo() {
        if (!this.currentItem) {
            return;
        }

        if (!this.sameAsBranchInfo) {
            const billingAddress = new Address();
            this.currentItem.billingAddress = billingAddress;
            this.currentItem.billingAddressType = null;
            this.currentItem.billingAddress.country = "USA";
            this.currentItem.billingPoBox = "";
            this.currentItem.billingPhone = "";
            this.currentItem.billingFax = "";
            this.currentItem.billingEmail = "";
        }

        if (this.isValid) {
            return;
        }
    }

    public edit(item: DealerBranchModel) {
        const originalItemText = Serializer.serialize(item);
        this.originalItem = Serializer.deserialize(originalItemText);
        this.isDetailViewVisible = true;
        this.currentItem = item;
        this.sameAsBranchInfo = !this.currentItem.billingAddress;
    }

    public cancelEdit() {
        if (this.originalItem && this.currentItem) {
            const index = this.items.indexOf(this.currentItem);
            this.currentItem = this.originalItem;
            this.items[index] = this.originalItem;
            this.originalItem = null;
        }
        this.isDetailViewVisible = false;
        this.addNew = false;
        this.addContact = false;
    }

    public async saveBranch(companyId: number | null): Promise<void> {
        const args = new DealerBranchSaveArgs();
        let baseUrl = "";
        if (this.isAdmin) {
            baseUrl = "/api/dealer/saveadmindealerbranch";
            args.companyId = companyId;
        } else {
            if (companyId) {
                baseUrl = "/api/dealer/saveorganizationbranch";
                args.companyId = companyId;
            } else {
                baseUrl = "/api/dealer/savedealerbranch";
            }
        }
        this.isDetailViewVisible = false;
        if (this.addNew && this.currentItem) {
            this.addNew = false;
        }
        try {
            this.statusMessage = this.savingMessage;
            this.loading = true;
            if (this.currentItem) {
                if (this.sameAsBranchInfo) {
                    this.currentItem.billingAddress = this.currentItem.address;
                    this.currentItem.billingAddressType = this.currentItem.addressType;
                    this.currentItem.billingPoBox = this.currentItem.poBox;
                    this.currentItem.billingPhone = this.currentItem.phone;
                    this.currentItem.billingFax = this.currentItem.fax;
                    this.currentItem.billingEmail = this.currentItem.email;
                }
                this.currentItem.phone = this.removePhoneFormatting(this.currentItem.phone);
                this.currentItem.billingPhone = this.removePhoneFormatting(this.currentItem.billingPhone);
                args.Model = this.currentItem;
                const serverResults = await WebHelper.postJsonData(baseUrl, args);
                const results = JSON.parse(serverResults);
                await this.list(true);

                if (results.showCheckNexusSettingsMessage || results.showDisabledTaxMessage) {
                    await this.showNexusMessage(results);
                }
            }
        } catch (error) {
            this.errorMessage = Utils.ServerErrorMessage;
        } finally {
            this.loading = false;
        }
    }

    public async addNewBranch() {
        if (this.area == NavigationArea.OrganizationDetails) {
            // use add dialog for adding organization branch
            const addOrganizationBranchViewModel = new OrganizationBranchEditViewModel();
            addOrganizationBranchViewModel.orgId = this.orgId!;
            this.addOrganizationBranchViewModel = addOrganizationBranchViewModel;
            const result = await this.addOrganizationBranchViewModel.edit();
            this.addOrganizationBranchViewModel = null;
            if (result?.canceled) {
                return;
            }
            this.loading = true;
            try {
                const newId = JSON.parse(
                    await WebHelper.postJsonData(
                        "/api/Organization/PostOrganizationBranchEditModel",
                        addOrganizationBranchViewModel.toModel()
                    )
                ) as number;
                if (newId > 0) {
                    this.successMessage = "Branch Added";
                }
            } finally {
                this.loading = false;
            }
            this.list(true);
        } // use edit view for adding dealer branch
        else {
            const branchModel = new DealerBranchModel();
            branchModel.address = new Address();
            branchModel.billingAddress = new Address();
            branchModel.address.country = "USA";
            branchModel.billingAddress.country = "USA";
            this.currentItem = branchModel;
            this.currentItem.enabled = true;
            this.sameAsBranchInfo = false;
            this.isDetailViewVisible = true;
            this.addNew = true;
        }
    }

    public validateBranchName() {
        const branchNameExist = this.items.some(
            (item) =>
                item !== this.currentItem &&
                item.branchName.toLowerCase() === this.currentItem?.branchName.toLowerCase()
        );
        this.branchNameValidation.valid = !branchNameExist;
    }

    public get branchNameErrors() {
        this.validateBranchName();
        if (this.currentItem && !this.currentItem.branchName) {
            return "Branch name is required";
        }
        return this.branchNameValidation.valid ? "" : `Branch Name is already in use`;
    }

    public get phoneErrors() {
        if (this.currentItem && !this.currentItem.phone) {
            return "Phone is required";
        }
        return "";
    }

    public get billingPhoneErrors() {
        if (this.currentItem && !this.currentItem.billingPhone) {
            return "Phone is required";
        }
        return "";
    }

    public get emailErrors() {
        if (this.currentItem && !this.currentItem.email) {
            return "Email is required";
        }
        return "";
    }

    public get billingEmailErrors() {
        if (this.currentItem && !this.currentItem.billingEmail) {
            return "Billing Email is required";
        }
        return "";
    }

    public removePhoneFormatting(phoneNumber: string): string {
        if (!phoneNumber) {
            return "";
        }
        return phoneNumber.replace(/[^0-9]/g, "");
    }

    public async showNexusMessage(results: IDealerBranchesSaveResults) {
        const vm = new NexusTaxMessageCardViewModel();
        if (results.showCheckNexusSettingsMessage) {
            vm.showCheckNexusSettingsMessage = results.showCheckNexusSettingsMessage;
            vm.dealerId = results.dealerId;
            this.nexusTaxMessageViewModel = vm;
            await vm.interact();
            this.nexusTaxMessageViewModel = null;
        } else if (results.showDisabledTaxMessage) {
            vm.showDisabledTaxMessage = results.showDisabledTaxMessage;
            vm.state = results.state;
            vm.dealerId = results.dealerId;
            this.nexusTaxMessageViewModel = vm;
            await vm.interact();
            this.nexusTaxMessageViewModel = null;
        }
    }

    public removeModelPhoneFormatting(model: DealerBranchModel) {
        if (model) {
            model.phone = this.removePhoneFormatting(model.phone);
            model.billingPhone = this.removePhoneFormatting(model.billingPhone);
        }
        return model;
    }

    public backButtonClicked() {
        const callback = () => {
            if (this.isDetailViewVisible) {
                this.cancelEdit();
            } else {
                window.location.href = UrlUtils.getVersionedLink(`/ng/list-organizations.html`);
            }
        };
        this.saveBeforeLeave(callback);
    }

    protected async saveBeforeLeave(callback: () => void) {
        if (this.hasChanges()) {
            this.confirmViewModel = new AsyncInteractionViewModel();
            const result = await this.confirmViewModel.interact();
            if (result === "cancel") {
                this.confirmViewModel = null;
                return;
            } else if (result === "accept") {
                await this.save();
            } else if (result === "continue") {
                this.confirmViewModel = null;
            }
        }
        callback();
    }

    public hasChanges() {
        if (!this.originalItem) {
            return false;
        }
        let currentItem = JSON.parse(JSON.stringify(this.currentItem));
        currentItem = this.removeModelPhoneFormatting(currentItem);
        const originalItem = this.removeModelPhoneFormatting(this.originalItem);
        return !_.isEqual(currentItem, originalItem);
    }
}
