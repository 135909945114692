














import { WebHelper } from "chipply-common";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import LongRunningOperationDialog from "@/components/utility/LongRunningOperationDialog.vue";
import StoreCreationOptions from "@/components/events/StoreCreationOptions.vue";
import ICreateEventArgs from "@/chipply/event/ICreateEventArgs";

@Component({
    components: { LongRunningOperationDialog, StoreCreationOptions },
    model: {
        prop: "value",
        event: "change",
    },
})
export default class EventAdder extends Vue {
    public name = "EventAdder";
    @Prop({ type: Boolean })
    public value!: boolean;
    @Prop({ type: String })
    public eventType!: string;
    public selectedEventType = "0";
    public showCopyingDialog = false;
    public showStoreOptionsDialog = false;
    public statusMessage = "";

    @Watch("eventType")
    protected onEventTypeChanged() {
        this.selectedEventType = this.eventType;
    }

    @Watch("value")
    protected async onShowChanged(nv: boolean) {
        if (!nv) {
            return;
        }
        this.showStoreOptionsDialog = true;
    }

    private async addStore(args: { storeName: string; eventType: number; accepted: boolean }) {
        const newStoreName = args.storeName;
        const newEventType = Number(args.eventType);

        this.showStoreOptionsDialog = false;

        if (!args.accepted) {
            this.$emit("change", false);
            return;
        }

        const addArgs: ICreateEventArgs = {
            eventName: newStoreName,
            eventType: newEventType,
        };

        this.showCopyingDialog = true;
        this.statusMessage = `Creating Store ${newStoreName}`;
        const results = await WebHelper.postJsonData("/api/Events/CreateEvent", addArgs);
        this.statusMessage = "Store Creation Completed!";
        document.location.href = "/ng/settings.html?eventid=" + results;
    }
}
