import EditableViewModel from "@/chipply/EditableViewModel";
import IOrganizationEditModel from "@/chipply/organization/IOrganizationEditModel";
import { Address, IAddress } from "chipply-common";

export default class OrganizationEditViewModel extends EditableViewModel {
    public orgId = 0;
    public organizationName = "";

    public address: IAddress = new Address();

    public constructor(model?: IOrganizationEditModel | null) {
        super();
        if (model) {
            this.orgId = model.orgId;
            this.organizationName = model.orgName;
            this.address = model.address;
        }
    }

    public toModel(): IOrganizationEditModel {
        return {
            address: new Address(this.address!),
            orgId: this.orgId,
            orgName: this.organizationName,
        } as IOrganizationEditModel;
    }
}
