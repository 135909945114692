import IAsyncEventArgs from "@/chipply/event/IAsyncEventArgs";
import { EventBus } from "../EventBus";
import IMenuData from "../IMenuData";
import { IVendorDto, VendorDto } from "@/chipply/vendor/VendorDto";
import FilterViewModel from "./FilterViewModel";

export abstract class PageViewModel {
    protected readonly savingMessage = "Saving...";
    protected readonly loadingMessage = "Loading...";
    protected readonly deletingMessage = "deleting...";
    protected readonly loadingErrorMessage = "An error occurred while loading the data.";

    public isValid = false;

    // Not bound, informational only
    public isNavigationSideExpanded = true;
    public isOptionsDrawerVisible = false;
    public loading = true;
    public statusMessage: string | null = this.loadingMessage;
    public errorMessage: string | null = null;
    public successMessage: string | null = null;
    public showErrorMessage = false;
    public showSuccessMessage = false;

    public filtersViewModel: FilterViewModel = new FilterViewModel();

    public get isModified() {
        return this._isModified;
    }

    public set isModified(value) {
        this._isModified = value;
    }

    public menuData: IMenuData = { area: 0 };
    public init = true;
    public area = 0;

    private _isModified = false;

    protected hasChanges() {
        return false;
    }

    public constructor() {
        EventBus.$on("menu-data-complete", (menuData: IMenuData) => {
            this.menuData = menuData;
            this.area = (<any>menuData).area;
            this.init = true;
        });
    }

    protected save(): Promise<boolean> {
        throw new Error("Save is not implemented");
    }

    protected startHandlePageNavigation() {
        // ignore. Override in child class
    }

    protected endHandlePageNavigation() {
        // ignore. Override in child class
    }

    protected handlePageNavigation = (location: string, eventArgs: IAsyncEventArgs) => {
        if (this.hasChanges()) {
            const promise = new Promise(async (resolve, reject) => {
                let canceled = false;
                try {
                    this.startHandlePageNavigation();
                    const saveResult = await this.save();
                    canceled = !saveResult;
                    eventArgs.cancel = canceled;
                    resolve(canceled);
                } catch (e) {
                    reject(e);
                } finally {
                    this.endHandlePageNavigation();
                }
            });
            eventArgs.promises.push(promise);
        }
    };

    protected dispose() {
        //to be override in child page
    }
}
