import SpreadsheetProductViewModel from "../view-model/SpreadsheetProductViewModel";
import ITreeItem from "../interface/i-tree-item";
import { IProductSpreadsheetFilters } from "./IProductSpreadsheetFilters";
import ProductsSpreadsheetHelper from "./ProductsSpreadsheetHelper";
import { ProductSearchModeSelectorViewModel } from "./add/ProductSearchModeSelectorViewModel";
import { ProductSearchGeneralProductsMode, ProductSearchMode } from "./add/ProductSearchMode";
import { SimpleAsyncInteractionViewModel } from "chipply-common";
import IProcessCost from "../process/IProcessCost";

export default class ProductsSelectorViewModel {
    public onlyOrderedProducts!: boolean;
    public isHeaderVisible = true;
    public isFiltersVisible = true;
    public searchModeSelectorViewModel!: ProductSearchModeSelectorViewModel;
    public isSelectAll = false;
    public supressRefreashOnEventIdChanged = false;
    public clearSelectedIdsWhenSearch = false;
    public excludeStoreId = 0;
    public catalogBatchId = 0;

    public isSelectingNextPage = false;
    public wasLastPageSelected = false;
    public allowSelectAllInTileView = false;

    // When selected product count is great thant the limitation, user won't be able to add products.
    // Currently the only user case is in product adder, the browser will become very stuck when there are more than 2000 images loaded.
    // But in other pages such as Promo Code and Sorting Package page, we shoudn't have this limitation.
    // There are stores who have more than 6000 products and there is no problem to select all in  Promo Code and Sorting Package pages, and we should allow user to select all in these pages.
    public productsToAddCountLimt = 0;
    public showProuctAddConfirmDialog = false;
    public productAddConfirmViewModel: SimpleAsyncInteractionViewModel | null = null;
    public showProductCountExceedLimitWarning = false;
    public multipleSelect = false;

    public get searchMode() {
        return this.searchModeSelectorViewModel.searchMode;
    }

    public get filters() {
        return this.searchMode.filters;
    }

    public get useFeedProducts() {
        return this.searchMode.useFeedProducts;
    }

    public get useChipplyTemplateDealer() {
        return this.searchMode.useChipplyTemplateDealer;
    }

    public get dealerId() {
        return [ProductSearchMode.DealerTemplateProducts, ProductSearchMode.DealerProducts].indexOf(
            this.searchMode.name
        ) > -1
            ? this.filters.dealerId
            : null;
    }

    public get pages() {
        if (this.pageSize == null || this.totalItems == null) {
            return 1;
        }

        const pages = Math.ceil(this.totalItems / this.pageSize);
        return pages > 0 ? pages : 1;
    }

    public get useExactMatch() {
        return (
            this.filters &&
            this.filters.exactMatch &&
            this.filters.styleNameKeywords &&
            this.filters.styleNameKeywords.length
        );
    }

    public get pageSize() {
        return this.useExactMatch ? 2000 : this.filters.pageSize;
    }

    public get shouldPreselectAllAfterSearch(): boolean {
        if (this.searchMode.name === ProductSearchMode.GeneralProducts) {
            return false;
        }

        const isTemplateSelected =
            (this.searchMode.name === ProductSearchMode.ChipplyTemplateProducts ||
                this.searchMode.name === ProductSearchMode.DealerTemplateProducts) &&
            this.filters.templateId &&
            this.filters.templateId > 0;
        const isExactMatch =
            this.filters.exactMatch &&
            this.filters.styleNameKeywords !== null &&
            this.filters.styleNameKeywords !== undefined &&
            this.filters.styleNameKeywords.trim().length > 0;

        return isTemplateSelected || isExactMatch;
    }

    public loading = true;
    public items: SpreadsheetProductViewModel[] = [];
    public disabledIds!: number[];
    public get disabledIdsLength() {
        return (this.disabledIds || []).length;
    }
    public initialSelectedIds!: number[];
    public selectedIds: number[] = this.initialSelectedIds || [];
    public get selectedItemsCount() {
        if (this.isSelectAll && this.totalItems) {
            return this.totalItems - this.disabledIdsLength;
        }

        return this.selectedIds.length;
    }
    public allProductIds: number[] = [];

    public showAdditionalFiltersDialog = false;
    public hasCategories = false;
    public headers: any[] = [];
    public itemCategories: ITreeItem[] = [];

    public totalItems: number | null = null;
    public loadingMessage: string | null = null;
    public suppressPageChangeCallback!: boolean;
    public errorMessage = "";
    public errorReportOpened = false;
    public showNoDataWarning = false;
    public noDataWarningMessage = "";

    public dialog = false;
    public prodId: number | null = null;

    protected getItemIdPath(): string {
        return "eventProductId";
    }

    public constructor() {
        this.searchModeSelectorViewModel = new ProductSearchModeSelectorViewModel();
        this.searchModeSelectorViewModel.searchMode = new ProductSearchGeneralProductsMode<string, boolean>();
        this.searchModeSelectorViewModel.searchMode.isSelected = true;
        this.searchModeSelectorViewModel.isShowAllModes = false;
        this.searchModeSelectorViewModel.allSearchModes = [this.searchModeSelectorViewModel.searchMode];
    }

    public initialize(
        initialSelectedIds: number[],
        disabledIds: number[],
        onlyOrderedProducts: boolean,
        eventId: number,
        catalogBatchId: number,
        multipleSelect: boolean
    ) {
        this.initialSelectedIds = initialSelectedIds;
        this.disabledIds = disabledIds;
        this.onlyOrderedProducts = onlyOrderedProducts;
        this.multipleSelect = multipleSelect;
        this.catalogBatchId = catalogBatchId;

        const idsCopy = [];
        if (initialSelectedIds) {
            idsCopy.push(...initialSelectedIds);
        }
        this.selectedIds = idsCopy;
        this.searchMode.calcStoreId = eventId;
        this.filters.pageNumber = 1;
        this.loadData();
    }

    public async applyFilters() {
        if (this.clearSelectedIdsWhenSearch) {
            this.selectedIds = [];
        }
        this.suppressPageChangeCallback = true;
        this.filters.pageNumber = 1;

        await this.loadData();
        this.suppressPageChangeCallback = false;
    }

    public async sortByChanged() {
        this.suppressPageChangeCallback = true;
        this.filters.pageNumber = 1;
        await this.loadData();
        this.suppressPageChangeCallback = false;
    }

    protected startLoading() {
        this.loading = true;
        this.showNoDataWarning = false;
    }

    protected doneLoading() {
        this.loading = false;
    }

    protected buildFilters(): IProductSpreadsheetFilters {
        const searchMode = this.searchMode;
        const sourceFilters = this.filters;
        return {
            styleNameKeywords: sourceFilters.styleNameKeywords,
            vendorIds: sourceFilters.vendorIds,
            categoryIds: searchMode.isTemplateMode ? sourceFilters.templateCategoryIds : sourceFilters.storeCategoryIds,
            processIds: sourceFilters.processId ? [sourceFilters.processId] : [],
            minProdPrice: null,
            maxProdPrice: null,
            taxType: sourceFilters.taxType,
            weights: [],
            onlyOrderedProducts: this.onlyOrderedProducts,
            exactMatch: sourceFilters.exactMatch,
            dealerId: this.dealerId,
            searchMode: this.searchMode.id,
            isEnabled: true,
            organizationId: sourceFilters.organizationId,
            excludeStoreId: this.searchMode.name == ProductSearchMode.DealerProducts ? this.excludeStoreId : undefined,
        };
    }

    protected getPageLoadingText() {
        if (this.useExactMatch) {
            return "Loading Products";
        }
        return (
            `Loading Products ${(this.filters.pageNumber - 1) * this.pageSize + 1} to ` +
            this.filters.pageNumber * this.pageSize
        );
    }

    public async onEventIdChanged(newEventId: number) {
        this.searchMode.calcStoreId = newEventId;
        if (!this.supressRefreashOnEventIdChanged) {
            this.filters.pageNumber = 1;
            await this.loadData();
        }
    }

    public get eventId() {
        return this.searchMode.calcStoreId;
    }

    protected async loadData() {
        this.startLoading();
        try {
            this.loadingMessage = this.getPageLoadingText();
            await this.refreshSpreadsheetData();
        } catch {
            this.errorMessage = "An error ocurred while loading the data.";
            this.errorReportOpened = true;
        } finally {
            this.doneLoading();
        }
    }

    public async selectNextPage(): Promise<SpreadsheetProductViewModel[]> {
        this.isSelectingNextPage = true;
        this.startLoading();
        try {
            this.loadingMessage = this.getPageLoadingText();
            const filters = this.buildFilters();
            const results = await ProductsSpreadsheetHelper.searchProduct(
                this.searchMode.calcStoreId,
                this.catalogBatchId,
                this.filters.pageNumber,
                this.pageSize,
                filters,
                this.filters.sortBy,
                false,
                false
            );
            const tempArray = [];
            for (const product of results.products) {
                //Get rid of blank products
                const vm = new SpreadsheetProductViewModel(
                    product,
                    this.searchModeSelectorViewModel.storeCategories,
                    this.searchModeSelectorViewModel.processCosts,
                    this.searchModeSelectorViewModel.processes
                );
                tempArray.push(vm);
            }

            this.items.push(...tempArray);
            this.setSelectedItemsForTheNextPage(tempArray);

            if (results.products.length >= this.pageSize) {
                this.filters.pageNumber++;
                this.wasLastPageSelected = false;
            } else {
                this.wasLastPageSelected = true;
            }

            return tempArray;
        } catch {
            this.errorMessage = "An error ocurred while loading the data.";
            this.errorReportOpened = true;
            return [];
        } finally {
            this.isSelectingNextPage = false;
            this.doneLoading();
        }
    }

    protected async refreshSpreadsheetData() {
        const shouldReturnAllProductIds =
            this.multipleSelect &&
            (this.searchMode.name === ProductSearchMode.GeneralProducts ||
                this.shouldPreselectAllAfterSearch ||
                this.allowSelectAllInTileView);
        const filters = this.buildFilters();
        this.items = [];
        this.lastCheckedRow = null;
        this.showNoDataWarning = false;

        const promiseResults = await Promise.all([
            ProductsSpreadsheetHelper.getSpreadsheetMetaData(this.searchMode.calcStoreId),
            ProductsSpreadsheetHelper.searchProduct(
                this.searchMode.calcStoreId,
                this.catalogBatchId,
                this.filters.pageNumber,
                this.pageSize,
                filters,
                this.filters.sortBy,
                shouldReturnAllProductIds,
                false
            ),
        ]);

        const metaDataResults = promiseResults[0];
        const dataResults = promiseResults[1];

        this.totalItems = dataResults.totalProducts;
        this.hasCategories = metaDataResults.categories.length > 0;
        this.searchModeSelectorViewModel.storeCategories = metaDataResults.categories;
        if (this.hasCategories) {
            const pickerCategories: ITreeItem[] = [];
            pickerCategories.push(...metaDataResults.categories);
            pickerCategories.splice(0, 1);
            this.itemCategories = pickerCategories;
        } else {
            this.itemCategories = [];
        }
        this.searchModeSelectorViewModel.processes = metaDataResults.processes;
        this.searchModeSelectorViewModel.processCosts = metaDataResults.processCosts;

        this.buildHeaders();

        const tempArray = [];
        for (const product of dataResults.products) {
            //Get rid of blank products
            const vm = new SpreadsheetProductViewModel(
                product,
                metaDataResults.categories,
                metaDataResults.processCosts,
                metaDataResults.processes
            );
            tempArray.push(vm);
        }

        this.items = tempArray;
        if (shouldReturnAllProductIds) {
            const allProductIds = dataResults.allProductIds;
            this.allProductIds = [...allProductIds];
            if (this.shouldPreselectAllAfterSearch) {
                this.selectedIds = [...allProductIds];
            }
        }

        this.resetSelectedItems();

        // set no data warnings
        if (this.items.length === 0) {
            this.showNoDataWarning = true;
            this.noDataWarningMessage =
                "Your search resulted in 0 matches. Please select a vendor or refine your search and refresh the list.";
        } else if (dataResults.unfoundStyles.length > 0) {
            this.showNoDataWarning = true;
            this.noDataWarningMessage = "Styles not found: " + dataResults.unfoundStyles.join(", ") + ".";
        }

        if (dataResults.products.length >= this.pageSize) {
            this.filters.pageNumber++;
            this.wasLastPageSelected = false;
        } else {
            this.wasLastPageSelected = true;
        }
    }

    public showInfo(productId: number) {
        this.prodId = productId;
        this.dialog = true;
    }

    public lastCheckedRow: SpreadsheetProductViewModel | null = null;

    public rowCheckboxClick(evt: PointerEvent, item: SpreadsheetProductViewModel) {
        if (!this.multipleSelect) {
            return;
        }
        this.insertOrRemoveSelectedId(item);

        if (item.isSelected) {
            if (!this.lastCheckedRow) {
                this.lastCheckedRow = item;
                this.setSelectAllStatus();
                return;
            }

            if (evt.shiftKey) {
                let start = this.items.indexOf(this.lastCheckedRow);
                let end = this.items.indexOf(item);
                if (start > -1 && end > -1) {
                    if (start > end) {
                        [start, end] = [end, start];
                    }
                    for (let i = start + 1; i < end; i++) {
                        this.items[i].isSelected = true;
                        this.insertOrRemoveSelectedId(this.items[i]);
                    }

                    const selection = window.getSelection();
                    if (selection) {
                        selection.empty();
                    }
                }
            }
            this.lastCheckedRow = item;
        }

        this.setSelectAllStatus();
    }

    public check(item: SpreadsheetProductViewModel) {
        this.insertOrRemoveSelectedId(item);
        this.setSelectAllStatus();
    }

    public resetSelectedItems() {
        for (const item of this.items) {
            item.isSelected = this.selectedIds.indexOf(item.eventProductId) > -1;
        }
        this.setSelectAllStatus();
    }

    public setSelectedItemsForTheNextPage(itemsInTheNextPage: SpreadsheetProductViewModel[]) {
        if (this.isSelectAll) {
            for (const item of itemsInTheNextPage) {
                if (!this.isDisabled(item.eventProductId)) {
                    item.isSelected = true;
                    if (!this.selectedIds.includes(item.eventProductId)) {
                        this.selectedIds.push(item.eventProductId);
                    }
                }
            }
        } else {
            for (const item of itemsInTheNextPage) {
                item.isSelected = this.selectedIds.indexOf(item.eventProductId) > -1;
            }
        }
    }

    public toggleSelectAll() {
        this.isSelectAll = !this.isSelectAll;
        this.checkAllChanged();
    }

    public checkAllChanged() {
        if (this.isSelectAll) {
            if (this.allProductIds.length > 0) {
                this.selectedIds = this.allProductIds.filter((id) => !this.isDisabled(id));
            } else {
                this.selectedIds = this.items
                    .filter((item) => !this.isDisabled(item.eventProductId))
                    .map((item) => item.eventProductId);
            }
        } else {
            this.selectedIds = [];
        }
        for (const item of this.items) {
            if (!this.isDisabled(item.eventProductId)) {
                item.isSelected = this.isSelectAll;
            }
        }
    }

    public isDisabled(id: number) {
        return (this.disabledIds || []).indexOf(id) > -1;
    }

    protected insertOrRemoveSelectedId(item: SpreadsheetProductViewModel) {
        if (item.isSelected) {
            if (this.selectedIds.indexOf(item.eventProductId) == -1) {
                this.selectedIds.push(item.eventProductId);
            }
        } else {
            const removalIndex = this.selectedIds.indexOf(item.eventProductId);
            this.selectedIds.splice(removalIndex, 1);
        }
    }

    protected setSelectAllStatus() {
        const totalCount = this.totalItems || 0;
        this.isSelectAll = this.selectedIds.length === totalCount - this.disabledIdsLength;
    }

    public async raiseProductSelectedDialog() {
        const vm = new SimpleAsyncInteractionViewModel();
        this.showProductCountExceedLimitWarning =
            this.productsToAddCountLimt > 0 && this.selectedItemsCount > this.productsToAddCountLimt;
        vm.headerText = this.showProductCountExceedLimitWarning
            ? `Warning: ${this.selectedItemsCount} Products Selected`
            : "Add Products";

        vm.text = this.showProductCountExceedLimitWarning
            ? `Please tweak the filter settings to cut down the number of products to be added at one time. Currently, only ${this.productsToAddCountLimt} products can be added in a single batch at most.`
            : `You are about to add ${this.selectedItemsCount} products. Are you sure you want to continue?`;

        this.productAddConfirmViewModel = vm;
        this.showProuctAddConfirmDialog = true;
        const result = await vm.interact();
        this.showProuctAddConfirmDialog = false;
        this.productAddConfirmViewModel = null;
        return result === "accept";
    }

    protected buildHeaders() {
        this.headers = [
            { sortable: false, text: " ", value: "image" },
            {
                class: ["checkboxTableHeader", "noPadding"],
                sortable: false,
                text: "Active",
                value: "active",
            },
            {
                class: ["sortOrderTableHeader"],
                sortable: false,
                text: "Sort",
                width: "50px",
                value: "sort",
            },
            {
                class: ["noPadding"],
                sortable: false,
                text: "Product Name",
                value: "productname",
            },
            {
                class: "vendorStyleTableHeader",
                sortable: false,
                text: "Vendor / Style",
                value: "vendorstyle",
            },
            { sortable: false, text: "Process", value: "process" },
        ];

        if (this.hasCategories) {
            this.headers.push({
                sortable: false,
                text: "Categories",
                class: "psCategoryHeader",
                width: "200px",
                value: "categories",
            });
        }

        this.headers.push(
            ...[
                {
                    align: "center",
                    class: ["moneyTableHeader", "psNoPadding"],
                    sortable: false,
                    text: "Sell Price",
                    width: "50px",
                    value: "sellprice",
                },
            ]
        );
    }
}
