import { EventType } from "@/chipply/event/EventType";

export default class StoreListFilters {
    public $type = "Chipply.Service.Events.StoreListFilters, Chipply.Service";
    public organizationId?: number;
    public salesRepId?: number;
    public openDate?: Date;
    public closeDate?: Date;
    public storeName?: string;
    public saleOrder?: string;
    public statuses: string[] = [];
    public storeType?: EventType;
    public hideDeleted = true;
    public isChipply = false;
    public showPurchasingItems = false;
}
