import { typeHint } from "chipply-common";

@typeHint({ typeHint: "StoresMenuData" })
export class StoresMenuData {
    myClosedStatusCount = 0;
    myRequestorCount = 0;
    closedStatusCount = 0;
    requestorCount = 0;
    salesRepId = 0;
}
